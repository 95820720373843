import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

import { FormControl } from '@angular/forms';
import { CustomerChain } from '@shared/models/customer-location-chain';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { CustomerLocation } from '@shared/models/customer-location';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'app-autocomplete-searchable-elements',
  templateUrl: './autocomplete-searchable-elements.component.html',
  styleUrls: ['./autocomplete-searchable-elements.component.scss']
})
export class AutocompleteSearchableElementsComponent implements OnInit, OnChanges {
  @ViewChild('matAutocompleteInput') public matAutocompleteInput: MatInput;
  @ViewChild('autocompleteInput') public autocompleteInput: HTMLInputElement;
  @Input() customerLocationChainsFilterOptions: CustomerChain[] | CustomerLocation[];
  @Input() countriesFilterOptions: string[];
  @Input() type: string;
  @Input() name: string;
  @Input() classList = "";
  @Input() inputDisabled: boolean;
  @Output() storeSearchableElements: EventEmitter<string> = new EventEmitter<string>();
  searchableElementsControl = new FormControl('');
  public customChainsSearchOptions: any;
  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    console.log('CHANGES => ', changes);
    if (this.type !== 'c') {
      this.customerLocationChainsFilterOptions = changes.customerLocationChainsFilterOptions.currentValue;
      this.customChainsSearchOptions = changes.customerLocationChainsFilterOptions.currentValue;
    } else {
      this.countriesFilterOptions = changes.countriesFilterOptions.currentValue;
      this.customChainsSearchOptions = changes.countriesFilterOptions.currentValue;
    }
    this.inputDisabled = changes.inputDisabled ? changes.inputDisabled.currentValue : false;
  }
  public async ngOnInit(): Promise<void> {
    this.searchableElementsControl.valueChanges.subscribe((term: string) => {
      if (this.type !== 'c') {
        this.customChainsSearchOptions = this.customerLocationChainsFilterOptions?.filter(ccp => {
          return ccp.name.toLowerCase().includes(term.toLowerCase());
        });
      } else {
        this.customChainsSearchOptions = this.countriesFilterOptions?.filter(ccp => {
          return ccp.toLowerCase().includes(term.toLowerCase());
        });
      }
    });
  }
  public selectedACStoreChainFilter(storeChain: any, auto: MatAutocomplete): void {
    this.storeSearchableElements.emit(storeChain.option.value);
    this.searchableElementsControl.setValue('');
    this.matAutocompleteInput.focused = false;
    (this.autocompleteInput as any).nativeElement.blur();
  }
}
