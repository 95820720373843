import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router} from '@angular/router';

import { LanguageService } from '../services/language.service';
import {TokenSessionService} from "@shared/services/token.session.service";
import {Config} from "../../config";

@Injectable({ providedIn: 'root' })
export class UsersLoginResolver implements Resolve<void> {
  constructor(private tokenService: TokenSessionService, private languageService: LanguageService, private router: Router) {}
  public defaultLanguage = localStorage.getItem('defaultLanguage') ? localStorage.getItem('defaultLanguage') : Config.defaultLanguage;
  public async resolve(route: ActivatedRouteSnapshot): Promise<void> {
    if (this.tokenService.isAuthenticated())
      await this.router.navigateByUrl('/' + this.defaultLanguage + '/dashboard');
  }
}
