import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { debounceTime } from 'rxjs/operators';

import { User } from '@users/domain/models/user';
import { UserListService } from '@users/components/user-list/user-list.service';
import { EditUserFromFrontendInput } from '@users/components/user-list/edit-user-from-frontend.input';
import { CompanyType, GeneralCompany } from '@shared/services/general-company.interface';
import { PagedResult } from '@shared/services/paged-result';
import { Role, RoleAllowedCompanyType } from '@users/domain/models/role';
import { RoleBusinessId } from '@users/domain/models/role-business-id.enum';
import { UtilsService } from '@shared/services/utils.service';
import { UserRole } from '@users/domain/models/user-role';
import { CustomerChain } from '@shared/models/customer-location-chain';
import { CustomerLocation } from '@shared/models/customer-location';
import { InstallerChain } from '@shared/models/installer-chain';
import { Installer } from '@shared/models/installer';
import { UserRoleUpdateInput } from '@users/components/user-list/user-role.update.input';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '@shared/services/language.service';
import { UserService } from '@users/domain/services/user.service';
import { CountriesService } from '@shared/services/countries.service';
import { Countries } from '@shared/models/countries';
import { UserMigration } from '../../../../landing-page/domain/user-migration';
import { LandingPageService } from '../../../../landing-page/domain/services/landing-page.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Permission } from '@users/domain/services/user.enums';

const USER_STORE_DATA = [
  {
    companyName: 'Carrefour Market Briançon',
    role: 'Responsable magasin',
    alarms: 'all'
  },
  {
    companyName: 'Carrefour Market Briançon',
    role: 'Assistant Responsable National',
    alarms: 'none'
  },
  {
    companyName: 'Carrefour Market Briançon',
    role: 'boss',
    alarms: 'all'
  }
];

@Component({
  selector: 'app-user-edit-dialog',
  templateUrl: './user-edit-dialog.component.html',
  styleUrls: ['./user-edit-dialog.component.scss']
})
export class UserEditDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<UserEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { user: User, isMigrator: boolean },
    private userListService: UserListService,
    private utilsService: UtilsService,
    public changeDetectorRef: ChangeDetectorRef,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private userService: UserService,
    private countriesService: CountriesService,
    private landingPageService: LandingPageService,
    private snackbar: MatSnackBar
  ) {

  }

  get customerLocationAlarmsDisabledFormArray(): FormArray{
    return this.userForm.get('customerLocationAlarmsDisabled') as FormArray;
  }
  get useFilteredAlarmsFormControl(): FormControl{
    return this.userForm.get('useFilteredAlarms') as FormControl;
  }
  public crtLang: string;
  public userStoreDataSource = USER_STORE_DATA;
  public userStoreDisplayedColumns: string[] = ['companyName', 'role', 'actions'];
  public editedUser: User;
  public userForm: FormGroup;
  public userRolesForm: FormGroup;
  public foundCompaniesForEditUser: GeneralCompany[];
  public availableRoles: PagedResult<Role>;
  public dataReadyToRender = false;
  private cachePossibleRolesForCompany: Map<Role, Role[]> = new Map();
  public userMigration: UserMigration[];
  public validationErrors: {
    userFormNotValid: boolean;
    minimumOneUserRole: boolean;
  } = {
    userFormNotValid: false,
    minimumOneUserRole: false
  };
  public trInstant: (key: string | Array<string>, interpolateParams?: Object) => string | any;
  public rolesTabErrorMessage = '';
  public userDataTabErrorMessage = '';
  public submitToServerTried = false;
  public errorWhenSaving = false;
  public countriesCodes: Countries[];
  public selectedCountryCode: Countries;
  public isMigrator = false;
  public userMigrationRoles: Role[];
  public columnsUserMigration = ['firstName', 'lastName', 'company', 'email', 'phone', 'role'];

  hide = true;
  token = '';
  public createAdminCtrl: FormControl = new FormControl<boolean>(false);

  public Permission = Permission;

  async ngOnInit(): Promise<void> {
    this.crtLang = this.languageService.currentLanguage;
    this.trInstant = this.translateService.instant;
    this.trInstant = this.trInstant.bind(this.translateService);

    const allCountries = await this.countriesService.getAllCountries();
    this.selectedCountryCode = allCountries.filter(c => c.code === 33)[0];
    this.countriesCodes = allCountries;
    let editedUser: User = null;
    if (this.data.user) {
      editedUser = Object.assign(new User({} as any), this.data.user);
      if (this.data.user.roles) {
        editedUser.roles = this.data.user.roles.map((roleData: any) => {
          return new Role(roleData);
        });
      }
    } else {
      editedUser = new User({} as any);
    }
    this.editedUser = editedUser;
    if (this.data.isMigrator) {
      this.userMigration = [];
      const roles = await this.landingPageService.retrieveRolesList();
      const currentUserMigration = (await this.landingPageService.retrieveUserMigrationWithEmail(editedUser.email));
      this.userMigration.push(currentUserMigration);
      const userMigrationRoles: Role[] = [];
      currentUserMigration.role.forEach(r => {
        userMigrationRoles.push(roles.filter(f => f._id === r)[0]);
      });
      this.userMigrationRoles = userMigrationRoles;
      this.isMigrator = this.data.isMigrator;
    }
    console.log('UserEditDialogComponent ngOnInit this.editedUser', this.editedUser);
    this.availableRoles = await this.userListService.getUsersFilterRoles();
    console.log('UserEditDialogComponent ngOnInit this.availableRoles', this.availableRoles);

    if (this.data.user === null) {
      this.buildUserForm();
      this.buildUserRolesForm();
      this.dataReadyToRender = true;
    } else {
      this.createAdminCtrl.patchValue(this.editedUser.roles.some((role) => role.uniqueBusinessId === RoleBusinessId.MATELEX_ADMIN));
      this.userService.getUserToken(this.data.user.id).subscribe(
        res => {
          this.token = res;
          this.buildUserForm();
          this.buildUserRolesForm();
          this.dataReadyToRender = true;
        },
        err => console.log('SideBarComponent : Error in fetching token', err)
      );
    }
  }

  private buildUserForm(): void {
    if (this.token !== '') {
      this.userForm = new FormGroup({
        firstName: new FormControl({ value: this.editedUser.firstName, disabled: false }, [
          Validators.required,
          Validators.min(1),
          Validators.max(30)
        ]),
        lastName: new FormControl({ value: this.editedUser.lastName, disabled: false }, [
          Validators.required,
          Validators.min(1),
          Validators.max(30)
        ]),
        indicatif: new FormControl({value: this.selectedCountryCode.countryISO, disabled: false}),
        phone: new FormControl({ value: this.editedUser.phone, disabled: false }),
        email: new FormControl({ value: this.editedUser.email, disabled: false }, [Validators.required, Validators.email]),
        userToken: new FormControl({ value: this.token, disabled: true }),
        useFilteredAlarms: new FormControl(!!this.editedUser.useFilteredAlarms),
        customerLocationAlarmsDisabled: new FormArray(this.editedUser.customerLocationAlarmsDisabled?.map((customerLocation) => new FormControl(customerLocation)) ?? [])
      });
    } else {
      this.userForm = new FormGroup({
        firstName: new FormControl({ value: '', disabled: false }, [
          Validators.required,
          Validators.min(1),
          Validators.max(30)
        ]),
        lastName: new FormControl({ value: '', disabled: false }, [
          Validators.required,
          Validators.min(1),
          Validators.max(30)
        ]),
        indicatif: new FormControl({value: this.selectedCountryCode.countryISO, disabled: false}),
        phone: new FormControl({ value: '', disabled: false }),
        email: new FormControl({ value: '', disabled: false }, [Validators.required, Validators.email]),
        useFilteredAlarms: new FormControl(true),
        customerLocationAlarmsDisabled: new FormArray([])
      });
    }
  }

  private buildUserRolesForm(): void {
    this.userRolesForm = new FormGroup({
      name: new FormControl({ value: '', disabled: false }, Validators.required)
    });

    this.userRolesForm.controls.name.valueChanges.pipe(debounceTime(1000)).subscribe((name: string) => {
      console.log('UserEditDialogComponent buildUserRolesForm name.valueChanges name', name);
      this.searchCompaniesForEditUser(name);
    });
  }

  private async searchCompaniesForEditUser(name: string): Promise<void> {
    if (typeof name !== 'string' && (name as GeneralCompany).name) {
      name = (name as GeneralCompany).name;
    } else if (typeof name !== 'string') {
      name = '';
    }

    const result: PagedResult<GeneralCompany> = await this.userListService.searchCompaniesForEditUser(name);
    this.foundCompaniesForEditUser = result.itemsPage;

    console.log('UserEditDialogComponent searchCompaniesForEditUser this.foundCompaniesForEditUser', this.foundCompaniesForEditUser);
  }

  public companyAutoCompleteDisplayForSelectedCompany(selectedCompany: GeneralCompany) {
    return selectedCompany && selectedCompany.name ? selectedCompany.name : '';
  }

  public getPossibleRolesForCompany(companyRole: Role): Role[] {
    if (this.cachePossibleRolesForCompany.has(companyRole)) {
      return this.cachePossibleRolesForCompany.get(companyRole);
    }

    const possibleRolesForCompany: Role[] = this.availableRoles.itemsPage.filter((role: Role) => {
      if (companyRole.userRole.customerChain && role.allowedCompanyTypes?.includes(RoleAllowedCompanyType.CustomerChain)) {
        return true;
      } else if (companyRole.userRole.customerLocation && role.allowedCompanyTypes?.includes(RoleAllowedCompanyType.CustomerLocation)) {
        return true;
      } else if (companyRole.userRole.installerChain && role.allowedCompanyTypes?.includes(RoleAllowedCompanyType.InstallerChain)) {
        return true;
      } else if (companyRole.userRole.installer && role.allowedCompanyTypes?.includes(RoleAllowedCompanyType.Installer)) {
        return true;
      }

      return false;
    });

    this.cachePossibleRolesForCompany.set(companyRole, possibleRolesForCompany);
    return possibleRolesForCompany;
  }

  public onChangePossibleRoleForCompany($eventNewRoleIdForCompany: any, prevRoleForCompany: Role, prevRoleForCompanyIndex: number): void {
    console.log('UserEditDialogComponent onChangePossibleRoleForCompany $eventNewRoleIdForCompany', $eventNewRoleIdForCompany);
    console.log('UserEditDialogComponent onChangePossibleRoleForCompany prevRoleForCompany', prevRoleForCompany);
    console.log('UserEditDialogComponent onChangePossibleRoleForCompany prevRoleForCompanyIndex', prevRoleForCompanyIndex);

    const newRoleIdForCompany: string = $eventNewRoleIdForCompany.value;
    const newRoleForCompany: Role = this.utilsService.deepCopyRole(
      this.availableRoles.itemsPage.find((availableRole: Role) => {
        return availableRole._id === newRoleIdForCompany;
      })
    );
    console.log('UserEditDialogComponent onChangePossibleRoleForCompany newRoleForCompany', newRoleForCompany);

    // (START) create the new userRole
    const newUserRole: UserRole = new UserRole({} as any);
    newUserRole.user = this.editedUser._id;
    newUserRole.role = newRoleForCompany._id as any;
    // copy the company from previous role
    if (prevRoleForCompany.userRole.customerChain) {
      newUserRole.customerChain = prevRoleForCompany.userRole.customerChain;
    } else if (prevRoleForCompany.userRole.customerLocation) {
      newUserRole.customerLocation = prevRoleForCompany.userRole.customerLocation;
    } else if (prevRoleForCompany.userRole.installerChain) {
      newUserRole.installerChain = prevRoleForCompany.userRole.installerChain;
    } else if (prevRoleForCompany.userRole.installer) {
      newUserRole.installer = prevRoleForCompany.userRole.installer;
    }

    newRoleForCompany.userRole = newUserRole;
    // (END) create the new userRole

    this.editedUser.roles.splice(prevRoleForCompanyIndex, 1, newRoleForCompany);
    this.editedUser.roles = this.editedUser.roles.slice();
    console.log('UserEditDialogComponent onChangePossibleRoleForCompany this.editedUser.roles', this.editedUser.roles);
  }


  public onSearchCompanySelected(selectedEvent: MatAutocompleteSelectedEvent) {
    console.log('UserEditDialogComponent onSearchCompanySelected selectedEvent', selectedEvent);
    const selectedCompany: GeneralCompany = selectedEvent.option.value;
    console.log('UserEditDialogComponent onSearchCompanySelected selectedCompany', selectedCompany);

    let availableRole: Role = null;
    // set default values for the new role
    if (selectedCompany.type === CompanyType.CustomerChain) {
      availableRole = this.availableRoles.itemsPage.find((availableRole: Role) => {
        return availableRole.uniqueBusinessId === RoleBusinessId.CUSTOMER_CHAIN_REGIONAL_MANAGER;
      });
    } else if (selectedCompany.type === CompanyType.CustomerLocation) {
      availableRole = this.availableRoles.itemsPage.find((availableRole: Role) => {
        return availableRole.uniqueBusinessId === RoleBusinessId.CUSTOMER_LOCATION_RESTRICTED_USER;
      });
    } else if (selectedCompany.type === CompanyType.InstallerChain || selectedCompany.type === CompanyType.Installer) {
      availableRole = this.availableRoles.itemsPage.find((availableRole: Role) => {
        return availableRole.uniqueBusinessId === RoleBusinessId.INSTALLER;
      });
    }

    const newRole: Role = this.utilsService.deepCopyRole(availableRole);
    const newUserRole: UserRole = new UserRole({} as any);
    newUserRole.user = this.editedUser._id;
    newUserRole.role = newRole._id as any;
    if (selectedCompany.type === CompanyType.CustomerChain) {
      newUserRole.customerChain = new CustomerChain({ _id: selectedCompany.id, name: selectedCompany.name });
    } else if (selectedCompany.type === CompanyType.CustomerLocation) {
      newUserRole.customerLocation = new CustomerLocation({ _id: selectedCompany.id, name: selectedCompany.name });
    } else if (selectedCompany.type === CompanyType.InstallerChain) {
      newUserRole.installerChain = new InstallerChain({ _id: selectedCompany.id, name: selectedCompany.name });
    } else if (selectedCompany.type === CompanyType.Installer) {
      newUserRole.installer = new Installer({ _id: selectedCompany.id, name: selectedCompany.name });
    }
    newRole.userRole = newUserRole;

    this.editedUser.roles = this.editedUser.roles ? this.editedUser.roles : [];
    this.editedUser.roles.push(newRole);
    this.editedUser.roles = this.editedUser.roles.slice();
    console.log('UserEditDialogComponent onChangePossibleRoleForCompany this.editedUser.roles', this.editedUser.roles);
    // this.changeDetectorRef.detectChanges();
  }

  public onClickRemoveRole(clickEvent: any, roleIndex: number) {
    console.log('UserEditDialogComponent onChangePossibleRoleForCompany clickEvent', clickEvent);
    console.log('UserEditDialogComponent onChangePossibleRoleForCompany roleIndex', roleIndex);

    this.editedUser.roles.splice(roleIndex, 1);
    this.editedUser.roles = this.editedUser.roles.slice();
    console.log('UserEditDialogComponent onChangePossibleRoleForCompany this.editedUser.roles', this.editedUser.roles);
  }

  public handleIndicatifChange(): void {
    this.userForm.get('phone').patchValue('');
    this.isInputValidForSubmit();
  }
  public handlePhoneChange(event: any): void {
    this.userForm.get('phone').patchValue(event);
    this.isInputValidForSubmit();
  }

  public isInputValidForSubmit(): boolean {
    let isValid = true;

    if (!this.userForm.valid) {
      this.validationErrors.userFormNotValid = true;
      isValid = false;
    } else {
      this.validationErrors.userFormNotValid = false;
    }

    // a minimum of one role must be selected
    if (!this.createAdminCtrl.value){
      if (!this.editedUser.roles || !this.editedUser.roles.length) {
        this.validationErrors.minimumOneUserRole = true;
        isValid = false;
      } else {
        this.validationErrors.minimumOneUserRole = false;
      }
    }


    this.buildErrorMessages();
    return isValid;
  }

  private buildErrorMessages() {
    if (this.validationErrors.userFormNotValid) {
      this.userDataTabErrorMessage = this.trInstant('website.users.dialog.tabs.stores.errors.userFormNotValid', this.crtLang);
    } else {
      this.userDataTabErrorMessage = '';
    }

    if (this.validationErrors.minimumOneUserRole) {
      this.rolesTabErrorMessage = this.trInstant('website.users.dialog.tabs.stores.errors.minimumOneUserRole', this.crtLang);
    } else {
      this.rolesTabErrorMessage = '';
    }
  }

  public async onSubmitUserEdit(): Promise<void> {
    this.submitToServerTried = true;
    if (!this.isInputValidForSubmit()) {
      return;
    }

    console.log('UserEditDialogComponent onSubmitUserEdit this.userForm.value', this.userForm.value);
    const userToSave: User = new User(Object.assign(this.editedUser, this.userForm.value));
    console.log('UserEditDialogComponent onSubmitUserEdit userToSave', userToSave);

    const userDataToSet = new EditUserFromFrontendInput();
    userDataToSet._id = this.editedUser._id;
    userDataToSet.email = this.editedUser.email;
    userDataToSet.firstName = this.editedUser.firstName;
    userDataToSet.lastName = this.editedUser.lastName;
    userDataToSet.phone = this.editedUser.phone;
    userDataToSet.useFilteredAlarms = this.editedUser.useFilteredAlarms;
    userDataToSet.customerLocationAlarmsDisabled = this.editedUser.customerLocationAlarmsDisabled;
    userDataToSet.active = true;
    if (this.isMigrator) {
      userDataToSet.isMigrator = true;
    }

    // (START) create the userRoles structure for saving
    const userRoles: UserRole[] = this.editedUser?.roles?.map((role: Role) => {
      const userRole: UserRole = new UserRole({} as any);

      userRole.role = role._id as any;

      if (role.userRole.customerLocation && (role.userRole.customerLocation as CustomerLocation)._id) {
        userRole.customerLocation = (role.userRole.customerLocation as CustomerLocation)._id as any;
      }
      if (role.userRole.customerChain && (role.userRole.customerChain as CustomerChain)._id) {
        userRole.customerChain = (role.userRole.customerChain as CustomerChain)._id as any;
      }
      if (role.userRole.installer && (role.userRole.installer as Installer)._id) {
        userRole.installer = (role.userRole.installer as Installer)._id as any;
      }
      if (role.userRole.installerChain && (role.userRole.installerChain as InstallerChain)._id) {
        userRole.installerChain = (role.userRole.installerChain as InstallerChain)._id as any;
      }

      return userRole;
    }) ?? [];

    if (this.createAdminCtrl.value){
      const adminRole =   this.availableRoles.itemsPage.find((availableRole: Role) => {
        return availableRole.uniqueBusinessId === RoleBusinessId.MATELEX_ADMIN;
      });
      userRoles.push({role: this.utilsService.deepCopyRole(adminRole)._id as any} as any);
    }

    userDataToSet.userRoles = userRoles as unknown as UserRoleUpdateInput[];

    console.log('UserEditDialogComponent onSubmitUserEdit userDataToSet before save', userDataToSet);
    // (END) create the userRoles structure for saving

    this.errorWhenSaving = false;
    this.userForm.controls.email.setErrors(null);
    try {
      await this.userListService.editUserFromFrontend(userDataToSet);
    } catch (error: any) {
      console.log('UserEditDialogComponent onSubmitUserEdit error', JSON.stringify(error));
      if (error?.networkError?.error?.errors?.length) {
        this.errorWhenSaving = true;
        error?.networkError?.error?.errors.forEach((error: any) => {
          if (error.translationKey && error.translationKey === 'AUTH.ERRORS.EMAIL_ALREADY_EXISTS') {
            this.userForm.controls.email.setErrors({ emailAreadyExists: true });
          }
        });
        this.isInputValidForSubmit();
      }
      return;
    }
    this.dialogRef.close();
  }

  public onCloseClick(): void {
    this.dialogRef.close();
  }

  public useAlarmsFilterChange(customerLocation: CustomerLocation, useAlarmsFilterValue: boolean): void {
   this.editedUser.updateCustomerLocationAlarmsDisabled(useAlarmsFilterValue, customerLocation._id);
   this.customerLocationAlarmsDisabledFormArray.clear();
   this.editedUser.customerLocationAlarmsDisabled.forEach((customerLocationItem) => {
      this.customerLocationAlarmsDisabledFormArray.push(new FormControl(customerLocationItem));
    });
  }

  public async sendTestEmail(): Promise<void> {
    await this.userService.testEmail(this.editedUser.email);
    this.snackbar.open('Test de réception des mails envoyé');
  }

}
