import { Injectable } from '@angular/core';
import { GraphqlService } from '@graphql/graphql.service';
import { ConfigChangeRequestAction } from '@shared/models/config-change-request-action.enum';
import { gql } from 'apollo-angular';
import { EquipmentConfig } from '@equipment/domain/equipment-config';

@Injectable({
  providedIn: 'root'
})
export class ConfigChangeRequestService {
  private requestActionQuery = gql`
    mutation CreateConfigChangeRequest($equipment: String!, $action: ConfigChangeRequestAction!) {
      createConfigChangeRequest(input: { equipment_id: $equipment, action: $action }) {
        _id
      }
    }
  `;

  private requestActionQueryReboot = gql`
    mutation createRebootConfigChangeRequest($main_device_id: String!, $action: ConfigChangeRequestAction!) {
      createRebootConfigChangeRequest(input: {main_device_id: $main_device_id, action: $action }) {
        _id
      }
    }
  `;

  private requestActionQueryCertificate = gql`
    mutation createCertificationConfigChangeRequest($main_device_id: String!, $action: ConfigChangeRequestAction!) {
      createCertificationConfigChangeRequest(input: {main_device_id: $main_device_id, action: $action }) {
        _id
      }
    }
  `;

  private requestActionQueryAcknowledge = gql`
    mutation createAcknowledgeConfigChangeRequest($equipmentId: String!, $action: ConfigChangeRequestAction!,$details:DetailsConfigChangeRequest) {
      createAcknowledgeConfigChangeRequest(input: { equipment_id: $equipmentId, action: $action,details: $details }) {
        _id
      }
    }
  `;

  private requestActionQueryRelearning = gql`
    mutation createRelearningConfigChangeRequest($equipmentId: String!, $action: ConfigChangeRequestAction!) {
      createRelearningConfigChangeRequest(input: { equipment_id: $equipmentId, action: $action }) {
        _id
      }
    }
  `;

  private requestActionQueryRecalculation = gql`
    mutation createRecalculationConfigChangeRequest($equipmentId: String!, $action: ConfigChangeRequestAction!) {
      createRecalculationConfigChangeRequest(input: { equipment_id: $equipmentId, action: $action }) {
        _id
      }
    }
  `;

  private requestActionQueryForceSync = gql`
    mutation createForceSyncConfigChangeRequest($equipmentId: String!, $action: ConfigChangeRequestAction!) {
      createForceSyncConfigChangeRequest(input: { equipment_id: $equipmentId, action: $action }) {
        _id
      }
    }
  `;



  constructor(private graphqlService: GraphqlService) {}

  public async requestAction(equipmentId: string,  action: ConfigChangeRequestAction, main_device_id?: string): Promise<void> {
    if (action == 'Reboot') { await this.graphqlService.mutate(this.requestActionQueryReboot, { main_device_id, action }).toPromise(); }
    else { await this.graphqlService.mutate(this.requestActionQuery, { equipment: equipmentId, action }).toPromise(); }
  }
  public async requestAlarmAcknowledgement(equipmentId: string, action: string, details?: EquipmentConfig): Promise<void> {
    await this.graphqlService.mutate(this.requestActionQueryAcknowledge, { equipmentId, action, details }).toPromise();
  }

  public async requestEquipmentRelearning(equipmentId: string, action: string): Promise<void> {
    await this.graphqlService.mutate(this.requestActionQueryRelearning, { equipmentId, action }).toPromise();
  }

  public async requestDeviceReboot(main_device_id: string, action: string): Promise<void> {
    await this.graphqlService.mutate(this.requestActionQueryReboot, { main_device_id, action }).toPromise();
  }

  public async requestActionCertificate(main_device_id: string, action: string): Promise<void> {
    await this.graphqlService.mutate(this.requestActionQueryCertificate, { main_device_id, action }).toPromise();
  }

  public async requestEquipmentRecalculation(equipmentId: string, action: string): Promise<void> {
    await this.graphqlService.mutate(this.requestActionQueryRecalculation, { equipmentId, action }).toPromise();
  }

  public async requestEquipmentForceSync(equipmentId: string, action: string): Promise<void> {
    await this.graphqlService.mutate(this.requestActionQueryForceSync, { equipmentId, action }).toPromise();
  }
}
