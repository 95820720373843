import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigChangeRequestService } from '@equipment/services/config-change-request.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfigChangeRequestAction } from '@shared/models/config-change-request-action.enum';
import { UtilsService } from '@shared/services/utils.service';
import { Permission } from '@users/domain/services/user.enums';
import moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { DeviceType } from 'src/app/shared/models/device-type.enum';
import { Config } from '../../../config';
import { Device } from '../../../shared/models/device';
import { Equipment } from '../../../shared/models/equipment';
import { LanguageService } from '../../../shared/services/language.service';
import { EquipmentDetailsService } from '../../services/equipment-details.service';
import { AlarmModalComponent } from '../email-alarm/alarm-modal/alarm-modal.component';
import { EquipmentConfigModalComponent } from './equipment-config-modal/equipment-config-modal.component';
import { Engine } from '@equipment/components/equipment-details/equipment-performances/interfaces/Engine.interface';
import {
  EquipmentConfigModalConfirmationComponent
} from '@equipment/components/equipment-details/equipment-config-modal-confirmation/equipment-config-modal-confirmation.component';
import { EquipmentService } from '@equipment/services/equipment.service';
import { AppMapClickData } from '@shared/components/ol-map/ol-map.component';
import { CustomerLocation } from '@shared/models/customer-location';

enum TABS {
  DESCRIPTION,
  FLUID_CHARTS,
  ENERGY_CHARTS,
  PERFORMANCE,
  ACTIVITY,
  ALARMS,
  USERS,
}

export const equipmentStatusInfo: {[key: string]: any } = {
  relearning: { name: 'EVT_RELEARNING', severityColor: 'info' },
  recalculation: { name: 'EVT_RECALCULATION', severityColor: 'info' },
  technical_alarm: { name: 'EVT_TECHNICAL', severityColor: 'danger' },
  fluid_alarm: { name: 'EVT_FLUID', severityColor: 'danger' },
  statistical_alarm: { name: 'EVT_STATISTICAL', severityColor: 'danger' },
  disconnected: { name: 'EVT_DISCONNECTED', severityColor: 'warn'},
  normal: {name: 'NORMAL_STATE', severityColor: 'success'},
  ok: {name: 'NORMAL_STATE', severityColor: 'success'},
  firstConnection: {name: 'FIRST_CONNECTION', severityColor: 'secondary'}

};

interface FeaturesFlag {
  _id: string;
  active: boolean;
  featureName: string;
}

export interface EquipmentFieldsEditionInterface {
  fieldName: string;
  value?: string;
  fieldLabel: string;
}


@Component({
  selector: 'app-equipment-details',
  templateUrl: './equipment-details.component.html',
  styleUrls: ['./equipment-details.component.scss']
})
export class EquipmentDetailsComponent implements OnInit, OnDestroy {
  public selectedMapMarkerCustomerLocation: CustomerLocation = null;
  public Permission = Permission;
  public configChangeRequestAction: typeof ConfigChangeRequestAction = ConfigChangeRequestAction;
  public panelOpenState = true;
  public dateFiller = new Date();
  public equipmentId: string;
  public equipment$: Observable<any> | undefined;
  public needsControl$: Observable<boolean> | undefined;
  public breadcrumbsElements: { displayValue: string; routerLink: any }[] = [];
  public equipmentsInCustomerLocationForBreadCrumbsComponent$: Observable<any> | undefined;
  public crtLang = '';
  public trInstant: (key: string | Array<string>, interpolateParams?: Object) => string | any;
  public childDevicesOfMainDevice: Device[] = [];
  public equipmentActiveAlarmsCount = 0;
  public tabs = TABS;
  public selectedTabIndex = TABS.DESCRIPTION;
  public readonly DeviceType = DeviceType;
  private subscriptions: Subscription[] = [];

  public equipmentLastTimeLive: Date = null;

  // (START) temp data during migration from mockAPI to kickstart API
  public tmpMockEquipmentId = 400;
  public dnisInStoreForBreadCrumbqsComponent$: Observable<any> | undefined;
  public dniActiveAlarms$: Observable<any> | undefined;
  public alarmsDataSource = new MatTableDataSource([]);
  // (END) temp data during migration from mockAPI to kickstart API

  tabIndex = [
    'description', 'fluid', 'energy', 'performance', 'activity', 'alarms', 'users'
  ];

  dni = true; // default
  polarbox = false;
  public hasExternalTemp: Device;


  constructor(
    private activatedRoute: ActivatedRoute,
    private equipmentDetailsService: EquipmentDetailsService,
    private configChangeRequestService: ConfigChangeRequestService,
    private router: Router,
    public dialog: MatDialog,
    private translateService: TranslateService,
    private languageService: LanguageService,
    public utilsService: UtilsService,
    public equipmentService: EquipmentService
  ) {

  }

  public editableFields: EquipmentFieldsEditionInterface[] = [
    {
      fieldLabel: this.translateService.instant('website.equipment.detail.id_central_reference'),
      fieldName: 'referenceCentrale',
    },
    {
      fieldLabel: this.translateService.instant('website.equipment.detail.id_name'),
      fieldName: 'name',
    },
    {
      fieldLabel: 'Numéro de série',
      fieldName: 'serial_number',
    },
    {
      fieldLabel: 'S/N Kit',
      fieldName: 'serial_number',
    }
  ];
  public equipmentStatus: any[] = [];
  public equipment: Equipment = null;
  public async ngOnInit(): Promise<void> {

    const featuresFlags = localStorage.getItem(btoa('featuresFlag'));
    if (featuresFlags) {
      const arrayFeaturesFlag = JSON.parse(atob(featuresFlags)) as unknown as FeaturesFlag[];
        // Temporaire. TODO => Trouver une nomenclature et intégration générique des feature flags
      if (arrayFeaturesFlag.find(f => f.featureName.toLowerCase().includes('performance') && !f.active)) {
          this.tabIndex.splice(this.tabIndex.indexOf('performance'), 1);
        }
    }
    console.log('tabIndex => ', this.tabIndex);
    this.activatedRoute.queryParams.pipe(first()).subscribe((params) => {
      this.selectedTabIndex = this.tabIndex.indexOf(params.tab);
      if (params.tab === 'alarms' && params.alarm_id) {
        const alarm_id: string = params.alarm_id;
        this.openDialog(alarm_id);
      }
    });


    this.crtLang = this.languageService.currentLanguage;
    this.trInstant = this.translateService.instant;
    this.trInstant = this.trInstant.bind(this.translateService);
    await this.refresDataEquipment();
  }

  private async refresDataEquipment(): Promise<void> {
    this.equipmentId = this.activatedRoute.snapshot.queryParams.id;

    this.equipment$ = this.equipmentDetailsService.getEquipment(this.equipmentId);
    this.needsControl$ = this.equipment$.pipe(
      map(equipment => !moment().subtract(Config.equipmentTareMonths, 'months').isBefore(moment(equipment?.last_tare_date)))
    );

    this.subscriptions.push(
      this.equipment$.subscribe((equipment: Equipment) => {
        this.equipment = equipment;
        if (this.equipment.unit_flags_and_alarms) {
          this.equipment.unit_flags_and_alarms.simultaneous_unit_status?.forEach(s => {
            // @ts-ignore
            if (equipmentStatusInfo[s]) {
              // @ts-ignore
              this.equipmentStatus.push(equipmentStatusInfo[s]);
            } else if (s === 'ok') {
              // @ts-ignore
              this.equipmentStatus.push(equipmentStatusInfo.normal);
            }
          });
        }

        if (this.equipmentStatus.length === 0) {
          if (equipment.main_device_id?.hardware_version !== '1.0.0' && !equipment.main_device_id?.license_enabled) {
            this.equipmentStatus.push(equipmentStatusInfo.firstConnection);
          } else {
            this.equipmentStatus.push(equipmentStatusInfo.normal);
          }
        }

        if (this.equipment.main_device_id?.hardware_version != '1.0.0') {
          this.dni = false;
          this.polarbox = true;
        }
        this.breadcrumbsElements = [
          {
            displayValue: this.translateService.instant('website.equipment.detail.breadcrumb'),
            routerLink: '../../equipment'
          },
          { displayValue: equipment?.customerLocation?.name, routerLink: '../../equipment' }
        ];

        this.equipmentsInCustomerLocationForBreadCrumbsComponent$ =
          this.equipmentDetailsService.getEquipmentsInCustomerLocationForBreadCrumbsComponent(equipment?.customerLocation?._id);
        this.hasExternalTemp = this.equipment.childDevicesOfMainDevice?.find((childDevice) => childDevice.type.toLowerCase() === 'temp_external');
        this.childDevicesOfMainDevice = this.equipmentDetailsService.prepareForViewListChildDevicesOfMainDevice(equipment);


      })
    );

    this.equipmentLastTimeLive = await this.equipmentDetailsService.getLastTimeLive(this.equipmentId);
    console.log('EquipmentDetailsComponent ngOnInit this.equipmentLastTimeLive', this.equipmentLastTimeLive);
  }

  openDialog(alarm_id: any): void {
    const dialogRef = this.dialog.open(AlarmModalComponent, {
      width: '1200px',
      data: {
        id: alarm_id
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  public async handleUpdateFields(event: any): Promise<void> {
    console.log(event);
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  public translateEnginesNames(engines: Engine[]): Engine[] {
    return engines.map(engine => {
      const newEngine = {...engine};
      const engineName = newEngine.name?.split('-');
      newEngine.engine_type = this.trInstant('website.equipment.detail.charts.energy.' + newEngine.engine_type.toLowerCase() + '_label');
      if (engineName[0] === 'compressor' || engineName[0] === 'condenser' || engineName[0] === 'pump') {
        const label = this.trInstant('website.equipment.detail.charts.energy.' + engineName[0] + '_label');
        newEngine.name = label + ' ' + engineName[1];
      }
      return newEngine;
    });
  }

  public async handleRequestAction(action: string): Promise<void> {
    switch (action) {
      case 'Reboot': {
        const mainDeviceId = this.equipment?.main_device_id?.id;
        if (mainDeviceId) {
          await this.configChangeRequestService.requestDeviceReboot(mainDeviceId, action);
        }
        break ;
      }
      case 'Recalculation': {
        await this.configChangeRequestService.requestEquipmentRecalculation(this.equipmentId, action);
        break ;
      }
      case 'Acknowledge': {
        await this.configChangeRequestService.requestAlarmAcknowledgement(this.equipmentId, action);
        break ;
      }
      case 'Relearning': {
        await this.configChangeRequestService.requestEquipmentRelearning(this.equipmentId, action);
        break ;
      }
      case 'ForcedSync': {
        await this.configChangeRequestService.requestEquipmentForceSync(this.equipmentId, action);
        break ;
      }
    }
  }
  public async sendAction(action: ConfigChangeRequestAction): Promise<void> {
    const dialogRef = this.dialog.open(EquipmentConfigModalConfirmationComponent, {
      width: '673px',
      disableClose: false,
      autoFocus: false,
      data: {
        act: action,
        equipmentId: this.equipment.id
      }
    });
    dialogRef.afterClosed().subscribe(async (afterClosed) => {
      if (afterClosed.dialogAction === 'yes') {
        await this.handleRequestAction(action);
      }

    });
  }

  public onEquipmentActiveAlarmsCount(equipmentActiveAlarmsCount: number) {
    this.equipmentActiveAlarmsCount = equipmentActiveAlarmsCount;
  }

  public handleDropdownChange(equipmentId: number): void {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([this.languageService.currentLanguage, 'equipment', 'details'], {
        queryParams: { id: equipmentId }
      })
    );
  }

  public async openEquipmentConfig(): Promise<void> {
    await this.refresDataEquipment();
    const dialogRef = this.dialog.open(EquipmentConfigModalComponent, {
      width: '1276px',
      disableClose: false,
      data: {
        equipmentId: this.equipment.id,
        measure_device: this.equipment.childDevicesOfMainDevice?.find(device => device.type === DeviceType.MEASURE),
        energy_devices: this.childDevicesOfMainDevice?.filter(device => device.type === DeviceType.ENERGY),
        controller_device: this.equipment?.main_device_id,
        totalUnsolvedChangeRequests: this.equipment.totalUnsolvedChangeRequests,
        equipment: this.equipment
      }
    });

    const result = await dialogRef.afterClosed().toPromise();
    if (result) {
      await this.refresDataEquipment();
    }
  }



  selectedTabChange(event: any) {
    this.router.navigate(
      [this.languageService.currentLanguage, 'equipment', 'details'],
      {
        queryParams: { id: this.equipmentId, tab: this.tabIndex[event.index] }
      }
    );
    console.log(event.index);
    this.selectedTabIndex = event.index;
  }

  public async createOrUpdateModuleTemp(exteTempDeviceId: string): Promise<void> {

    this.hasExternalTemp = await this.equipmentService.createOrUpdateDeviceExternalTemp({
      externalTempDeviceId: exteTempDeviceId,
      controllerId: this.equipment?.main_device_id?.id
    }) as Device;

  }


  public async onClickOnMap(mapClickEvent: AppMapClickData) {
    console.log('DashboardMapComponent onClickOnMap mapClickEvent', mapClickEvent);
    if (mapClickEvent?.attributes?.isMarker) {
      this.selectedMapMarkerCustomerLocation = this.equipment.customerLocation;
      console.log('DashboardMapComponent onClickOnMap this.selectedMapMarkerCustomerLocation', this.selectedMapMarkerCustomerLocation);
    } else {
      this.selectedMapMarkerCustomerLocation = null;
    }
  }
}
