import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfigChangeRequestAction } from '@shared/models/config-change-request-action.enum';
import { Alarm } from '@shared/models/alarm';
import { Config } from '../../../../config';
import { EquipmentDetailsService } from '@equipment/services/equipment-details.service';
import { AlarmsListSearchCriteria } from '@equipment/services/alarms-list-search-criteria.interface';

const INITIAL_CRITERIA: AlarmsListSearchCriteria = {
  filters: {
    alarmType: [],
    active: true,
    includeAlarmConnection: true
  },
  sort: {
    field: 'date',
    direction: 'DESC'
  },
  pagination: {
    perPage: 5,
    page: 0
  }
};

@Component({
  selector: 'app-equipment-config-modal-confirmation',
  templateUrl: './equipment-config-modal-confirmation.component.html',
  styleUrls: ['./equipment-config-modal-confirmation.component.scss']
})
export class EquipmentConfigModalConfirmationComponent implements OnInit {
  public crtLang: string = Config.defaultLanguage;
  public displayedColumnsAlarms: string[] = ['alarmType', 'value', 'date'];
  public alarms: Alarm[];
  public criteria: AlarmsListSearchCriteria;
  public showAlarms = false;
  public action: string;
  public isLoading = false;
  public isCheckedAlarms = false;
  public hideCheckboxForAlarms = false;

  constructor(
    private equipmentDetailsService: EquipmentDetailsService,
    @Inject(MAT_DIALOG_DATA) public data: { act: ConfigChangeRequestAction, equipmentId: string },
    public dialogRef: MatDialogRef<EquipmentConfigModalConfirmationComponent>
  ) {
  }

  async ngOnInit(): Promise<void> {
    switch (this.data.act) {
      case ConfigChangeRequestAction.Acknowledge:
        this.action = 'website.equipment.detail.modalActions.acknowledge';
        break;
      case ConfigChangeRequestAction.ForcedSync:
        this.action = 'website.equipment.detail.modalActions.forcedSync';
        break;
      case ConfigChangeRequestAction.Relearning:
        this.action = 'website.equipment.detail.modalActions.relearning';
        break;
      case ConfigChangeRequestAction.Reboot:
        this.action = 'website.equipment.detail.modalActions.reboot';
        break;
      case ConfigChangeRequestAction.Recalculation:
        this.action = 'website.equipment.detail.modalActions.recalculation';
        break;
      default:
        this.action = '';
        break;
    }
    console.log('ACT => ', this.data);
    if (this.data.act === ConfigChangeRequestAction.Acknowledge) {
      this.isLoading = true;
      this.criteria = JSON.parse(JSON.stringify(INITIAL_CRITERIA));
      this.alarms = (await this.equipmentDetailsService.getEquipmentActiveAlarms(this.data.equipmentId, this.criteria)).itemsPage;
      if (this.alarms.filter(f => f?.alarm_type?.id === 'technical').length === this.alarms.length) {
        this.hideCheckboxForAlarms = true;
        this.isCheckedAlarms = true;
      }
      this.isLoading = false;
    } else {
      this.isLoading = false;
    }
  }

  handleAccept(): void {
    this.dialogRef.close({dialogAction: 'yes', confirmRepairedLeaks: this.isCheckedAlarms});
  }

  handleReject(): void {
    this.dialogRef.close('no');
  }

  handleShowAlarms(): void {
    this.showAlarms = !this.showAlarms;
  }

  handleCheckConfirmLeaks(): void {
    this.isCheckedAlarms = !this.isCheckedAlarms;
  }

}
