import { Options } from '@angular-slider/ngx-slider';
import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SliderComponent,
      multi: true
    }
  ]
})
export class SliderComponent implements ControlValueAccessor, OnInit {
  @Input() public singleValue?: number;
  @Input() public formControlName: string;
  @Input() step: number = 1;
  @Input() inferiorValue: number = 0;
  @Input() superiorValue: number = 100;

  @Input() public options: Options = null;

  public manualRefresh: EventEmitter<void> = new EventEmitter<void>();

  public onChange: (value: number) => void = () => {
    return null;
  };

  public onTouched: () => void = () => {
    return null;
  };

  public val: number;
  public writeValue(value: number): void {
    this.val = this.singleValue = value;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public ngOnInit(): void {
    if(!this.options) {
      this.options = {
        floor: this.inferiorValue,
        ceil: this.superiorValue,
        step: this.step
      };
    }
    this.emitManualRefresh();
  }

  private emitManualRefresh() {
    setTimeout(() => {
      this.manualRefresh.emit();
    }, 300);
  }
}
