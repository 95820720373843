export enum ConfigChangeRequestAction {
  Reboot = 'Reboot',
  Acknowledge = 'Acknowledge',
  Relearning = 'Relearning',
  Recalculation = 'Recalculation',
  ForcedSync = 'ForcedSync',
  BlacklistToken = 'BlacklistToken',
  WhitelistToken = 'WhitelistToken',
  SendTlsCertificate= 'SendTlsCertificate',
}
