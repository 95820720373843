import { Component, ComponentFactoryResolver, Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'loader',
  template: `
    <div class='d-flex justify-content-center m-5 p-5'>
      <mat-spinner diameter='70'  ></mat-spinner>
    </div>`,
  imports: [
    MatProgressSpinnerModule
  ],
  standalone: true,
  styles: [``]
})
export class LoaderComponent  {

}

@Directive({
  selector: '[isLoading]'
})
export class LoadingDirective {

  @Input()
  set isLoading(isLoading: boolean) {
    if (isLoading) {
      this.view.clear();
      this.view.createComponent(LoaderComponent);
    } else {
      this.view.clear();
      this.view.createEmbeddedView(this.template);
    }
  }

  constructor(private view: ViewContainerRef, private componentFactoryResolver: ComponentFactoryResolver, private template: TemplateRef<AnimationPlayState>) {

  }

}
