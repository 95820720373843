import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import moment from 'moment-timezone';
import * as Highcharts from 'highcharts';
import HighchartsBoost from 'highcharts/modules/boost';
import Xrange from 'highcharts/modules/xrange';
import noData from 'highcharts/modules/no-data-to-display';

HighchartsBoost(Highcharts);
Xrange(Highcharts);
noData(Highcharts);
@Component({
  selector: 'app-equipment-performances-charts',
  templateUrl: './equipment-performances-charts.component.html',
  styleUrls: ['./equipment-performances-charts.component.scss']
})
export class EquipmentPerformancesChartsComponent implements OnInit, OnChanges {

  @Input() data: any;
  @Input() dataEngines: any;
  @Input() name: string;
  @Input() minDate: string;
  @Input() maxDate: string;
  @Input() width: number;
  @Input() height: number;
  updateFlag = false;
  public Highcharts: typeof Highcharts = Highcharts;
  public chartOptions: Highcharts.Options;

  private chartSeries: any[] = [
    {
      name: 'Temps de marche ',
      // pointPadding: 0,
      // groupPadding: 0,
      borderColor: 'gray',
      pointWidth: 20,
      data: []
    }
  ];
  public defineWidth(): number {
    if (window.innerWidth / 1.9 < 600) {
      return 700;
    } else {
      return window.innerWidth / 1.9
    }
  }
  private buildChartOptions(): void {
    const ctx = this;
    this.chartOptions = {
      chart: {
        type: 'xrange',
        zoomType: 'xy',
        height: this.height ? this.height : 100,
        backgroundColor: 'transparent',
        width: this.width ? this.width === 700 ? this.defineWidth() : this.width : 450,
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: ['viewFullscreen'],
            symbol: 'url(../../../../../../assets/images/open_in_full.png)',
            symbolSize: 20,
            symbolX: 23,
            symbolY: 23
          }
        }
      },
      legend: {
        enabled: false
      },
      tooltip: {
        formatter(): any {
          if (this.points) {
            const secondsToHms = (seconds: number) => {
              seconds = Number(seconds);

              const h = Math.floor(seconds / 3600);
              const m = Math.floor(seconds % 3600 / 60);
              const s = Math.floor(seconds % 3600 % 60);

              return ('0' + h).slice(-2) + ':' + ('0' + m).slice(-2) + ':' + ('0' + s).slice(-2);
            };
            return this.points.reduce((previous: any, current: any) => {
              const delta: any = (current.x2 / 1000) - (current.x / 1000);
              return `Démarrage de ${previous} à <b>${moment(current.x2).format('HH:mm:ss')}</b> (${secondsToHms(delta)})`;
            }, `<b>${moment(this.x).format('HH:mm:ss')}</b>`);
          } else {
            return `<b>${moment(this.x).format('DD/MM/YYYY HH:mm:ss')}</b>`;
          }
        },
        style: {
          color: '#66788a',
          fontWeight: 'normal',
          fontSize: '12px',
          letterSpacing: '-0.04px',
          lineHeight: '20px',
          fontFamily: 'Ubuntu'
        },
        shared: true,
        borderWidth: 0,
        backgroundColor: '#fff',
        borderRadius: 8,
        padding: 15
      },
      credits: {
        enabled: false
      },
      time: {
        timezone: moment.tz.guess()
      },
      title: {
        text: ''
      },
      accessibility: {
        point: {
          descriptionFormatter: ((point: any) => {
            const ix = point.index + 1,
              category = point.yCategory,
              from = new Date(point.x),
              to = new Date(point.x2);
            return ix + '. ' + category + ', ' + from.toDateString() +
              ' to ' + to.toDateString() + '.';
          })
        }
      },
      yAxis: {
        visible: false,
      },
      xAxis: {
        type: 'datetime',
        max: new Date(this.maxDate).getTime(),
        min: new Date(this.minDate).getTime(),
      },
      plotOptions: {xrange: {minPointLength: 3} },
      series: this.chartSeries,
    };
  }
  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes && changes.data) {
      this.chartSeries = [
        {
          borderColor: 'gray',
          pointWidth: 20,
          data: changes.data.currentValue.data,
          dataLabels: {
            enabled: false
          }
        }
      ];
    }
    this.buildChartOptions();

    this.updateFlag = true;
  }
  public async ngOnInit(): Promise<void> {
    this.chartSeries = [
      {
        borderColor: 'gray',
        pointWidth: 20,
        data: [...this.data.data],
        dataLabels: {
          enabled: false
        }
      }
    ];
    this.buildChartOptions();
    this.updateFlag = true;
  }
}
