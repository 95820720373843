<div class="breadcrumbs">
  <ul class='{{classList}}'>
    <li *ngFor="let el of elements">
      <a *ngIf="el.routerLink !== ''" [routerLink]="[el.routerLink]" [attr.aria-label]="'breadcrumbs - ' + el.displayValue">{{ el.displayValue }}</a>
      <a *ngIf="el.routerLink === ''">{{ el.displayValue }}</a>
    </li>
    <li *ngIf="dropDownElements">
      <div class="auto-width">
        <mat-form-field appearance="fill" class='custom-select-like-dropdown'>
          <mat-select [value]="selectedValue" (selectionChange)="emitDropDownValue($event)" disableOptionCentering tabindex="0">
            <mat-option [attr.aria-label]="'breadcrumbs - ' + element.value" [value]="element.value" *ngFor="let element of dropDownElements">{{element.value}} - {{ element.displayValue }} </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </li>
  </ul>
</div>
