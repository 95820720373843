import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { EquipmentFieldsEditionInterface } from '@equipment/components/equipment-details/equipment-details.component';
import { EquipmentService } from '@equipment/services/equipment.service';
import { Equipment } from '@shared/models/equipment';
import { isEmpty } from 'lodash';
import { Device } from '@shared/models/device';

@Component({
  selector: 'app-equipment-edit-fields',
  templateUrl: './equipment-edit-fields.component.html',
  styleUrls: ['./equipment-edit-fields.component.scss']
})
export class EquipmentEditFieldsComponent implements OnInit, OnChanges {
  @Input() fieldData: EquipmentFieldsEditionInterface;
  @Input() equipment: Equipment;
  @Input() device: Device;
  @Input() value: string;
  @Input() type: string;
  @Output() editField: EventEmitter<string> = new EventEmitter<string>();
  public currentValue = '';
  public editing = false;
  public isLoading = false;
  public cmpValue = '';
  @ViewChild('editInput') editInput: ElementRef;
  constructor(private equipmentService: EquipmentService) {}

  public handleOnClickEdit(): void {
    this.editing = true;
  }

  public checkIsEmpty(value: string): boolean {
    return isEmpty(value);
  }
  public handleOnClear(inputElement: any): void {
    inputElement.value = '';
  }
  public handleFocusOut(): void {
    this.editing = false;
  }
  public async handleOnClickCheck(inputElement: any): Promise<void> {
    if (!isEmpty(inputElement.value)) {
      this.isLoading = true;
      if (this.fieldData.fieldName === 'serial_number') {
        let input;
        if (this.type === 'main') {
          input = {
            id: this.equipment.main_device_id.id,
            serial_number: inputElement.value
          };
        } else {
          input = {
            id: this.device.id,
            serial_number: inputElement.value
          };
        }
        await this.equipmentService.updateDevice(input as any);
      } else {
        const input = {
          id: this.equipment.id,
          [this.fieldData.fieldName]: inputElement.value
        };
        await this.equipmentService.updateEquipment(input as any);
      }

      this.cmpValue = inputElement.value;
      this.isLoading = false;
      this.editing = false;
    } else {
      this.handleFocusOut();
    }
  }
  public async ngOnInit(): Promise<void> {
    this.cmpValue = this.value;
  }
  public ngOnChanges(changes: SimpleChanges): void {
  }
}
