import { Component, Input, OnInit } from '@angular/core';
import { PagedResult } from '@shared/services/paged-result';
import { InstChartData } from '@shared/models/inst-chart-data';
import { EquipmentChartsService } from '@equipment/services/equipment-charts.service';
import { InstChartDataQueryCriteria } from '@equipment/services/inst-chart-data-query-criteria.interface';
import moment from 'moment-timezone';
import { MatTableDataSource } from '@angular/material/table';
import {
  EnginesChartTypes
} from '@equipment/components/equipment-details/equipment-performances/interfaces/EnginesChartTypes.interface';
import {
  TableElement
} from '@equipment/components/equipment-details/equipment-performances/interfaces/TableElement.interface';
import { Engine } from '@equipment/components/equipment-details/equipment-performances/interfaces/Engine.interface';
import {
  PerformanceData
} from '@equipment/components/equipment-details/equipment-performances/interfaces/PerformanceData.interface';
import { EquipmentPerformancesDialogComponent } from '@equipment/components';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

const INST_QUERY_CRITERIA: InstChartDataQueryCriteria = {
  columns: ['compressor_state', 'energy{\npower\n engine_id\n}\n'],
  filters: {
    minDate: moment().startOf('day').toISOString(),
    maxDate: moment().endOf('day').toISOString()
  },
  pagination: {
    page: 1,
    perPage: 30000
  }
};

// Minimum energy to define the engine as active
const MIN_POWER = 0.1

export class Group {
  level = 0;
  parent: Group;
  expanded = true;
  get visible(): boolean {
    return !this.parent || (this.parent.visible && this.parent.expanded);
  }
}
@Component({
  selector: 'app-equipment-performances',
  templateUrl: './equipment-performances.component.html',
  styleUrls: ['./equipment-performances.component.scss']
})
export class EquipmentPerformancesComponent implements OnInit {
  @Input() public equipmentId: string;
  @Input() public equipmentEngines: Engine[];
  public currentDayDate: string = moment(new Date(Date.now())).startOf('day').toISOString();
  public instCriteria: InstChartDataQueryCriteria;
  private chartData: PagedResult<InstChartData>;
  private instDatePickerMinDate: Date;
  public inputDataSeries: TableElement[] = [];
  public dataSource = new MatTableDataSource<TableElement | Group>([]);
  groupByColumns: string[] = ['type'];
  displayedColumns: string[] = ['label', 'chronogramme', 'tempsTotal', 'freqDemar', 'tpsMinBetweenDemar', 'tpsMinWorking', 'tpsMaxWorking', 'informations'];
  public readyToRender = false;
  private chartHasError: boolean;
  public trInstant: (key: string | Array<string>, interpolateParams?: Object) => string | any;
  constructor(private equipmentChartsService: EquipmentChartsService, private dialog: MatDialog,
              private translateService: TranslateService) {
  }
  public async ngOnInit(): Promise<void>  {
    this.trInstant = this.translateService.instant;
    this.trInstant = this.trInstant.bind(this.translateService);
    this.instDatePickerMinDate = moment(new Date()).subtract(3, 'months').toDate();
    this.instCriteria = INST_QUERY_CRITERIA;
    await this.changeDatePicker({value: this.currentDayDate});
    this.dataSource.data = this.addGroups(this.inputDataSeries, this.groupByColumns);
    this.dataSource.filterPredicate = this.customFilterPredicate.bind(this);
  }

  public instDatePickerFilter = (date: Date | null): boolean => {
    if (!date) {
      return true;
    }
    if (moment(date).isBefore(this.instDatePickerMinDate)) {
      return false;
    }
    return true;
  }

  public formatData(itemsPage: any): [] {
    const newArray: any = [];
    if (itemsPage.length === 0) {
      return newArray;
    }
    let i = 0;
    while (itemsPage[i] && itemsPage[i].power === 0) {
      i++;
    }
    let addOnceOn = false;
    let addOnceOff = false;
    while (itemsPage[i] && i < itemsPage.length) {
      while (itemsPage[i] && Math.abs(itemsPage[i].power) >= MIN_POWER) {
        if (!addOnceOn) {
          newArray.push(itemsPage[i].date_time);
          addOnceOn = true;
          addOnceOff = false;
        }
        i++;
      }
      while (itemsPage[i] && Math.abs(itemsPage[i].power) < MIN_POWER) {
        if (!addOnceOff) {
          newArray.push(itemsPage[i].date_time);
          addOnceOff = true;
          addOnceOn = false;
        }
        i++;
      }
    }
    if (itemsPage[i - 1].power > 0) {
      const lastDate = itemsPage[itemsPage.length - 1]?.date_time;
      newArray.push(lastDate ? lastDate : this.instCriteria.filters.maxDate);
    }
    return newArray;
  }
  public formatDataChronographEquipment(itemsPage: InstChartData[]): Array<any> {
    const newArray = [];
    let i = 0;
    let lastState = (itemsPage[i].compressor_state === 1);
    if (lastState) {
      newArray.push(this.instCriteria.filters.minDate);
    }
    while (i < itemsPage.length) {
      if (itemsPage[i]) {
        if ((itemsPage[i].compressor_state === 1) !== lastState) {
          lastState = !lastState;
          newArray.push(itemsPage[i].date_time);
        }
      }
      i++;
    }
    if (lastState) {
      const lastDate = itemsPage[itemsPage.length - 1]?.date_time;
      newArray.push(lastDate ? lastDate : this.instCriteria.filters.maxDate);
    }
    return newArray;
  }

  public formatDataChronographEngines(itemsPage: InstChartData[]): any {
    let i = 0;
    const newObj: EnginesChartTypes = {};
    while (i < itemsPage.length) {
      itemsPage[i]?.energy?.forEach((engine, index) => {
        if (engine && engine.engine_id) {
          if (!newObj[engine.engine_id]) {
            newObj[engine.engine_id] = [];
            newObj[engine.engine_id].push({power: engine.power, date_time: itemsPage[i].date_time});
          } else {
            newObj[engine.engine_id].push({power: engine.power, date_time: itemsPage[i].date_time});
          }
        }
      });
      i++;
    }
    return newObj;
  }
  public async changeDatePicker(event: any): Promise<any> {
    this.dataSource.data = [];
    this.readyToRender = false;
    let newArray;
    let newArrayEngines: any;
    this.instCriteria.filters.minDate = moment(new Date(event.value)).startOf('day').toISOString();
    this.instCriteria.filters.maxDate = moment(new Date(event.value)).endOf('day').toISOString();
    this.chartData = await this.loadChartData();
    if (this.chartData.itemsPage.length === 0) {
      this.readyToRender = true;
      return;
    }
    const itemsPage = this.chartData.itemsPage;
    newArray = this.formatDataChronographEquipment(itemsPage);
    newArrayEngines = this.formatDataChronographEngines(itemsPage);
    const keys = Object.keys(newArrayEngines);
    const newFormattedArray: any = [];
    keys.forEach((e: any) => {
      newFormattedArray.push({id: e, data: this.formatData(newArrayEngines[e])});
    });
    let i = 0;
    const dataSeriesEngines: TableElement[] = [];
    newFormattedArray.forEach((ar: any) => {
      const engine = this.equipmentEngines.filter(e => e.id === ar.id)[0];
      i = 0;
      const tmpArray: any = [];
      while (i < ar.data.length) {
        if (ar.data[i + 1]) {
          tmpArray.push({x: new Date(ar.data[i]).getTime(),
            x2: new Date(ar.data[i + 1]).getTime(),
            y: 0});
        }
        i += 2;
      }
      if (tmpArray.length > 0) {
        dataSeriesEngines.push({
          id: engine.id,
          name: engine.name,
          data: tmpArray,
          performanceData: this.setPerformances(tmpArray, engine.engine_type)
        });
      }
    });
    i = 0;
    const dataSeries: any = [];
    while (i < newArray.length) {
      if (newArray[i + 1]) {
        dataSeries.push({x: new Date(newArray[i]).getTime(),
          x2: new Date(newArray[i + 1]).getTime(),
          y: 0});
      }
      i += 2;
    }
    const tmpCentrale: TableElement[] = [{
      id: 'centrale',
      name: this.trInstant('website.equipment.detail.charts.energy.equipment_label'),
      data: [...dataSeries],
      performanceData: this.setPerformances(dataSeries)
    }];
    this.inputDataSeries = tmpCentrale.concat([...dataSeriesEngines]);
    this.dataSource.data = this.addGroups(this.inputDataSeries, this.groupByColumns);
    this.dataSource.filterPredicate = this.customFilterPredicate.bind(this);
    this.readyToRender = true;
  }

  private async loadChartData(): Promise<PagedResult<InstChartData>> {
    let chartResult;
    chartResult = await this.loadInstChartData();
    return chartResult;
  }

  public async loadInstChartData(): Promise<PagedResult<InstChartData>> {
    this.chartHasError = false;
    try {
      const instChartResult = await this.equipmentChartsService.getEnergyInstChartData(this.equipmentId,
        this.equipmentEngines,
        this.instCriteria);
      return instChartResult;
    } catch (error) {
      this.chartHasError = true;
      return { itemsPage: [] } as PagedResult<InstChartData>;
    }
  }

  public secondsToHms(seconds: number): string {
    seconds = Number(seconds);

    const h = Math.floor(seconds / 3600);
    const m = Math.floor(seconds % 3600 / 60);
    const s = Math.floor(seconds % 3600 % 60);

    return ('0' + h).slice(-2) + ':' + ('0' + m).slice(-2) + ':' + ('0' + s).slice(-2);
  }

  public setPerformances(element: any[], type?: string): PerformanceData {
    const performanceData: PerformanceData = {};
    if (type) {
      performanceData.type = type + 's';
    } else {
      performanceData.type = this.trInstant('website.equipment.detail.charts.energy.equipment_label');
    }
    let total = 0;
    let currentTotal = 0;
    let tpsMaxWorking = 0;
    let tpsMinWorking = element.length === 0 ? 0 : Date.now();
    let tpsMinBetweenStartup = element.length > 1 ? Date.now() : 0;
    let tpsBetweenStartup = 0;
    let i = 0;
    while (i < element.length) {
      const elem = element[i];
      const nextElem = element[i + 1] ? element[i + 1] : undefined;
      if (nextElem && element.length > 1) {
        tpsBetweenStartup = ((nextElem.x / 1000) - (elem.x2 / 1000));
        if (tpsBetweenStartup < tpsMinBetweenStartup) {
          tpsMinBetweenStartup = tpsBetweenStartup;
        }
      }
      const x2Secondes = elem.x2 / 1000;
      const xSecondes = elem.x / 1000;
      currentTotal = (x2Secondes - xSecondes);
      if (currentTotal > tpsMaxWorking) {
        tpsMaxWorking = currentTotal;
      }
      if (currentTotal < tpsMinWorking) {
        tpsMinWorking = currentTotal;
      }
      total += (x2Secondes - xSecondes);
      i++;
    }
    performanceData.tempsTotal = this.secondsToHms(total);
    performanceData.freqDemar = element.length.toString();
    performanceData.tpsMaxWorking = this.secondsToHms(tpsMaxWorking);
    performanceData.tpsMinWorking = this.secondsToHms(tpsMinWorking);
    performanceData.tpsMinBetweenDemar = this.secondsToHms(tpsMinBetweenStartup);
    return performanceData;
  }

  customFilterPredicate(data: TableElement | Group, filter: string): boolean {
    return (data instanceof Group) ? data.visible : this.getDataRowVisible(data);
  }

  getDataRowVisible(data: TableElement): boolean {
    const groupRows = this.dataSource.data.filter(
      (row: any) => {
        if (!(row instanceof Group)) {
          return false;
        }

        let match = true;
        this.groupByColumns.forEach(
          column => {
            // @ts-ignore
            if (!row[column] || !data.performanceData[column] || row[column] !== data.performanceData[column]) {
              match = false;
            }
          }
        );
        return match;
      }
    );

    if (groupRows.length === 0) {
      return true;
    }
    if (groupRows.length > 1) {
      throw new Error('Data row is in more than one group!');
    }
    const parent = groupRows[0] as Group;  // </Group> (Fix syntax coloring)

    return parent.visible && parent.expanded;
  }

  public groupHeaderClick(row: any): void {
    row.expanded = !row.expanded;
    this.dataSource.filterPredicate = this.customFilterPredicate.bind(this);
    this.dataSource.filter = performance.now().toString(); // hack to trigger filter refresh
  }

  addGroups(data: any[], groupByColumns: string[]): any[] {
    const rootGroup = new Group();
    return this.getSublevel(data, 0, groupByColumns, rootGroup);
  }

  getSublevel(data: TableElement[], level: number, groupByColumns: string[], parent: Group): any[] {
    // Recursive function, stop when there are no more levels.
    if (level >= groupByColumns.length) {
      return data;
    }
    const groups = this.uniqueBy(
      data.map(
        (row) => {
          const result = new Group();
          result.level = level + 1;
          result.parent = parent;
          for (let i = 0; i <= level; i++) {
            // @ts-ignore
            result[groupByColumns[i]] = row.performanceData[groupByColumns[i]];
          }
          return result;
        }
      ),
      JSON.stringify);

    const currentColumn = groupByColumns[level];

    let subGroups: any = [];
    groups.forEach((group: any) => {
      const rowsInGroup = data.filter((row: any) => group[currentColumn] === row.performanceData[currentColumn]);
      const subGroup = this.getSublevel(rowsInGroup, level + 1, groupByColumns, group);
      subGroup.unshift(group);
      subGroups = subGroups.concat(subGroup);
    });
    return subGroups;
  }


  public uniqueBy(a: any, key: any): any {
    const seen: any = {};
    return a.filter((item: any) => {
      const k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
  }

  isGroup(index: number, item: any): boolean {
    return item.level;
  }

  public openEquipmentUserModal(element: TableElement): void {
    let indexOf = 0;
    this.inputDataSeries.forEach((d, index) => {
      if (d.id === element.id) {
        indexOf = index;
      }
    });
    const dialogRef = this.dialog.open(EquipmentPerformancesDialogComponent, {
      height: '700px',
      data: {
        data: [...this.inputDataSeries],
        chartData: {...this.chartData},
        currentElement: element,
        minDateSelected: this.instCriteria.filters.minDate,
        maxDateSelected: this.instCriteria.filters.maxDate,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('EquipmentPerformancesDialogComponent onClickUser afterClosed result', result);
    });
  }
}
