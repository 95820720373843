import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  TableElement
} from '@equipment/components/equipment-details/equipment-performances/interfaces/TableElement.interface';
import * as Highcharts from 'highcharts';
import HighchartsBoost from 'highcharts/modules/boost';
import noData from 'highcharts/modules/no-data-to-display';
import moment from 'moment-timezone';
import { PagedResult } from '@shared/services/paged-result';
import { InstChartData } from '@shared/models/inst-chart-data';
import { TranslateService } from '@ngx-translate/core';

HighchartsBoost(Highcharts);
noData(Highcharts);
export interface ArrayHours {
  [key: string]: number;
}
@Component({
  selector: 'app-equipment-performances-dialog',
  templateUrl: './equipment-performances-dialog.component.html',
  styleUrls: ['./equipment-performances-dialog.component.scss']
})
export class EquipmentPerformancesDialogComponent implements OnInit {
  constructor(
    private translateService: TranslateService,
    private dialogRef: MatDialogRef<EquipmentPerformancesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}
  public trInstant: (key: string | Array<string>, interpolateParams?: Object) => string | any;
  public Highcharts: typeof Highcharts = Highcharts;
  public chartOptions: Highcharts.Options;
  public chartOptionsHours: Highcharts.Options;
  public chartOptionsEnergy: Highcharts.Options;
  public series: any = [];
  public chartData: PagedResult<InstChartData> = this.data.chartData;
  public filterCategories: any[] = [];
  updateFlag = false;
  allData: TableElement[] = this.data.data;
  currentData: TableElement = this.data.currentElement;
  minDateSelected: string = this.data.minDateSelected;
  maxDateSelected: string = this.data.maxDateSelected;
  displayedColumns: string[] = ['tempsTotal', 'freqDemar', 'tpsMinBetweenDemar', 'tpsMinWorking', 'tpsMaxWorking'];
  startupHours: any[] = this.getStartupHoursFrequency(this.currentData);
  chartIsLoading = true;

  public async ngOnInit(): Promise<void> {
    this.trInstant = this.translateService.instant;
    this.trInstant = this.trInstant.bind(this.translateService);
    this.buildHoursStartupCountChartOptions();
    this.buildStartupAfterStopFrequenciesOptions();
    this.buildFilters(this.currentData);
    this.buildChartOptions(this.chartData);
    this.updateFlag = true;
    setTimeout(() => {
      this.chartIsLoading = false;
    }, 500);
  }
  public buildFilters(currentData: TableElement): void {
    const originalFilter = { key: '', name: '', valueSuffix: 'kW/h', checked: true, };
    const newPowerFilter = {...originalFilter};
    const newIntensityFilter = {...originalFilter};
    const newCosPhiFilter = {...originalFilter};
    newPowerFilter.key = 'energy.power.' + currentData.id;
    newPowerFilter.name = this.trInstant('website.equipment.config.performance.power') + ' ' + currentData.name;
    newIntensityFilter.key = 'energy.intensity.' + currentData.id;
    newIntensityFilter.name = this.trInstant('website.equipment.config.performance.intensity') + ' ' + currentData.name;
    newCosPhiFilter.key = 'energy.cos_phi.' + currentData.id;
    newCosPhiFilter.name = this.trInstant('website.equipment.config.performance.cos_phi') + ' ' + currentData.name;
    const NEW_FILTERS: any = [newPowerFilter, newIntensityFilter, newCosPhiFilter];
    this.filterCategories = JSON.parse(JSON.stringify(NEW_FILTERS));
  }
  private buildEnergySeriesPointValues(chartData: PagedResult<InstChartData>, filterKey: string): any[] {
    let pointValues: any[];
    const items = chartData.itemsPage.map((item: any) => {
      const itemEnergy = item.energy?.find((element: any) => element.engine_id === this.currentData.id);
      return [item, itemEnergy];
    });
    if (filterKey === 'energy.power.' + this.currentData.id) {
      pointValues = items.map((item: any) => [
        new Date(item[0]?.date_time).getTime(),
        (item[1] && item[1].power ? item[1].power : 0)
      ]);
    } else if (filterKey === 'energy.intensity.' + this.currentData.id) {
      pointValues = items.map((item: any) => [
        new Date(item[0]?.date_time).getTime(),
        (item[1] && item[1].intensity ? item[1].intensity : 0)
      ]);
    } else if (filterKey === 'energy.cos_phi.' + this.currentData.id) {
      pointValues = items.map((item: any) => [
        new Date(item[0]?.date_time).getTime(),
        (item[1] && item[1].cos_phi ? item[1].cos_phi : 0)
      ]);
    }
    return pointValues;
  }
  private buildSeriesObject(filterItem: any, chartData: PagedResult<InstChartData>): any {
    const seriesObject = {
      id: filterItem.key,
      name: filterItem.name,
      data: [...this.buildEnergySeriesPointValues(chartData, filterItem.key)],
      tooltip: {
        valueSuffix: filterItem.valueSuffix
      },
      boostThreshold: 1,
      yAxis: 0
    };
    return seriesObject;
  }
  private buildChartSeries(chartData: PagedResult<InstChartData>): any[] {
    const energyChartSeries = [];
    for (const filterItem of this.filterCategories) {
      energyChartSeries.push({ ...this.buildSeriesObject(filterItem, chartData) });
    }
    return energyChartSeries;
  }

  private buildChartOptions(chartData: PagedResult<InstChartData>): void {
    this.chartOptionsEnergy = {
      chart: { zoomType: 'x', marginTop: 100, height: 650, width: 1000 },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: ['viewFullscreen'],
            symbol: 'url(../../../../../../assets/images/open_in_full.png)',
            symbolSize: 20,
            symbolX: 23,
            symbolY: 23
          }
        }
      },
      colors: ['#a6cee3', '#1f78b4', '#b2df8a', '#33a02c', '#fb9a99', '#e31a1c', '#fdbf6f', '#ff7f00', '#cab2d6', '#6a3d9a'],
      time: { timezone: moment.tz.guess() },
      title: { text: '' },
      xAxis: [{ type: 'datetime' }
      ],
      yAxis: [{ title: { text: 'Values' }, }, { title: { text: '' }, labels: { enabled: false } }],
      tooltip: {
        formatter(): any {
          if (this.points) {
            return this.points.reduce((previous: any, current: any) => {
              return `${previous}<br/>${current.series.name}: ${current.y}${current.series.options.tooltip.valueSuffix}`;
            }, `<i>Tirez pour zoomer</i><br/><br/><b>${moment(this.x).format('DD/MM/YYYY HH:mm:ss')}</b>`);
          } else {
            return `<b>${moment(this.x).format('DD/MM/YYYY HH:mm:ss')}</b>`;
          }
        },
        style: {
          color: '#66788a',
          fontWeight: 'normal',
          fontSize: '12px',
          letterSpacing: '-0.04px',
          lineHeight: '20px',
          fontFamily: 'Ubuntu'
        },
        shared: true,
        borderWidth: 0,
        backgroundColor: '#fff',
        borderRadius: 8,
        padding: 15
      },
      legend: {
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'top',
        floating: true,
        backgroundColor: Highcharts.defaultOptions.legend.backgroundColor
      },
      series: [...this.buildChartSeries(chartData)],
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              legend: {
                floating: false,
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
                x: 0,
                y: 0
              },
              yAxis: [
                {
                  labels: {
                    align: 'right',
                    x: 0,
                    y: -6
                  },
                  showLastLabel: true
                },
                {
                  labels: {
                    align: 'left',
                    x: 0,
                    y: -6
                  },
                  showLastLabel: true
                },
                {
                  visible: false
                }
              ]
            }
          }
        ]
      },
      credits: { enabled: false },
      lang: { noData: 'Error' }
    };
  }
  public getStartupHours(currentElement: TableElement): ArrayHours[] {
    const arrayHours: ArrayHours[] = [];
    const element = currentElement.data;
    let i = 0;
    let y = 0;
    while (i < element.length) {
      const currentHour = new Date(element[i].x).getHours();
      y = 0;
      let startupCount = 0;
      let tpsBetweenStartup = 0;
      while (y < element.length) {
        if (new Date(element[y].x).getHours() === currentHour) {
          startupCount++;
        }
        y++;
      }
      const hourStartup: ArrayHours = {[currentHour]: startupCount};

      const nextElem = element[i + 1] ? element[i + 1] : undefined;
      if (nextElem && element.length > 1) {
        tpsBetweenStartup = ((nextElem.x / 1000) - (element[i].x2 / 1000));
        hourStartup.interval = tpsBetweenStartup;
      } else if (!nextElem && element.length > 1){
        tpsBetweenStartup = ((element[i].x / 1000) - element[i - 1].x2 / 1000);
        hourStartup.interval = tpsBetweenStartup;
      }
      arrayHours.push(hourStartup);
      i++;
    }
    return arrayHours;
  }
  public getStartupHoursFrequency(currentElement: TableElement): [] {
    const element = currentElement.data;
    const stHours = this.getStartupHours(currentElement);
    const startupHoursSeries: any = [];

    const arrayTimeFrequencies1: any = [[], [], [], [], []];
    stHours.forEach((a: any) => {
      if (a.interval <= 10) {
        arrayTimeFrequencies1[0].push(a);
      } else if (a.interval <= 30) {
        arrayTimeFrequencies1[1].push(a);
      } else if (a.interval <= 60) {
        arrayTimeFrequencies1[2].push(a);
      } else if (a.interval <= 120) {
        arrayTimeFrequencies1[3].push(a);
      } else if (a.interval > 120) {
        arrayTimeFrequencies1[4].push(a);
      }
    });
    const newArrayFrequencies: any = [[], [], [], [], []];
    arrayTimeFrequencies1.forEach((a: any, index: number) => {
      const checkedHours: any[] = [];
      a.forEach((ap: any) => {
        const currentHours = Object.keys(ap)[0];
        if (!checkedHours.find(ch => ch === currentHours)) {
          newArrayFrequencies[index].push({[currentHours]: a.filter((aa: any) => Object.keys(aa)[0] === currentHours).length})
          checkedHours.push(currentHours);
        }
      });
    });
    newArrayFrequencies.forEach((arrays: any) => {
      const bArray: any[] = [];
      arrays.forEach((a: any) => {
        const key = Object.keys(a)[0];
        bArray.push([moment(new Date(this.minDateSelected).setHours(parseInt(key, 10), 0, 0)).toDate().getTime(), a[key]]);
      });
      startupHoursSeries.push(bArray);
    });
    return startupHoursSeries;
  }
  public getStartupAfterStopFrequenciesSeries(currentElement: TableElement): [] {
    const element = currentElement.data;
    const arrayFrequencies: any = [];
    let i = 0;
    let tpsBetweenStartup = 0;
    while (i < element.length) {
      const elem = element[i];
      const nextElem = element[i + 1] ? element[i + 1] : undefined;
      if (nextElem && element.length > 1) {
        tpsBetweenStartup = ((nextElem.x / 1000) - (elem.x2 / 1000));
        arrayFrequencies.push(tpsBetweenStartup);
      } else if (!nextElem && element.length > 1){
        tpsBetweenStartup = ((elem.x / 1000) - element[i - 1].x2 / 1000);
        arrayFrequencies.push(tpsBetweenStartup);
      }
      i++;
    }
    const arrayTimeFrequencies: any = [0, 0, 0, 0, 0];
    arrayFrequencies.forEach((a: any) => {
      if (a <= 10) {
        arrayTimeFrequencies[0]++;
      } else if (a <= 30) {
        arrayTimeFrequencies[1]++;
      } else if (a <= 60) {
        arrayTimeFrequencies[2]++;
      } else if (a <= 120) {
        arrayTimeFrequencies[3]++;
      } else if (a > 120) {
        arrayTimeFrequencies[4]++;
      }
    });
    return arrayTimeFrequencies;
  }
  public handleSelectChange(event: any): void {
    this.chartIsLoading = true;
    this.currentData = this.allData.filter(d => d.id === event.value)[0];
    this.startupHours = this.getStartupHoursFrequency(this.currentData);
    this.chartData = this.data.chartData;
    this.buildStartupAfterStopFrequenciesOptions();
    this.buildHoursStartupCountChartOptions();
    this.buildFilters(this.currentData);
    this.buildChartOptions(this.chartData);
    this.updateFlag = true;
    setTimeout(() => {
      this.chartIsLoading = false;
    }, 500);
  }
  private buildHoursStartupCountChartOptions(): void {
    this.chartOptionsHours = {
      chart: {
        type: 'column',
        zoomType: 'y',
        marginTop: 10,
        height: 200,
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: ['viewFullscreen'],
            symbol: 'url(../../../../../../assets/images/open_in_full.png)',
            symbolSize: 20,
            symbolX: 23,
            symbolY: 23
          }
        }
      },
      credits: {
        enabled: false
      },
      time: {
        timezone: moment.tz.guess()
      },
      title: {
        text: ''
      },
      xAxis: {
        title: {
          text: null
        },
        type: 'datetime',
        max: new Date(this.maxDateSelected).getTime(),
        min: new Date(this.minDateSelected).getTime(),
        tickInterval: 3600 * 1000,
      },
      yAxis: {
        title: {
          text: 'Heures de démarrages centrale'
        },
        labels: {
          overflow: 'justify',
          format: '{value}'
        },
        stackLabels: {
          enabled: true,
          align: 'center'
        }
      },
      plotOptions: {
        series: {
          stacking: 'normal'
        },
        column: {
          dataLabels: {
            enabled: false,
          }
        }
      },
      tooltip: {
        valueSuffix: '',
        stickOnContact: true,
        backgroundColor: 'rgba(255, 255, 255, 0.93)'
      },
      legend: {
        enabled: false
      },
      series: this.buildSeries()
    };
  }
  private buildStartupAfterStopFrequenciesOptions(): void {
    this.chartOptions = {
      chart: {
        type: 'column',
        zoomType: 'y',
        marginTop: 10,
        height: 200,
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: ['viewFullscreen'],
            symbol: 'url(../../../../../../assets/images/open_in_full.png)',
            symbolSize: 20,
            symbolX: 23,
            symbolY: 23
          }
        }
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      xAxis: {
        title: {
          text: null
        },
        categories: ['<= 10s', '<= 30s', '<= 60s', '<= 120s', '> 120s']
      },
      yAxis: {
        title: {
          text: 'Nombre de démarrages centrale'
        },
        labels: {
          overflow: 'justify',
          format: '{value}'
        }
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            format: '{y}'
          }
        }
      },
      tooltip: {
        valueSuffix: '',
        stickOnContact: true,
        backgroundColor: 'rgba(255, 255, 255, 0.93)'
      },
      legend: {
        enabled: false
      },
      series: [
        {
          zoneAxis: 'x',
          zones: [
            { value: 1, color: 'red'},
            { value: 2, color: 'red'},
            { value: 3, color: 'orange'},
            { value: 4, color: 'salmon'},
            { color: 'green' }
          ],
          name: 'Nombre de démarrages centrale ',
          type: 'column',
          data: this.getStartupAfterStopFrequenciesSeries(this.currentData),
          pointWidth: 100
        }
      ]
    };
  }
  public defineColor(s: number): string {
    switch (s) {
      case 0:
        return 'red';
      case 1:
        return 'red';
      case 2:
        return 'orange';
      case 3:
        return 'salmon';
      default:
        return 'green';
    }
  }
  public defineSeriesTitle(s: number): string {
    const getTitle = (num?: number) => {
      if (num) {
        return 'Nombre de démarrage moins de ' + num + 's après le dernier arrêt';
      } else {
        return 'Nombre de démarrage 120s après le dernier arrêt';
      }
    };
    switch (s) {
      case 0:
        return getTitle(10);
      case 1:
        return getTitle(30);
      case 2:
        return getTitle(60);
      case 3:
        return getTitle(120);
      default:
        return getTitle();
    }
  }
  public buildSeries(): any {
    const arraySeries: any = [];
    this.startupHours.forEach((s, index) => {
      const objSeries = {
        name: this.defineSeriesTitle(index),
        color: this.defineColor(index),
        data: [...s],
        pointWidth: 20
      };
      arraySeries.push(objSeries);
    });
    return arraySeries;
  }
}
