
  <link rel='stylesheet' href='user-list.component.scss'>
  <div class=" dni-list-content users-list">
    <app-breadcrumbs [elements]="[
        { displayValue: 'website.users.breadcrumb.management_value' | translate, routerLink: '../users' },
        { displayValue: 'website.users.breadcrumb.users_value' | translate, routerLink: '../users' }
      ]">
    </app-breadcrumbs>
    <div class="content-header">
      <h1>{{ 'website.users.table.title' | translate }}</h1>

      <div>
        <button [matx-if-has-permission]="[Permission.CreateUser]" mat-raised-button color="primary"
          (click)="openEditDialog(null)">
          <mat-icon>add</mat-icon>{{ 'website.users.table.add' | translate }}
        </button>

      </div>
    </div>
    <!-- /.content-header -->

    <mat-card>
      <mat-tab-group mat-stretch-tabs="false" [(selectedIndex)]="matTabIndex" (selectedIndexChange)="onTabChange($event)">
        <mat-tab [label]="'website.users.table.filters.status.active' | translate">
          <ng-container *ngTemplateOutlet="tabContent"></ng-container>
        </mat-tab>
        <mat-tab [label]="'website.users.table.filters.status.inactive' | translate" matBadge="4"
          matBadgeOverlap="false">
          <ng-container *ngTemplateOutlet="tabContent"></ng-container>
        </mat-tab>
        <ng-container *ngIf="isAdmin">
          <mat-tab [label]="'En attente'" matBadge="4"
                   matBadgeOverlap="false">
            <ng-container *ngTemplateOutlet="tabContent"></ng-container>
          </mat-tab>
        </ng-container>
      </mat-tab-group>
    </mat-card>

    <ng-template #tabContent>
      <div *ngIf="filtersDataReadyForView" class="filters-wrapper">
        <app-search-bar [(ngModel)]='searchInput' (onClickSearchEvent)='search($event.search)' [placeholder]="'website.users.table.filters.search_input' | translate" ></app-search-bar>

        <div class="two-buttons">
          <ng-container *ngIf='selection.selected.length <= 1 && !isAllSelectedState'>
      <span>{{ 'website.users.table.filters.selected' | translate: {
        count:
        selection.selected.length
      } }}</span>
          </ng-container>
          <ng-container *ngIf='selection.selected.length > 1 && !isAllSelectedState'>
      <span>{{ 'website.users.table.filters.selectedMany' | translate: {
        count:
        selection.selected.length
      } }}</span>
          </ng-container>
          <ng-container *ngIf='selection.selected.length > 0 && isAllSelectedState'>
      <span>{{ 'website.users.table.filters.selectedMany' | translate: {
        count:
        totalAllSelected
      } }}</span>
          </ng-container>
<!--          <span class="selected-items">{{ 'website.users.table.filters.selected' | translate: { count:-->
<!--            selection.selected.length } }}</span>-->
          <button mat-stroked-button class='pv-button-secondary' [disabled]="!selection.selected.length" (click)="deactivateBulk()"
            *ngIf="matTabIndex === 0">
            <i class="material-icons material-icons-outlined">block</i>{{ 'website.users.table.actions.inactivate' |
            translate }}
          </button>
          <button mat-stroked-button class='pv-button-secondary' [disabled]="!selection.selected.length" (click)="activateBulk()"
            *ngIf="matTabIndex === 1">
            <i class="material-icons material-icons-outlined">check_circle_outline</i>{{
            'website.users.table.actions.activate' | translate }}
          </button>
          <!-- <button mat-stroked-button><mat-icon>delete</mat-icon>{{ 'website.users.table.actions.suppress' | translate }}</button> -->
        </div>

      </div>


      <mat-divider></mat-divider>
      <div class="d-flex justify-content-between py-2">

          <mat-form-field class='custom-select-like-dropdown col-md-2' appearance="fill" *ngIf="generalCompanyFilterOptions?.length">
            <mat-label>{{ 'website.users.table.filters.company_lbl' | translate }}</mat-label>
            <mat-select  [compareWith]='compareObjects' [formControl]="companiesFilterCtrl" multiple
              matTooltip="{{ trInstant('website.users.table.filters.company_lbl', crtLang) }}" [disabled]="false"
              >
              <mat-option [value]="generalCompany" *ngFor="let generalCompany of generalCompanyFilterOptions">{{
                generalCompany.name }}
              </mat-option>
            </mat-select>

          </mat-form-field>
          <mat-form-field class='custom-select-like-dropdown col-md-2' appearance="fill" *ngIf="rolesFilterOptions?.length">
            <mat-label>
              {{ 'website.users.table.filters.role_lbl' | translate }}
            </mat-label>

            <mat-select  [formControl]='rolesFilterCtrl'
              matTooltip="{{ trInstant('website.users.table.filters.role_lbl', crtLang) }}" multiple>
              <mat-option *ngFor="let role of rolesFilterOptions" [value]="role._id">{{ role.name }}</mat-option>
            </mat-select>
          </mat-form-field>
<div class='align-content-end align-items-end col ms-auto'>
  <button class="float-end" style='position:relative;top: -11px!important; right: 25px'  mat-button (click)="removeFilters()" [disabled]="false">
    {{ 'website.users.table.filters.remove_filters' | translate }}
  </button>
</div>

      </div>

      <div *isLoading='isLoading'>
        <table mat-table [dataSource]="collection?.itemsPage" matSort matSortDirection='desc' (matSortChange)="sortData($event)">
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox class="example-margin" [color]="'primary'" (change)="masterToggle()"
                            [checked]="selection.hasValue() && isAllSelectedState"
                            [indeterminate]="selection.hasValue() && !isAllSelectedState">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let user">
              <mat-checkbox  [color]="'primary'" class="example-margin" (change)="$event ? handleRowCheckbox(user) : null"
                [checked]="selection.isSelected(user) || isChecked(user)" [disabled]="!user.canBeRead || !user.canBeEdited"></mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'website.users.table.columns.name' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="user-name-wrapper">
                <!-- <app-profile-image [userInfo]="element"></app-profile-image> -->
                {{ element.lastName }} {{ element.firstName }}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="firm">
            <th mat-header-cell *matHeaderCellDef>{{ 'website.users.table.columns.company' | translate }}</th>
            <td mat-cell *matCellDef="let element">
              {{ element.companyName }}
            </td>
          </ng-container>

          <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef>{{ 'website.users.table.columns.role' | translate }}</th>
            <td mat-cell *matCellDef="let user">
              {{ user.getRolesNames().join(', ') }}
            </td>
          </ng-container>

          <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef>{{ 'website.users.table.columns.active' | translate }}</th>
            <td mat-cell *matCellDef="let element">
              {{ (element.active ? 'website.users.table.enums.active_user.active' :
              'website.users.table.enums.active_user.inactive') | translate }}
            </td>
          </ng-container>

          <ng-container matColumnDef="createdOn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'website.users.table.columns.created_at' | translate
              }}</th>
            <td mat-cell *matCellDef="let element">
              {{ element.createdOn | date }}
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>{{ 'website.users.table.columns.action' | translate }}</th>
            <td mat-cell *matCellDef="let user" class="actions">
              <!-- todo: remove disabled-link class & add click event when you can edit/create users -->
              <div  class='d-flex'>
                <div    class=' d-inline pointer-event ' [ngClass]="{ 'disabled-link': !user.canBeEdited }" [matTooltip]="
                  user.canBeEdited ? ('website.users.table.edit_user' | translate) : ('website.users.table.no_access' | translate)
                " [matTooltipPosition]="position.value" (click)="openEditDialog(user)">
                  <span class="custom-icon custom-icon-edit-alt"></span>
                </div>
                <div [ngClass]="{ 'disabled-link': !user.canBeRead }"
                   class=' d-inline pointer-event'
                   [matTooltip]="user.canBeRead ? ('website.users.table.user_info' | translate) : ('website.users.table.no_access' | translate)"
                   [matTooltipPosition]="position.value" (click)="openUserInfoModal(user); $event.stopPropagation()">
                  <mat-icon>arrow_forward</mat-icon>
                </div>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

      </div>

      <div *ngIf="collection?.itemsPage?.length === 0 && !isLoading" class="no-results-container">
        <img src="assets/images/no-matching-results.svg" />
        <div class="no-results-text">
          <p>
            {{ 'website.customer_location.table.errors.' + (hasFilters ? 'no_results' : 'no_collection') | translate }}
          </p>
          <p *ngIf="hasFilters">
            {{ 'website.customer_location.table.errors.try_again' | translate }}
          </p>
        </div>
      </div>


      <mat-paginator (page)="onPaginatorChange($event)" [length]="collection?.total" [pageSize]="10"
        [pageSizeOptions]="[5, 10, 25, 100]">
      </mat-paginator>
    </ng-template>
  </div>

