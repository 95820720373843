import gql from "graphql-tag";

export const saveUserMigration = gql`
  mutation saveUserMigration($email: String!) {
    saveUserMigration(email: $email) {
      _id
      email
      status
      date
      accountExists
    }
  }
`;
export const saveUserInfos = gql`
  mutation saveUserInfos($input: UserMigrationUpdateInput!) {
    saveUserInfos(input: $input) {
      status
    }
  }
`;


export const createUserFromMigration = gql`
  mutation createUserFromMigration($input: UserMigrationCreateInput!) {
    createUserFromMigration(input: $input) {
      status
    }
  }
`;
