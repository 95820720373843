import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export interface ValidationResult {
  [key: string]: boolean;
}

export const StrengthPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const hasNumber = /\d/.test(control.value);
  const hasUpper = /[A-Z]/.test(control.value);
  const hasLower = /[a-z]/.test(control.value);
  const hasLength = control.value.length > 5;
  const valid = hasNumber && hasUpper && hasLower && hasLength;

  if (!valid) {
    return {
      notNumber: !hasNumber,
      notUpper: !hasUpper,
      notLower: !hasLower,
      notLength: !hasLength,
      isInvalid: !hasNumber || !hasUpper || !hasLower || !hasLength
    };
  }
  return null;
};
