<div  class="modal-header">
  <h1 mat-dialog-title>Alarme Niveau Bas</h1>
  <mat-icon  (click)="onNoClick()">close</mat-icon>
</div>

<div mat-dialog-content class="content px-md-4">
  <div class="alert alert-warning mt-3 " role="alert">
    <div class="row">
      <div class="col-1">
        <svg width="24" height="25" viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_7031_66686)">
            <path
              d="M12 6.49L19.53 19.5H4.47L12 6.49ZM12 2.5L1 21.5H23L12 2.5ZM13 16.5H11V18.5H13V16.5ZM13 10.5H11V14.5H13V10.5Z"
              fill="#FF9800" />
          </g>
          <defs>
            <clipPath id="clip0_7031_66686">
              <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div class="col-11">
        {{ 'website.equipment.detail.alarm_modal.reglementation_time' | translate: { reg_time: reg_time } }}
      </div>
    </div>
  </div>
  <app-alarm-dni-infos [alarm_id]="id" [alarmPreparedData]='alarmPreparedData' (url)="naviagteTo($event)"></app-alarm-dni-infos>
  <app-alarm-accordion [alarm_id]="id"></app-alarm-accordion>
</div>

<div mat-dialog-actions [align]='"end"' class=' border-top border-1'>
  <button mat-button class="button-border" (click)='copyPasta()' [disabled]='disableSharebtn'><mat-icon>share</mat-icon> {{ 'website.equipment.detail.alarm_modal.share' | translate }}</button>
  <div class='copied-to-clipboard' *ngIf='showCopiedToClipboard'>
    <mat-icon>check</mat-icon>
    <p>{{ 'website.equipment.detail.alarm_modal.copied_link' | translate }}</p>
  </div>
  <matelex-secondary-button [label]="'website.equipment.detail.alarm_modal.close' | translate" (click)='onNoClick()'></matelex-secondary-button>
</div>
