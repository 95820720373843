 <div class="modal-header">
    <h1 mat-dialog-title>{{ 'website.users.dialog.title1' | translate }} {{ editedUser?.firstName }} {{ editedUser?.lastName }}</h1>
    <mat-icon (click)="onCloseClick()">close</mat-icon>
  </div>

 <mat-dialog-content  class='mb-3 p-0'   *ngIf="dataReadyToRender">
   <div class='container-fluid'>
     <mat-tab-group mat-stretch-tabs="false">
       <mat-tab>
         <ng-template mat-tab-label>
          <span
            matBadge="!"
            [matBadgeHidden]="!submitToServerTried || !validationErrors.userFormNotValid"
            [matTooltipDisabled]="!submitToServerTried || !validationErrors.userFormNotValid"
            matTooltip="{{ userDataTabErrorMessage }}"
            [ngClass]="{ rolesTabHasError: validationErrors.userFormNotValid && submitToServerTried }"
          >{{ 'website.users.dialog.tabs.contact.title' | translate }}</span
          >
         </ng-template>

         <form [formGroup]="userForm" class='p-2'>
           <div class='container'>
             <div class='row justify-content-center'>

               <div class='col-md-5'>
                 <mat-form-field appearance="outline" class='col-12' >
                   <input
                     matInput
                     placeholder="{{ 'website.users.dialog.tabs.contact.fields.first_name' | translate }}"
                     name="firstName"
                     [formControlName]="'firstName'"
                     #firstName
                     (change)="isInputValidForSubmit()"
                   />
                   <mat-error
                     class="user-edit-form-error"
                     *ngIf="userForm.controls.firstName.hasError('required') && userForm.controls.firstName.touched"
                   >
                     {{ 'website.users.dialog.tabs.contact.errors.first_name_required' | translate }}
                   </mat-error>
                 </mat-form-field>
               </div>


<div class='col-md-5'>
  <mat-form-field appearance="outline" class='col-12'  >
    <mat-label>{{ 'website.users.dialog.tabs.contact.fields.last_name' | translate }}</mat-label>
    <input
      matInput
      name="lastName"
      [formControlName]="'lastName'"
      placeholder="{{ 'website.users.dialog.tabs.contact.fields.last_name' | translate }}"
      (change)="isInputValidForSubmit()"
    />
    <mat-error
      *ngIf="userForm.controls.lastName.hasError('required') && userForm.controls.lastName.touched"
    >
      {{ 'website.users.dialog.tabs.contact.errors.last_name_required' | translate }}
    </mat-error>
  </mat-form-field>
</div>



             </div>
             <div class='row justify-content-center mt-1'>
               <div class='col-md-5'>
                 <phone-indicatif (indicatif)='handleIndicatifChange()'
                                  (newPhone)='handlePhoneChange($event)'
                                  [phone]='editedUser.phone'></phone-indicatif>

               </div>
    <div class='col-md-5' *ngIf='userForm.get("userToken") !== null'>
      <mat-form-field  appearance="fill" class='col-12' >
        <mat-label>ID PolarBox</mat-label>
        <input matInput [type]="hide ? 'password' : 'text'"  [formControlName]="'userToken'" [disabled]="true">
        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
      </mat-form-field>
    </div>
           <div class='col-md-5 '>
             <mat-form-field  appearance="outline" class='col-12' >
               <mat-label>{{ 'website.users.dialog.tabs.contact.fields.email' | translate }}</mat-label>
               <input
                 matInput
                 name="email"
                 [formControlName]="'email'"
                 placeholder="{{ 'website.users.dialog.tabs.contact.fields.email' | translate }}"
                 (change)="isInputValidForSubmit()"
               />
               <mat-error  *ngIf="userForm.controls.email.hasError('required') && userForm.controls.email.touched">
                 {{ 'website.users.dialog.tabs.contact.errors.email_required' | translate }}
               </mat-error>
               <mat-error
                 *ngIf="userForm.controls.email.hasError('emailAreadyExists') && userForm.controls.email.touched"
               >
                 {{ 'website.users.dialog.tabs.contact.errors.email_aready_wxists' | translate }}
               </mat-error>
             </mat-form-field>

           </div>


               <div class='col-md-5 '>
               <button mat-raised-button color="primary" class='mt-3' *ngIf='userForm.get("userToken") !== null'  (click)='sendTestEmail()' >
                 {{ 'website.users.dialog.test_connection' | translate }}
               </button>
             </div>

               <div class='col-md-5 ' [matx-if-has-permission]='[Permission.MatelexAdminPermission]'>
                 <mat-checkbox
                   [color]="'primary'"
                   [formControl]="createAdminCtrl"
                  >
                   Admin
                 </mat-checkbox>
               </div>

               <div class='col-md-5 '>

               </div>

             </div>
           </div>
         </form>

       </mat-tab>

       <mat-tab *ngIf='!createAdminCtrl.value'>
         <ng-template mat-tab-label>
          <span
            matBadge="!"
            [matBadgeHidden]="!submitToServerTried || !validationErrors.minimumOneUserRole"
            [matTooltipDisabled]="!submitToServerTried || !validationErrors.minimumOneUserRole"
            matTooltip="{{ rolesTabErrorMessage }}"
            [ngClass]="{ rolesTabHasError: submitToServerTried && validationErrors.minimumOneUserRole }"
          >{{ 'website.users.dialog.tabs.stores.title' | translate }}</span
          >
         </ng-template>
     <div class='container-fluid'>
       <div class='row'>
         <form  [formGroup]="userRolesForm" class='custom-search-autocomplete'>
           <div class="form-group col-md-5  ">
             <mat-form-field class="example-full-width" appearance="outline">
               <mat-icon matPrefix>search</mat-icon>
               <input type="text"
                      [placeholder]="'website.users.dialog.tabs.stores.filters.search_input' | translate"
                      matInput
                      name="name"
                      [formControlName]="'name'"
                      [matAutocomplete]="nameAutocomplete">
               <mat-autocomplete    #nameAutocomplete="matAutocomplete"
                                    autoActiveFirstOption
                                    [displayWith]="companyAutoCompleteDisplayForSelectedCompany"
                                    (optionSelected)="onSearchCompanySelected($event)">
                 <mat-option *ngFor="let company of foundCompaniesForEditUser" [value]="company">
                   {{ company.name }}
                 </mat-option>
               </mat-autocomplete>
             </mat-form-field>

             </div>

         </form>

       </div>

       <table  mat-table [dataSource]="editedUser.roles">
         <ng-container matColumnDef="companyName">
           <th mat-header-cell *matHeaderCellDef></th>

           <td mat-cell *matCellDef="let role">
             {{ role.userRole.getCompanyName() }}
           </td>
         </ng-container>

         <ng-container matColumnDef="role">
           <th mat-header-cell *matHeaderCellDef></th>
           <td mat-cell *matCellDef="let role; let roleIndex = index">
             <!-- {{ role.name }}<br /> -->
             <mat-form-field appearance="outline" class='mt-3'>
               <!-- <mat-label>{{ 'website.users.dialog.tabs.stores.enums.roles.title' | translate }}</mat-label> -->
               <mat-select
                 [value]="role._id"
                 class="select-possible-roles-for-company auto-width"
                 (selectionChange)="onChangePossibleRoleForCompany($event, role, roleIndex)"
               >
                 <mat-option
                   *ngFor="let possibleRoleForCompany of this.getPossibleRolesForCompany(role)"
                   [value]="possibleRoleForCompany._id"
                 >{{ possibleRoleForCompany.name }}</mat-option
                 >
               </mat-select>
             </mat-form-field>
           </td>
         </ng-container>


         <ng-container matColumnDef="actions">
           <th mat-header-cell *matHeaderCellDef></th>
           <td mat-cell *matCellDef="let role; let roleIndex = index" class="align-center">
                <span
                  class="material-icons-outlined icon-delete-role"
                  [matTooltip]="'website.users.dialog.tabs.stores.actions.delete' | translate"
                  aria-hidden="false"
                  aria-label="Delete"
                  (click)="onClickRemoveRole($event, roleIndex)"
                >
                  delete
                </span>
           </td>
         </ng-container>

         <tr mat-header-row *matHeaderRowDef="userStoreDisplayedColumns"></tr>
         <tr mat-row *matRowDef="let row; columns: userStoreDisplayedColumns"></tr>
       </table>
     </div>
       </mat-tab>
       <mat-tab         *ngIf='token'>
         <ng-template mat-tab-label>
         {{ 'website.users.dialog.tabs.alarms.title' | translate }}
         </ng-template>

         <app-user-customers-locations [useFilteredAlarmsFormControl]='useFilteredAlarmsFormControl'  [user]='editedUser' (useAlarmsFilterChangeEvent)='useAlarmsFilterChange($event.customerLocation,$event.useAlarmsFilterValue)'></app-user-customers-locations>



       </mat-tab>
     </mat-tab-group>
     <ng-container *ngIf="userMigration">

       <h3>Informations à valider pour terminer la migration de {{userMigration[0].firstName}}</h3>
       <table mat-table [dataSource]="userMigration" class="mat-elevation-z0">
         <ng-container matColumnDef="firstName">
           <th mat-header-cell *matHeaderCellDef>{{'website.users.modal.fields.first_name' | translate}}</th>
           <td mat-cell *matCellDef="let element">{{element.firstName}}</td>
         </ng-container>

         <ng-container matColumnDef="lastName">
           <th mat-header-cell *matHeaderCellDef>{{'website.users.modal.fields.last_name' | translate}}</th>
           <td mat-cell *matCellDef="let element">{{element.lastName}}</td>
         </ng-container>

         <ng-container matColumnDef="company">
           <th mat-header-cell *matHeaderCellDef>{{'website.users.modal.fields.company' | translate}}</th>
           <td mat-cell *matCellDef="let element">{{element.company}}</td>
         </ng-container>

         <ng-container matColumnDef="email">
           <th mat-header-cell *matHeaderCellDef>{{'website.users.modal.fields.email' | translate}}</th>
           <td mat-cell *matCellDef="let element">{{element.email}}</td>
         </ng-container>

         <ng-container matColumnDef="phone">
           <th mat-header-cell *matHeaderCellDef>{{'website.users.modal.fields.phone' | translate}}</th>
           <td mat-cell *matCellDef="let element">{{element.phone}}</td>
         </ng-container>

         <ng-container matColumnDef="role">
           <th mat-header-cell *matHeaderCellDef>Role</th>
           <td mat-cell *matCellDef="let element"><p *ngFor="let role of userMigrationRoles">
             {{role.name}}
           </p></td>
         </ng-container>

         <tr mat-header-row *matHeaderRowDef="columnsUserMigration"></tr>
         <tr mat-row *matRowDef="let row; columns: columnsUserMigration"></tr>
       </table>
     </ng-container>
   </div>

  </mat-dialog-content>

<mat-divider></mat-divider>
 <mat-dialog-actions align="end">
    <button mat-button (click)="onCloseClick()">Fermer</button>
    <button mat-raised-button color="primary" cdkFocusInitial (click)="onSubmitUserEdit()">
      {{ 'website.users.dialog.button_submit_form' | translate }}
    </button>
 </mat-dialog-actions>
