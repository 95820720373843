<div class="create-dni-modal">
  <div class="modal-header">
    <h1 mat-dialog-title>{{ 'website.equipment.list.unassigned_equipment_dialog.title' | translate }}</h1>
    <mat-icon tabindex="0" class='close' (click)="dismiss()">close</mat-icon>
  </div>
  <mat-divider></mat-divider>

  <div mat-dialog-content>
    <mat-tab-group mat-stretch-tabs="false" (selectedTabChange)='setTab($event)'>
      <mat-tab label="DNI">
        <form class="form-wrapper mt-2" [formGroup]="form">
          <mat-form-field appearance="outline" class='custom-mat-suffix-next-to-field'>
            <mat-label>{{ 'website.equipment.list.unassigned_equipment_dialog.fields.equipment_id' | translate }}</mat-label>
            <input matInput formControlName="equipmentId" />
            <mat-icon matSuffix *ngIf="form.controls.equipmentId.valid; else cancelIcon"> check_circle </mat-icon>
            <ng-template #cancelIcon>
              <mat-icon matSuffix class="error-icon">cancel</mat-icon>
            </ng-template>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'website.equipment.list.unassigned_equipment_dialog.fields.name' | translate }}</mat-label>
            <input matInput formControlName="equipmentName" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'website.equipment.list.unassigned_equipment_dialog.fields.reference_centrale' | translate }}</mat-label>
            <input matInput formControlName="referenceCentrale" />
          </mat-form-field>
          <ng-select  class="ng-select-equipment-create" appearance="outline"
                      [items]='customerLocations'
                      [bindLabel]="'name'"
                      [bindValue]="'_id'"
                      appendTo="body"
                      formControlName="customerLocation"
                      [placeholder]="
                               'website.equipment.list.unassigned_equipment_dialog.fields.customer_location' | translate"
                      aria-label="Ng Select Items">
          </ng-select>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'website.equipment.list.unassigned_equipment_dialog.fields.serial_number' | translate }}</mat-label>
            <input matInput formControlName="serial_number" />
          </mat-form-field>
        </form>
      </mat-tab>
      <mat-tab label="Polarbox">
        <form class="form-wrapper mt-2" [formGroup]="pbForm">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'website.equipment.list.unassigned_equipment_dialog.pb_fields.equipment_id' | translate }}</mat-label>
            <input matInput formControlName="equipmentId" />
            <mat-icon matSuffix *ngIf="form.controls.equipmentId.valid; else cancelIcon"> check_circle </mat-icon>
            <ng-template #cancelIcon>
              <mat-icon matSuffix class="error-icon">cancel</mat-icon>
            </ng-template>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'website.equipment.list.unassigned_equipment_dialog.pb_fields.unit_count' | translate }}</mat-label>
              <mat-select formControlName="unit_count" disableOptionCentering>
                <mat-option [value]="1">1</mat-option>
                <mat-option [value]="2">2</mat-option>
                <mat-option [value]="3">3</mat-option>
                <mat-option [value]="4">4</mat-option>
              </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'website.equipment.list.unassigned_equipment_dialog.pb_fields.wireless_module' | translate }}</mat-label>
            <input matInput formControlName="wireless_module" />
          </mat-form-field>
          <ng-select  class="ng-select-equipment-create" appearance="outline"
                      [items]='customerLocations'
                      [bindLabel]="'name'"
                      [bindValue]="'_id'"
                      appendTo="body"
                      formControlName="customerLocation"
                      [placeholder]="
                               'website.equipment.list.unassigned_equipment_dialog.fields.customer_location' | translate"
                      aria-label="Ng Select Items">
          </ng-select>
        </form>
      </mat-tab>

    </mat-tab-group>
    <mat-error>{{queryResult?.message}}</mat-error>
  </div>
  <mat-divider></mat-divider>
  <div mat-dialog-actions [align]='"end"'>
    <matelex-secondary-button (click)='dismiss()' [label]="'website.equipment.list.unassigned_equipment_dialog.buttons.dismiss' | translate"></matelex-secondary-button>
    <matelex-primary-button (click)='submit()' [disabled]='!isFormValid' [loading]='isLoading' [label]="'website.equipment.list.unassigned_equipment_dialog.buttons.submit' | translate"></matelex-primary-button>
<!--    <button mat-button class="btn-secondary" (click)="submit()" [disabled]="!isFormValid" cdkFocusInitial>-->
<!--      <mat-spinner class="loading-spinner" *ngIf='isLoading' diameter="37"></mat-spinner>-->
<!--      <span *ngIf='!isLoading'>-->
<!--        {{ 'website.equipment.list.unassigned_equipment_dialog.buttons.submit' | translate}}-->
<!--      </span>-->
<!--    </button>-->
  </div>
</div>
