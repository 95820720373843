import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PagedResult } from '@shared/services/paged-result';
import { Permission } from '@users/domain/services/user.enums';
import { UserService } from '@users/domain/services/user.service';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { debounceTime, skip, take } from 'rxjs/operators';

import { AppMapClickData, AppOlMapMarkerData } from '../../../shared/components/ol-map/ol-map.component';
import { CustomerLocation } from '../../../shared/models/customer-location';
import { CustomerChain } from '../../../shared/models/customer-location-chain';
import { Equipment } from '../../../shared/models/equipment';
import { LanguageService } from '../../../shared/services/language.service';
import { CustomerChainsResult } from '../../services/customer-chains-result';
import {
  EquipmentListSearchCriteria,
  EquipmentListSearchCriteriaForView
} from '../../services/equipment-list-search-criteria.interface';
import { EquipmentService } from '../../services/equipment.service';
import {
  EquipmentsGroupedByCustomerLocationResult
} from '../../services/equipments-grouped-by-customer-location.result';
import { EquipmentCreateDialogComponent } from './equipment-create-dialog/equipment-create-dialog.component';
import { DEFAULT_INSTALLERS_SEARCH_CRITERIA, InstallerService } from '@shared/services/installer.service';
import { Installer } from '@shared/models/installer';
import { equipmentStatusInfo } from '@equipment/components/equipment-details/equipment-details.component';
import { FormControl } from '@angular/forms';

const INITIAL_CRITERIA: EquipmentListSearchCriteria = {
  filters: {
    term: '',
    customerChain: [],
    country: [],
    customerLocation: [],
    installers: [],
    status: 'all',
    onlyHavingActiveAlarms: false,
    controllerHardware:[]
  },
  pagination: {
    perPage: 10,
    page: 0
  }
};

@Component({
  selector: 'app-equipment-list',
  templateUrl: './equipment-list.component.html',
  styleUrls: ['./equipment-list.component.scss']
})
export class EquipmentListComponent implements OnInit, OnDestroy {
  constructor(
    public dialog: MatDialog,
    public equipmentService: EquipmentService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private languageService: LanguageService,
    private translateService: TranslateService,
    public userService: UserService,
    public installerService: InstallerService
  ) {
    this.equipmentsGroupedByCustomerLocation$$ = equipmentService.equipmentsGroupedByCustomerLocation$$;
  }
  public Permission = Permission;

  public crtLang: string;
  public subscriptions: Subscription[] = [];
  public panelOpenState = true;
  // public totalAlarmsCount$: Observable<any> | undefined;
  public statusIndex = 0;
  public statuses: any[];
  public displayedColumns: string[] = ['equipmentNumber', 'name', 'alarmInfo', 'maintenanceDate'];
  public displayedUnassignedEquimentsColumns = ['equipmentNumber', 'name'];
  public isLoadingSearchEquipments = false;
  public inputSubject = new BehaviorSubject('');

  public customerLocationsWithEquipments: EquipmentsGroupedByCustomerLocationResult;
  public unassignedEquipments: PagedResult<Equipment>;
  public equipmentsGroupedByCustomerLocation$$: Observable<any>;
  public customerLocationChainsFilterOptions: CustomerChainsResult = null;
  public installersFilterOptions: Installer[] = null;
  public customerLocationsCountryFilterOptions: any = null;

  public customerLocationsMapCoords: Array<AppOlMapMarkerData>;
  public selectedMapMarkerCustomerLocation: CustomerLocation = null;
  public totalEquipmentsHavingAlarms: number;
  public customerChainsSearchOptions: CustomerChain[];
  public equipmentTypeSearchOptions: {hardwareVersion: string, label: string}[] = [{hardwareVersion: '1', label: 'dni'}, {hardwareVersion: '2', label: 'polarbox'}];
  public installersSearchOptions: Installer[];
  public countriesSearchOptions: string[];
  public equipmentStatusInfo = equipmentStatusInfo;
  public trInstant: (key: string | Array<string>, interpolateParams?: Object) => string | any;

  public hasAdminPermission: boolean;

  public criteria: EquipmentListSearchCriteria;
  public criteriaForView: EquipmentListSearchCriteriaForView = {
    filters: {
      customerChainsById: {},
      customerLocationsById: {},
      installersById: {},
      countries: [],
      controllerHardwareById: {}
    }
  };
  public controllerHardwareCtrl: FormControl = new FormControl();


  async ngOnInit(): Promise<void> {
    this.crtLang = this.languageService.currentLanguage;
    this.trInstant = this.translateService.instant;
    this.trInstant = this.trInstant.bind(this.translateService);

    this.statuses = [
      { value: 'all', displayValue: this.trInstant('website.equipment.list.tabs.all', this.crtLang) },
      {
        value: 'onlyHavingActiveAlarms',
        displayValue: this.trInstant('website.equipment.list.tabs.having_alarm', this.crtLang)
      },
      {
        value: 'onlyEquipmentsWithForeseenControl',
        displayValue: this.trInstant('website.equipment.list.tabs.control_foreseen', this.crtLang)
      },
      {
        value: 'notAttached',
        displayValue: this.trInstant('website.equipment.list.tabs.unassigned', this.crtLang)
      }
    ];

    this.hasAdminPermission = this.userService.loggedInUserHasPermissions([Permission.MatelexAdminPermission]);
    this.criteria = JSON.parse(JSON.stringify(INITIAL_CRITERIA));
    this.readFiltersFromRoute();

    this.equipmentService.getEquipmentListFilterCustomerLocationChains();
    this.updateCustomerChainFilterValues();

    this.equipmentService.getEquipmentFilterCountries();
    this.updateCountryFilterValues();

    this.statusIndex = this.statuses.findIndex((el: any) => el.value === this.criteria.filters.status);
    const criteriaInstaller = DEFAULT_INSTALLERS_SEARCH_CRITERIA;
    criteriaInstaller.removeInstallerWithoutCustomerLocation = true;
    const installerResult = await this.installerService.getInstallers(criteriaInstaller);
    if (Array.isArray(installerResult.itemsPage)) {
      this.criteriaForView.filters.installersById = _.keyBy(installerResult.itemsPage, '_id') as any;
    } else {
      this.criteriaForView.filters.installersById = {};
    }

    this.criteriaForView.filters.controllerHardwareById = _.keyBy(this.equipmentTypeSearchOptions, 'hardwareVersion') as any;
    this.updateInstallerFilterValues();
    this.updateData();

    this.subscriptions.push(
      this.inputSubject.pipe(skip(1), debounceTime(1000)).subscribe(() => {
        this.updateData();
      })
    );

    const customerChainsResult: CustomerChainsResult = await this.equipmentService.customerLocationChains$$.pipe(take(1)).toPromise();
    if (Array.isArray(customerChainsResult.customerChainsPage)) {
      this.criteriaForView.filters.customerChainsById = _.keyBy(customerChainsResult.customerChainsPage, '_id') as any;
    } else {
      this.criteriaForView.filters.customerChainsById = {};
    }

    const customerLocationsCountriesResult: string[] = await this.equipmentService.customerLocationsCountries$$.pipe(take(1)).toPromise();
    if (Array.isArray(customerLocationsCountriesResult)) {
      this.criteriaForView.filters.countries = customerLocationsCountriesResult;
    } else {
      this.criteriaForView.filters.customerLocationsById = {};
    }

    this.subscriptions.push(
      this.equipmentsGroupedByCustomerLocation$$.subscribe((customerLocationsWithEquipments: EquipmentsGroupedByCustomerLocationResult) => {
        if (!Array.isArray(customerLocationsWithEquipments.customerLocationsPage)) {
          return;
        }
        this.customerLocationsMapCoords = [];
        for (const customerLocation of customerLocationsWithEquipments.customerLocationsPage) {
          const markerColor: string = this.computeMarkerColor(customerLocation);
          this.customerLocationsMapCoords.push({
            lon: customerLocation.longitude as unknown as string,
            lat: customerLocation.latitude as unknown as string,
            attributes: { isMarker: true, markerColor, customerLocationId: customerLocation._id }
          });
        }
        this.customerLocationsWithEquipments = customerLocationsWithEquipments;
        this.customerLocationsWithEquipments.customerLocationsPage.map(cl => {
          cl.equipments.map(eq => {
            if (eq.main_device_id && eq.main_device_id?.hardware_version !== '1.0.0' && !eq.main_device_id.license_enabled) {
              this.userService.getDniLicense(eq.main_device_id.id.split('-')[1]).subscribe(res => {
                eq.licenceString = res;
              });
            }
          });
        });
        console.log('EquipmentListComponent ngOnInit this.customerLocationsMapCoords', this.customerLocationsMapCoords);
        this.isLoadingSearchEquipments = false;
      })
    );
  }

  public isPolarBoxNotConnected(equipment: Equipment): boolean {
    if (equipment.main_device_id?.hardware_version !== '1.0.0' && !equipment.main_device_id?.license_enabled ) {
      return true;
    } else {
      return false;
    }
  }
  public disableClickIfNotActive(equipmentGroup: CustomerLocation): boolean {
    return !this.hasAdminPermission && !equipmentGroup.is_active;
  }
  public displayEquipmentType(equipment: Equipment): string {
    if (equipment.main_device_id?.hardware_version !== '1.0.0') {
      return this.trInstant('website.equipment.list.table.polarbox_lbl', this.crtLang);
    } else {
      return this.trInstant('website.equipment.list.table.equipment_lbl', this.crtLang);
    }
  }
  /**
   * According to Confluence get the color of "Worst" EquipmentStatus (the most reddish one)
   */
  private computeMarkerColor(customerLocation: CustomerLocation): string {
    if (!Array.isArray(customerLocation.equipments)) {
      return null;
    }
    // return null;

    return customerLocation.equipments.sort((equipment1: Equipment, equipment2: Equipment) => {
      return equipment1?.status?.colorDistanceFromRed - equipment2?.status?.colorDistanceFromRed;
    })[0]?.status?.color;
  }

  public async onClickOnMap(mapClickEvent: AppMapClickData) {
    console.log('EquipmentListComponent onClickOnMap mapClickEvent', mapClickEvent);
    const equipmentsGroupedByCustomerLocation: EquipmentsGroupedByCustomerLocationResult = await this.equipmentsGroupedByCustomerLocation$$
      .pipe(take(1))
      .toPromise();
    if (mapClickEvent?.attributes?.isMarker && mapClickEvent?.attributes?.customerLocationId) {
      this.selectedMapMarkerCustomerLocation = equipmentsGroupedByCustomerLocation.customerLocationsPage.find(
        (customerLocation: CustomerLocation) => {
          return customerLocation._id === mapClickEvent?.attributes?.customerLocationId;
        }
      );

      console.log('EquipmentListComponent onClickOnMap this.selectedMapMarkerCustomerLocation', this.selectedMapMarkerCustomerLocation);
    } else {
      this.selectedMapMarkerCustomerLocation = null;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  public openCreateDialog(): void {
    const dialogRef = this.dialog.open(EquipmentCreateDialogComponent, {
      data: { name: 'test name', data: ' test data' }
    });

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.updateData();
        }
      })
    );
  }

  public onPaginatorChange(event: PageEvent): void {
    this.criteria.pagination.perPage = event.pageSize;
    this.criteria.pagination.page = event.pageIndex;
    this.updateData();
  }

  public removeFilters(): void {
    const statusFilterBackup = JSON.parse(JSON.stringify(this.criteria.filters.status));
    this.criteria = JSON.parse(JSON.stringify(INITIAL_CRITERIA));
    this.criteria.filters.status = statusFilterBackup;
    this.updateCountryFilterValues();
    this.updateCustomerChainFilterValues();
    this.updateInstallerFilterValues();
    this.updateData();
  }

  public async updateData(): Promise<void> {
    if (this.statuses[this.statusIndex].value === 'notAttached') {
      try {
        this.unassignedEquipments = await this.equipmentService.getUnassignedEquipments(this.criteria);
      } catch (e) {
        console.log(e);
      }
      return;
    } else {
      this.unassignedEquipments = null;
    }

    console.log('EquipmentListComponent updateData pass by here');
    this.isLoadingSearchEquipments = true;

    this.equipmentService.loadEquipmentsGroupedByCustomerLocation(this.criteria);

    this.selectedMapMarkerCustomerLocation = null;
    if (this.criteria.pagination.perPage === 1000) {
      this.criteria.pagination.perPage = 10;
    }
  }

  public async updateCustomerChainFilterValues(): Promise<void> {
    const customerChainsResult: CustomerChainsResult = await this.equipmentService.customerLocationChains$$.pipe(take(1)).toPromise();
    const filteredResult: CustomerChainsResult = Object.assign({}, customerChainsResult);
    filteredResult.customerChainsPage = customerChainsResult.customerChainsPage.filter(
      (clc: CustomerChain) => this.criteria.filters.customerChain.indexOf(clc._id) < 0
    );
    this.customerLocationChainsFilterOptions = filteredResult;
    this.customerChainsSearchOptions = [...filteredResult?.customerChainsPage];
    // this.customerLocationChainsFilterOptions$$.next(filteredResult);
  }

  public async updateInstallerFilterValues(): Promise<void> {
    const criteriaInstaller = DEFAULT_INSTALLERS_SEARCH_CRITERIA;
    criteriaInstaller.removeInstallerWithoutCustomerLocation = true;

    const installerPagedResult = await this.installerService.getInstallers(criteriaInstaller);
    const filteredResult = Object.assign({}, installerPagedResult);
    filteredResult.itemsPage = installerPagedResult.itemsPage.filter(
      (installer: Installer) => this.criteria.filters.installers.indexOf(installer._id) < 0
    );


    this.installersSearchOptions = [...filteredResult.itemsPage];

    this.installersFilterOptions = filteredResult.itemsPage;
  }

  public async updateEquipmentTypeFilterValues(): Promise<void> {
    const defaultValue =  [{hardwareVersion: '1', label: 'dni'}, {hardwareVersion: '2', label: 'polarbox'}];
    this.equipmentTypeSearchOptions = defaultValue.filter(
      (equipmentType: {hardwareVersion: string, label: string}) => this.criteria.filters.controllerHardware.indexOf(equipmentType.hardwareVersion) < 0
    );
    this.controllerHardwareCtrl.patchValue(null);

  }

  public async updateCountryFilterValues(): Promise<void> {
    const customerLocationsCountries: string[] = await this.equipmentService.customerLocationsCountries$$.pipe(take(1)).toPromise();
    let filteredResult: string[] = [...customerLocationsCountries];
    filteredResult = filteredResult.filter((clc: string) => this.criteria.filters.country.indexOf(clc) < 0);
    this.countriesSearchOptions = [...filteredResult];
    this.customerLocationsCountryFilterOptions = filteredResult;
  }

    public handleAutoComplete(output: string, type: string): void {
    switch (type) {
      case 'cc':
        this.criteria.filters.customerChain.push(output);
        this.updateCustomerChainFilterValues();
        break;
      case 'inst':
        this.criteria.filters.installers.push(output);
        this.updateInstallerFilterValues();
        break;
      case'equipmentType':
        console.log("test",output)
        this.criteria.filters.controllerHardware.push(output);
        this.updateEquipmentTypeFilterValues();
        console.log(this.criteria.filters.controllerHardware)
        break;
      default:
        this.criteria.filters.country.push(output);
        this.updateCountryFilterValues();
        break;
    }
    this.updateData();
  }
  public removeCustomerChainFilterKey(value: string): void {
    this.criteria.filters.customerChain.splice(this.criteria.filters.customerChain.indexOf(value), 1);
    this.updateCustomerChainFilterValues();
    this.updateData();
  }

  public removeEquipmentTypeFilterKey(value: string): void {
    this.criteria.filters.controllerHardware.splice(this.criteria.filters.controllerHardware.indexOf(value), 1);
    this.updateEquipmentTypeFilterValues();
    this.updateData();
  }

  public removeInstallersFilterKey(value: string): void {
    this.criteria.filters.installers.splice(this.criteria.filters.installers.indexOf(value), 1);
    this.updateInstallerFilterValues();
    this.updateData();
  }

  public removeCountryFilterKey(value: string): void {
    this.criteria.filters.country.splice(this.criteria.filters.country.indexOf(value), 1);
    this.updateCountryFilterValues();
    this.updateData();
  }

  public setStatusFilter(index: number): void {
    this.criteria.pagination.page = 0;
    this.criteria.filters.status = this.statuses[index].value;
    this.updateData();
  }

  public readFiltersFromRoute(): void {
    if (!this.activatedRoute.snapshot.params.criteria) {
      this.criteria = JSON.parse(JSON.stringify(INITIAL_CRITERIA));
      console.log('EquipmentListComponent readFiltersFromRoute 1 this.criteria ', this.criteria);
      return;
    }

    this.criteria = JSON.parse(this.activatedRoute.snapshot.params.criteria);
    console.log('EquipmentListComponent readFiltersFromRoute 2 this.criteria', this.criteria);
  }


  public onClickedOnEquipmentLink() {
    console.log('EquipmentListComponent onClickedOnEquipmentLink Pass By here');
    window.setTimeout(() => {
      this.readFiltersFromRoute();
      console.log('EquipmentListComponent onClickedOnEquipmentLink this.criteria', this.criteria);
      this.equipmentService.loadEquipmentsGroupedByCustomerLocation(this.criteria);
    }, 0);
  }
}
