<div class="mt-5 container">
  <h1>{{ 'website.equipment.detail.alarm_modal.accodion.analysis' | translate }} : {{minDate | date: 'shortDate'}} - {{maxDate | date: 'shortDate'}}</h1>
  <mat-accordion>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <b>{{ 'website.equipment.detail.alarm_modal.accodion.graph' | translate }}</b>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-fluid-charts *ngIf="!isLoading" [equipmentId]="equipment" [showCheckCard]="false" [minDate]="minDate" [maxDate]="maxDate"></app-fluid-charts>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <b>{{ 'website.equipment.detail.alarm_modal.accodion.log' | translate }}</b>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-logs-table *ngIf="!isLoading" [equipmentId]="equipment" [minDate]="minDate" [maxDate]="maxDate"></app-logs-table>
    </mat-expansion-panel>

    <mat-expansion-panel disabled>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <b>{{ 'website.equipment.detail.alarm_modal.accodion.advice' | translate }}</b>
        </mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel>

    <mat-expansion-panel disabled>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <b>{{ 'website.equipment.detail.alarm_modal.accodion.intervention' | translate }}</b>
        </mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel>

  </mat-accordion>
</div>
