import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { TokenSessionService } from '../../../shared/services/token.session.service';
import { environment } from '../../../../environments/environment';
import { Config } from '../../../config';
import { LanguageService } from '../../../shared/services/language.service';
import { UserService } from '../../../users/domain/services/user.service';
import { Equipment } from '@shared/models/equipment';
import { EquipmentService } from '@equipment/services/equipment.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'matelex-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup = null;
  public tooltipMessage: string = '';
  public crtLng: string = null;
  public componentIsUsedAsTokenReceiver: boolean = false;
  public hasLoginError = false;

  public countEquipments: Equipment[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private tokenSessionService: TokenSessionService,
    private languageService: LanguageService,
    private userService: UserService,
    private equipmentService: EquipmentService,
    private titleService: Title,
    private translateService: TranslateService,

  ) {}

  public ngOnInit(): void {
    this.titleService.setTitle('Matelex - ' + this.translateService.instant('website.AUTH.LOGIN.TITLE'))
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
    const token = this.route.snapshot.params.token;
    this.crtLng = this.languageService.currentLanguage || Config.defaultLanguage;

    if (token) {
      this.componentIsUsedAsTokenReceiver = true;
      this.tokenSessionService.storeToken(token, true);
      let changeLanguageAfterLoginTo = localStorage.getItem('changeLanguageAfterLoginTo');
      changeLanguageAfterLoginTo = changeLanguageAfterLoginTo ? changeLanguageAfterLoginTo : '/' + this.crtLng;
      localStorage.removeItem('changeLanguageAfterLoginTo');

      this.userService.loginStatusUpdates.next({
        userInfo: this.tokenSessionService.userInfo,
        token: this.tokenSessionService.getToken()
      });

      window.setTimeout(() => {
        this.router.navigate(['/' + changeLanguageAfterLoginTo]);
      }, 0);
    }
    this.tooltipMessage = this.translateService.instant('AUTH.TOOLTIP_ALL_FIELDS_REQUIRED');
  }

  public async onLoginSubmit(): Promise<any> {
    const login = await this.userService.login(
      this.loginForm.controls.email.value,
      this.loginForm.controls.password.value,
      true
    );
    if (login) {
      this.tokenSessionService.handleToken();
      if (this.userService.redirectUrl) {
        this.router.navigateByUrl(this.userService.redirectUrl);
        this.userService.redirectUrl = null;
      } else {
        this.router.navigateByUrl(this.route.snapshot.queryParams.returnRoute || '');
      }
    } else {
      this.hasLoginError = true;
    }
  }

  public loginWithKeycloak() {
    this.navigateTo('keycloak');
  }

  public navigateTo(provider: string): void {
    const serverUrl =
      document.location.protocol +
      '//' +
      document.location.hostname +
      environment.apiUrl.replace('/{{lang}}', '').replace('/graphql', '').replace('0.0.0.0', 'localhost');
    const serverAuthUrl = `${serverUrl}/auth/${provider}`;
    window.location.href = serverAuthUrl + '?from=front';
    localStorage.setItem('changeLanguageAfterLoginTo', this.crtLng);
  }
}
