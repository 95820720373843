import { Component, Input} from '@angular/core';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent {
  @Input() color: 'danger'|'warn'|'success'|'info'|'secondary' = 'success';
  @Input() customClass: string;
  @Input() customStyle: any = {};
  constructor() { }

}
