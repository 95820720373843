
 <ng-container *ngIf="equipment$ | async as equipment">
    <div class=" dni-description">
      <div class='d-flex'>
        <div class='d-flex'>
          <app-breadcrumbs

            [elements]="breadcrumbsElements"
            [dropDownElements]="equipmentsInCustomerLocationForBreadCrumbsComponent$ | async"
            [selectedValue]="equipmentId"
            (dropdownChange)="handleDropdownChange($event)"
          >
          </app-breadcrumbs>
        </div>

        <div  class='d-flex float-end   ms-auto mt-2'>
          <button
            mat-stroked-button
            class='pv-button-secondary me-1'
            (click)="openEquipmentConfig()"
            [matx-if-has-permission]="[Permission.RetrieveEquipmentConfig, Permission.UpdateEquipmentConfig]"
          >
            <span class="custom-icon custom-icon-settings mx-1"></span>{{ 'website.equipment.detail.config_btn' | translate }}
          </button>


          <button
            mat-raised-button
            color="primary"
            class='ms-2'
            [matMenuTriggerFor]="executeMenu"
            [matx-if-has-permission]="[Permission.CreateAcknowledgeConfigChangeRequest,Permission.CreateRelearningConfigChangeRequest,Permission.MatelexAdminPermission,Permission.CreateRecalculationConfigChangeRequest,Permission.CreateForceSyncConfigChangeRequest,Permission.CreateRebootConfigChangeRequest]"
          >
            <span class="custom-icon custom-icon-tool mx-1"></span>{{ 'website.equipment.detail.run_btn' | translate }}
          </button>

          <mat-menu #executeMenu="matMenu" class='auto-width'>
            <button mat-menu-item [matx-if-has-permission]="[Permission.CreateAcknowledgeConfigChangeRequest,Permission.MatelexAdminPermission]" (click)="sendAction(configChangeRequestAction.Acknowledge)"  [disabled]="!equipmentActiveAlarmsCount">
              <mat-icon>pan_tool</mat-icon>
              <span matTooltip="{{ 'website.equipment.detail.actions.execute.options.acknowledge' | translate }}">{{
                  'website.equipment.detail.actions.execute.options.acknowledge' | translate
                }}</span>
            </button>
            <button mat-menu-item [matx-if-has-permission]="[Permission.CreateRelearningConfigChangeRequest,Permission.MatelexAdminPermission]"  (click)="sendAction(configChangeRequestAction.Relearning)">
              <mat-icon>school</mat-icon>
              <span matTooltip="{{ 'website.equipment.detail.actions.execute.options.relearning' | translate }}">{{
                  'website.equipment.detail.actions.execute.options.relearning' | translate
                }}</span>
            </button>
            <button mat-menu-item [matx-if-has-permission]="[Permission.CreateRecalculationConfigChangeRequest,Permission.MatelexAdminPermission]" (click)="sendAction(configChangeRequestAction.Recalculation)">
              <mat-icon>settings_input_antenna</mat-icon>
              <span matTooltip="{{ 'website.equipment.detail.actions.execute.options.recalculate_reference_level' | translate }}">{{
                  'website.equipment.detail.actions.execute.options.recalculate_reference_level' | translate
                }}</span>
            </button>
            <button mat-menu-item [matx-if-has-permission]="[Permission.CreateForceSyncConfigChangeRequest,Permission.MatelexAdminPermission]"  (click)="sendAction(configChangeRequestAction.ForcedSync)">
              <mat-icon>refresh</mat-icon>
              <span matTooltip="{{ 'website.equipment.detail.actions.execute.options.refresh_data' | translate }}">{{
                  'website.equipment.detail.actions.execute.options.refresh_data' | translate
                }}</span>
            </button>
            <button mat-menu-item [matx-if-has-permission]="[Permission.CreateRebootConfigChangeRequest,Permission.MatelexAdminPermission]"  (click)="sendAction(configChangeRequestAction.Reboot)">
              <mat-icon>error_outline</mat-icon>
              <span matTooltip="{{ 'website.equipment.detail.actions.execute.options.restart_dni' | translate }}">{{
                  'website.equipment.detail.actions.execute.options.restart_dni' | translate
                }}</span>
            </button>
          </mat-menu>

        </div>
      </div>


      <div class="content-header">
        <div class="content-header-title">
          <div class="title">
            <h1>{{ equipment?.name }}</h1>
          </div>
        </div>
        <div class="content-header-text">
          <div>
            <strong>{{ 'website.equipment.detail.status_label' | translate }} : </strong
            >

            <ng-container *ngFor="let status of equipmentStatus">
              <app-badge [color]='status?.severityColor'>
                {{ 'website.alarms.' + status?.name | translate }}
              </app-badge>

            </ng-container>
            <strong>{{ 'website.equipment.detail.last_control_date_label' | translate }} :</strong>
            <span>{{
              equipment?.last_tare_date
                ? (equipment?.last_tare_date | date: 'dd/MM/yyyy')
                : trInstant('website.equipment.detail.last_control_date_not_specified')
            }}</span>
          </div>
        </div>
      </div>
      <div class="main-content w-80" >
        <mat-tab-group mat-stretch-tabs="false" (selectedTabChange)="selectedTabChange($event)" [selectedIndex]="selectedTabIndex">
          <mat-tab>
            <ng-template mat-tab-label>{{ 'website.equipment.detail.description_tab_label' | translate }} </ng-template>
            <div class="description-tab">
              <div class='row'>
             <div class='card-width  align-items-stretch'>
               <mat-card class='card-width  align-items-stretch' >
                 <mat-card-header>
                   <mat-card-title>{{ 'website.equipment.detail.identification_label' | translate }}</mat-card-title>
                   <!-- <a href="#">
                     <span class="custom-icon custom-icon-edit"></span>
                   </a> -->
                 </mat-card-header>
                 <mat-card-content>
                   <div class="mat-card-item">
                     <span>{{ 'website.equipment.detail.id_number_label' | translate }}</span>
                     <span>{{ equipment?.id }}</span>
                   </div>

                   <app-equipment-edit-fields
                     [fieldData]='editableFields[0]'
                     [equipment]='equipment'
                     [type]='"main"'
                     [value]='equipment?.referenceCentrale' (editField)='handleUpdateFields($event)'></app-equipment-edit-fields>

                   <app-equipment-edit-fields
                     [fieldData]='editableFields[1]'
                     [equipment]='equipment'
                     [type]='"main"'
                     [value]='equipment?.name' (editField)='handleUpdateFields($event)'></app-equipment-edit-fields>

                   <div class="mat-card-item">
                     <span>{{ 'website.equipment.detail.id_fluid' | translate }}</span>
                     <span>
                      {{ equipment?.fluid?.name }}
                       <ng-container *ngIf="equipment?.fluid?.id === 'CO2'">
                        <span
                          *ngIf="
                            equipment?.transcritical &&
                            !equipment?.subcritical
                          "
                        >
                          - {{ 'website.equipment.detail.transcritical' | translate }}</span
                        >
                        <span *ngIf="equipment?.subcritical">
                          - {{ 'website.equipment.detail.subcritical' | translate }}</span
                        >
                      </ng-container>
                    </span>

                     <!-- </ng-container> -->
                   </div>


                   <div class="mat-card-item">
                     <span>{{ 'website.equipment.detail.id_reference_level' | translate }}</span>
                     <span>{{ utilsService.gramsToKg(equipment?.unit_flags_and_alarms?.average_reference).toFixed(1) }} kg</span>
                   </div>
                   <div class="mat-card-item">
                     <span>{{ 'website.equipment.detail.id_low_level' | translate }}</span>
                     <span>{{ utilsService.gramsToKg(equipment?.lowLevel).toFixed(1) }} kg</span>
                   </div>
                 </mat-card-content>
               </mat-card>
             </div>
                <div class='card-width  align-items-stretch'>
                  <mat-card class='card-width  align-items-stretch'>
                    <mat-card-header>
                      <mat-card-title>{{ 'website.equipment.detail.desc_tab.history_box_label' | translate }}</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>

                      <div class="mat-card-item">
                        <span>{{ 'website.equipment.detail.desc_tab.history.installation_date' | translate }}</span>
                        <span class="text-add">
                      {{
                            equipment?.installation_date
                              ? (equipment?.installation_date | date: 'dd/MM/yyyy HH:mm:ss')
                              : trInstant('website.equipment.detail.desc_tab.history.installation_date_not_specified')
                          }}
                    </span>
                      </div>

                      <div class="mat-card-item">
                        <span>{{ 'website.equipment.detail.desc_tab.history.last_tare_date' | translate }}</span>
                        <span class="text-add">
                      {{
                            equipment?.last_tare_date
                              ? (equipment?.last_tare_date | date: 'dd/MM/yyyy HH:mm:ss')
                              : trInstant('website.date_not_specified')
                          }}
                          <span *ngIf="needsControl$ | async" class="message danger">{{
                              'website.equipment.detail.desc_tab.history.annual_control' | translate
                            }}</span>
                    </span>
                      </div>


                      <div class="mat-card-item">
                        <span>{{ 'website.equipment.detail.desc_tab.history.last_learning_date' | translate }}</span>
                        <span>{{ equipment?.last_relearning_date | date: 'dd/MM/yyyy' }}</span>
                      </div>


                      <div class="mat-card-item">
                        <span>{{ 'website.equipment.detail.desc_tab.history.last_time_live' | translate }}</span>
                        <span *ngIf="equipmentLastTimeLive">{{ equipmentLastTimeLive | date: 'dd/MM/yyyy' }}</span>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
                <div class='card-width  align-items-stretch'  *ngIf="dni" >
                  <mat-card class='card-width  align-items-stretch text-break'>
                    <mat-card-header>
                      <mat-card-title>{{ 'website.equipment.detail.version_box_label' | translate }}</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>

                      <div class="mat-card-item border-0 ">
                        <div class="container_vertical">
                          <div class="container_horizontal">
                            <span>{{ 'website.equipment.detail.device_type_label' | translate }}</span>
                            <span>{{ 'website.device.type.' + equipment?.main_device_id?.type | translate }}</span>
                          </div>
                          <div class="container_horizontal">
                            <span>{{ 'website.equipment.detail.software_version_label' | translate }}</span>
                            <span>{{ equipment?.main_device_id?.software_version }}</span>
                          </div>
                          <div class="container_horizontal">
                            <span>{{ 'website.equipment.detail.hardware_version_label' | translate }}</span>
                            <span>{{ equipment?.main_device_id?.hardware_version }}</span>
                          </div>
                          <div class="container_horizontal">
                            <span>{{ 'website.equipment.detail.kernel_version_label' | translate }}</span>
                            <span>{{ equipment?.main_device_id?.kernel_version }}</span>
                          </div>
                          <app-equipment-edit-fields
                            [fieldData]='editableFields[2]'
                            [equipment]='equipment'
                            [type]='"main"'
                            [value]='equipment?.main_device_id?.serial_number' (editField)='handleUpdateFields($event)'
                          ></app-equipment-edit-fields>
                        </div>


                      </div>
                      <div *ngFor="let childDeviceOfMainDevice of childDevicesOfMainDevice" >
                        <div class="container_vertical">
                          <div class="container_horizontal">
                            <span>{{ 'website.equipment.detail.device_type_label' | translate }}</span>
                            <span>{{ 'website.device.type.' + childDeviceOfMainDevice?.type | translate }}</span>
                          </div>
                          <div class="container_horizontal">
                            <span>{{ 'website.equipment.detail.software_version_label' | translate }} </span>
                            <span>{{ childDeviceOfMainDevice?.software_version }}</span>
                          </div>
                          <div class="container_horizontal">
                            <span>{{ 'website.equipment.detail.hardware_version_label' | translate }}</span>
                            <span>{{ childDeviceOfMainDevice?.hardware_version }}</span>
                          </div>
                          <app-equipment-edit-fields
                            [fieldData]='editableFields[3]'
                            [device]='childDeviceOfMainDevice'
                            [type]='"child"'
                            [value]='childDeviceOfMainDevice?.serial_number' (editField)='handleUpdateFields($event)'
                          ></app-equipment-edit-fields>
                        </div>

                      </div>

                    </mat-card-content>
                  </mat-card>
                </div>
                <div  class='card-width  align-items-stretch' *ngIf='polarbox'>
                  <mat-card   class='card-width  align-items-stretch'>
                    <mat-card-header>
                      <mat-card-title>Version et Identification</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>

                      <div class="mat-card-item">
                        <div class="container_vertical">
                          <div class="container_horizontal">
                            <span>PolarBox</span>
                            <span>Version {{ equipment?.main_device_id?.software_version }}</span>
                          </div>
                          <div class="container_horizontal">
                            <span></span>
                            <span>S/N : {{ equipment?.main_device_id?.serial_number }}</span>
                          </div>
                          <div class="container_horizontal">
                            <span></span>
                            <span>Licence : {{ equipment?.main_device_id?.license_enabled || 'false'}}</span>
                          </div>

                        </div>

                      </div>



                      <div *ngFor="let childDevice of childDevicesOfMainDevice" class="mat-card-item">
                        <div class="container_vertical">
                          <div class="container_horizontal">
                            <span *ngIf="childDevice.type === DeviceType.MEASURE">Module Mesure</span>
                            <span *ngIf="childDevice.type === DeviceType.GPIO">Module GPIO</span>
                            <span *ngIf="childDevice.type === DeviceType.ENERGY">Module Energie</span>
                            <span>({{ childDevice?.monitored_unit_id }}) Version {{ childDevice?.software_version }}</span>
                          </div>
                          <div class="container_horizontal">
                            <span></span>
                            <span>S/N : {{ childDevice?.serial_number }}</span>
                          </div>
                          <div class="container_horizontal" *ngIf='childDevice.type === DeviceType.MEASURE && childDevice?.strain_gauge_id!==null '>
                            <span></span>
                            <span>{{ 'website.equipment.detail.gauge' | translate }} : {{ childDevice?.strain_gauge_id }}</span>
                          </div>
                        </div>
                      </div>
                      <app-label-editable [label]="'Module Température'" [defaultValue]='hasExternalTemp?.serial_number' [required]='true' (onSubmitEvent)='createOrUpdateModuleTemp($event.value)' [hasPermissionToEdit]='[Permission.MatelexAdminPermission]' [editable]='true'    [minLength]='15'></app-label-editable>

                    </mat-card-content>
                  </mat-card>
                </div>

                <div  class='card-width  align-items-stretch'>
                  <mat-card class='card-width  align-items-stretch text-break'>
                    <mat-card-header>
                      <mat-card-title>{{ 'website.equipment.detail.installer_box_label' | translate }}</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                      <div  *ngIf='!equipment?.customerLocation?.installer'>

                        <span class='row  p-4'>{{ 'website.equipment.detail.installer.no_technician_store_contact_support' | translate }}</span>

                      </div>

                      <div *ngIf='equipment?.customerLocation?.installer?.name'>
                        <div class='mat-card-item'>
                          <span>{{ 'website.equipment.detail.installer.agency_label' | translate }}</span>
                          <span>
                      {{ equipment?.customerLocation?.installer?.name }}</span>
                        </div>

                        <div class='mat-card-item'>
                          <span>{{ 'website.equipment.detail.installer.chain_label' | translate }}</span>
                          <span>{{ equipment?.customerLocation?.installer?.chain?.name }}</span>
                        </div>

                        <div class='mat-card-item'>
                          <span>{{ 'website.equipment.detail.installer.phone' | translate }}</span>
                          <span>{{ equipment?.customerLocation?.installer?.phone }}</span>
                        </div>

                        <div class='mat-card-item'>
                          <span>{{ 'website.equipment.detail.installer.email' | translate }}</span>
                          <span>{{ equipment?.customerLocation?.installer?.email }}</span>
                        </div>

                        <div class='mat-card-item'>
                          <span>{{ 'website.equipment.detail.installer.address' | translate }}</span>
                          <span>{{ equipment?.customerLocation?.installer?.streetAndNumber + ' '
                          + equipment?.customerLocation?.installer?.postalCode + ' '
                          + equipment?.customerLocation?.installer?.city + ' '
                          + equipment?.customerLocation?.installer?.country}}</span>
                        </div>

                        <div class='mat-card-item'>
                          <span>{{ 'website.equipment.detail.installer.manager_name' | translate }}</span>
                          <span>{{ (equipment?.customerLocation?.installer?.manager)?(equipment?.customerLocation?.installer?.manager?.firstName + ' '
                            + equipment?.customerLocation?.installer?.manager?.lastName):""}}</span>
                        </div>
                      </div>

                    </mat-card-content>
                  </mat-card>
                </div>
                <div  class='card-width  align-items-stretch'>
                  <mat-card class='card-width  align-items-stretch'>
                    <mat-card-header>
                      <mat-card-title>{{ 'website.equipment.detail.localization_box_label' | translate }}</mat-card-title>
                    </mat-card-header>

                    <mat-card-content class="custom-map-card-wrapper p-2 ">
                      <div>
                        <app-ol-map
                          (clickOnMap)='onClickOnMap($event)'
                          [markersCoords]="[
                        {
                          lon: equipment?.customerLocation?.longitude,
                          lat: equipment?.customerLocation?.latitude,
                          attributes: { isMarker: true }
                        }
                      ]"
                          [center]="[equipment?.customerLocation?.longitude, equipment?.customerLocation?.latitude]"
                          [zoom]="16"
                          [containerWidth]="'100%'"
                          [containerHeight]="'400px'"
                          [containerUniqueId]="'dniDetailsMap'"
                        ></app-ol-map>
                      </div>

                      <mat-card class='popup-map ' *ngIf="this.selectedMapMarkerCustomerLocation">
                        <mat-card-header> <mat-card-title>{{ equipment?.customerLocation?.name }}</mat-card-title></mat-card-header>
                        <mat-card-content  class='py-4' >
                          <div>
                            <span>{{ equipment?.customerLocation?.streetAndNumber }}, {{ equipment?.customerLocation?.postalCode }}</span>
                            <span>{{ equipment?.customerLocation?.city }}</span>
                            <span>{{ equipment?.customerLocation?.country }}</span>
                          </div>

                        </mat-card-content>

                      </mat-card>
                    </mat-card-content>
                  </mat-card>
                </div>
              </div>









            </div>
          </mat-tab>
          <mat-tab label="{{ 'website.equipment.detail.charts.fluid_charts' | translate }}">
            <app-fluid-charts *ngIf="equipment.id && selectedTabIndex === tabs.FLUID_CHARTS" [equipmentId]="equipment.id" ></app-fluid-charts>
          </mat-tab>
          <mat-tab label="{{ 'website.equipment.detail.charts.energy_charts' | translate }}">
            <app-energy-charts *ngIf="equipment.id && selectedTabIndex === tabs.ENERGY_CHARTS" [equipmentId]="equipment.id" [equipmentEngines]="translateEnginesNames(equipment.engines)"></app-energy-charts>
          </mat-tab>

          <mat-tab *ngIf='tabIndex.includes("performance")'
                   label="{{ 'website.equipment.detail.performance_label' | translate }}">
            <app-equipment-performances *ngIf='equipment.id && selectedTabIndex === tabs.PERFORMANCE'
                                        [equipmentId]='equipment.id'
                                        [equipmentEngines]='translateEnginesNames(equipment.engines)'></app-equipment-performances>
          </mat-tab>
          <mat-tab label="{{ 'website.equipment.detail.activity_tab_label' | translate }}">
            <div class="activity-tab">
              <div class="row">
                <app-logs-table [equipmentId]="equipmentId"></app-logs-table>
                <app-equipment-advice-list [equipment]="equipment"></app-equipment-advice-list>
                <app-history-list-component [equipment]="equipment"></app-history-list-component>
              </div>
            </div>
            <!-- /.activity-tab -->
          </mat-tab>

          <mat-tab>
            <ng-template mat-tab-label>
              <span
                class='custom-badge-danger'
                [matBadge]="equipmentActiveAlarmsCount"
                matBadgePosition="above after"
                matBadgeOverlap="false"
                [matBadgeHidden]="!equipmentActiveAlarmsCount"
              >
                {{ 'website.equipment.detail.alarm_tab_label' | translate }}
              </span>
            </ng-template>
            <div class="activity-tab">
              <div class="col">
                <div class="row">
                  <app-equipment-alarms-list [equipment]="equipment" (activeAlarmsCount)="onEquipmentActiveAlarmsCount($event)">
                  </app-equipment-alarms-list>
                </div>
                <div class="row">
                  <app-equipment-alarms-history [equipment]="equipment"></app-equipment-alarms-history>
                </div>
              </div>
            </div>
          </mat-tab>

          <!-- <mat-tab label="{{ 'website.equipment.detail.documents.tab_label' | translate }}">
            <app-files [equipmentId]="tmpMockEquipmentId"></app-files>
          </mat-tab> -->

          <mat-tab label="{{ 'website.equipment.detail.users.tab_label' | translate }}">
            <ng-container *ngIf="tabIndex[selectedTabIndex] === 'users'">
              <app-equipment-user-list [equipment]="equipment" ></app-equipment-user-list>
            </ng-container>
            <!-- /.users-tab -->
          </mat-tab>

          <button mat-stroked-button color="primary"><mat-icon>add</mat-icon>Régler</button>
          <button mat-raised-button color="primary"><mat-icon>add</mat-icon>Executer</button>
        </mat-tab-group>
      </div>
    </div>
  </ng-container>

