<div id="reset-password" fxLayout="column">
  <div id="reset-password-form-wrapper" fxLayout="column" fxLayoutAlign="center center">
    <div id="reset-password-form" [@animate]="{ value: '*', params: { duration: '300ms', y: '100px' } }">
      <div class="logo">
        <img src="assets/images/project-logo.png" alt="reset-form-logo" />
      </div>

      <div class="title">{{ 'AUTH.RESET_PASSWORD.TITLE' | translate }}</div>

      <form name="resetPasswordForm" [formGroup]="resetPasswordForm" novalidate (ngSubmit)="onResetSubmit()">
        <mat-form-field>
          <input matInput [placeholder]="'AUTH.PLACEHOLDERS.EMAIL' | translate" formControlName="email" required tabindex="0" />

          <mat-error *ngIf="resetPasswordForm.get('email').hasError('required')">
            {{ 'AUTH.VALIDATIONS.EMAIL_REQUIRED' | translate }}
          </mat-error>
          <mat-error *ngIf="resetPasswordForm.get('email').hasError('email')">
            {{ 'AUTH.VALIDATIONS.EMAIL_INVALID' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput [placeholder]="'AUTH.PLACEHOLDERS.RESET_CODE' | translate" formControlName="resetCode" required tabindex="0" />

          <mat-error *ngIf="resetPasswordForm.get('resetCode').hasError('required')">
            {{ 'AUTH.VALIDATIONS.RESET_CODE_REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input
            #passwordInput
            matInput
            type="password"
            [placeholder]="'AUTH.PLACEHOLDERS.NEW_PASSWORD' | translate"
            formControlName="password"
            required
          />
          <div class="show-password" [anaisShowPassword]="passwordInput" tabindex="0">
            <i class="material-icons">remove_red_eye</i>
          </div>
          <mat-error *ngIf="resetPasswordForm.get('password').hasError('required')">
            {{ 'AUTH.VALIDATIONS.PASS_REQUIRED' | translate }}
          </mat-error>
          <mat-error *ngIf="resetPasswordForm.get('password').invalid && !resetPasswordForm.get('password').hasError('required')">
            {{ 'AUTH.VALIDATIONS.PASS_INVALID' | translate }}
          </mat-error>
        </mat-form-field>
        <anais-password-strength [control]="resetPasswordForm.get('password')"></anais-password-strength>

        <button
          mat-raised-button
          class="submit-button"
          color="accent"
          aria-label="RESET MY PASSWORD"
          [disabled]="resetPasswordForm.invalid"
        >
          {{ 'AUTH.RESET_PASSWORD.ACTION' | translate }}
        </button>
      </form>

      <div class="login" fxLayout="row" fxLayoutAlign="center center">
        <a class="link" (click)="navigateToLogin()">{{ 'AUTH.RESET_PASSWORD.REDIRECT_LOGIN' | translate }}</a>
      </div>
    </div>
  </div>
</div>
