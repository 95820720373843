<div class='container-fluid'>
  <div class='row justify-content-end'>
    <mat-form-field class='col-md-3 mt-3' appearance="outline">
      <mat-label>   {{ 'website.users.dialog.tabs.contact.fields.alarms' | translate }}</mat-label>
      <mat-select [formControl]='useFilteredAlarmsFormControl' (selectionChange)='onSelectAlarmsChange($event)'>
        <mat-option [value]="true">
          {{ 'website.users.dialog.tabs.stores.enums.alarms.all' | translate }}
        </mat-option>
        <mat-option [value]="false">
          {{ 'website.users.dialog.tabs.stores.enums.alarms.none' | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class='row justify-content-between'>
    <div class='col-md-6'>
      <app-search-bar  [placeholder]="'website.users.table.filters.search_input' | translate"  (onClickSearchEvent)='search($event.search)'  ></app-search-bar>
    </div>
  </div>

<div class='row' *ngIf='!isLoading' >

  <table  mat-table [dataSource]="this.customersLocations?.customerLocationsPage"  matSort>


    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef >{{ 'website.customer_location.table.columns.name' | translate }}</th>
      <td mat-cell *matCellDef="let customerLocation">
        {{ customerLocation.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="customerChain_name">
      <th mat-header-cell *matHeaderCellDef >{{ 'website.customer_location.table.columns.company' | translate }}</th>
      <td mat-cell *matCellDef="let customerLocation">
        {{ customerLocation.chain?.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="manager_name">
      <th mat-header-cell *matHeaderCellDef >{{ 'website.customer_location.table.columns.manager_name' | translate }}</th>
      <td mat-cell *matCellDef="let customerLocation">{{ customerLocation.manager?.name }}</td>
    </ng-container>
    <ng-container matColumnDef="alarms">
      <th mat-header-cell *matHeaderCellDef >{{'website.users.dialog.tabs.contact.fields.alarms'  | translate }}</th>
      <td mat-cell *matCellDef="let customerLocation">
        <mat-select class='bg-transparent' (selectionChange)='useAlarmsFilterChange(customerLocation,$event)' [(ngModel)]="customerLocation.isAlarmsCustomerLocationDisabled" [disabled]="!user.canBeEdited || !user?.useFilteredAlarms"  >
          <mat-option  [value]=false>{{ 'website.customer_location.dialog.tabs.users.enums.alarms.all' | translate }}</mat-option>
          <mat-option [value]=true>{{ 'website.customer_location.dialog.tabs.users.enums.alarms.none' | translate }}</mat-option>
        </mat-select>
      </td>
    </ng-container>




    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <div class='row justify-content-center p-5' *ngIf="this.customersLocations?.total === 0 && !isLoading">
  <div  class="no-results-container">
    <img src="assets/images/no-matching-results.svg" />
    <div class="no-results-text">
      <p>
        {{ 'website.customer_location.table.errors.no_results'  | translate }}
      </p>
    </div>
  </div>
  </div>
</div>
  <div class='row justify-content-center p-5' *ngIf="isLoading" >
      <mat-spinner class="loading-spinner" diameter="75"></mat-spinner>
  </div>
  <mat-paginator  (page)="onPaginatorChange($event)"
                 [length]="this.customersLocations?.total"
                 [pageSize]="10"
                 [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

</div>
