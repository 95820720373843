<div class="dni-configuration-modal-wrapper">
  <!-- HEADER -->
  <div class="modal-header border-1 border-bottom">

      <h1 mat-dialog-title> <mat-icon
        class="cr-unsolved p-3"
        *ngIf="data.totalUnsolvedChangeRequests"
        [matTooltip]="'website.equipment.config.unsolvedCR' | translate"
      >error_outline</mat-icon
      >{{ 'website.equipment.config.title' | translate }}</h1>

    <a class="close" (click)="close()">
      <span class="material-icons">close</span>
    </a>
  </div>

<div  mat-dialog-content>
  <form *ngIf="!isloading" [formGroup]="form">
    <div mat-dialog-content>
      <mat-tab-group mat-stretch-tabs="false">
        <!-- TAB ALARMS -->
        <mat-tab formGroupName="alarms">
          <ng-template mat-tab-label>
            <mat-icon *ngIf="invalidAlarmDataMessage" [matTooltip]="invalidAlarmDataMessage">error_outline</mat-icon>
            {{ 'website.equipment.config.alarms.title' | translate }}
          </ng-template>
          <div class=' container-fluid mt-2 ps-md-4 ms-md-4'>
            <div class='row'>
              <div class='col-md-11 col-12'>
                <mat-form-field  appearance="outline" class='col-md-3 col-12'>
                  <mat-label>{{ 'website.equipment.config.alarms.fields.general_low_level_limit.label' | translate }}</mat-label>
                  <input formControlName="general_low_level_limit" mask="separator.1"   autocomplete="off" matInput  />
                  <mat-error
                    *ngIf="
                    form?.get('alarms.general_low_level_limit')?.invalid &&
                    (form?.get('alarms.general_low_level_limit')?.dirty || form?.get('alarms.general_low_level_limit')?.touched)
                  "
                  >
                    {{ 'website.equipment.config.alarms.fields.general_low_level_limit.errors.no_value' | translate }}
                  </mat-error>
                  <span matSuffix>kg</span>
                </mat-form-field>
              </div>
              <div class='col-md-11 col-12'>
                <mat-form-field  appearance="outline" class='col-md-3 col-12' >
                  <mat-label>{{ 'website.equipment.config.alarms.fields.general_low_level_delay.label' | translate }}</mat-label>
                  <input formControlName="general_low_level_delay"  mask="0*"    autocomplete="off" matInput  />
                  <mat-error
                    *ngIf="
                    form?.get('alarms.general_low_level_delay').invalid &&
                    (form?.get('alarms.general_low_level_delay').dirty || form?.get('alarms.general_low_level_delay').touched)
                  "
                  >
                    {{ 'website.equipment.config.alarms.fields.general_low_level_delay.errors.no_value' | translate }}
                  </mat-error>
                  <span matSuffix>min</span>
                </mat-form-field>


              </div>


            </div>

            <div class='row' style='margin-bottom: 16px'>
              <div class='level third-level col-12'>
                <mat-slide-toggle  [color]="'primary'"  [formControl]="pumpdownStatus">{{
                    'website.equipment.config.alarms.fields.activate_second_level.label' | translate
                  }}</mat-slide-toggle>
              </div>
            </div>

            <div class='row ms-md-3 mt-2 '>
              <div class='col-md-11 col-12'>
                <mat-form-field
                  class='col-md-3 col-12'
                  appearance="outline"
                >
                  <mat-label>{{ 'website.equipment.config.alarms.fields.general_low_level_limit_pumpdown.label' | translate }}</mat-label>
                  <input
                    matInput
                    formControlName="general_low_level_limit_pumpdown"
                    autocomplete="off"
                    mask="separator.1"
                  />
                  <span matSuffix>kg</span>
                </mat-form-field>
              </div>
              <div class='col-md-11 col-12'>
                <mat-form-field
                  class='col-md-3 col-12'
                  appearance="outline"
                >
                  <mat-label>{{ 'website.equipment.config.alarms.fields.general_low_level_delay_pumpdown.label' | translate }}</mat-label>
                  <input
                    formControlName="general_low_level_delay_pumpdown"
                    matInput
                    mask="0*"
                    autocomplete="off"

                  />
                  <span matSuffix>min</span>
                </mat-form-field>
              </div>
            </div>
            <div class='row'>
              <div class="col-12">
                <p class="text-line general-label font-12">
                  {{ 'website.equipment.config.alarms.fields.general_statistic_3days_limit.label' | translate }}
                  <a
                    [matTooltip]="'website.equipment.config.alarms.fields.general_statistic_3days_limit.info' | translate"
                    [matTooltipPosition]="position.value"
                    class='icon-info'
                    aria-label="Button that displays a tooltip when focused or hovered over"
                  >
                    <span class="material-icons ">info</span>
                  </a>
                </p>
                <div class="col-md-3 col-12 m-3" >
                  <app-slider
                    class='col-12  '
                    formControlName="general_statistic_3days_limit"
                    [inferiorValue]="1"
                    [superiorValue]="6"
                    [options]="sensibilityOptions"
                  ></app-slider>
                </div>
              </div>

            </div>
          </div>

        </mat-tab>

        <!-- TAB CONFIGURATION -->
        <mat-tab formGroupName="configuration">
          <ng-template mat-tab-label>
            <mat-icon *ngIf="invalidConfigurationDataMessage" [matTooltip]="invalidConfigurationDataMessage">error_outline</mat-icon>
            {{ 'website.equipment.config.configuration.title' | translate }}
          </ng-template>
          <div class="accordion-wrapper">
            <mat-accordion >
              <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>{{ 'website.equipment.config.configuration.fluid.title' | translate }}</mat-panel-title>
                  <span class="material-icons-outlined">expand_more</span>
                </mat-expansion-panel-header>
                <!--row-->
                <div class='row'>
                  <!--column-->
                  <div class='col-md-3 col-12'>
                    <mat-form-field  appearance="outline"  class='col-12 mt-1'>
                      <mat-label>{{
                          'website.equipment.config.configuration.fields.installation_information_fluid_charge.label' | translate
                        }}</mat-label>
                      <input formControlName="installation_information_fluid_charge"   mask="separator.1" matInput autocomplete="off" />
                      <span matSuffix>kg</span>
                    </mat-form-field>
                  </div>


                  <ng-select class='col-md-3 col-12' appearance="outline"
                             [items]='fluidsResult.itemsPage'
                             appendTo="body"
                             [clearable]='false'
                             bindLabel='name'
                             [placeholder]="
                               'website.equipment.config.configuration.fields.installation_information_fluid_type.placeholder' | translate"
                             aria-label="Type de fluide"
                             formControlName="installation_information_fluid_type">
                  </ng-select>

                  <div class='col-md-3 col-12'>
                    <mat-form-field class="col-12 mt-1 " appearance="outline">
                      <mat-label>{{ 'website.equipment.config.configuration.fields.teq_CO2.label' | translate }}</mat-label>
                      <input matInput formControlName="teq_CO2" />
                      <mat-hint>
                        <ng-container *ngIf="data?.equipment?.fluid_type === 'CO2'">
                          <span *ngIf="data?.equipment?.transcritical && !data?.equipment?.subcritical">
                            {{ 'website.equipment.config.configuration.fields.transcritical' | translate }}</span
                          >
                          <span *ngIf="data?.equipment?.subcritical">
                            {{ 'website.equipment.config.configuration.fields.subcritical' | translate }}</span
                          >
                        </ng-container>
                      </mat-hint>
                    </mat-form-field>
                  </div>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>{{ 'website.equipment.config.configuration.consigne.title' | translate }}</mat-panel-title>
                  <span class="material-icons-outlined">expand_more</span>
                </mat-expansion-panel-header>
                <!--row-->
                <div class='container-fluid'>
                  <div class='row'>
                    <div class='col-md-3 col-12'>
                    <span class="input-info">{{
                        'website.equipment.config.configuration.fields.installation_information_hp_consign.label' | translate
                      }}</span>
                      <div class="mat-slider-container">
                        <app-slider
                          formControlName="installation_information_hp_consign"
                          [inferiorValue]="15"
                          [superiorValue]="50"
                        ></app-slider>
                      </div>
                    </div>
                    <!--end column-->

                    <!--column-->
                    <div class='col-md-3 col-12'>
                      <span class="input-info">{{ 'website.equipment.config.configuration.order' | translate }}</span>
                      <div class="mat-slider-container">
                        <app-slider
                          formControlName="installation_information_lp_consign"
                          [inferiorValue]="-50"
                          [superiorValue]="10"
                        ></app-slider>
                      </div>
                    </div>
                  </div>

                </div>
                <!--end row-->
              </mat-expansion-panel>

              <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>{{ 'website.equipment.config.configuration.tank.title' | translate }}</mat-panel-title>
                  <span class="material-icons-outlined">expand_more </span>
                </mat-expansion-panel-header>

                <div class='container-fluid'>
                  <div class="row">
                    <!--column-->
                    <div class='col-12 col-md-3'>
                      <mat-form-field appearance="outline" class='col-12'>
                        <mat-label>{{
                            'website.equipment.config.configuration.fields.installation_information_tank_type.label' | translate
                          }}</mat-label>
                        <mat-select formControlName="installation_information_tank_type" disableOptionCentering>
                          <mat-option *ngFor="let tank of EqConfigInstallationInformationTankType | keyvalue" [value]="tank.key">{{
                              tank.value | translate
                            }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class='col-12 col-md-3'  *ngIf="form?.get('configuration.installation_information_tank_type').value === 'HORIZONTAL'">
                      <mat-form-field

                        class="col-12"
                        appearance="outline"
                      >
                        <mat-label>{{
                            'website.equipment.config.configuration.fields.installation_information_tank_tilt.label' | translate
                          }}</mat-label>
                        <input formControlName="installation_information_tank_tilt" matInput autocomplete="off" matx-input-auto-width />
                        <span matSuffix>mm</span>
                      </mat-form-field>
                    </div>
                    <div class='col-12 col-md-3'>
                      <mat-form-field appearance="outline"
                                      class="col-12">
                        <mat-label>{{
                            'website.equipment.config.configuration.fields.installation_information_tank_number.label' | translate
                          }}</mat-label>
                        <mat-select matNativeControl formControlName="installation_information_tank_number" disableOptionCentering>
                          <mat-option *ngFor="let number of EqConfigInstallationInformationTankNumber" [value]="number">{{
                              number
                            }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class='col-12 col-md-3'>
                      <mat-form-field
                        class="col-12" appearance="outline">
                        <mat-label>{{
                            'website.equipment.config.configuration.fields.installation_information_tank_diameter.label' | translate
                          }}</mat-label>
                        <input matInput formControlName="installation_information_tank_diameter" autocomplete="off" matx-input-auto-width />
                        <span matSuffix>mm</span>
                      </mat-form-field>
                    </div>
                    <div class='col-12 col-md-3'>
                      <mat-form-field class="col-12" appearance="outline" >
                        <mat-label>{{
                            'website.equipment.config.configuration.fields.installation_information_tank_volume.label' | translate
                          }}</mat-label>
                        <input matInput formControlName="installation_information_tank_volume" autocomplete="off" matx-input-auto-width />
                        <span matSuffix>dm<sup>3</sup></span>
                      </mat-form-field>
                    </div>





                    <!--end column-->

                    <!--column-->

                    <div class='col-12 col-md-3'>
                      <mat-form-field
                        class="col-12" appearance="outline">
                        <mat-label>{{
                            'website.equipment.config.configuration.fields.installation_information_tank_height.label' | translate
                          }}</mat-label>
                        <input matInput formControlName="installation_information_tank_height" autocomplete="off" matx-input-auto-width />
                        <span matSuffix>mm</span>
                      </mat-form-field>
                    </div>
                    <!--end column-->

                    <!--column-->

                    <mat-slide-toggle [color]="'primary'"  class="slider-tank" formControlName="flags_cooler">{{
                        'website.equipment.config.configuration.fields.flags_cooler.label' | translate
                      }}</mat-slide-toggle>
                  </div>
                </div>

              </mat-expansion-panel>

              <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(3)" hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>{{ 'website.equipment.config.configuration.condenser.title' | translate }}</mat-panel-title>
                  <span class="material-icons-outlined">expand_more </span>
                </mat-expansion-panel-header>
                <!--row-->
                <div class='container-fluid'>

                  <!--column-->
                  <div class='row'>
                    <div class='col-md-4 col-12 border-end-only-md  border-1'>
                      <div class='row'>
                        <div class='col-12 '>
                          <mat-form-field appearance="outline" class='col-12 col-md-10'>
                            <mat-label>{{
                                'website.equipment.config.configuration.fields.installation_information_condeser_type.label' | translate
                              }}</mat-label>
                            <mat-select matNativeControl formControlName="installation_information_condeser_type" disableOptionCentering>
                              <mat-option *ngFor="let condenser of EqConfigInstallationInformationCondeserType | keyvalue" [value]="condenser.key"
                              >{{ condenser.value | translate }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class='col-12 '>
                          <mat-form-field class="col-12 col-md-10" appearance="outline">
                            <mat-label>{{
                                'website.equipment.config.configuration.fields.installation_information_condenser_volume.label' | translate
                              }}</mat-label>
                            <input
                              matInput
                              formControlName="installation_information_condenser_volume"
                              autocomplete="off"
                              matx-input-auto-width
                            />
                            <span matSuffix>dm<sup>3</sup></span>
                          </mat-form-field>

                        </div>

                        <div class='col-12'>
                          <mat-slide-toggle [color]="'primary'"  class="block-type" formControlName="flags_floating_hp">
                            {{ 'website.equipment.config.configuration.fields.flags_floating_hp.label' | translate }}
                          </mat-slide-toggle>

                          <mat-slide-toggle [color]="'primary'"  *ngIf="polarbox" class="block-type" formControlName="heat_recovery">
                            {{ 'website.equipment.config.configuration.condenser.fields.heat_recovery.label' | translate }}
                          </mat-slide-toggle>
                        </div>
                      </div>

                    </div>

                    <div class='col-md-5 col-12 '>
                      <div class='row ms-md-4 mt-3 mt-md-0'>
                        <div class='col-12 col-md-9'>
                          <mat-form-field appearance="outline" class='col-12'>
                            <mat-label>{{
                                'website.equipment.config.configuration.fields.installation_information_compressor_type.label' | translate
                              }}</mat-label>
                            <mat-select matNativeControl formControlName="installation_information_compressor_type" disableOptionCentering>
                              <mat-option
                                *ngFor="let compressor of EqConfigInstallationInformationCompressorType | keyvalue"
                                [value]="compressor.key"
                              >{{ compressor.value | translate }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class='col-12 col-md-9'>
                          <mat-form-field *ngIf="dni" class='col-12' appearance="outline">

                            <mat-icon class='pointer-event mat-primary' matPrefix
                                      [matTooltip]="
                          'website.equipment.config.configuration.fields.installation_information_condenser_power.info' | translate
                        "
                                      [matTooltipPosition]="position.value"
                                      aria-label="Puissance du condenseur details" >info</mat-icon>

                            <mat-label>{{
                                'website.equipment.config.configuration.fields.installation_information_condenser_power.label' | translate
                              }}</mat-label>
                            <input matInput formControlName="installation_information_condenser_power" autocomplete="off" matx-input-auto-width />
                            <span matSuffix>kW</span>
                          </mat-form-field>
                        </div>

                        <div class='col-12'>
                          <mat-slide-toggle  color='primary' class="block-type" formControlName="flags_floating_lp">
                            {{ 'website.equipment.config.configuration.fields.flags_floating_lp.label' | translate }}
                          </mat-slide-toggle>

                          <mat-slide-toggle color='primary' *ngIf="polarbox" class="block-type" formControlName="watering_condenser">
                            {{ 'website.equipment.config.configuration.condenser.fields.watering_condenser.label' | translate }}
                          </mat-slide-toggle>
                        </div>
                      </div>

                    </div>



                  </div>
                </div>

                <!--end row-->
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </mat-tab>

        <!-- TAB CARTE MESURE -->
        <mat-tab formGroupName="measureCard">
          <ng-template mat-tab-label>
            <mat-icon *ngIf="invalidMeasureCardDataMessage" [matTooltip]="invalidMeasureCardDataMessage">error_outline</mat-icon>
            {{ 'website.equipment.config.measureCard.title' | translate }}
          </ng-template>
          <div class="accordion-wrapper">
            <mat-accordion class="general-accordion">
              <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>{{ 'website.equipment.config.measureCard.pressure.title' | translate }}</mat-panel-title>
                  <span class="material-icons-outlined">expand_more </span>
                </mat-expansion-panel-header>
                <!--row-->
                <div class='container-fluid expansion-content'>
                  <div class='row'>
                    <div class='col-md-4 col-12 border-end-only-md  border-1 pe-md-5 px-sm-0'>
                      <div class='row'>

                        <div class='col-12 col-md-10'>
                                  <span class="input-info">{{
                                      'website.equipment.config.measureCard.fields.measure_configuration_hp.label' | translate
                                    }}</span>
                          <app-slider class='col-12 col-md-10' formControlName="measure_configuration_hp_min" [inferiorValue]="-1" [superiorValue]="200"></app-slider>


                          <app-slider class='col-12 col-md-10' formControlName="measure_configuration_hp_max" [inferiorValue]="-1" [superiorValue]="200"></app-slider>

                        </div>
                      </div>

                   <div class='row'>
                      <span
                        class="custom-error"
                        *ngIf="
                        !validateMinimumMaximum(
                          measureCardForm.controls.measure_configuration_hp_min.value,
                          measureCardForm.controls.measure_configuration_hp_max.value
                        )
                      "
                      >
                      {{
                          'website.equipment.config.measureCard.fields.measure_configuration_hp.errors.invalid_minimum_and_maximum'
                            | translate
                        }}
                    </span>
                   </div>

             <div class='row'>
               <div class='col-md-12'>
                 <mat-form-field class="col-12 col-md-10 mt-5" appearance="outline">
                   <mat-label>{{
                       'website.equipment.config.measureCard.fields.measure_configuration_hp_calibration.label1' | translate
                     }}</mat-label>
                   <input matInput formControlName="measure_configuration_hp_calibration" autocomplete="off" matx-input-auto-width />
                   <span matSuffix>bar</span>
                 </mat-form-field>
               </div>

             </div>
                      <div class='row'>
<div class='col-md-7'>
  <button mat-stroked-button color="primary"  (click)="resetField(['measure_configuration_hp_calibration'])">
    {{ 'website.equipment.config.measureCard.fields.measure_configuration_hp_calibration.reset_button' | translate }}
  </button>
</div>

                      </div>


                    </div>
                    <div class='col-md-4 col-12  border-end-only-md  border-1 px-md-5 px-sm-0'>
                      <div class='row'>


                        <div  class='col-12 '>
                              <span class="input-info">{{
                                  'website.equipment.config.energyModule.fields.measure_configuration_lp.label' | translate
                                }}</span>
                          <app-slider     class='col-12' formControlName="measure_configuration_lp_min" [inferiorValue]="-1" [superiorValue]="200"></app-slider>

                          <app-slider     class='col-12' formControlName="measure_configuration_lp_max" [inferiorValue]="-1" [superiorValue]="200"></app-slider>

                        </div>

                      </div>
 <div class='row'>
          <span
            class="custom-error"
            *ngIf="
                        !validateMinimumMaximum(
                          measureCardForm.controls.measure_configuration_lp_min.value,
                          measureCardForm.controls.measure_configuration_lp_max.value
                        )
                      "
          >
                      {{
              'website.equipment.config.energyModule.fields.measure_configuration_lp.errors.invalid_minimum_and_maximum'
                | translate
            }}
                    </span>
 </div>

                      <div class='row'>
                        <div class="col-12 mt-5">
                          <mat-form-field  appearance="outline" class='col-12'>
                            <mat-label>{{
                                'website.equipment.config.energyModule.fields.measure_configuration_lp_calibration.label1' | translate
                              }}</mat-label>
                            <input matInput placeholder="Insert value" formControlName="measure_configuration_lp_calibration" readonly />
                          </mat-form-field>
                      </div>

                      </div>


                      <div class='row'>
                        <div class='col-12 col-md-8'>
                          <button mat-stroked-button color="primary" (click)="resetField(['measure_configuration_lp_calibration'])">
                            {{ 'website.equipment.config.energyModule.fields.measure_configuration_lp_calibration.reset_button' | translate }}
                          </button>
                        </div>
                      </div>

                    </div>
                    <div class='col-md-4 col-12 px-md-5 px-sm-0'>
                      <div class='row'>
                        <div class='col-12' >
                            <span class="input-info">{{
                                'website.equipment.config.energyModule.fields.measure_configuration_hp_gas.label' | translate
                              }}</span>
                          <app-slider
                            class='col-12'
                            formControlName="measure_configuration_hp_gas_min"
                            [inferiorValue]="-1"
                            [superiorValue]="200"
                          ></app-slider>

                          <app-slider
                            class='col-12'
                            formControlName="measure_configuration_hp_gas_max"
                            [inferiorValue]="-1"
                            [superiorValue]="200"
                          ></app-slider>
                        </div>




                      </div>
<div class='row'>
           <span
             class="custom-error"
             *ngIf="
                        !validateMinimumMaximum(
                          measureCardForm.controls.measure_configuration_hp_gas_min.value,
                          measureCardForm.controls.measure_configuration_hp_gas_max.value
                        )
                      "
           >
                      {{
               'website.equipment.config.energyModule.fields.measure_configuration_hp_gas.errors.invalid_minimum_and_maximum'
                 | translate
             }}
                    </span>
</div>
                      <div class='row'>
                        <div class='col-12'>
                          <mat-form-field class=" mt-5 col-12" appearance="outline">
                            <mat-label>{{
                                'website.equipment.config.energyModule.fields.measure_configuration_hp_gas_calibration.label2' | translate
                              }}</mat-label>
                            <input matInput placeholder="Insert value" formControlName="measure_configuration_hp_gas_calibration" readonly />
                          </mat-form-field>
                        </div>

                      </div>
                      <div class='row'>
                    <div class='col-md-8'>
                      <button mat-stroked-button color="primary" (click)="resetField(['measure_configuration_hp_gas_calibration'])">
                        {{ 'website.equipment.config.energyModule.fields.measure_configuration_hp_gas_calibration.reset_button' | translate }}
                      </button>
                    </div>
                      </div>



                    </div>
                  </div>


                  <!--end column-->
                </div>
                <!--end row-->
              </mat-expansion-panel>

              <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>{{ 'website.equipment.config.measureCard.temperature.column_1.title3' | translate }}</mat-panel-title>
                  <span class="material-icons-outlined">expand_more </span>
                </mat-expansion-panel-header>

                <div class='container-fluid expansion-content p-0 '>
                  <div class='row'>
                    <div class='col-md-4 col-12 border-end-only-md  border-1 pe-md-5'>
                      <div class='row'>
                        <div class='col-12  mb-4'>
                          <mat-form-field class="col-12 col-md-11" appearance="outline">
                            <mat-label>{{
                                'website.equipment.config.measureCard.fields.measure_configuration_input_temp_correction.label' | translate
                              }}</mat-label>
                            <input
                              matInput
                              placeholder="Insert value"
                              [value]="
                          measureCardForm.controls.input_temp_correction_auto.value + measureCardForm.controls.input_temp_correction.value
                        "
                              readonly
                              autocomplete="off"
                              matx-input-auto-width
                            />
                            <span matSuffix>&#176; C</span>
                            <mat-hint *ngIf="dni">
                              {{
                                'website.equipment.config.measureCard.fields.measure_configuration_input_temp_correction.info'
                                  | translate
                                  : {
                                    auto: data.measure_device?.input_temp_correction_auto || 0,
                                    manual: data.measure_device?.input_temp_correction || 0
                                  }
                              }}
                            </mat-hint>
                          </mat-form-field>
                        </div>

                      </div>

                      <div class='row'>
                        <div class='col-12 mb-4'>
                          <button
                            class='mt-1'
                            mat-stroked-button
                            color="primary"
                            (click)="resetField(['input_temp_correction_auto', 'input_temp_correction'])"
                          >
                            {{ 'website.equipment.config.measureCard.fields.measure_configuration_hp_calibration.reset_button' | translate }}
                          </button>
                        </div>

                      </div>
                      <div class='row'>
                        <div class='col-12 mb-4'>
                        <mat-form-field class="col-md-11 col-12" appearance="outline">
                            <mat-label>Correction de la sonde de refoulement</mat-label>
                            <input
                              matInput
                              placeholder="Insert value"
                              [value]="
                          measureCardForm.controls.discharge_temp_correction_auto.value +
                          measureCardForm.controls.discharge_temp_correction.value
                        "
                              readonly
                              autocomplete="off"
                              matx-input-auto-width
                            />
                            <span matSuffix>&#176; C</span>

                            <mat-hint  *ngIf="dni">
                              {{
                                'website.equipment.config.energyModule.fields.measure_configuration_discharge_temp_correction.info'
                                  | translate
                                  : {
                                    auto: data.measure_device?.discharge_temp_correction_auto || 0,
                                    manual: data.measure_device?.discharge_temp_correction || 0
                                  }
                              }}
                            </mat-hint>
                          </mat-form-field>
                        </div>

                      </div>
<div class='row'>
  <div class='col-12 mb-4'>
    <button
      mat-stroked-button
      color="primary"
      class='mt-1'
      (click)="resetField(['discharge_temp_correction_auto', 'discharge_temp_correction'])"
    >
      {{ 'website.equipment.config.measureCard.fields.measure_configuration_hp_calibration.reset_button' | translate }}
    </button>
</div>

</div>



                   <div class='row'>
                     <mat-slide-toggle color='primary' class="block-type"> Module sans-fil de température exterieure </mat-slide-toggle>
                   </div>

                    </div>
                    <div class='col-md-4 col-12 border-end-only-md    px-md-5'>
                      <div class='row'>
                        <div class='col-12 mb-4'>
                          <mat-form-field class="col-12" appearance="outline">
                            <mat-label>{{
                                'website.equipment.config.measureCard.fields.measure_configuration_output_temp_correction.label' | translate
                              }}</mat-label>
                            <input
                              matInput
                              placeholder="Insert value"
                              [value]="
                          measureCardForm.controls.output_temp_correction_auto.value + measureCardForm.controls.output_temp_correction.value
                        "
                              readonly
                              autocomplete="off"
                              matx-input-auto-width
                            />
                            <span matSuffix>&#176; C</span>
                            <mat-hint  *ngIf="dni">
                              {{
                                'website.equipment.config.measureCard.fields.measure_configuration_output_temp_correction.info'
                                  | translate
                                  : {
                                    auto: data.measure_device?.output_temp_correction_auto || 0,
                                    manual: data.measure_device?.output_temp_correction || 0
                                  }
                              }}
                            </mat-hint>
                          </mat-form-field>
                        </div>

                      </div>

                      <div class='row'>
                        <div class='col-12 mb-4'>
                          <button
                            class='mt-1'
                            mat-stroked-button
                            color="primary"
                            (click)="resetField(['output_temp_correction_auto', 'output_temp_correction'])"
                          >
                            {{ 'website.equipment.config.measureCard.fields.measure_configuration_hp_calibration.reset_button' | translate }}
                          </button>
                        </div>

                      </div>

                      <div class='row'>
      <div class='col-12 mb-4'>
        <mat-form-field class="col-12" appearance="outline">
          <mat-label>{{ 'website.equipment.config.measureCard.fields.external_temp_correction.label' | translate }}</mat-label>
          <input
            matInput
            placeholder="Insert value"
            [value]="
                          measureCardForm.controls.external_temp_correction_auto.value +
                          measureCardForm.controls.external_temp_correction.value
                        "
            readonly
            autocomplete="off"
            matx-input-auto-width
          />
          <span matSuffix>&#176; C</span>
          <mat-hint *ngIf="dni" >
            {{
              'website.equipment.config.measureCard.fields.external_temp_correction.info'
                | translate
                : {
                  auto: data.equipment?.external_temp_correction_auto || 0,
                  manual: data.equipment?.external_temp_correction || 0
                }
            }}
          </mat-hint>
        </mat-form-field>
      </div>

                      </div>
                      <div class='row'>
      <div class='col-12 mb-4'>
        <button
          class='mt-1'
          mat-stroked-button
          color="primary"
          (click)="resetField(['external_temp_correction_auto', 'external_temp_correction'])"
        >
          {{ 'website.equipment.config.measureCard.fields.measure_configuration_hp_calibration.reset_button' | translate }}
        </button>
      </div>
                      </div>





                    </div>
                    <div class='col-md-4 col-12 px-md-5'>


                      <div class='row'>
                        <div class='col-12 mb-4'>
                          <mat-form-field appearance="outline"  class='col-12'>
                            <mat-label>{{
                                'website.equipment.config.energyModule.fields.measure_configuration_suction_temp_correction.label' | translate
                              }}</mat-label>
                            <input
                              matInput
                              placeholder="Insert value"
                              [value]="data.measure_device?.suction_temp_correction_auto + data.measure_device?.suction_temp_correction"
                              readonly
                            />
                            <mat-hint>
                              {{
                                'website.equipment.config.energyModule.fields.measure_configuration_suction_temp_correction.info'
                                  | translate
                                  : {
                                    auto: data.measure_device?.suction_temp_correction_auto || 0,
                                    manual: data.measure_device?.suction_temp_correction || 0
                                  }
                              }}
                            </mat-hint>
                          </mat-form-field>
                        </div>

                      </div>

                <div class='row'>
                  <div class='col-12'>
                    <button
                      mat-stroked-button
                      class='mt-1 reset-offset-button'
                      color="primary"
                      (click)="resetField(['discharge_temp_correction_auto', 'discharge_temp_correction'])"
                    >
                      {{ 'website.equipment.config.measureCard.fields.measure_configuration_hp_calibration.reset_button' | translate }}
                    </button>
                  </div>
                </div>

                    </div>

                  </div>
                </div>

              </mat-expansion-panel>

              <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>{{ 'website.equipment.config.measureCard.temperature.column_2.title2' | translate }}</mat-panel-title>
                  <span class="material-icons-outlined">expand_more </span>
                </mat-expansion-panel-header>
                <!--row-->

                <div class='container-fluid expansion-content'>
                  <div class='row'>
                    <div class='col-md-6  border-end-only-md ms-0 ps-0 ' >
                      <div class='row'>
                        <div class='col-12'>
                          <mat-form-field appearance="outline" class='col-12 col-md-7'>
                            <mat-label>{{
                                'website.equipment.config.measureCard.fields.installation_information_column_type.label' | translate
                              }}</mat-label>
                            <mat-select matNativeControl formControlName="installation_information_column_type" disableOptionCentering>
                              <mat-option *ngFor="let material of EqConfigInstallationInformationColumnType | keyvalue" [value]="material.key">
                                {{ material.value | translate }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class='col-12'>

                          <mat-form-field class='col-12 col-md-7' *ngIf="polarbox"  appearance="outline">
                            <mat-label>{{ 'website.equipment.config.measureCard.fields.quilting.label' | translate }}</mat-label>
                            <input matInput placeholder="Insert value" formControlName="quilting" autocomplete="off" matx-input-auto-width />
                            <span matSuffix>mm</span>
                          </mat-form-field>
                        </div>
                      </div>

                    </div>
                    <div class='col-md-6'>
                      <div class='row ms-md-2'>
                        <div class='col-12'>
                          <mat-form-field class="col-12 col-md-7" appearance="outline">
                            <mat-label>{{
                                'website.equipment.config.measureCard.fields.installation_information_column_tare.label' | translate
                              }}</mat-label>
                            <input
                              matInput
                              placeholder="Insert value"
                              formControlName="installation_information_column_tare"
                              autocomplete="off"
                              matx-input-auto-width
                            />
                            <span matSuffix>g</span>
                          </mat-form-field>
                        </div>
                        <div class='col-12'>
                          <mat-form-field class="col-12 col-md-7" appearance="outline">
                            <mat-label>{{ 'website.equipment.config.measureCard.fields.top_bottom_tapping.label' | translate }}</mat-label>
                            <input
                              matInput
                              placeholder="Insert value"
                              formControlName="top_bottom_tapping"
                              autocomplete="off"
                              matx-input-auto-width
                            />
                            <span matSuffix>mm</span>
                          </mat-form-field>
                        </div>
                      </div>




                    </div>
                  </div>
                </div>

                <!--end row-->
              </mat-expansion-panel>

              <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(3)" hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>{{ 'website.equipment.config.measureCard.system.title' | translate }}</mat-panel-title>
                  <!-- <mat-panel-description> Configuration du module: {{ EqConfigEnergyIsConfigured[data.config?.energy_is_configured] }} </mat-panel-description> -->
                  <span class="material-icons-outlined">expand_more </span>
                </mat-expansion-panel-header>

                <div fxflex="100" fxLayout="row" fxLayoutGap="80px" fxLayoutGap.md="20px" fxLayout.lt-md="column" class="flex-container">
                  <div fxFlex.lt-md="1 1 100%" fxFlex.xl="1 1 33%" fxFlex.lg="1 1 33%" fxFlex.md="1 1 0%">
                    <div class="data-text-box">
                      <h3>{{ 'website.equipment.config.measureCard.fields.software_version.label' | translate }}</h3>
                      <span>{{ data.measure_device?.software_version }}</span>
                    </div>
                    <div class="data-text-box">
                      <h3>{{ 'website.equipment.config.measureCard.fields.measure_card_1_is_configured.label' | translate }}</h3>
                      <span>{{ data.measure_device?.is_configured ? 'OK' : 'NOK' }}</span>
                    </div>
                    <div class="data-text-box">
                      <h3>{{ 'website.equipment.config.measureCard.fields.addresage.label' | translate }}: 1000</h3>
                      <img src="../assets/images/adressage_1000.svg" alt="" />
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </mat-tab>

        <!-- TAB MODULE ENERGY -->
        <mat-tab [disabled]='totalEnergyModules === 0'>
          <ng-template mat-tab-label>
            <mat-icon *ngIf="invalidEnergyModuleForm" [matTooltip]="invalidEnergyModuleForm">error_outline</mat-icon>
            {{ 'website.equipment.config.energyModule.title' | translate }}
          </ng-template>
          <div class='container-fluid'>
            <div class='row'>
              <div formGroupName="energyModule">
                <mat-accordion *ngIf="dni" >
                  <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle *ngIf="totalEnergyModules > 0">
                    <mat-expansion-panel-header>
                      <mat-panel-title>{{ 'website.equipment.config.energyModule.module_1.title' | translate }}</mat-panel-title>
                      <mat-panel-description>{{
                          'website.equipment.config.energyModule.module_1.description'
                            | translate: { status: EqConfigEnergyIsConfigured[data.energy_devices[0]?.is_configured] | translate }
                        }}</mat-panel-description>
                      <span class="material-icons-outlined"> expand_more </span>
                    </mat-expansion-panel-header>

                    <div class="table-responsive p-0">      <table class="configuration-table">
                      <thead>
                      <tr>
                        <th>{{ 'website.equipment.config.energyModule.power_table.columns.power' | translate }}</th>
                        <th>{{ 'website.equipment.config.energyModule.power_table.columns.assignment' | translate }}</th>
                        <th>{{ 'website.equipment.config.energyModule.power_table.columns.ti_power' | translate }}</th>
                        <th>{{ 'website.equipment.config.energyModule.power_table.columns.ti_number' | translate }}</th>
                        <th>{{ 'website.equipment.config.energyModule.power_table.columns.charged' | translate }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr >
                        <td class="section">{{ 'website.equipment.config.energyModule.power_table.lines.power_1' | translate }}</td>
                        <td>
                          <mat-form-field appearance="outline" subscriptSizing='dynamic'>
                            <mat-select matNativeControl formControlName="module1_power1_type" disableOptionCentering>
                              <mat-option
                                *ngFor="let module1_power1_type of EqConfigModulePowerType | keyvalue"
                                [value]="module1_power1_type.key"
                              >
                                {{ module1_power1_type.value | translate }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </td>
                        <td>
                          <mat-form-field appearance="outline" subscriptSizing='dynamic'>
                            <mat-select matNativeControl formControlName="module1_power1_ti_range" disableOptionCentering>
                              <mat-option
                                *ngFor="let module1_power1_ti_range of EqConfigModulePowerTiRange | keyvalue"
                                [value]="module1_power1_ti_range.key"
                              >
                                {{ module1_power1_ti_range.value }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </td>
                        <td>
                          <mat-form-field appearance="outline" subscriptSizing='dynamic'>
                            <mat-select matNativeControl formControlName="module1_power1_number_ti" disableOptionCentering>
                              <mat-option
                                *ngFor="let module1_power1_number_ti of EqConfigModulePowerNumberTi | keyvalue"
                                [value]="module1_power1_number_ti.key"
                              >
                                {{ module1_power1_number_ti.value }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </td>
                        <td>
                          <mat-form-field appearance="outline" subscriptSizing='dynamic'>
                            <mat-select matNativeControl formControlName="module1_power1_power_ratio" disableOptionCentering>
                              <mat-option
                                *ngFor="let module1_power1_power_ratio of EqConfigModulePowerRatio"
                                [value]="module1_power1_power_ratio.key"
                              >
                                {{ module1_power1_power_ratio.value }}%
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </td>
                      </tr>
                      <tr>
                        <td class="section">{{ 'website.equipment.config.energyModule.power_table.lines.power_2' | translate }}</td>
                        <td>
                          <mat-form-field appearance="outline" subscriptSizing='dynamic'>
                            <mat-select matNativeControl formControlName="module1_power2_type" disableOptionCentering>
                              <mat-option
                                *ngFor="let module1_power2_type of EqConfigModulePowerType | keyvalue"
                                [value]="module1_power2_type.key"
                              >
                                {{ module1_power2_type.value | translate }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </td>
                        <td>
                          <mat-form-field appearance="outline" subscriptSizing='dynamic'>
                            <mat-select matNativeControl formControlName="module1_power2_ti_range" disableOptionCentering>
                              <mat-option
                                *ngFor="let module1_power2_ti_range of EqConfigModulePowerTiRange | keyvalue"
                                [value]="module1_power2_ti_range.key"
                              >
                                {{ module1_power2_ti_range.value }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </td>
                        <td>
                          <mat-form-field appearance="outline" subscriptSizing='dynamic'>
                            <mat-select matNativeControl formControlName="module1_power2_number_ti" disableOptionCentering>
                              <mat-option
                                *ngFor="let module1_power2_number_ti of EqConfigModulePowerNumberTi | keyvalue"
                                [value]="module1_power2_number_ti.key"
                              >
                                {{ module1_power2_number_ti.value }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </td>
                        <td>
                          <mat-form-field appearance="outline" subscriptSizing='dynamic'>
                            <mat-select matNativeControl formControlName="module1_power2_power_ratio" disableOptionCentering>
                              <mat-option
                                *ngFor="let module1_power2_power_ratio of EqConfigModulePowerRatio"
                                [value]="module1_power2_power_ratio.key"
                              >
                                {{ module1_power2_power_ratio.value }}%
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </td>
                      </tr>
                      <tr>
                        <td class="section">{{ 'website.equipment.config.energyModule.power_table.lines.power_3' | translate }}</td>
                        <td>
                          <mat-form-field appearance="outline" subscriptSizing='dynamic'>
                            <mat-select matNativeControl formControlName="module1_power3_type" disableOptionCentering>
                              <mat-option
                                *ngFor="let module1_power3_type of EqConfigModulePowerType | keyvalue"
                                [value]="module1_power3_type.key"
                              >
                                {{ module1_power3_type.value | translate }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </td>
                        <td>
                          <mat-form-field appearance="outline" subscriptSizing='dynamic'>
                            <mat-select matNativeControl formControlName="module1_power3_ti_range" disableOptionCentering>
                              <mat-option
                                *ngFor="let module1_power3_ti_range of EqConfigModulePowerTiRange | keyvalue"
                                [value]="module1_power3_ti_range.key"
                              >
                                {{ module1_power3_ti_range.value }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </td>
                        <td>
                          <mat-form-field appearance="outline" subscriptSizing='dynamic'>
                            <mat-select matNativeControl formControlName="module1_power3_number_ti" disableOptionCentering>
                              <mat-option
                                *ngFor="let module1_power3_number_ti of EqConfigModulePowerNumberTi | keyvalue"
                                [value]="module1_power3_number_ti.key"
                              >
                                {{ module1_power3_number_ti.value }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </td>
                        <td>
                          <mat-form-field appearance="outline" subscriptSizing='dynamic'>
                            <mat-select matNativeControl formControlName="module1_power3_power_ratio" disableOptionCentering>
                              <mat-option
                                *ngFor="let module1_power3_power_ratio of EqConfigModulePowerRatio"
                                [value]="module1_power3_power_ratio.key"
                              >
                                {{ module1_power3_power_ratio.value }}%
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </td>
                      </tr>
                      </tbody>
                    </table></div>


                    <div
                      fxflex="100"
                      fxLayout="row"
                      fxLayoutGap="80px"
                      fxLayoutGap.md="20px"
                      fxLayout.lt-md="column"
                      class="flex-container panel-container"
                    >
                      <div fxFlex.lt-md="1 1 100%" fxFlex.xl="1 1 50%" fxFlex.lg="1 1 50%" fxFlex.md="1 1 100%">
                        <h3>{{ 'website.equipment.config.energyModule.fields.adressage.label' | translate }}: 000110</h3>
                        <div style="margin-bottom: 45px">
                          <img src="/assets/images/adressage_000110.svg" alt="" />
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel>

                  <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle *ngIf="totalEnergyModules > 1">
                    <mat-expansion-panel-header>
                      <mat-panel-title>{{ 'website.equipment.config.energyModule.module_2.title' | translate }}</mat-panel-title>
                      <mat-panel-description>{{
                          'website.equipment.config.energyModule.module_2.description'
                            | translate: { status: EqConfigEnergyIsConfigured[data.energy_devices[1]?.is_configured] | translate }
                        }}</mat-panel-description>
                      <span class="material-icons-outlined"> expand_more </span>
                    </mat-expansion-panel-header>

                    <div
                      class="table-responsive p-0"
                    >

                      <table class="configuration-table">
                        <thead>
                        <tr>
                          <th>{{ 'website.equipment.config.energyModule.power_table.columns.power' | translate }}</th>
                          <th>{{ 'website.equipment.config.energyModule.power_table.columns.assignment' | translate }}</th>
                          <th>{{ 'website.equipment.config.energyModule.power_table.columns.ti_power' | translate }}</th>
                          <th>{{ 'website.equipment.config.energyModule.power_table.columns.ti_number' | translate }}</th>
                          <th>{{ 'website.equipment.config.energyModule.power_table.columns.charged' | translate }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td class="section">{{ 'website.equipment.config.energyModule.power_table.lines.power_1' | translate }}</td>
                          <td>
                            <mat-form-field appearance="outline" subscriptSizing='dynamic'>
                              <mat-select matNativeControl formControlName="module2_power1_type" disableOptionCentering>
                                <mat-option
                                  *ngFor="let module2_power1_type of EqConfigModulePowerType | keyvalue"
                                  [value]="module2_power1_type.key"
                                >
                                  {{ module2_power1_type.value | translate }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </td>
                          <td>
                            <mat-form-field appearance="outline" subscriptSizing='dynamic'>
                              <mat-select matNativeControl formControlName="module2_power1_ti_range" disableOptionCentering>
                                <mat-option
                                  *ngFor="let module2_power1_ti_range of EqConfigModulePowerTiRange | keyvalue"
                                  [value]="module2_power1_ti_range.key"
                                >
                                  {{ module2_power1_ti_range.value }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </td>
                          <td>
                            <mat-form-field appearance="outline" subscriptSizing='dynamic'>
                              <mat-select matNativeControl formControlName="module2_power1_number_ti" disableOptionCentering>
                                <mat-option
                                  *ngFor="let module2_power1_number_ti of EqConfigModulePowerNumberTi | keyvalue"
                                  [value]="module2_power1_number_ti.key"
                                >
                                  {{ module2_power1_number_ti.value }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </td>
                          <td>
                            <mat-form-field appearance="outline" subscriptSizing='dynamic'>
                              <mat-select matNativeControl formControlName="module2_power1_power_ratio" disableOptionCentering>
                                <mat-option
                                  *ngFor="let module2_power1_power_ratio of EqConfigModulePowerRatio"
                                  [value]="module2_power1_power_ratio.key"
                                >
                                  {{ module2_power1_power_ratio.value }}%
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </td>
                        </tr>
                        <tr>
                          <td class="section">{{ 'website.equipment.config.energyModule.power_table.lines.power_2' | translate }}</td>
                          <td>
                            <mat-form-field appearance="outline" subscriptSizing='dynamic'>
                              <mat-select matNativeControl formControlName="module2_power2_type" disableOptionCentering>
                                <mat-option
                                  *ngFor="let module2_power2_type of EqConfigModulePowerType | keyvalue"
                                  [value]="module2_power2_type.key"
                                >
                                  {{ module2_power2_type.value | translate }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </td>
                          <td>
                            <mat-form-field appearance="outline" subscriptSizing='dynamic'>
                              <mat-select matNativeControl formControlName="module2_power2_ti_range" disableOptionCentering>
                                <mat-option
                                  *ngFor="let module2_power2_ti_range of EqConfigModulePowerTiRange | keyvalue"
                                  [value]="module2_power2_ti_range.key"
                                >
                                  {{ module2_power2_ti_range.value }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </td>
                          <td>
                            <mat-form-field appearance="outline" subscriptSizing='dynamic'>
                              <mat-select matNativeControl formControlName="module2_power2_number_ti" disableOptionCentering>
                                <mat-option
                                  *ngFor="let module2_power2_number_ti of EqConfigModulePowerNumberTi | keyvalue"
                                  [value]="module2_power2_number_ti.key"
                                >
                                  {{ module2_power2_number_ti.value }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </td>
                          <td>
                            <mat-form-field appearance="outline" subscriptSizing='dynamic'>
                              <mat-select matNativeControl formControlName="module2_power2_power_ratio" disableOptionCentering>
                                <mat-option
                                  *ngFor="let module2_power2_power_ratio of EqConfigModulePowerRatio"
                                  [value]="module2_power2_power_ratio.key"
                                >
                                  {{ module2_power2_power_ratio.value }}%
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </td>
                        </tr>
                        <tr>
                          <td class="section">{{ 'website.equipment.config.energyModule.power_table.lines.power_3' | translate }}</td>
                          <td>
                            <mat-form-field appearance="outline" subscriptSizing='dynamic'>
                              <mat-select matNativeControl formControlName="module2_power3_type" disableOptionCentering>
                                <mat-option
                                  *ngFor="let module2_power3_type of EqConfigModulePowerType | keyvalue"
                                  [value]="module2_power3_type.key"
                                >
                                  {{ module2_power3_type.value | translate }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </td>
                          <td>
                            <mat-form-field appearance="outline" subscriptSizing='dynamic'>
                              <mat-select matNativeControl formControlName="module2_power3_ti_range" disableOptionCentering>
                                <mat-option
                                  *ngFor="let module2_power3_ti_range of EqConfigModulePowerTiRange | keyvalue"
                                  [value]="module2_power3_ti_range.key"
                                >
                                  {{ module2_power3_ti_range.value }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </td>
                          <td>
                            <mat-form-field appearance="outline" subscriptSizing='dynamic'>
                              <mat-select matNativeControl formControlName="module2_power3_number_ti" disableOptionCentering>
                                <mat-option
                                  *ngFor="let module2_power3_number_ti of EqConfigModulePowerNumberTi | keyvalue"
                                  [value]="module2_power3_number_ti.key"
                                >
                                  {{ module2_power3_number_ti.value }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </td>
                          <td>
                            <mat-form-field appearance="outline" subscriptSizing='dynamic'>
                              <mat-select matNativeControl formControlName="module2_power3_power_ratio" disableOptionCentering>
                                <mat-option
                                  *ngFor="let module2_power3_power_ratio of EqConfigModulePowerRatio"
                                  [value]="module2_power3_power_ratio.key"
                                >
                                  {{ module2_power3_power_ratio.value }}%
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <div
                      fxflex="100"
                      fxLayout="row"
                      fxLayoutGap="80px"
                      fxLayoutGap.md="20px"
                      fxLayout.lt-md="column"
                      class="flex-container panel-container"
                    >
                      <div fxFlex.lt-md="1 1 100%" fxFlex.xl="1 1 50%" fxFlex.lg="1 1 50%" fxFlex.md="1 1 100%">
                        <h3>{{ 'website.equipment.config.energyModule.fields.adressage.label' | translate }}: 0001111</h3>
                        <div style="margin-bottom: 45px">
                          <img src="/assets/images/adressage_000111.svg" alt="" />
                        </div>
                        <!-- <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>{{
                          'website.equipment.config.energyModule.fields.measure_configuration_hp_gas_calibration.label' | translate
                        }}</mat-label>
                        <input
                          matInput
                          placeholder="Insert value"
                          formControlName="measure_configuration_hp_gas_calibration"
                          readonly
                        /> </mat-form-field
                      ><br />
                      <button
                        mat-stroked-button
                        color="primary"
                        (click)="energyModuleForm.controls.measure_configuration_hp_gas_calibration.setValue(0)"
                      >
                        {{
                          'website.equipment.config.energyModule.fields.measure_configuration_hp_gas_calibration.reset_button' | translate
                        }}
                      </button> -->
                      </div>
                      <!-- <div fxFlex.lt-md="1 1 100%" fxFlex.xl="1 1 50%" fxFlex.lg="1 1 50%" fxFlex.md="1 1 100%">
                      <h3>{{ 'website.equipment.config.energyModule.fields.measure_configuration_hp_gas.label' | translate }}</h3>

                      <div class="mat-slider-group">
                        <div class="mat-slider-container">
                          <app-slider
                            formControlName="measure_configuration_hp_gas_min"
                            [inferiorValue]="-1"
                            [superiorValue]="200"
                          ></app-slider>
                        </div>
                        <div class="mat-slider-container">
                          <app-slider
                            formControlName="measure_configuration_hp_gas_max"
                            [inferiorValue]="-1"
                            [superiorValue]="200"
                          ></app-slider>
                        </div>
                      </div>
                      <span
                        class="custom-error"
                        *ngIf="
                          !validateMinimumMaximum(
                            energyModuleForm.controls.measure_configuration_hp_gas_min.value,
                            energyModuleForm.controls.measure_configuration_hp_gas_max.value
                          )
                        "
                      >
                        {{
                          'website.equipment.config.energyModule.fields.measure_configuration_hp_gas.errors.invalid_minimum_and_maximum'
                            | translate
                        }}
                      </span>

                      <h3>
                        {{
                          'website.equipment.config.energyModule.fields.measure_configuration_discharge_temp_correction.title' | translate
                        }}
                      </h3>
                      <mat-form-field class="example-full-width mat-form-field-disabled" appearance="outline">
                        <mat-label>{{
                          'website.equipment.config.energyModule.fields.measure_configuration_discharge_temp_correction.label' | translate
                        }}</mat-label>
                        <input
                          matInput
                          placeholder="Insert value"
                          [value]="data.measure_device?.discharge_temp_correction_auto + data.measure_device?.discharge_temp_correction"
                          readonly
                        />
                      </mat-form-field>
                      <span class="input-info">{{
                        'website.equipment.config.energyModule.fields.measure_configuration_discharge_temp_correction.info'
                          | translate
                            : {
                                auto: data.measure_device?.discharge_temp_correction_auto || 0,
                                manual: data.measure_device?.discharge_temp_correction || 0
                              }
                      }}</span>
                    </div> -->
                    </div>
                  </mat-expansion-panel>

                  <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" hideToggle *ngIf="totalEnergyModules > 2">
                    <mat-expansion-panel-header>
                      <mat-panel-title>{{ 'website.equipment.config.energyModule.module_3.title' | translate }}</mat-panel-title>
                      <mat-panel-description>{{
                          'website.equipment.config.energyModule.module_3.description'
                            | translate: { status: EqConfigEnergyIsConfigured[data.energy_devices[2]?.is_configured] | translate }
                        }}</mat-panel-description>
                      <span class="material-icons-outlined"> expand_more </span>
                    </mat-expansion-panel-header>
                    <div
                      class="table-responsive p-0"
                    >
                      <table class="configuration-table">
                        <thead>
                        <tr>
                          <th>{{ 'website.equipment.config.energyModule.power_table.columns.power' | translate }}</th>
                          <th>{{ 'website.equipment.config.energyModule.power_table.columns.assignment' | translate }}</th>
                          <th>{{ 'website.equipment.config.energyModule.power_table.columns.ti_power' | translate }}</th>
                          <th>{{ 'website.equipment.config.energyModule.power_table.columns.ti_number' | translate }}</th>
                          <th>{{ 'website.equipment.config.energyModule.power_table.columns.charged' | translate }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td class="section">{{ 'website.equipment.config.energyModule.power_table.lines.power_1' | translate }}</td>
                          <td>
                            <mat-form-field appearance="outline" subscriptSizing='dynamic'>
                              <mat-select matNativeControl formControlName="module3_power1_type" disableOptionCentering>
                                <mat-option
                                  *ngFor="let module3_power1_type of EqConfigModulePowerType | keyvalue"
                                  [value]="module3_power1_type.key"
                                >
                                  {{ module3_power1_type.value | translate }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </td>
                          <td>
                            <mat-form-field appearance="outline" subscriptSizing='dynamic'>
                              <mat-select matNativeControl formControlName="module3_power1_ti_range" disableOptionCentering>
                                <mat-option
                                  *ngFor="let module3_power1_ti_range of EqConfigModulePowerTiRange | keyvalue"
                                  [value]="module3_power1_ti_range.key"
                                >
                                  {{ module3_power1_ti_range.value }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </td>
                          <td>
                            <mat-form-field appearance="outline" subscriptSizing='dynamic'>
                              <mat-select matNativeControl formControlName="module3_power1_number_ti" disableOptionCentering>
                                <mat-option
                                  *ngFor="let module3_power1_number_ti of EqConfigModulePowerNumberTi | keyvalue"
                                  [value]="module3_power1_number_ti.key"
                                >
                                  {{ module3_power1_number_ti.value }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </td>
                          <td>
                            <mat-form-field appearance="outline" subscriptSizing='dynamic'>
                              <mat-select matNativeControl formControlName="module3_power1_power_ratio" disableOptionCentering>
                                <mat-option
                                  *ngFor="let module3_power1_power_ratio of EqConfigModulePowerRatio"
                                  [value]="module3_power1_power_ratio.key"
                                >
                                  {{ module3_power1_power_ratio.value }}%
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </td>
                        </tr>
                        <tr>
                          <td class="section">{{ 'website.equipment.config.energyModule.power_table.lines.power_2' | translate }}</td>
                          <td>
                            <mat-form-field appearance="outline" subscriptSizing='dynamic'>
                              <mat-select matNativeControl formControlName="module3_power2_type">
                                <mat-option
                                  *ngFor="let module3_power2_type of EqConfigModulePowerType | keyvalue"
                                  [value]="module3_power2_type.key"
                                >
                                  {{ module3_power2_type.value | translate }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </td>
                          <td>
                            <mat-form-field appearance="outline" subscriptSizing='dynamic'>
                              <mat-select matNativeControl formControlName="module3_power2_ti_range" disableOptionCentering>
                                <mat-option
                                  *ngFor="let module3_power2_ti_range of EqConfigModulePowerTiRange | keyvalue"
                                  [value]="module3_power2_ti_range.key"
                                >
                                  {{ module3_power2_ti_range.value }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </td>
                          <td>
                            <mat-form-field appearance="outline" subscriptSizing='dynamic'>
                              <mat-select matNativeControl formControlName="module3_power2_number_ti" disableOptionCentering>
                                <mat-option
                                  *ngFor="let module3_power2_number_ti of EqConfigModulePowerNumberTi | keyvalue"
                                  [value]="module3_power2_number_ti.key"
                                >
                                  {{ module3_power2_number_ti.value }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </td>
                          <td>
                            <mat-form-field appearance="outline" subscriptSizing='dynamic'>
                              <mat-select matNativeControl formControlName="module3_power2_power_ratio" disableOptionCentering>
                                <mat-option
                                  *ngFor="let module3_power2_power_ratio of EqConfigModulePowerRatio"
                                  [value]="module3_power2_power_ratio.key"
                                >
                                  {{ module3_power2_power_ratio.value }}%
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </td>
                        </tr>
                        <tr>
                          <td class="section">{{ 'website.equipment.config.energyModule.power_table.lines.power_3' | translate }}</td>
                          <td>
                            <mat-form-field appearance="outline" subscriptSizing='dynamic'>
                              <mat-select matNativeControl formControlName="module3_power3_type" disableOptionCentering>
                                <mat-option
                                  *ngFor="let module3_power3_type of EqConfigModulePowerType | keyvalue"
                                  [value]="module3_power3_type.key"
                                >
                                  {{ module3_power3_type.value | translate }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </td>
                          <td>
                            <mat-form-field appearance="outline" subscriptSizing='dynamic'>
                              <mat-select matNativeControl formControlName="module3_power3_ti_range" disableOptionCentering>
                                <mat-option
                                  *ngFor="let module3_power3_ti_range of EqConfigModulePowerTiRange | keyvalue"
                                  [value]="module3_power3_ti_range.key"
                                >
                                  {{ module3_power3_ti_range.value }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </td>
                          <td>
                            <mat-form-field appearance="outline" subscriptSizing='dynamic'>
                              <mat-select matNativeControl formControlName="module3_power3_number_ti" disableOptionCentering>
                                <mat-option
                                  *ngFor="let module3_power3_number_ti of EqConfigModulePowerNumberTi | keyvalue"
                                  [value]="module3_power3_number_ti.key"
                                >
                                  {{ module3_power3_number_ti.value }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </td>
                          <td>
                            <mat-form-field appearance="outline" subscriptSizing='dynamic'>
                              <mat-select matNativeControl formControlName="module3_power3_power_ratio" disableOptionCentering>
                                <mat-option
                                  *ngFor="let module3_power3_power_ratio of EqConfigModulePowerRatio"
                                  [value]="module3_power3_power_ratio.key"
                                >
                                  {{ module3_power3_power_ratio.value }}%
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <div
                      fxflex="100"
                      fxLayout="row"
                      fxLayoutGap="80px"
                      fxLayoutGap.md="20px"
                      fxLayout.lt-md="column"
                      class="flex-container panel-container"
                    >
                      <div fxFlex.lt-md="1 1 100%" fxFlex.xl="1 1 50%" fxFlex.lg="1 1 50%" fxFlex.md="1 1 100%">
                        <h3>{{ 'website.equipment.config.energyModule.fields.adressage.label' | translate }}: 001000</h3>
                        <div style="margin-bottom: 45px">
                          <img src="/assets/images/adressage_001000.svg" alt="" />
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
              <div *ngIf="polarbox" formGroupName="energyV2Module">

                <div *ngIf="engine_nbr">
                  <div class="stage-wrapper">
                    <mat-select [(value)]="selectedStage">
                      <mat-option *ngFor="let n of numSequence(engine_nbr); let i = index" [value]="i"> Etage{{ i + 1 }} </mat-option>
                    </mat-select>
                  </div>

                  <div fxflex="100" fxLayout="row" fxLayoutGap="80px" fxLayoutGap.md="20px" fxLayout.lt-md="column">
                    <div fxFlex.lt-md="1 1 100%" fxFlex.xl="1 1 50%" fxFlex.lg="1 1 33%" fxFlex.md="1 1 50%">
                      <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Nombre de moteurs</mat-label>
                        <input
                          disabled
                          matInput
                          type="number"
                          [(ngModel)]="enginesPerStage"
                          [ngModelOptions]="{ standalone: true }"
                          min="1"
                          max="9"
                          placeholder="Insert value"
                        />
                      </mat-form-field>
                    </div>
                    <div fxFlex.lt-md="1 1 100%" fxFlex.xl="1 1 50%" fxFlex.lg="1 1 33%" fxFlex.md="1 1 50%">
                      <button class="nbr-btn" disabled mat-button mat-raised-button color="primary" (click)="updateEngineV2()">CONFIRMER</button>
                    </div>
                  </div>

                  <div class="example-headers-align" formArrayName="stages_form_array">
                    <div [formArrayName]="selectedStage">
                      <div *ngFor="let element of engines_form_array(selectedStage).controls; let l = index">
                        <mat-accordion>
                          <div [formGroupName]="l">
                            <mat-expansion-panel>
                              <mat-expansion-panel-header>
                                <mat-panel-title>Moteur {{ l + 1 }}</mat-panel-title>
                                <mat-panel-description>Ti associé.s</mat-panel-description>
                              </mat-expansion-panel-header>
                              <!--row-->
                              <div
                                fxflex="100"
                                fxLayout="row"
                                fxLayoutGap="80px"
                                fxLayoutGap.md="20px"
                                fxLayout.lt-md="column"
                                class="flex-container"
                              >
                                <!--column-->
                                <div fxFlex.lt-md="1 1 100%" fxFlex.xl="1 1 33%" fxFlex.lg="1 1 33%" fxFlex.md="1 1 50%">
                                  <mat-form-field appearance="outline">
                                    <mat-label>Nom du moteur</mat-label>
                                    <input matInput placeholder="Insert value" formControlName="name" />
                                  </mat-form-field>
                                  <mat-form-field appearance="outline">
                                    <mat-label>Type de moteur</mat-label>
                                    <mat-select matNativeControl disableOptionCentering formControlName="engine_type">
                                      <mat-option *ngFor="let type of EngineType | keyvalue" [value]="type.key"
                                      >{{ type.value | translate }}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                  <mat-form-field
                                    appearance="outline"
                                    *ngIf="engines_form_array(selectedStage).controls[l].get('engine_type').value == 'CONDENSER'"
                                  >
                                    <mat-label>Répartition sur cette centrale</mat-label>
                                    <input matInput matx-input-auto-width formControlName="distribution" />
                                    <span matSuffix>%</span>
                                  </mat-form-field>
                                </div>
                                <!--end column-->
                                <!--column-->
                                <div fxFlex.lt-md="1 1 100%" fxFlex.xl="1 1 100%" fxFlex.lg="1 1 60%" fxFlex.md="1 1 70%">
                                  <div style="width: 700px">
                                    <mat-form-field class="event-type-field" appearance="outline" [floatLabel]="'always'" style="width: 100%">
                                      <mat-label>Associer Ti</mat-label>
                                      <mat-chip-listbox #chipList aria-label="item selection">
                                        <mat-chip
                                          color="primary"
                                          *ngFor="let item of engines_form_array(selectedStage).controls[l]?.get('itemCtrl')?.value"
                                        >
                                          {{ item }}
                                        </mat-chip>
                                      </mat-chip-listbox>
                                    </mat-form-field>
                                  </div>
                                  <mat-slide-toggle color='primary' class="block-type" formControlName="variator"> Variateur </mat-slide-toggle>
                                  <div
                                    fxflex="100"
                                    fxLayout="row"
                                    fxLayoutGap="80px"
                                    fxLayoutGap.md="20px"
                                    fxLayout.lt-md="column"
                                    class="flex-container"
                                  >
                                    <div fxFlex.lt-md="1 1 100%" fxFlex.xl="1 1 40%" fxFlex.lg="1 1 33%" fxFlex.md="1 1 50%">
                                      <mat-form-field appearance="outline">
                                        <mat-label>Marque/Reference</mat-label>
                                        <input matInput placeholder="Insert value" formControlName="reference" />
                                      </mat-form-field>
                                    </div>
                                    <div fxFlex.lt-md="1 1 100%" fxFlex.xl="1 1 60%" fxFlex.lg="1 1 33%" fxFlex.md="1 1 50%">
                                      <mat-form-field appearance="outline">
                                        <mat-label>Mode</mat-label>
                                        <mat-select matNativeControl disableOptionCentering formControlName="engine_mode">
                                          <mat-option *ngFor="let mode of EngineMode | keyvalue" [value]="mode.key"
                                          >{{ mode.value }}
                                          </mat-option>
                                        </mat-select>
                                      </mat-form-field>
                                    </div>
                                  </div>
                                </div>
                                <!--end column-->
                              </div>
                              <!--end row-->
                            </mat-expansion-panel>
                          </div>
                        </mat-accordion>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <!-- <mat-tab label="Module HPF">Module HPF</mat-tab> -->

        <!-- TAB NETWORK -->
        <mat-tab [label]="'website.equipment.config.network.title' | translate">
          <div class="accordion-wrapper">
            <mat-accordion class="general-accordion">
              <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>{{ 'website.equipment.config.network.mac.title' | translate }}</mat-panel-title>
                  <!-- <mat-panel-description> Configuration du module: {{ EqConfigEnergyIsConfigured[data.config?.energy_is_configured] }} </mat-panel-description> -->
                  <span class="material-icons-outlined">expand_more </span>
                </mat-expansion-panel-header>

                <div fxflex="100" fxLayout="row" fxLayoutGap="80px" fxLayoutGap.md="20px" fxLayout.lt-md="column" class="flex-container">
                  <div fxFlex.lt-md="1 1 100%" fxFlex.xl="1 1 33%" fxFlex.lg="1 1 33%" fxFlex.md="1 1 50%">
                    <mat-form-field class="mat-form-field-readonly" appearance="outline">
                      <mat-label>{{ 'website.equipment.config.network.fields.mac_address_external.label' | translate }}</mat-label>
                      <input matInput placeholder="Insert value" readonly [value]="data.controller_device?.mac_address_external" />
                    </mat-form-field>
                    <mat-form-field class="mat-form-field-readonly" appearance="outline"
                    ><mat-label>{{ 'website.equipment.config.network.fields.network_connection_type.label' | translate }}</mat-label>
                      <input
                        matInput
                        placeholder="Insert value"
                        readonly
                        [value]="EqConfigNetworkConnectionType[data.controller_device?.connection_type]"
                      />
                    </mat-form-field>

                    <mat-form-field class="ms-md-3 mat-form-field-readonly" appearance="outline" >
                      <mat-label>{{ 'website.equipment.config.network.fields.network_wifi_ssid.label' | translate }}</mat-label>
                      <input matInput placeholder="Insert value" readonly [value]="data.controller_device?.wifi_ssid" />
                    </mat-form-field>

                    <mat-form-field  class="ms-md-3 mat-form-field-readonly" appearance="outline">
                      <mat-label>{{ 'website.equipment.config.network.fields.network_wifi_key.label' | translate }}</mat-label>
                      <input matInput placeholder="Insert value" readonly [value]="data.controller_device?.wifi_key" />
                    </mat-form-field>

                    <mat-form-field  class="ms-md-3 mat-form-field-readonly"  appearance="outline">
                      <mat-label>{{ 'website.equipment.config.network.fields.network_wifi_type.label' | translate }}</mat-label>
                      <input matInput placeholder="Insert value" readonly [value]="data.controller_device?.wifi_type" />
                    </mat-form-field>
                  </div>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>{{ 'website.equipment.config.network.dhcp.title' | translate }}</mat-panel-title>
                  <!-- <mat-panel-description> Configuration du module: {{ EqConfigEnergyIsConfigured[data.config?.energy_is_configured] }} </mat-panel-description> -->
                  <span class="material-icons-outlined"> expand_more </span>
                </mat-expansion-panel-header>

                <div fxflex="100" fxLayout="row" fxLayoutGap="80px" fxLayoutGap.md="20px" fxLayout.lt-md="column" class="flex-container">
                  <div fxFlex.lt-md="1 1 100%" fxFlex.xl="1 1 33%" fxFlex.lg="1 1 33%" fxFlex.md="1 1 50%">
                    <mat-form-field class="mat-form-field-readonly" appearance="outline">
                      <mat-label>{{ 'website.equipment.config.network.fields.network_dhcp.label' | translate }}</mat-label>
                      <input matInput placeholder="Insert value" readonly [value]="EqConfigNetworkDhcp[data.controller_device?.dhcp]" />
                    </mat-form-field>

                    <mat-form-field  class="ms-md-3 mat-form-field-readonly" appearance="outline">
                      <mat-label>{{ 'website.equipment.config.network.fields.network_gateway.label' | translate }}</mat-label>
                      <input matInput placeholder="Insert value" readonly [value]="data.controller_device?.gateway" />
                    </mat-form-field>

                    <mat-form-field  class="ms-md-3 mat-form-field-readonly" appearance="outline">
                      <mat-label>{{ 'website.equipment.config.network.fields.network_mask.label' | translate }}</mat-label>
                      <input matInput placeholder="Insert value" readonly [value]="data.controller_device?.mask" />
                    </mat-form-field>

                    <mat-form-field  class="ms-md-3 mat-form-field-readonly"  appearance="outline">
                      <mat-label>{{ 'website.equipment.config.network.fields.network_ip_address.label' | translate }}</mat-label>
                      <input matInput placeholder="Insert value" readonly [value]="data.controller_device?.ip" />
                    </mat-form-field>
                    <mat-form-field class=" mat-form-field-readonly" appearance="outline">
                      <mat-label>{{ 'website.equipment.config.network.fields.network_vpn_ip_address.label' | translate }}</mat-label>
                      <input matInput placeholder="Insert value" readonly [value]="data.controller_device?.vpn_ip_address" />
                    </mat-form-field>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>


  </form>
</div>

  <mat-dialog-actions align='end' class='border-1 border-top py-2 ' >
  <button mat-button (click)="close()" >{{ 'BUTTONS.CANCEL' | translate }}</button>
  <button
    class='me-2'
    mat-button
    mat-raised-button
    color="primary"
    type="submit"
    (click)="submit()"
    [disabled]="!isFormInvalid()"
    [matx-if-has-permission]="[context === 'CREATE' ? Permission.CreateEquipmentConfig : Permission.UpdateEquipmentConfig]"
  >
    {{ 'BUTTONS.' + (context === 'CREATE' ? 'CREATE' : 'SAVE') | translate }}
  </button>
  </mat-dialog-actions>
</div>
