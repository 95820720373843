import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'anais-password-strength',
  templateUrl: 'password-strength.component.html',
  styleUrls: ['password-strength.component.scss']
})
export class PasswordStrengthComponent implements OnInit, OnDestroy {
  @Input() public control: AbstractControl;

  public strength: number = 0;
  public unsubscribeAll: Subject<any> = new Subject();

  constructor(private translateService: TranslateService) {}

  public ngOnInit(): void {
    this.control.valueChanges.pipe(takeUntil(this.unsubscribeAll)).subscribe(data => this.checkPasswordStrength());
  }

  public ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  public get statusMessage(): string {
    let message = '';
    if (this.control.errors) {
      for (const error in this.control.errors) {
        if (this.control.hasError(error)) {
          switch (error) {
            case 'notUpper':
              message += this.translateService.instant('AUTH.VALIDATIONS.NO_UPPERCASE');
              break;
            case 'notLower':
              message += this.translateService.instant('AUTH.VALIDATIONS.NO_LOWERCASE');
              break;
            case 'notLength':
              message += this.translateService.instant('AUTH.VALIDATIONS.NO_LENGTH');
              break;
            case 'notNumber':
              message += this.translateService.instant('AUTH.VALIDATIONS.NO_NUMBER');
              break;
            default:
              break;
          }
        }
      }
    }
    return message;
  }

  private checkPasswordStrength(): void {
    const errors = _.cloneDeep(this.control.errors);
    if (errors) {
      this.strength = 0;
      delete errors.isInvalid;
    } else {
      this.strength = 100;
    }
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        if (!errors[key]) {
          this.strength += 25;
        } else if (this.control.value.length === 0) {
          this.strength = 0;
        }
      }
    }
  }
}
