<div class="row container">

    <mat-card class="col align-items-stretch">
      <mat-card-header>
        <mat-card-title style="font-size: 16px">{{ 'website.equipment.detail.alarm_modal.dni.title' | translate }}</mat-card-title>
      </mat-card-header>
      <div *ngIf="!isLoading">
        <mat-list>
          <ng-container>
              <mat-list-item *ngFor="let row of dniAlarmInfo; let index = index" class='py-2 separator-custom h-100'>
                <div class='row break-line py-2'>
                  <div class='col-6'>{{ row[0] }}</div>
                  <div class='col-6 text-secondary'>
                       <span *ngIf="row[0] === 'Type d’événement'" class="alarm-type-cell">
                      <img *ngIf="alarm?.alarm_type?.icon" [src]="alarm?.alarm_type?.icon" class="alarm-icon" />
                      <span
                        class="alert-message"
                        [ngStyle]="{ 'color': alarm?.alarm_type?.color, 'background-color': alarm?.alarm_type?.backgroundColor }"
                      >{{ alarm?.alarm_type?.mlVal('name', crtLang) }}</span
                      >
                    </span>
                    <span *ngIf="row[0] != 'Type d’événement'" class="span-row">{{ row[1] }}</span>
                  </div>
                </div>
              </mat-list-item>
          </ng-container>

        </mat-list>
      </div>
    </mat-card>


    <mat-card class="col align-items-stretch" >
      <mat-card-header>
        <mat-card-title style="font-size: 16px">{{ 'website.equipment.detail.alarm_modal.alarm.title' | translate }}</mat-card-title>
      </mat-card-header>
      <div *ngIf="!isLoading">


        <mat-list>
            <mat-list-item  class='py-2 separator-custom h-100'>
              <div class='row break-line py-2'>
                <div class='col-6'>{{ 'website.equipment.detail.alarm_modal.alarm.event_type' | translate }}</div>
                <div class='col-6 text-secondary'>{{ alarm?.alarm_type?.id }}</div>
              </div>
            </mat-list-item>

            <mat-list-item  class='py-2 separator-custom h-100'>
              <div class='row break-line py-2'>
                <div class='col-6'>{{ 'website.equipment.detail.alarm_modal.alarm.event_date' | translate }}</div>
                <div class='col-6 text-secondary'>{{ alarm?.date_time }}</div>
              </div>
            </mat-list-item>
            <ng-container *ngIf="alarm?.alarm_type?.id === 'level'">
              <mat-list-item class='py-2 separator-custom h-100'>
                <div class='row break-line py-2'>
                  <div class='col-6'>{{ 'website.equipment.detail.alarm_modal.alarm.delay' | translate }}</div>
                  <div class='col-6 text-secondary'>{{alarmPreparedData.delay}}</div>
                </div>
              </mat-list-item>

              <mat-list-item  class='py-2 separator-custom h-100'>
                <div class='row py-2'>
                  <div class='col-6'>{{ 'website.equipment.detail.alarm_modal.alarm.Consigne' | translate }}</div>
                  <div class='col-6 text-secondary'>{{alarmPreparedData.consign}}</div>
                </div>
              </mat-list-item>
            </ng-container>

            <mat-list-item  class='py-2 separator-custom h-100'>
              <div class='row break-line py-2'>
                <div class='col-6'>{{ 'website.equipment.detail.alarm_modal.alarm.triggering' | translate }}</div>
                <div class='col-6 text-secondary'>{{alarmPreparedData.alarmLimit}}</div>
              </div>
            </mat-list-item>



              <mat-list-item *ngIf="alarm?.alarm_type?.id == 'statistical'"  class='py-1 separator-custom h-100'>
                <div class='row break-line py-2'>
                  <div class='col-6'>{{ 'website.equipment.detail.alarm_modal.alarm.sensivity' | translate }}</div>
                  <div class='col-6 text-secondary'>{{calculatedSensibility}}</div>
                </div>
              </mat-list-item>


          <mat-list-item class='text-end h-100 py-2 mt-2'>
            <a class='link-primary ' (click)="navigateTo()">
              {{ 'website.equipment.detail.alarm_modal.alarm.dni_activity' | translate }}
            </a>
          </mat-list-item>
        </mat-list>
      </div>
    </mat-card>
  </div>

