<mat-form-field class="custom-select-like-dropdown {{classList}}" *ngIf='customerLocationChainsFilterOptions?.length || countriesFilterOptions?.length' appearance='fill'>
  <mat-label style='cursor: pointer'>{{ name }} <mat-icon>keyboard_arrow_down</mat-icon></mat-label>

  <input
    matInput
    #matAutocompleteInput='matInput'
    #autocompleteInput
    type='text'
    [disabled]='inputDisabled'
    [placeholder]='name'
    [matAutocomplete]="autocomplete"
    [formControl]="searchableElementsControl">
  <mat-autocomplete #autocomplete="matAutocomplete" [panelWidth]='280' (optionSelected)='selectedACStoreChainFilter($event, autocomplete)'>
    <ng-container *ngIf='type !== "c" '>
      <mat-option *ngFor='let cc of customChainsSearchOptions'
                  [value]='cc._id'>{{cc.name}}
      </mat-option>
    </ng-container>
    <ng-container *ngIf='type === "c" '>
      <mat-option *ngFor='let c of customChainsSearchOptions'
                  [value]='c'>{{c}}
      </mat-option>
    </ng-container>
  </mat-autocomplete>

</mat-form-field>
