<mat-spinner class="charts-loading-spinner" *ngIf="!readyToRender" diameter="75"></mat-spinner>
<div class='container-fluid'>
<form [formGroup]="filtersFormGroup" class='row'>

  <div class='col-md-8 col-12'>
    <mat-card class="graph-card" style="{{readyToRender ? {opacity: 1} : {opacity: 0} }}">
      <mat-card-header>
        <mat-card-title>Courbes</mat-card-title>
      </mat-card-header>
      <mat-card-content *ngIf="readyToRender">
        <div class="graph-container ">
          <div class="graph-container-header-field">

            <button class='reload-pos'  *ngIf="activeTab === chartDataType.INST"
                    (click) = "refreshData()"
                    mat-icon-button aria-label="Example icon button with a vertical three dot icon">
              <mat-icon>refresh</mat-icon>
            </button>

            <mat-form-field class='datepicker-pos' appearance="outline" *ngIf="activeTab === chartDataType.INST" (click)="instDatePicker.open()">
              <mat-label>{{ 'website.equipment.detail.charts.period' | translate }}</mat-label>
              <input
                matInput
                [matDatepickerFilter]="instDatePickerFilter"
                [matDatepicker]="instDatePicker"
                readonly
                formControlName="instDateFilter"
              />
              <mat-datepicker-toggle matSuffix [for]="instDatePicker">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #instDatePicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field class='datepicker-pos' appearance="outline" *ngIf="activeTab === chartDataType.AVG" (click)="avgRangePicker.open()">
              <mat-label>{{ 'website.equipment.detail.charts.period' | translate }}</mat-label>
              <mat-date-range-input [rangePicker]="avgRangePicker">
                <input matStartDate readonly formControlName="avgStartDateFilter" />
                <input matEndDate readonly formControlName="avgEndDateFilter" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="avgRangePicker">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
              </mat-datepicker-toggle>
              <mat-date-range-picker #avgRangePicker></mat-date-range-picker>
            </mat-form-field>
          </div>

          <mat-tab-group  mat-stretch-tabs="false" class='mt-4'   (selectedTabChange)="selectedTabChange($event)" [selectedIndex]="activeTab">
            <mat-tab label="{{ 'website.equipment.detail.charts.daily' | translate }}" [disabled]="chartIsLoading">
              <div *isLoading='chartIsLoading && activeTab === chartDataType.INST' class='chart-container'>
                <highcharts-chart
                  class="chart"
                  [Highcharts]="Highcharts"
                  [options]="chartOptions"
                  *ngIf="!chartIsLoading && activeTab === chartDataType.INST"
                ></highcharts-chart>
              </div>

            </mat-tab>
            <mat-tab label="{{ 'website.equipment.detail.charts.average' | translate }}" [disabled]="chartIsLoading" class='chart-container'>
              <div *isLoading='chartIsLoading && activeTab === chartDataType.AVG'>
                <highcharts-chart
                  class="chart"
                  [Highcharts]="Highcharts"
                  [options]="chartOptions"
                  *ngIf="!chartIsLoading && activeTab === chartDataType.AVG"
                ></highcharts-chart>
              </div>

            </mat-tab>
          </mat-tab-group>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div class='col-md-4 col-12 filter-section'>
    <mat-card class="check-card" style="{{readyToRender ? {opacity: 1} : {opacity: 0} }}">
      <mat-card-header>
        <mat-card-title>{{ 'website.equipment.detail.charts.elements_display' | translate }}</mat-card-title>
      </mat-card-header>

      <mat-card-content  class='card-filter-content'>
        <ng-container class=' pt-2 pb-2' *ngFor="let filterCategory of filterCategories; let last = last">
          <mat-expansion-panel class='h-100  mat-elevation-z0 expansion-content' [expanded]="filterCategory.checked" *ngIf="getAvailableFiltersInCategoryCount(filterCategory)">
            <mat-expansion-panel-header class='h-100  header-expansion'>
              <mat-checkbox [color]="'primary'" [formControlName]="filterCategory.key" (click)="$event.stopPropagation()"></mat-checkbox>
              <div>
                <mat-panel-title class='header-expansion-title'>{{ filterCategory.translationSlug | translate }}</mat-panel-title>
                <mat-panel-description class='header-expansion-description'
                >{{ getCheckedFiltersInCategoryCount(filterCategory) }}/{{ getAvailableFiltersInCategoryCount(filterCategory) }}
                  {{ 'website.equipment.detail.charts.selected' | translate }}</mat-panel-description
                >
              </div>
            </mat-expansion-panel-header>

            <mat-list>
              <ng-container *ngFor="let filterItem of filterCategory.filters">
                <mat-list-item *ngIf="filterItem.dataType.includes(activeTab)"  class='ps-1'>
                  <mat-checkbox [color]="'primary'" [formControlName]="filterItem.key"></mat-checkbox>
                  <span style='text-wrap: wrap;'  class='checkbox-expansion-label'>
                  {{ filterItem.translationSlug | translate }}&nbsp;
                  <span *ngIf="filterItem.valueSuffix">(</span>{{ filterItem.valueSuffix }}<span *ngIf="filterItem.valueSuffix">)</span>
                  </span>
               </mat-list-item>
              </ng-container>
            </mat-list>
          </mat-expansion-panel>
        </ng-container>
      </mat-card-content>
      <mat-card-actions [align]='"end"'>
        <button mat-flat-button class='pv-button-grey' (click)="resetFilters()" [disabled]="chartIsLoading">
          {{ 'website.equipment.detail.charts.reset' | translate }}
        </button>
      </mat-card-actions>
    </mat-card>
  </div>

</form>
</div>
