import { Injectable } from '@angular/core';
import { GraphqlService } from '@graphql/graphql.service';
import { Installer } from '@shared/models/installer';
import { PagedResult } from '@shared/services/paged-result';
import gql from 'graphql-tag';
import { map, take } from 'rxjs/operators';
import { InstallerSearchCriteria } from './installer-search-criteria.interface';
import { InstallerChainSearchCriteria } from './installer-chain-search-criteria.interface';
import { InstallerChain } from '@shared/models/installer-chain';

export const DEFAULT_INSTALLERS_SEARCH_CRITERIA: InstallerSearchCriteria = {
  filters: {},
  sort: {
    field: 'name',
    direction: 'ASC'
  },
  pagination: {
    page: 1,
    perPage: 1000
  },
  removeInstallerWithoutCustomerLocation: false
};

const DEFAULT_INSTALLERS_CHAINS_SEARCH_CRITERIA: InstallerChainSearchCriteria = {
  filters: {},
  sort: {
    field: 'name',
    direction: 'ASC'
  },
  pagination: {
    page: 1,
    perPage: 1000
  }
};

@Injectable({
  providedIn: 'root'
})
export class InstallerService {
  constructor(private graphqlService: GraphqlService) {}

  public getInstallers(criteria: InstallerSearchCriteria = DEFAULT_INSTALLERS_SEARCH_CRITERIA): Promise<PagedResult<Installer>> {
    const gqlParams: any = {};

    if (criteria.sort.field) {
      gqlParams.sortField = criteria.sort.field;
    }
    gqlParams.sortDir = criteria.sort.direction;
    if (!gqlParams.sortDir) {
      gqlParams.sortDir = 'ASC';
    }

    if (criteria.pagination) {
      gqlParams.page = criteria.pagination.page;
      gqlParams.perPage = criteria.pagination.perPage;
    }
    gqlParams.removeInstallerWithoutCustomerLocation = criteria.removeInstallerWithoutCustomerLocation;


    return this.graphqlService
      .query(this.getInstallersQuery(), gqlParams, false)
      .pipe(
        take(1),
        map(result => {
          const installersData = result.data.getInstallers.itemsPage;
          const installersResult: PagedResult<Installer> = new PagedResult<Installer>();
          if (Array.isArray(installersData)) {
            installersResult.itemsPage = installersData.map(installersDataItem => {
              return new Installer(installersDataItem);
            });
          }
          installersResult.total = result.data.getInstallers.total;
          return installersResult;
        })
      )
      .toPromise() as Promise<PagedResult<Installer>>;
  }

  public getInstallerChains(
    criteria: InstallerChainSearchCriteria = DEFAULT_INSTALLERS_CHAINS_SEARCH_CRITERIA
  ): Promise<PagedResult<InstallerChain>> {
    const gqlParams: any = {};

    if (criteria.sort.field) {
      gqlParams.sortField = criteria.sort.field;
    }
    gqlParams.sortDir = criteria.sort.direction;
    if (!gqlParams.sortDir) {
      gqlParams.sortDir = 'ASC';
    }

    if (criteria.pagination) {
      gqlParams.page = criteria.pagination.page;
      gqlParams.perPage = criteria.pagination.perPage;
    }

    return this.graphqlService
      .query(this.getInstallersChainsQuery(), gqlParams, false)
      .pipe(
        take(1),
        map(result => {
          const installerChainsData = result.data.getInstallerChains.itemsPage;
          const installerChainsResult: PagedResult<InstallerChain> = new PagedResult<InstallerChain>();
          if (Array.isArray(installerChainsData)) {
            installerChainsResult.itemsPage = installerChainsData.map(installerChainsDataItem => {
              return new InstallerChain(installerChainsDataItem);
            });
          }
          installerChainsResult.total = result.data.getInstallerChains.total;
          return installerChainsResult;
        })
      )
      .toPromise() as Promise<PagedResult<InstallerChain>>;
  }

  private getInstallersQuery() {
    return gql`
      query getInstallers($sortField: String!, $sortDir: String!, $page: Float!, $perPage: Float!,$removeInstallerWithoutCustomerLocation:Boolean!) {
        getInstallers(criteria: { sortField: $sortField, sortDir: $sortDir, page: $page, perPage: $perPage,removeInstallerWithoutCustomerLocation:$removeInstallerWithoutCustomerLocation }) {
          total
          itemsPage {
            _id
            name
          }
        }
      }
    `;
  }
  private getInstallersChainsQuery() {
    return gql`
      query getInstallerChains($sortField: String!, $sortDir: String!, $page: Float!, $perPage: Float!) {
        getInstallerChains(criteria: { sortField: $sortField, sortDir: $sortDir, page: $page, perPage: $perPage }) {
          total
          itemsPage {
            _id
            name
          }
        }
      }
    `;
  }
}
