<mat-card>
  <mat-card-header>

    <mat-card-title>{{ 'website.equipment.detail.activity.events.events_log_title' | translate }}</mat-card-title>
    <!-- <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="">
      <mat-icon>more_vert</mat-icon>
    </button> -->
    <mat-menu #menu="matMenu">
      <button mat-menu-item>
        <mat-icon>dialpad</mat-icon>
        <span>Some action</span>
      </button>
      <button mat-menu-item disabled>
        <mat-icon>voicemail</mat-icon>
        <span>Some other action</span>
      </button>
    </mat-menu>

  </mat-card-header>



  <mat-card-content>
    <div class='container-fluid'>
      <div class="row justify-content-between ">
        <div class='col-md-5 col-12'>
          <ng-select class='mt-3'
                     (open)="onOpen()"
                     [items]="mappedEventTypes"
                     appendTo="body"
                     [multiple]="true"
                     groupBy="group"
                     [selectableGroup]="true"
                     [closeOnSelect]='false'
                     [selectableGroupAsModel]="false"
                     [formControl]="eventTypeFilterControl"
                     bindLabel='name'
                     notFoundText="{{ 'website.equipment.detail.activity.events.not_found' | translate }}"
                     placeholder="{{ 'website.equipment.detail.activity.events.filters.event' | translate }}"
                     [(ngModel)]="selectedMappedEventTypes">
            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
              <div class="row input-logs">
                <input class='mat-mdc-checkbox mat-mdc-checkbox-checked' id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" [checked]="item$.selected !== true"/> {{item.group | uppercase}}
              </div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
              <div class="row input-logs">
                <input class='mat-mdc-checkbox checkbox-label' id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"    [ngModelOptions]="{standalone: true}"/>
                {{item['name']}}
              </div>
            </ng-template>
            <ng-template ng-multi-label-tmp let-items="items">
              <div class="ng-value" *ngIf="items.length > 0 && items.length !== mappedEventTypes.length">
                <span class="ng-value-label">{{ 'website.equipment.detail.activity.events.selection' | translate }} {{" (" + items.length + ")"}}</span>
              </div>
              <div class="ng-value" *ngIf="items.length === mappedEventTypes.length">
                <span class="ng-value-label">{{ 'website.equipment.detail.activity.events.all_events' | translate }}</span>
              </div>
            </ng-template>
          </ng-select>
        </div>
<div class='col-md-3 col-12 col-sm-6'>
  <mat-form-field class='mt-3 col-12' appearance="outline">
    <mat-label>{{ 'website.equipment.detail.activity.events.filters.period' | translate }}</mat-label>
    <mat-date-range-input [rangePicker]="filterEventTypeDatePicker">
      <input
        matStartDate
        readonly
        (dateChange)="onFilterEventTypeMinDateChanged($event)"
        [value]="minDate ? minDate : criteria.filters.minDate"
        (click)="openFilterEventTypeDatePicker(filterEventTypeDatePicker)"
      />
      <input
        matEndDate
        readonly
        (dateChange)="onFilterEventTypeMaxDateChanged($event)"
        [value]="maxDate ? maxDate : criteria.filters.maxDate"
        (click)="openFilterEventTypeDatePicker(filterEventTypeDatePicker)"
      />
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="filterEventTypeDatePicker">
      <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
    </mat-datepicker-toggle>
    <mat-date-range-picker #filterEventTypeDatePicker></mat-date-range-picker>
  </mat-form-field>
</div>

      </div>

    </div>
   <div class='row'>
     <table
       *isLoading='isLoading'
       mat-table
       [dataSource]="eventsResult.eventsPage"
       matSort
       (matSortChange)="onSortChange($event)"
       matSortActive="date"
       (matSortChange)="resetPaging()"
     >
       <ng-container matColumnDef="date">
         <th mat-header-cell *matHeaderCellDef mat-sort-header>
           {{ 'website.equipment.detail.activity.events.date_label' | translate }}
         </th>
         <td mat-cell *matCellDef="let event" (click)="openLogDetailsDialog(event)">
           {{ event.date_time | date: 'd/M/yyyy HH:mm:ss' }}
         </td>
       </ng-container>

       <ng-container matColumnDef="type">
         <th mat-header-cell *matHeaderCellDef>
           {{ 'website.equipment.detail.activity.events.alarm_type_label' | translate }}
         </th>
         <td mat-cell *matCellDef="let event"  (click)="openLogDetailsDialog(event)">
          <span
            class="alert-message"
            [ngStyle]="{ 'color': event?.event_type?.color, 'background-color': event?.event_type?.backgroundColor }"
          >{{ event.event_type?.mlVal('name', crtLang) }}</span
          >
         </td>
       </ng-container>

       <ng-container matColumnDef="description">
         <th mat-header-cell *matHeaderCellDef class="description-column">
           {{ 'website.equipment.detail.activity.events.description_label' | translate }}
         </th>
         <td mat-cell *matCellDef="let event" (click)="openLogDetailsDialog(event)">
           <!--<span class="breakWord">{{ event.mlVal('description', crtLang) }}</span>-->
           <div *ngIf="event?.event_id" class="breakWord">{{ 'website.alarms.' + event?.event_id | translate : {event: event} }}</div>
         </td>
       </ng-container>

       <tr mat-header-row  *matHeaderRowDef="displayedColumnsLogs"></tr>
       <tr mat-row class='pointer-event' *matRowDef="let row; columns: displayedColumnsLogs"></tr>
     </table>

     <mat-card *ngIf="eventsResult.total === 0 && !isLoading" class="no-results-container">
       {{ 'website.equipment.detail.activity.events.no_events' | translate }}
     </mat-card>





  <mat-paginator
    *ngIf='eventsResult.total>0'
    [pageIndex]="criteria?.pagination?.page"
    [length]="eventsResult.total"
    [pageSize]="criteria?.pagination?.perPage"
    [pageSizeOptions]="[2, 5, 10, 25]"
    (page)="onPaginatorChange($event)"
  >
  </mat-paginator>
   </div>
  </mat-card-content>
</mat-card>
