import { User } from '@users/domain/models/user';

import { BaseModel } from './base.model';
import { CustomerChain } from './customer-location-chain';
import { Equipment } from './equipment';
import { Installer } from './installer';

export class CustomerLocation extends BaseModel {
  public _id: string;
  public name: string;
  public longitude: number;
  public latitude: number;
  public chain: CustomerChain;
  public installer: Installer;
  public manager: User;
  public phone: string;
  public email: string;
  public country: string;
  public countryISO?: string;
  public postalCode: string;
  public city: string;
  public dateCreated: string;
  public streetAndNumber: string;
  public is_active: boolean;
  public totalEquipments?: number;
  public equipments?: Equipment[]; // not in domain model, used by code
  public isNewCustomerLocationCheck: boolean;
  public totalEquipmentsWithConnectionAlarm : number;
  constructor(valuesObj: any) {
    super(valuesObj);
    if (valuesObj.chain) {
      this.chain = new CustomerChain(valuesObj.chain);
    }
    if (valuesObj.installer) {
      this.installer = new Installer(valuesObj.installer);
    }
    if (valuesObj.manager) {
      this.manager = new User(valuesObj.manager);
    }
    if (valuesObj.equipments && Array.isArray(valuesObj.equipments)) {
      this.equipments = valuesObj.equipments.map((equipmentInfo: any) => {
        return new Equipment(equipmentInfo);
      });
    }

  }


}
