import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomerLocationsResult } from '@equipment/services/customer-locations-result';
import { CustomerLocationService } from '@customer-locations/services/customer-location.service';

import { PageEvent } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { CustomerLocation } from '@shared/models/customer-location';
import { User } from '@users/domain/models/user';
import { FormControl } from '@angular/forms';

const INITIAL_CRITERIA: any = {
  name: null,
  sort: {
    field: 'name',
    direction: 'ASC'
  },
  is_active: true,
  pagination: {
    page: 1,
    perPage: 10
  }
};

@Component({
  selector: 'app-user-customers-locations',
  templateUrl: './user-customers-locations.component.html',
  styleUrls: ['./user-customers-locations.component.scss']
})
export class UserCustomersLocationsComponent implements OnInit {

  constructor(private customerLocationService: CustomerLocationService) { }

  @Output() useAlarmsFilterChangeEvent: EventEmitter<{customerLocation: CustomerLocation, useAlarmsFilterValue: boolean}> = new EventEmitter();
  @Input() user: User;
  @Input() useFilteredAlarmsFormControl: FormControl;
  public isLoading: boolean;
  public customersLocations: CustomerLocationsResult;
  public criteria: any = JSON.parse(JSON.stringify(INITIAL_CRITERIA));
  public displayedColumns: string[] = ['name', 'customerChain_name', 'manager_name', 'alarms'];



  async ngOnInit(): Promise<void> {
    this.criteria.userId = this.user._id;
    await this.fetchData();

  }
    async fetchData(): Promise<void> {
      this.isLoading = true;
      this.customersLocations = await this.customerLocationService.getCustomerLocations(this.criteria);
      this.customersLocations.customerLocationsPage.map((customerLocation) => {
        customerLocation.isAlarmsCustomerLocationDisabled = this.user.isDisabledCustomerLocationAlarms(customerLocation._id);
      });
      this.isLoading = false;
    }

  public async search(search: string): Promise<void> {
    this.isLoading = true;
    this.criteria.name = search;
    this.criteria.pagination.page = 1;
    await this.fetchData();
    this.isLoading = false;
  }

  public useAlarmsFilterChange(customerLocation: CustomerLocation, $event: MatSelectChange): void {
    this.useAlarmsFilterChangeEvent.emit({ customerLocation, useAlarmsFilterValue: $event.value });
  }



  public async onPaginatorChange(event: PageEvent): Promise<void> {
    this.isLoading = true;
    this.criteria.pagination.perPage = event.pageSize;
    this.criteria.pagination.page = event.pageIndex + 1;
    await this.fetchData();
    this.isLoading = false;
  }

  onSelectAlarmsChange($event: MatSelectChange) : void {
    this.user.useFilteredAlarms = $event.value;
  }

}
