import { User } from '@users/domain/models/user';

import { ActionType } from './action-type';
import { BaseModel } from './base.model';
import { Equipment } from './equipment';
import { Fluid } from './fluid';

export class History extends BaseModel {
  public _id: string;
  public totalWeight: number;
  public actionType: ActionType;
  public date: Date;
  public noCerfa: string;
  public installerUser: User;
  public equipment: Equipment;
  public fluid: Fluid;
  public retrofit: boolean;

  constructor(valuesObj: any) {
    super(valuesObj);

    if (valuesObj.actionType) {
      this.actionType = new ActionType(valuesObj.actionType);
    }
    if (valuesObj.installerUser) {
      this.installerUser = new User(valuesObj.installerUser);
    }
    if (valuesObj.fluid) {
      this.fluid = new Fluid(valuesObj.fluid);
    }
  }
}
