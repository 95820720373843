import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '../user-list.component';

@Component({
  selector: 'app-user-deactivate-dialog',
  templateUrl: './user-deactivate-dialog.component.html'
})
export class UserDeactivateDialogComponent {
  constructor(public dialogRef: MatDialogRef<UserDeactivateDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onButtonClick(option: string): void {
    this.dialogRef.close(option);
  }
}
