import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AlarmsListSearchCriteria } from '@equipment/services/alarms-list-search-criteria.interface';
import { AvgChartDataQueryCriteria } from '@equipment/services/avg-chart-data-query-criteria.interface';
import { EquipmentChartsService } from '@equipment/services/equipment-charts.service';
import { EquipmentDetailsService } from '@equipment/services/equipment-details.service';
import { HistoryListSearchCriteria } from '@equipment/services/history-list-search-criteria.interface';
import { HistoryService } from '@equipment/services/history.service';
import { InstChartDataQueryCriteria } from '@equipment/services/inst-chart-data-query-criteria.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { AvgChartData } from '@shared/models/avg-chart-data';
import { History } from '@shared/models/history';
import { InstChartData } from '@shared/models/inst-chart-data';
import { LanguageService } from '@shared/services/language.service';
import { PagedResult } from '@shared/services/paged-result';
import * as Highcharts from 'highcharts';
import HighchartsBoost from 'highcharts/modules/boost';
import noData from 'highcharts/modules/no-data-to-display';
import moment from 'moment-timezone';
import { debounceTime } from 'rxjs/operators';
import { ProfileService } from '@shared/services/profile.service';
import { Alarm } from '@shared/models/alarm';


HighchartsBoost(Highcharts);
noData(Highcharts);
window.moment = moment;

enum CHART_DATA_TYPE {
  INST,
  AVG
}

enum CHART_SERIES_TYPE {
  Y = 'Y',
  ALARMS = 'ALARMS',
  REFILLS = 'REFILLS'
}

const COLORS = [
  '#a6cee3', '#1f78b4', '#b2df8a', '#33a02c', '#fb9a99',
  '#e31a1c', '#fdbf6f', '#ff7f00', '#cab2d6', '#6a3d9a',
  '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce',
  '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a',
];

const INST_QUERY_CRITERIA: InstChartDataQueryCriteria = {
  columns: ['average_reference', 'tank_weight', 'low_level_limit'],
  filters: {
    minDate: moment().startOf('day').toISOString(),
    maxDate: moment().endOf('day').toISOString()
  },
  pagination: {
    page: 1,
    perPage: 30000
  }
};

const AVG_QUERY_CRITERIA: AvgChartDataQueryCriteria = {
  columns: ['average_reference', 'tank_weight', 'low_level_limit'],
  filters: {
    minDate: moment().subtract(6, 'days').startOf('day').toISOString(),
    maxDate: moment().endOf('day').toISOString()
  },
  pagination: {
    page: 1,
    perPage: 30000
  }
};

const ALARMS_INST_QUERY_CRITERIA: AlarmsListSearchCriteria = {
  filters: {
    minDate: moment().startOf('day').toISOString(),
    maxDate: moment().endOf('day').toISOString()
  },
  sort: {
    field: 'date',
    direction: 'ASC'
  },
  pagination: {
    page: 0,
    perPage: 30000
  }
};

const ALARMS_AVG_QUERY_CRITERIA: AlarmsListSearchCriteria = {
  filters: {
    minDate: moment().subtract(6, 'days').startOf('day').toISOString(),
    maxDate: moment().endOf('day').toISOString()
  },
  sort: {
    field: 'date',
    direction: 'ASC'
  },
  pagination: {
    page: 0,
    perPage: 30000
  }
};

const HISTORY_INST_QUERY_CRITERIA: HistoryListSearchCriteria = {
  filters: {
    type: ['refill'],
    minDate: moment().startOf('day').toISOString(),
    maxDate: moment().endOf('day').toISOString()
  },
  sort: {
    field: 'date',
    direction: 'ASC'
  },
  pagination: {
    page: 0,
    perPage: 30000
  }
};

const HISTORY_AVG_QUERY_CRITERIA: HistoryListSearchCriteria = {
  filters: {
    type: ['refill'],
    minDate: moment().subtract(6, 'days').startOf('day').toISOString(),
    maxDate: moment().endOf('day').toISOString()
  },
  sort: {
    field: 'date',
    direction: 'ASC'
  },
  pagination: {
    page: 0,
    perPage: 30000
  }
};

const FILTERS = [
  {
    key: 'fluid_levels',
    translationSlug: 'website.equipment.detail.charts.fluid.fluid_levels',
    checked: true,
    filters: [
      {
        key: 'average_reference',
        type: CHART_SERIES_TYPE.Y,
        translationSlug: 'website.equipment.detail.charts.fluid.average_reference',
        valueSuffix: 'kg',
        checked: true,
        showInChart: true,
        colorIndex: 0,
        dataType: [CHART_DATA_TYPE.INST, CHART_DATA_TYPE.AVG],
        tooltipSeries: null
      },
      {
        key: 'tank_weight',
        type: CHART_SERIES_TYPE.Y,
        translationSlug: 'website.equipment.detail.charts.fluid.tank_weight',
        valueSuffix: 'kg',
        checked: true,
        showInChart: true,
        colorIndex: 1,
        dataType: [CHART_DATA_TYPE.INST, CHART_DATA_TYPE.AVG],
        tooltipSeries: null
      },
      {
        key: 'low_level_limit',
        type: CHART_SERIES_TYPE.Y,
        translationSlug: 'website.equipment.detail.charts.fluid.low_level_limit',
        valueSuffix: 'kg',
        checked: true,
        showInChart: true,
        colorIndex: 2,
        dataType: [CHART_DATA_TYPE.INST, CHART_DATA_TYPE.AVG],
        tooltipSeries: null
      }
    ]
  },
  {
    key: 'temperatures',
    translationSlug: 'website.equipment.detail.charts.fluid.temperatures',
    checked: false,
    filters: [
      {
        key: 'input_temp',
        type: CHART_SERIES_TYPE.Y,
        translationSlug: 'website.equipment.detail.charts.fluid.input_temp',
        valueSuffix: '°C',
        checked: false,
        showInChart: true,
        colorIndex: 3,
        dataType: [CHART_DATA_TYPE.INST, CHART_DATA_TYPE.AVG],
        tooltipSeries: null,
        yAxis: 1
      },
      {
        key: 'output_temp',
        type: CHART_SERIES_TYPE.Y,
        translationSlug: 'website.equipment.detail.charts.fluid.output_temp',
        valueSuffix: '°C',
        checked: false,
        showInChart: true,
        colorIndex: 4,
        dataType: [CHART_DATA_TYPE.INST, CHART_DATA_TYPE.AVG],
        tooltipSeries: null,
        yAxis: 1
      },
      {
        key: 'hp_temp',
        type: CHART_SERIES_TYPE.Y,
        translationSlug: 'website.equipment.detail.charts.fluid.hp_temp',
        valueSuffix: '°C | bar',
        checked: false,
        showInChart: true,
        colorIndex: 5,
        dataType: [CHART_DATA_TYPE.INST, CHART_DATA_TYPE.AVG],
        tooltipSeries: [
          {
            key: 'hp',
            type: CHART_SERIES_TYPE.Y,
            translationSlug: 'website.equipment.detail.charts.fluid.hp',
            valueSuffix: '°C | bar',
            checked: true,
            showInChart: false,
            dataType: [CHART_DATA_TYPE.INST],
            yAxis: 1
          }
        ]
      },
      {
        key: 'external_temp',
        type: CHART_SERIES_TYPE.Y,
        translationSlug: 'website.equipment.detail.charts.fluid.external_temp',
        valueSuffix: '°C',
        checked: false,
        showInChart: true,
        colorIndex: 6,
        dataType: [CHART_DATA_TYPE.INST, CHART_DATA_TYPE.AVG],
        tooltipSeries: null,
        yAxis: 1
      }
    ]
  },
  {
    key: 'complementary_temperatures',
    translationSlug: 'website.equipment.detail.charts.fluid.complementary_temperatures',
    checked: false,
    filters: [
      {
        key: 'suction_temp',
        type: CHART_SERIES_TYPE.Y,
        translationSlug: 'website.equipment.detail.charts.fluid.suction_temp',
        valueSuffix: '°C',
        checked: false,
        showInChart: true,
        colorIndex: 7,
        dataType: [CHART_DATA_TYPE.INST, CHART_DATA_TYPE.AVG],
        tooltipSeries: null,
        yAxis: 1
      },
      {
        key: 'discharge_temp_measured',
        type: CHART_SERIES_TYPE.Y,
        translationSlug: 'website.equipment.detail.charts.fluid.discharge_temp_measured',
        valueSuffix: '°C',
        checked: false,
        showInChart: true,
        colorIndex: 8,
        dataType: [CHART_DATA_TYPE.INST, CHART_DATA_TYPE.AVG],
        tooltipSeries: null,
        yAxis: 1
      },
      {
        key: 'lp_temp',
        type: CHART_SERIES_TYPE.Y,
        translationSlug: 'website.equipment.detail.charts.fluid.lp_temp',
        valueSuffix: '°C | bar',
        checked: false,
        showInChart: true,
        colorIndex: 9,
        dataType: [CHART_DATA_TYPE.INST, CHART_DATA_TYPE.AVG],
        tooltipSeries: [
          {
            key: 'lp',
            type: CHART_SERIES_TYPE.Y,
            translationSlug: 'website.equipment.detail.charts.fluid.lp',
            valueSuffix: '°C | bar',
            checked: true,
            showInChart: false,
            dataType: [CHART_DATA_TYPE.INST],
            yAxis: 1
          }
        ]
      }
    ]
  },
  {
    key: 'advanced_options',
    translationSlug: 'website.equipment.detail.charts.fluid.advanced_options',
    checked: false,
    filters: [
      {
        key: 'compressor_state',
        type: CHART_SERIES_TYPE.Y,
        translationSlug: 'website.equipment.detail.charts.fluid.compressor_state',
        valueSuffix: '',
        checked: false,
        showInChart: true,
        colorIndex: 10,
        dataType: [CHART_DATA_TYPE.INST],
        tooltipSeries: null
      },
      {
        key: 'under_low_level_number',
        type: CHART_SERIES_TYPE.Y,
        translationSlug: 'website.equipment.detail.charts.fluid.under_low_level_number',
        valueSuffix: '',
        checked: false,
        showInChart: false,
        colorIndex: 11,
        dataType: [CHART_DATA_TYPE.AVG],
        tooltipSeries: null
      },
      {
        key: 'flash_gas_number',
        type: CHART_SERIES_TYPE.Y,
        translationSlug: 'website.equipment.detail.charts.fluid.flash_gas_number',
        valueSuffix: '',
        checked: false,
        showInChart: true,
        colorIndex: 12,
        dataType: [CHART_DATA_TYPE.AVG],
        tooltipSeries: [
          {
            key: 'flash_gas_with_compressor_on_number',
            type: CHART_SERIES_TYPE.Y,
            translationSlug: 'website.equipment.detail.charts.fluid.flash_gas_with_compressor_on_number',
            valueSuffix: '',
            checked: false,
            showInChart: false,
            dataType: [CHART_DATA_TYPE.AVG]
          }
        ]
      },
      {
        key: 'compressor_state_on_number',
        type: CHART_SERIES_TYPE.Y,
        translationSlug: 'website.equipment.detail.charts.fluid.lp',
        valueSuffix: 'nb/h',
        checked: false,
        showInChart: false,
        colorIndex: 13,
        dataType: [CHART_DATA_TYPE.AVG],
        tooltipSeries: null
      }
    ]
  },
  {
    key: 'events',
    translationSlug: 'website.equipment.detail.charts.events',
    checked: true,
    filters: [
      {
        key: 'alarms',
        type: CHART_SERIES_TYPE.ALARMS,
        translationSlug: 'website.equipment.detail.charts.alarms',
        valueSuffix: '',
        checked: true,
        showInChart: true,
        dataType: [CHART_DATA_TYPE.INST, CHART_DATA_TYPE.AVG]
      },
      {
        key: 'refills',
        type: CHART_SERIES_TYPE.REFILLS,
        translationSlug: 'website.equipment.detail.charts.refills',
        valueSuffix: '',
        checked: false,
        showInChart: true,
        dataType: [CHART_DATA_TYPE.INST, CHART_DATA_TYPE.AVG]
      }
    ]
  }
];

@UntilDestroy()
@Component({
  selector: 'app-fluid-charts',
  templateUrl: './fluid-charts.component.html',
  styleUrls: ['./fluid-charts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FluidChartsComponent implements OnInit,  AfterViewInit {

  @Input() minDate: any;
  @Input() maxDate: any;

  @Input() showCheckCard = true;
  public chartIsLoading = true;
  public filtersFormGroup = new FormGroup({});

  public crtLang = '';
  public trInstant: (key: string | Array<string>, interpolateParams?: Object) => string | any;
  public Highcharts: typeof Highcharts = Highcharts;
  public chartOptions: Highcharts.Options;
  public chartDataType = CHART_DATA_TYPE;
  public activeTab = CHART_DATA_TYPE.AVG;
  public filterCategories: any[] = [];

  private instCriteria: InstChartDataQueryCriteria;
  private avgCriteria: AvgChartDataQueryCriteria;
  private alarmsInstCriteria: AlarmsListSearchCriteria;
  private alarmsAvgCriteria: AlarmsListSearchCriteria;
  private historyInstCriteria: HistoryListSearchCriteria;
  private historyAvgCriteria: HistoryListSearchCriteria;

  private chartSeries: any[] = [];
  private minChartValue = 0;
  private chartHasError: boolean;
  public readyToRender = false;

  @Input() public equipmentId: string;

  private instDatePickerMinDate: Date;
  public instDatePickerFilter = (date: Date | null): boolean => {
    if (!date) {
      return true;
    }
    if (moment(date).isBefore(this.instDatePickerMinDate)) {
      return false;
    }
    return true;
  }

  constructor(
    private languageService: LanguageService,
    private translateService: TranslateService,
    private equipmentChartsService: EquipmentChartsService,
    private equipmentDetailsService: EquipmentDetailsService,
    private historyService: HistoryService,
    private cdr: ChangeDetectorRef,
    private profileService: ProfileService
  ) {}

  ngAfterViewInit(): void {
    this.refreshData();
    this.readyToRender = true;
    }



  public async ngOnInit(): Promise<void> {
    if (this.profileService.isAdmin()) {
      if (!FILTERS.find(f => f.key === 'matelex_options')) {
        FILTERS.push({
        key: 'matelex_options',
        translationSlug: 'website.equipment.detail.charts.fluid.matelex_options',
        checked: false,
        filters: [
          {
            key: 'number_samples',
            type: CHART_SERIES_TYPE.Y,
            translationSlug: 'website.equipment.detail.charts.fluid.number_samples',
            valueSuffix: '',
            checked: false,
            showInChart: true,
            colorIndex: 14,
            dataType: [CHART_DATA_TYPE.AVG],
            tooltipSeries: null
          },
          {
            key: 'statistics_3days_limit',
            type: CHART_SERIES_TYPE.Y,
            translationSlug: 'website.equipment.detail.charts.fluid.statistics_3days_limit',
            valueSuffix: '',
            checked: false,
            showInChart: true,
            colorIndex: 15,
            dataType: [CHART_DATA_TYPE.AVG],
            tooltipSeries: null
          },
          {
            key: 'old_slope_3_days',
            type: CHART_SERIES_TYPE.Y,
            translationSlug: 'website.equipment.detail.charts.fluid.old_slope_3_days',
            valueSuffix: '',
            checked: false,
            showInChart: true,
            colorIndex: 16,
            dataType: [CHART_DATA_TYPE.AVG],
            tooltipSeries: null
          }
        ]
      });
      }
    }
    this.crtLang = this.languageService.currentLanguage;
    this.trInstant = this.translateService.instant;
    this.trInstant = this.trInstant.bind(this.translateService);

    this.instDatePickerMinDate = moment(new Date()).subtract(3, 'months').toDate();

    this.instCriteria = JSON.parse(JSON.stringify(INST_QUERY_CRITERIA));
    this.avgCriteria = JSON.parse(JSON.stringify(AVG_QUERY_CRITERIA));
    if (this.minDate && this.maxDate) {

      this.avgCriteria.filters.minDate = this.minDate.toISOString();
      this.avgCriteria.filters.maxDate = this.maxDate.toISOString();

      this.instCriteria.filters.minDate = moment(this.maxDate).startOf('day').toISOString();
      this.instCriteria.filters.maxDate = moment(this.maxDate).endOf('day').toISOString();
    }
    this.alarmsInstCriteria = JSON.parse(JSON.stringify(ALARMS_INST_QUERY_CRITERIA));
    this.alarmsAvgCriteria = JSON.parse(JSON.stringify(ALARMS_AVG_QUERY_CRITERIA));
    this.historyInstCriteria = JSON.parse(JSON.stringify(HISTORY_INST_QUERY_CRITERIA));
    this.historyAvgCriteria = JSON.parse(JSON.stringify(HISTORY_AVG_QUERY_CRITERIA));

    this.filterCategories = JSON.parse(JSON.stringify(FILTERS));

    this.activeTab = CHART_DATA_TYPE.AVG;
    this.buildDateFilters();
    this.buildChartFilters();
    this.resetChartFilters();

    this.toggleAllFilterFormControlsState(true);
    this.chartIsLoading = true;
    const chartData = await this.loadChartData(CHART_DATA_TYPE.AVG);
    const alarmsData = await this.loadAlarmsData(CHART_DATA_TYPE.AVG);
    const refillsData = await this.loadHistoryData(CHART_DATA_TYPE.AVG);
    this.chartIsLoading = false;
    this.toggleAllFilterFormControlsState(false);
    this.buildChart(chartData, alarmsData, refillsData);

    this.filtersFormGroup.valueChanges.pipe(untilDestroyed(this), debounceTime(50)).subscribe(async () => {
      this.chartIsLoading = true;
      this.toggleAllFilterFormControlsState(true);
      const chartData = await this.loadChartData(this.activeTab);
      const alarmsData = await this.loadAlarmsData(this.activeTab);
      const refillsData = await this.loadHistoryData(this.activeTab);
      this.chartIsLoading = false;
      this.toggleAllFilterFormControlsState(false);
      this.buildChart(chartData, alarmsData, refillsData);
    });

  }

  refreshData() {
    const event = { index: 0 };

    const chart = Highcharts.charts.filter(Boolean)[0];

    const series = chart?.series;
    const desabled_series = [];
    for (let index = 0; index < series?.length; index++) {
      if (!series[index].visible) { desabled_series.push(series[index].name); }
    }

    this.selectedTabChange(event, desabled_series);
  }

  public async selectedTabChange(event: any, desabled_series?: any) {
    this.chartIsLoading = true;
    this.activeTab = event.index;
    this.buildCriteriaColumnsToQuery();
    const chartData = await this.loadChartData(this.activeTab);
    const alarmsData = await this.loadAlarmsData(this.activeTab);
    const refillsData = await this.loadHistoryData(this.activeTab);
    this.chartIsLoading = false;
    this.buildChart(chartData, alarmsData, refillsData, desabled_series);
  }


  private buildChart(chartData: any, alarmsData: PagedResult<Alarm>, refillsData: any, desabled_series?: any) {
    alarmsData.itemsPage = alarmsData.itemsPage.filter((alarm: Alarm ) => alarm.alarm_type.id !== 'disconnected');
    this.buildChartSeries(chartData, alarmsData, refillsData);
    this.buildChartOptions();
    window.setTimeout(() => {
      this.cdr.detectChanges();
      const chart = Highcharts.charts.filter(Boolean)[0];

      if (desabled_series) {
        const series: any = chart?.series;
        series.map((item: any) => {
          if ( desabled_series.includes(item.name)) { item.hide(); }
        });
      }

      chart.reflow();
    }, 0);
  }

  private async loadChartData(dataType: CHART_DATA_TYPE): Promise<PagedResult<InstChartData | AvgChartData>> {
    let chartResult;
    if (dataType === CHART_DATA_TYPE.INST) {
      chartResult = await this.loadInstChartData();
    } else if (dataType == CHART_DATA_TYPE.AVG) {
      chartResult = await this.loadAvgChartData();
    }
    return chartResult;
  }

  private async loadAlarmsData(dataType: CHART_DATA_TYPE): Promise<PagedResult<any>> {
    let alarmsResult;
    if (dataType === CHART_DATA_TYPE.INST) {
      alarmsResult = await this.loadAlarmsInstData();
    } else if (dataType == CHART_DATA_TYPE.AVG) {
      alarmsResult = await this.loadAlarmsAvgData();
    }
    return alarmsResult;
  }

  private async loadHistoryData(dataType: CHART_DATA_TYPE): Promise<PagedResult<History>> {
    let historyResult;
    if (dataType === CHART_DATA_TYPE.INST) {
      historyResult = await this.loadHistoryInstData();
    } else if (dataType == CHART_DATA_TYPE.AVG) {
      historyResult = await this.loadHistoryAvgData();
    }
    return historyResult;
  }

  private async loadInstChartData(): Promise<PagedResult<InstChartData>> {
    this.chartHasError = false;
    try {
      const instChartResult = await this.equipmentChartsService.getFluidInstChartData(this.equipmentId, this.instCriteria);
      return instChartResult;
    } catch (error) {
      this.chartHasError = true;
      return { itemsPage: [] } as PagedResult<InstChartData>;
    }
  }

  private async loadAvgChartData(): Promise<PagedResult<AvgChartData>> {
    this.chartHasError = false;
    try {
      const avgChartResult = await this.equipmentChartsService.getFluidAvgChartData(this.equipmentId, this.avgCriteria);
      return avgChartResult;
    } catch (error) {
      this.chartHasError = true;
      return { itemsPage: [] } as PagedResult<AvgChartData>;
    }
  }

  private async loadAlarmsInstData(): Promise<PagedResult<any>> {
    const alarmsResult = await this.equipmentDetailsService.getEquipmentActiveAlarms(this.equipmentId, this.alarmsInstCriteria);
    return alarmsResult;
  }

  private async loadAlarmsAvgData(): Promise<PagedResult<any>> {
    const alarmsResult = await this.equipmentDetailsService.getEquipmentActiveAlarms(this.equipmentId, this.alarmsAvgCriteria);
    return alarmsResult;
  }

  private async loadHistoryInstData(): Promise<PagedResult<History>> {
    const historyResult = await this.historyService.getHistoryForEquipment(this.equipmentId, this.historyInstCriteria);
    return historyResult;
  }

  private async loadHistoryAvgData(): Promise<PagedResult<History>> {
    const historyResult = await this.historyService.getHistoryForEquipment(this.equipmentId, this.historyAvgCriteria);
    return historyResult;
  }

  private buildDateFilters(): void {
    const instDateFilterFormControl = new FormControl(this.maxDate ? this.maxDate : moment().toDate());
    this.filtersFormGroup.addControl('instDateFilter', instDateFilterFormControl);

    const avgStartDateFilterFormControl = new FormControl(this.minDate ? this.minDate : moment().subtract(6, 'days').toDate());
    this.filtersFormGroup.addControl('avgStartDateFilter', avgStartDateFilterFormControl);

    const avgEndDateFilterFormControl = new FormControl(this.maxDate ? this.maxDate : moment().toDate());
    this.filtersFormGroup.addControl('avgEndDateFilter', avgEndDateFilterFormControl);

    instDateFilterFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.instCriteria.filters.minDate = moment(this.filtersFormGroup.get('instDateFilter').value).startOf('day').toISOString();
      this.instCriteria.filters.maxDate = moment(this.filtersFormGroup.get('instDateFilter').value).endOf('day').toISOString();

      this.alarmsInstCriteria.filters.minDate = moment(this.filtersFormGroup.get('instDateFilter').value).startOf('day').toISOString();
      this.alarmsInstCriteria.filters.maxDate = moment(this.filtersFormGroup.get('instDateFilter').value).endOf('day').toISOString();

      this.historyInstCriteria.filters.minDate = moment(this.filtersFormGroup.get('instDateFilter').value).startOf('day').toISOString();
      this.historyInstCriteria.filters.maxDate = moment(this.filtersFormGroup.get('instDateFilter').value).endOf('day').toISOString();
    });

    avgStartDateFilterFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.avgCriteria.filters.minDate = moment(this.filtersFormGroup.get('avgStartDateFilter').value).startOf('day').toISOString();
      this.alarmsAvgCriteria.filters.minDate = moment(this.filtersFormGroup.get('avgStartDateFilter').value).startOf('day').toISOString();
      this.historyAvgCriteria.filters.minDate = moment(this.filtersFormGroup.get('avgStartDateFilter').value).startOf('day').toISOString();
    });

    avgEndDateFilterFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.avgCriteria.filters.maxDate = moment(this.filtersFormGroup.get('avgEndDateFilter').value).endOf('day').toISOString();
      this.alarmsAvgCriteria.filters.maxDate = moment(this.filtersFormGroup.get('avgEndDateFilter').value).endOf('day').toISOString();
      this.historyAvgCriteria.filters.maxDate = moment(this.filtersFormGroup.get('avgEndDateFilter').value).endOf('day').toISOString();
    });
  }

  private resetDateFilters(): void {
    // @ts-ignore
    this.filtersFormGroup.get('instDateFilter').setValue(moment().startOf('day').toDate());
    // @ts-ignore
    this.filtersFormGroup.get('avgStartDateFilter').setValue(moment().subtract(6, 'days').startOf('day').toDate());
    // @ts-ignore
    this.filtersFormGroup.get('avgEndDateFilter').setValue(moment().subtract(6, 'days').endOf('day').toDate());
  }

  private resetChartFilters(): void {
    const filtersCopy = JSON.parse(JSON.stringify(FILTERS));
    for (let categoryIndex = 0; categoryIndex < this.filterCategories.length; categoryIndex++) {
      this.filterCategories[categoryIndex].checked = filtersCopy[categoryIndex].checked;
      this.filtersFormGroup.get(this.filterCategories[categoryIndex].key).setValue(this.filterCategories[categoryIndex].checked);
      for (let filterIndex = 0; filterIndex < this.filterCategories[categoryIndex].filters.length; filterIndex++) {
        this.filterCategories[categoryIndex].filters[filterIndex].checked = filtersCopy[categoryIndex].filters[filterIndex].checked;
        this.filtersFormGroup
          .get(this.filterCategories[categoryIndex].filters[filterIndex].key)
          .setValue(this.filterCategories[categoryIndex].filters[filterIndex].checked);
      }
    }
  }

  private async buildChartFilters() {
    for (const filterCategory of this.filterCategories) {
      const categoryFilterFormControl = new FormControl();
      this.filtersFormGroup.addControl(filterCategory.key, categoryFilterFormControl);

      categoryFilterFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe(categoryFilterChecked => {
        filterCategory.checked = categoryFilterChecked;
      });

      for (const filterItem of filterCategory.filters) {
        const filterFormControl = new FormControl();
        this.filtersFormGroup.addControl(filterItem.key, filterFormControl);

        filterFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe(filterChecked => {
          filterItem.checked = filterChecked;
          this.buildCriteriaColumnsToQuery();
        });
      }
      categoryFilterFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe(categoryFilterChecked => {
        for (const filter of filterCategory.filters) {
          this.filtersFormGroup.get(filter.key).setValue(categoryFilterChecked);
        }
      });
    }

    this.filtersFormGroup.valueChanges.pipe(untilDestroyed(this), debounceTime(50)).subscribe(async () => {
      this.chartIsLoading = true;
      this.toggleAllFilterFormControlsState(true);
      const chartData = await this.loadChartData(this.activeTab);
      const alarmsData = await this.loadAlarmsData(this.activeTab);
      const refillsData = await this.loadHistoryData(this.activeTab);
      this.chartIsLoading = false;
      this.toggleAllFilterFormControlsState(false);
      this.buildChart(chartData, alarmsData, refillsData);
    });
  }

  private buildSeriesObject(filterItem: any, chartData: PagedResult<InstChartData | AvgChartData>): object {
    const seriesObject = {
      id: filterItem.key,
      color: filterItem.showInChart && filterItem.dataType.includes(this.activeTab) ? COLORS[filterItem.colorIndex] : 'transparent',
      borderColor: filterItem.showInChart && filterItem.dataType.includes(this.activeTab) ? COLORS[filterItem.colorIndex] : 'transparent',
      showInLegend: filterItem.showInChart && filterItem.dataType.includes(this.activeTab) ? undefined : false,
      name: this.trInstant(filterItem.translationSlug),
      data: chartData.itemsPage.map(item => [new Date(item.date_time).getTime(), item[filterItem.key]]),
      tooltip: {
        valueSuffix: filterItem.valueSuffix
      },
      boostThreshold: 1,
      yAxis: filterItem.yAxis ?? 0
    };

    return seriesObject;
  }

  private buildAlarmsSeriesObject(filterItem: any, alarmsData: PagedResult<any>): object {
    const seriesObject = {
      type: 'scatter',
      id: filterItem.key,
      showInLegend: true,
      name: this.trInstant(filterItem.translationSlug),
      data: alarmsData.itemsPage.map(item => [new Date(item.date_time).getTime(), this.minChartValue]),
      marker: {
        enabled: true,
        symbol: 'circle',
        fillColor: 'red',
        radius: 10
      },
      tooltip: {
        shared: false
      },
      boostThreshold: 1,
      yAxis: filterItem.yAxis ?? 2
    };

    return seriesObject;
  }

  private buildRefillsSeriesObject(filterItem: any, refillsData: PagedResult<any>): object {
    const seriesObject = {
      type: 'scatter',
      id: filterItem.key,
      showInLegend: true,
      name: this.trInstant(filterItem.translationSlug),
      data: refillsData.itemsPage.map(item => [new Date(item.date_time).getTime(), this.minChartValue]),
      marker: {
        enabled: true,
        symbol: 'circle',
        fillColor: 'green',
        radius: 10
      },
      tooltip: {
        shared: false
      },
      boostThreshold: 1,
      yAxis: filterItem.yAxis ?? 2
    };

    return seriesObject;
  }

  private buildChartSeries(
    chartData: PagedResult<InstChartData | AvgChartData>,
    alarmsData: PagedResult<any>,
    refillsData: PagedResult<any>
  ): void {
    this.chartSeries = [];
    for (const filterCategory of this.filterCategories) {
      for (const filterItem of filterCategory.filters) {
        if (!filterItem.checked) {
          continue;
        }
        if (filterItem.dataType.includes(this.activeTab) && filterItem.type === CHART_SERIES_TYPE.Y) {
          const series = this.buildSeriesObject(filterItem, chartData);
          this.chartSeries.push(series);
        } else if (filterItem.dataType.includes(this.activeTab) && filterItem.type === CHART_SERIES_TYPE.ALARMS) {
          const series = this.buildAlarmsSeriesObject(filterItem, alarmsData);
          this.chartSeries.push(series);
        } else if (filterItem.dataType.includes(this.activeTab) && filterItem.type === CHART_SERIES_TYPE.REFILLS) {
          const series = this.buildRefillsSeriesObject(filterItem, refillsData);
          this.chartSeries.push(series);
        }

        if (filterItem.tooltipSeries?.length) {
          for (const tooltipSeries of filterItem.tooltipSeries) {
            if (tooltipSeries.dataType.includes(this.activeTab)) {
              const series = this.buildSeriesObject(tooltipSeries, chartData);
              this.chartSeries.push(series);
            }
          }
        }
      }
    }
  }

  private buildChartOptions(): void {
    const ctx = this;

    this.chartOptions = {
      chart: {
        zoomType: 'xy',
        marginTop: 100,
        height: 600
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: ['viewFullscreen'],
            symbol: 'url(../../../../../../assets/images/open_in_full.png)',
            symbolSize: 20,
            symbolX: 23,
            symbolY: 23
          }
        }
      },
      colors: COLORS,
      time: {
        timezone: moment.tz.guess()
      },
      title: {
        text: ''
      },
      xAxis: [
        {
          type: 'datetime'
        }
      ],
      yAxis: [
        {
          title: {
            text: this.trInstant('website.equipment.detail.charts.values')
          },
          events: {
            afterSetExtremes: event => {
              this.minChartValue = event.min;
            }
          }
        },
        {
          opposite: true,
          title: {
            text: this.trInstant('Celsius')
          },
          events: {
            afterSetExtremes: event => {
              this.minChartValue = event.min;
            }
          }
        },
        {
          title: {
            text: ''
          },
          labels: {
            enabled: false
          }
        }
      ],
      tooltip: {
        formatter() {
          if (this.points) {
            return this.points.reduce((previous, current) => {
              return `${previous}<br/>${current.series.name}: ${current.y}${current.series.options.tooltip.valueSuffix}`;
            }, `<i>${ctx.trInstant('website.equipment.detail.charts.drag_to_zoom')}</i><br/><br/><b>${moment(this.x).format('DD/MM/YYYY HH:mm:ss')}</b>`);
          } else {
            return `<b>${moment(this.x).format('DD/MM/YYYY HH:mm:ss')}</b>`;
          }
        },
        style: {
          color: '#66788a',
          fontWeight: 'normal',
          fontSize: '12px',
          letterSpacing: '-0.04px',
          lineHeight: '20px',
          fontFamily: 'Ubuntu'
        },
        shared: true,
        borderWidth: 0,
        backgroundColor: '#fff',
        borderRadius: 8,
        padding: 15
      },
      legend: {
        layout: 'horizontal',
        align: 'center',
        itemMarginBottom: 2,
        verticalAlign: 'top',
        floating: true,
        backgroundColor: Highcharts.defaultOptions.legend.backgroundColor
      },
      series: this.chartSeries,
      responsive: {
        rules: [
          {
            condition: {
              maxWidth:700,
            },
            chartOptions: {
              legend: {
                floating: false,
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'top',
                x: 0,
                y: 0
              },
              yAxis: [
                {
                  labels: {
                    align: 'right',
                    x: 0,
                    y: -6
                  },
                  showLastLabel: true
                },
                {
                  labels: {
                    align: 'left',
                    x: 0,
                    y: -6
                  },
                  showLastLabel: true
                },
                {
                  visible: false
                }
              ]
            }
          }
        ]
      },
      credits: {
        enabled: false
      },
      lang: {
        noData: this.chartHasError
          ? this.trInstant('website.equipment.detail.charts.error')
          : this.trInstant('website.equipment.detail.charts.no_data')
      }
    };
  }

  private buildCriteriaColumnsToQuery(): void {
    this.instCriteria.columns = this.avgCriteria.columns = this.filterCategories
      .map(filterCategory => filterCategory.filters)
      .flat()
      .filter(
        filterItem =>
          filterItem.checked &&
          filterItem.type === CHART_SERIES_TYPE.Y &&
          filterItem.checked &&
          filterItem.dataType.includes(this.activeTab)
      )
      .map(filterItem => filterItem.key);

    const tooltipSeriesColumns = this.filterCategories
      .map(filterCategory => filterCategory.filters)
      .flat()
      .filter(
        filterItem =>
          filterItem.checked &&
          filterItem.type === CHART_SERIES_TYPE.Y &&
          filterItem.checked &&
          filterItem.dataType.includes(this.activeTab) &&
          filterItem.tooltipSeries !== null
      )
      .map(filterItem => filterItem.tooltipSeries)
      .flat()
      .filter(tooltipSeries => tooltipSeries.dataType.includes(this.activeTab))
      .map(tooltipSeries => tooltipSeries.key);

    this.instCriteria.columns = [...this.instCriteria.columns, ...tooltipSeriesColumns];
    this.avgCriteria.columns = [...this.avgCriteria.columns, ...tooltipSeriesColumns];
  }

  private toggleAllFilterFormControlsState(chartIsLoading: boolean) {
    for (const formControlKey in this.filtersFormGroup.controls) {
      const formControl = this.filtersFormGroup.get(formControlKey);
      chartIsLoading ? formControl.disable({ emitEvent: false }) : formControl.enable({ emitEvent: false });
    }
  }


  public getCheckedFiltersInCategoryCount(filterCategory: any) {
    return filterCategory.filters.filter((filterItem: any) => filterItem.checked === true && filterItem.dataType.includes(this.activeTab))
      .length;
  }

  public getAvailableFiltersInCategoryCount(filterCategory: any) {
    return filterCategory.filters.filter((filterItem: any) => filterItem.dataType.includes(this.activeTab)).length;
  }

  public async resetFilters() {
    window.scroll(0, 0);
    this.activeTab = CHART_DATA_TYPE.AVG;
    this.chartIsLoading = true;

    this.filtersFormGroup.reset();
    this.resetDateFilters();
    this.resetChartFilters();

    this.buildCriteriaColumnsToQuery();
    this.toggleAllFilterFormControlsState(true);
    const chartData = await this.loadChartData(CHART_DATA_TYPE.AVG);
    const alarmsData = await this.loadAlarmsData(CHART_DATA_TYPE.AVG);
    const refillsData = await this.loadHistoryData(CHART_DATA_TYPE.AVG);
    this.chartIsLoading = false;
    this.toggleAllFilterFormControlsState(false);
    this.buildChart(chartData, alarmsData, refillsData);
  }

  // public instDatePickerFilter(date: Date | null): boolean {
  //   if (!date) {
  //     return true;
  //   }
  //   console.log('FluidChartsComponent instDatePickerFilter date', date);
  //   console.log('FluidChartsComponent instDatePickerFilter this', this);
  //   console.log('FluidChartsComponent instDatePickerFilter this.instDatePickerMinDate', this.instDatePickerMinDate);
  //   if (moment(date).isBefore(this.instDatePickerMinDate)) {
  //     return false;
  //   }
  //   return true;
  // }
}
