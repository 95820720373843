import { Injectable } from '@angular/core';
import { element } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class EquipmentUtilsService {

  constructor() { }

  structureStage(engines: any): any {
    let stages = [];
    for(let i = 1 ; i <= 3 ; i++) {
      const stage = engines.filter((element: any) => element.stage === i);
      stages.push(stage);
    }
    return stages;
  }

  getTotalStages(engines: any): number {
    let max = 0;
    if(engines && engines?.length != 0) {
      for(let i=0 ; i<engines.length; i++) {
        if(max < engines[i].stage) max = engines[i].stage;
      }
    }
    return max;
  }
}
