import { Injectable } from '@angular/core';
import { AvgChartData } from '@shared/models/avg-chart-data';
import { InstChartData } from '@shared/models/inst-chart-data';
import { PagedResult } from '@shared/services/paged-result';
import { UtilsService } from '@shared/services/utils.service';
import gql from 'graphql-tag';
import { elementAt, map, take } from 'rxjs/operators';
import { GraphqlService } from '../../graphql/graphql.service';
import { AvgChartDataQueryCriteria } from './avg-chart-data-query-criteria.interface';
import { InstChartDataQueryCriteria } from './inst-chart-data-query-criteria.interface';

@Injectable({
  providedIn: 'root'
})
export class EquipmentChartsService {
  constructor(private graphqlService: GraphqlService,
              private utilService: UtilsService) {}

  public getFluidInstChartData(equipmentId: string, criteria: InstChartDataQueryCriteria): Promise<PagedResult<InstChartData>> {
    return this.graphqlService
      .query(
        this.getFluidInstChartDataQuery(
          equipmentId,
          criteria.columns,
          criteria.filters.minDate,
          criteria.filters.maxDate,
          criteria.pagination.page,
          criteria.pagination.perPage
        ),
        {},
        false
      )
      .pipe(
        map(result => {
          const instChartData = result.data.getInstData.instDataPage.map((in_elem:any) =>{
            let elem = Object.assign({},in_elem)
            elem.tank_weight = this.utilService.gramsToKg(elem.tank_weight);
            elem.average_reference = this.utilService.gramsToKg(elem.average_reference);
            elem.low_level_limit = this.utilService.gramsToKg(elem.low_level_limit);
            return elem;
          })
          const instChartDataResult: PagedResult<InstChartData> = new PagedResult<InstChartData>(instChartData);
          return instChartDataResult;
        }),
        take(1)
      )
      .toPromise() as Promise<PagedResult<InstChartData>>;
  }

  private getFluidInstChartDataQuery(
    equipmentId: string,
    columns: string[],
    minDateIsoStr?: string,
    maxDateIsoStr?: string,
    page?: number,
    perPage?: number
  ) {
    return gql`
      query getInstData {
        getInstData(criteria: {
          equipmentNumber: "${equipmentId}"
          minDate: "${minDateIsoStr}"
          maxDate: "${maxDateIsoStr}"
          page: ${page}
          perPage: ${perPage}
        }) {
          instDataPage {
            _id
            ${columns.join('\n')}
            date_time
          }
        }
      }
    `;
  }

  public getFluidAvgChartData(equipmentId: string, criteria: AvgChartDataQueryCriteria): Promise<PagedResult<AvgChartData>> {
    console.log('criteria statistics => ', criteria.columns);
    return this.graphqlService
      .query(
        this.getFluidAvgChartDataQuery(
          equipmentId,
          criteria.columns,
          criteria.filters.minDate,
          criteria.filters.maxDate,
          criteria.pagination.page,
          criteria.pagination.perPage
        ),
        {},
        false
      )
      .pipe(
        map(result => {
          const avgChartData = result.data.getAvgData.avgDataPage.map((in_avg: any) => {
            let avg = Object.assign({},in_avg)
            avg.tank_weight = this.utilService.gramsToKg(avg.tank_weight);
            avg.average_reference = this.utilService.gramsToKg(avg.average_reference);
            avg.low_level_limit = this.utilService.gramsToKg(avg.low_level_limit);
            return avg
          })
          const avgChartDataResult: PagedResult<AvgChartData> = new PagedResult<AvgChartData>(avgChartData);
          return avgChartDataResult;
        }),
        take(1)
      )
      .toPromise() as Promise<PagedResult<AvgChartData>>;
  }

  private getFluidAvgChartDataQuery(
    equipmentId: string,
    columns: string[],
    minDateIsoStr?: string,
    maxDateIsoStr?: string,
    page?: number,
    perPage?: number
  ) {
    return gql`
      query getAvgData {
        getAvgData(criteria: {
          equipmentNumber: "${equipmentId}"
          minDate: "${minDateIsoStr}"
          maxDate: "${maxDateIsoStr}"
          page: ${page}
          perPage: ${perPage}
        }) {
          total
          avgDataPage {
            _id
            ${columns.join('\n')}
            date_time
          }
        }
      }
    `;
  }

  public getEnergyInstChartData(equipmentId: string, equipmentEngines: any[], criteria: InstChartDataQueryCriteria): Promise<PagedResult<InstChartData>> {
    return this.graphqlService
      .query(
        this.getEnergyInstChartDataQuery(
          equipmentId,
          equipmentEngines,
          criteria.columns,
          criteria.filters.minDate,
          criteria.filters.maxDate,
          criteria.pagination.page,
          criteria.pagination.perPage
        ),
        {},
        false
      )
      .pipe(
        map(result => {
          const instChartData = result.data.getInstData.instDataPage;
          const instChartDataResult: PagedResult<InstChartData> = new PagedResult<InstChartData>(instChartData);
          return instChartDataResult;
        }),
        take(1)
      )
      .toPromise() as Promise<PagedResult<InstChartData>>;
  }

  private getEnergyInstChartDataQuery(
    equipmentId: string,
    equipmentEngines: any[],
    columns: string[],
    minDateIsoStr?: string,
    maxDateIsoStr?: string,
    page?: number,
    perPage?: number
  ) {
    let energyColumns = '';

    for (const column of columns) {
      equipmentEngines?.forEach(engine => {
        if (column === 'energy.power.' + engine.id) {
          energyColumns += 'energy{\npower\n engine_id\n}\n';
        } else if (column === 'energy.intensity.' + engine.id) {
          energyColumns += 'energy{intensity\n engine_id\n}\n';
        } else if (column === 'energy.cos_phi.' + engine.id) {
          energyColumns += 'energy{cos_phi\n engine_id\n}\n';
        }
      });
      if (!column.includes('energy.power.') && !column.includes('energy.intensity.') && !column.includes('energy.cos_phi.')) {
        energyColumns += `${column}\n`;
      }
    }
    return gql`
      query getInstData {
        getInstData(criteria: {
          equipmentNumber: "${equipmentId}"
          minDate: "${minDateIsoStr}"
          maxDate: "${maxDateIsoStr}"
          page: ${page}
          perPage: ${perPage}
        }) {
          instDataPage {
            _id
            ${energyColumns}
            date_time
          }
        }
      }
    `;
  }

  public getEnergyAvgChartData(equipmentId: string, equipmentEngines: any[], criteria: AvgChartDataQueryCriteria): Promise<PagedResult<AvgChartData>> {
    return this.graphqlService
      .query(
        this.getEnergyAvgChartDataQuery(
          equipmentId,
          equipmentEngines,
          criteria.columns,
          criteria.filters.minDate,
          criteria.filters.maxDate,
          criteria.pagination.page,
          criteria.pagination.perPage
        ),
        {},
        false
      )
      .pipe(
        map(result => {
          const avgChartData = result.data.getAvgData.avgDataPage;
          const avgChartDataResult: PagedResult<AvgChartData> = new PagedResult<AvgChartData>(avgChartData);
          return avgChartDataResult;
        }),
        take(1)
      )
      .toPromise() as Promise<PagedResult<AvgChartData>>;
  }

  private getEnergyAvgChartDataQuery(
    equipmentId: string,
    equipmentEngines: any[],
    columns: string[],
    minDateIsoStr?: string,
    maxDateIsoStr?: string,
    page?: number,
    perPage?: number
  ) {
    let energyColumns = '';

    for (const column of columns) {
      equipmentEngines?.forEach(engine => {
        if (column === 'energy.power.' + engine.id) {
          energyColumns += 'energy{\nenergy\n engine_id\n}\n';
        } else if (column === 'energy.intensity.' + engine.id) {
          energyColumns += 'energy{intensity\n engine_id\n}\n';
        } else if (column === 'energy.cos_phi.' + engine.id) {
          energyColumns += 'energy{cos_phi\n engine_id\n}\n';
        }
      });
      if (!column.includes('energy.power.') && !column.includes('energy.intensity.') && !column.includes('energy.cos_phi.')) {
        energyColumns += `${column}\n`;
      }
    }

    return gql`
      query getAvgData {
        getAvgData(criteria: {
          equipmentNumber: "${equipmentId}"
          minDate: "${minDateIsoStr}"
          maxDate: "${maxDateIsoStr}"
          page: ${page}
          perPage: ${perPage}
        }) {
          total
          avgDataPage {
            _id
            ${energyColumns}
            date_time
          }
        }
      }
    `;
  }
}
