import { Injectable } from '@angular/core';
import { HistoryCreate } from '@shared/models/history.create';
import gql from 'graphql-tag';
import { map, take } from 'rxjs/operators';

import { GraphqlService } from '../../graphql/graphql.service';
import { ActionType } from '../../shared/models/action-type';
import { History } from '../../shared/models/history';
import { PagedResult } from '../../shared/services/paged-result';
import { HistoryListSearchCriteria } from './history-list-search-criteria.interface';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {
  constructor(private graphqlService: GraphqlService) {}

  public createHistoryEntry(historyToCreate: HistoryCreate): Promise<any> {
    const gqlParams: any = {};

    console.log('HistoryService createHistoryEntry historyToCreate', historyToCreate);

    if (historyToCreate.totalWeight) {
      gqlParams.totalWeight = +historyToCreate.totalWeight;
    }
    if (historyToCreate.actionType) {
      gqlParams.actionType = historyToCreate.actionType;
    }
    if (historyToCreate.equipment) {
      gqlParams.equipment = historyToCreate.equipment;
    }
    if (historyToCreate.fluid) {
      gqlParams.fluid = historyToCreate.fluid.id;
    }
    if (historyToCreate.date) {
      gqlParams.date = historyToCreate.date;
    }
    if (historyToCreate.noCerfa) {
      gqlParams.noCerfa = historyToCreate.noCerfa;
    }
    if (historyToCreate.retrofit || historyToCreate.retrofit === false) {
      gqlParams.retrofit = historyToCreate.retrofit;
    }
    return this.graphqlService.mutate(this.createHistoryEntryMutation(), gqlParams).toPromise();
  }

  public getHistoryForEquipment(equipmentId: string, criteria: HistoryListSearchCriteria): Promise<PagedResult<History>> {
    const gqlParams: any = { equipmentId };

    if (criteria.sort.field) {
      gqlParams.sortField = criteria.sort.field;
    }
    gqlParams.sortDir = criteria.sort.direction;
    if (!gqlParams.sortDir) {
      gqlParams.sortDir = 'DESC';
    }

    if (criteria.pagination) {
      gqlParams.page = criteria.pagination.page + 1;
      gqlParams.perPage = criteria.pagination.perPage;
    }

    return this.graphqlService
      .query(this.getHistoryForEquipmentQuery(criteria.filters.type, criteria.filters.minDate, criteria.filters.maxDate), gqlParams, false)
      .pipe(
        take(1),
        map(result => {
          const historyData = result.data.equipmentHistoryItems.itemsPage;
          let historyResult: PagedResult<History> = new PagedResult<History>();
          if (Array.isArray(historyData)) {
            historyResult.itemsPage = historyData.map(historyDataItem => {
              return new History(historyDataItem);
            });
          }
          historyResult.total = result.data.equipmentHistoryItems.total;
          return historyResult;
        })
      )
      .toPromise() as Promise<PagedResult<History>>;
  }

  public getHistoryActionTypes() {
    return this.graphqlService
      .query(this.getHistoryActionTypesQuery(), {}, false)
      .pipe(
        map(resultData => {
          const result: PagedResult<ActionType> = { itemsPage: [], total: 0 };
          const actionTypesData = resultData.data.actionTypes.items;
          if (Array.isArray(actionTypesData)) {
            result.itemsPage = actionTypesData.map(actionTypeData => {
              return new ActionType(actionTypeData);
            });
          }
          result.total = resultData.data.actionTypes.meta.total;
          return result;
        })
      )
      .pipe(take(1))
      .toPromise() as Promise<PagedResult<ActionType>>;
  }

  private getHistoryActionTypesQuery() {
    return gql`
      query getHistoryActionTypes {
        actionTypes(criteria: {}) {
          meta {
            total
          }
          items {
            id
            color
            translations {
              locale
              fields {
                field
                value
              }
            }
          }
        }
      }
    `;
  }

  private getHistoryForEquipmentQuery(typesFilter: string[] = null, minDateIsoStr?: string, maxDateIsoStr?: string) {
    let gqlHistoryActionTypesFilter = '';
    if (Array.isArray(typesFilter) && typesFilter.length) {
      gqlHistoryActionTypesFilter = ',types: ["' + typesFilter.join('", "') + '" ]';
    }

    let gqlMinDateFilter = '';
    if (minDateIsoStr) {
      gqlMinDateFilter = `, minDateIsoStr: "${minDateIsoStr}"`;
    }

    let gqlMaxDateFilter = '';
    if (maxDateIsoStr) {
      gqlMaxDateFilter = `, maxDateIsoStr: "${maxDateIsoStr}"`;
    }

    return gql`
      query getHistories($equipmentId: String!, $sortField: String!, $sortDir: String!, $page: Float!, $perPage: Float!) {
        equipmentHistoryItems(criteria: { page: $page, perPage: $perPage, equipmentId: $equipmentId, sortField: $sortField, sortDir: $sortDir ${gqlHistoryActionTypesFilter} ${gqlMinDateFilter} ${gqlMaxDateFilter}}) {
          total
          itemsPage {
            _id
            date
            totalWeight
            noCerfa
            fluid {
              id
              name
            }
            retrofit
            actionType {
              id
              color
              backgroundColor
              translations {
                locale
                fields {
                  field
                  value
                }
              }
            }
            installerUser {
              _id
              email
              firstName
              lastName
              profileImage
            }
            equipment {
              _id
              id
              name
            }
          }
        }
      }
    `;
  }

  private createHistoryEntryMutation($fluidId: string = ''): any {
    let gqlStrFluid = '';
    if ($fluidId) {
      gqlStrFluid = 'fluid: "' + $fluidId + '"';
    }

    return gql`
      mutation createHistory($totalWeight: Float!, $actionType: ID!, $date: DateTime!, $noCerfa: String!, $equipment: ID!, $fluid: ID!, $retrofit: Boolean!) {
        createHistory(
          input: {
            totalWeight: $totalWeight
            date: $date
            noCerfa: $noCerfa
            actionType: $actionType
            equipment: $equipment
            ${gqlStrFluid}
            fluid: $fluid
            retrofit: $retrofit
          }
        ) {
          _id
        }
      }
    `;
  }
}
