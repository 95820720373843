<div class='sidebar' (click)='closeSidebar($event)'>
  <div class='profile-wrapper'>
    <!-- <app-profile-image [userInfo]="userInfo" width="64px" height="64px"></app-profile-image> -->
    <span class='user-name'>{{ userInfo?.name }}</span>
    <span class='company'>{{ (userInfo?.roles)[0] }}</span>
    <mat-form-field appearance='fill' class='token-polarbox'>
      <mat-label>ID PolarBox</mat-label>
      <input matInput [type]="hide ? 'password' : 'text'" ngModel='{{token}}' disabled>
      <button mat-icon-button matSuffix (click)='hide = !hide' [attr.aria-label]="'sidebar-hide-id-polarbox'"
              [attr.aria-pressed]='hide'>
        <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
      </button>
    </mat-form-field>
    <!-- <span> translate Example: {{ 'website.contact.contact_info' | translate }}</span> -->
  </div>
  <!-- /.profile-wrapper -->
  <div class='sidebar-container-wrapper'>
    <div class='sidebar-container'>

        <div *ngFor='let section of itemsSideBar'  >
          <div *ngIf='!section.hide ' [matx-if-has-permission]='section.hasPermissions?section.hasPermissions:null'>
            <h3>{{ section.title | translate }}</h3>
            <mat-nav-list>
              <a *ngFor='let item of section.items ' (click)='reloadCounterAlarms()'
                class='py-1 h-100'
                 [attr.aria-label]="item.ariaLabel" mat-list-item [routerLink]="item.link"
                 routerLinkActive='active-side-menu-link' [disabled]='item.disabled' [matx-if-has-permission]='item.hasPermissions?item.hasPermissions:null'
              >
<div   class='d-flex '>
  <div class="p-2 w-100 "><span class='custom-icon mt-5 {{item.icon}}'></span>{{ item.title | translate }}</div>

  <div class="p-2 flex-shrink-1 "  *ngIf='item.title==="website.sidebar.equipment"'> <span    matBadge='{{ totalEquipmentsHavingAlarms?.totalEquipmentsWithActiveAlarms }}'
                                                                                      [matBadgeHidden]='!totalEquipmentsHavingAlarms?.totalEquipmentsWithActiveAlarms'
                                                                                      matTooltip="{{ 'website.equipment.alarm.red_badge' | translate }}"
                                                                                      class='custom-badge-danger  '></span>
    <span  matBadge='{{ totalEquipmentsHavingAlarms?.totalEquipmentsWithConnectionAlarm }}'
           matTooltip="{{ 'website.equipment.alarm.yellow_badge' | translate }}"
           [matBadgeHidden]='!totalEquipmentsHavingAlarms?.totalEquipmentsWithConnectionAlarm'
           class='custom-badge-warn'> </span></div>

</div>



              </a>

            </mat-nav-list>
          </div>
        </div>


      <div class='help-content' x>
        <span>{{ 'website.sidebar.help_needed' | translate }}</span>
        <a
          [attr.aria-label]="'sidebar-read-manual'"
          href='https://www.matelex.com/centre-de-ressources/'>{{ 'website.sidebar.read_manual' | translate }}</a>
        <span class='custom-icon custom-icon-export'></span>
      </div>
    </div>
  </div>

  <p class='copyright'>&copy; {{ 'website.sidebar.copyright' | translate }}</p>
</div>
