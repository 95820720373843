
  <div class=" dni-list-content">
    <app-breadcrumbs [elements]="[
        { displayValue: trInstant('website.equipment.list.breadcrumb.equipment_lbl', crtLang), routerLink: 'equipment' },
        { displayValue: trInstant('website.equipment.list.breadcrumb.list_of_equipment', crtLang), routerLink: 'equipment' }
      ]">
    </app-breadcrumbs>
    <div class="content-header">
      <h1>{{ 'website.equipment.list.page_title' | translate }}</h1>
      <div>
        <button [matx-if-has-permission]="[Permission.MatelexAdminPermission]" mat-raised-button color="primary"
          (click)="openCreateDialog()">
          <mat-icon class='float-start'>add</mat-icon>{{ 'website.equipment.list.add_equipment' | translate }}
        </button>


        <!-- <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
          <mat-icon>more_vert</mat-icon>
        </button> -->
        <mat-menu #menu="matMenu">
          <button mat-menu-item>
            <mat-icon>dialpad</mat-icon>
            <span>{{ 'website.equipment.list.more_actions.action1' | translate }}</span>
          </button>
          <button mat-menu-item disabled>
            <mat-icon>voicemail</mat-icon>
            <span>{{ 'website.equipment.list.more_actions.action2' | translate }}</span>
          </button>
        </mat-menu>
      </div>
    </div>
    <div class='row'>
      <div class="col-md-7" >
        <mat-card class="content-card search-field">
          <mat-form-field appearance="fill" class='custom-search-field' subscriptSizing='dynamic'  >
            <mat-label>Recherche</mat-label>
            <input matInput placeholder="{{ trInstant('website.equipment.list.search_placeholder', crtLang) }}"
                   [(ngModel)]="criteria.filters.term" (input)="inputSubject.next('')">
            <mat-icon matPrefix>search</mat-icon>
          </mat-form-field>


          <div *ngIf='this.statuses[this.statusIndex].value !== "notAttached"'>
            <div class="row my-auto px-2 " >
              <div  class='col-md-2 col-12 p-1'>
                <app-autocomplete-searchable-elements
                  [classList]="'col-md-12'"
                  [type]='"cc"'
                  [name]='trInstant("website.equipment.list.filters.customer_chain_lbl", crtLang)'
                  [inputDisabled]="!!unassignedEquipments"
                  [customerLocationChainsFilterOptions]='customerChainsSearchOptions'
                  (storeSearchableElements)='handleAutoComplete($event, "cc")'>
                </app-autocomplete-searchable-elements>
              </div>
             <div    class='col-md-2 col-12 p-1'>
               <app-autocomplete-searchable-elements
                 [classList]="'col-md-12'"
                 [type]='"c"'
                 [name]='trInstant("website.equipment.list.filters.country_lbl", crtLang)'
                 [inputDisabled]="!!unassignedEquipments"
                 [countriesFilterOptions]='countriesSearchOptions'
                 (storeSearchableElements)='handleAutoComplete($event, "c")'>
               </app-autocomplete-searchable-elements>
             </div>
             <div  class='col-md-2 col-12 p-1'>
               <app-autocomplete-searchable-elements
                 [classList]="'col-md-12'"
                 [type]='"inst"'
                 [name]='trInstant("website.equipment.list.filters.customer_location_lbl", crtLang)'
                 [inputDisabled]="!!unassignedEquipments"
                 [customerLocationChainsFilterOptions]='installersSearchOptions'
                 (storeSearchableElements)='handleAutoComplete($event, "inst")'>
               </app-autocomplete-searchable-elements>
             </div>
              <div  class='col-md-2 col-12 p-1'>
                <mat-form-field   class="custom-select-like-dropdown col-12 "  appearance="fill" >
                  <mat-label>
                    {{"website.equipment.list.filters.controllerHardware_lbl"|translate}}  <mat-icon>keyboard_arrow_down</mat-icon>
                  </mat-label>
                  <input type="text"
                         style='cursor: pointer'
                         [placeholder]="'website.equipment.list.filters.controllerHardware_lbl'|translate"
                         aria-label="equipmentType"
                         matInput
                         [formControl]='controllerHardwareCtrl'
                         [matAutocomplete]="auto">
                  <mat-autocomplete #auto="matAutocomplete" (optionSelected)='handleAutoComplete($event.option.value, "equipmentType")'>
                    <mat-option *ngFor="let option of equipmentTypeSearchOptions" [value]="option.hardwareVersion">
                      {{option.label |titlecase }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>

              <button class='my-auto col-md-3 col-12  filter-button ms-md-4'  mat-button (click)="removeFilters()" [disabled]="!!unassignedEquipments">
                {{ 'website.equipment.list.filters.remove_filters' | translate }}
              </button>
            </div>

          </div>

          <ng-container *ngIf='(criteria.filters.customerChain.length > 0 || criteria.filters.country.length > 0 || criteria.filters.customerLocation.length > 0 || criteria.filters.installers.length > 0|| criteria.filters.controllerHardware.length > 0) && this.statuses[this.statusIndex].value !== "notAttached"'>
            <mat-divider></mat-divider>
            <div class="p-3">
              <mat-chip-listbox  aria-label="Filters selection">
                <mat-chip color='primary' *ngFor="let value of criteria.filters.customerChain">{{
                    (criteriaForView?.filters?.customerChainsById)[value]?.name
                  }}<mat-icon matChipRemove (click)="removeCustomerChainFilterKey(value)">cancel</mat-icon>
                </mat-chip>
                <mat-chip color='primary' *ngFor="let value of criteria.filters.country">{{ value }}<mat-icon matChipRemove
                                                                                                              (click)="removeCountryFilterKey(value)">cancel</mat-icon>
                </mat-chip>
                <mat-chip color='primary' *ngFor="let value of criteria.filters.installers">{{
                    (criteriaForView?.filters?.installersById)[value]?.name
                  }}<mat-icon matChipRemove (click)="removeInstallersFilterKey(value)">cancel</mat-icon>
                </mat-chip>

                <mat-chip color='primary' *ngFor="let value of criteria.filters.controllerHardware">{{
                    (criteriaForView?.filters?.controllerHardwareById)[value]?.label
                  }}<mat-icon matChipRemove (click)="removeEquipmentTypeFilterKey(value)">cancel</mat-icon>
                </mat-chip>
              </mat-chip-listbox>
            </div>
          </ng-container>


        </mat-card>
        <mat-tab-group class='mt-3' mat-stretch-tabs="false" [(selectedIndex)]="statusIndex" (selectedIndexChange)="setStatusFilter($event)">
          <mat-tab matBadgePosition="above after" matBadgeOverlap="false"
            *ngFor="let status of statuses | slice: 0:statuses.length - 1">
            <ng-template mat-tab-label>

              <div *ngIf="status.value === 'onlyHavingActiveAlarms'">
              <span class='custom-badge-danger' matBadge="{{ customerLocationsWithEquipments?.totalEquipmentsWithActiveAlarms }}"
                     matTooltip="{{ 'website.equipment.alarm.red_badge' | translate }}">{{ status.displayValue }}</span>
                <span *ngIf="status.value === 'onlyHavingActiveAlarms'" class='custom-badge-warn' matBadge="{{ customerLocationsWithEquipments?.totalEquipmentsWithConnectionAlarm }}"
                      matTooltip="{{ 'website.equipment.alarm.yellow_badge' | translate }}"   ></span>
              </div>


              <span class='custom-badge-danger' *ngIf="status.value === 'onlyEquipmentsWithForeseenControl'" matBadge="{{ customerLocationsWithEquipments?.totalWithForeseenControl }}"  matBadgePosition="above after" matBadgeOverlap="false">{{ status.displayValue }}</span>
              <span *ngIf="status.value !== 'onlyHavingActiveAlarms' && status.value !== 'onlyEquipmentsWithForeseenControl'" >{{ status.displayValue }}</span>
            </ng-template>
            <ng-container *ngTemplateOutlet="tabContent"></ng-container>
          </mat-tab>
          <mat-tab [matx-if-has-permission]='[Permission.MatelexAdminPermission]'
            matBadgePosition="above after"  matBadgeOverlap="false">
            <ng-template mat-tab-label>
              <span>{{ 'website.equipment.list.tabs.unassigned' | translate }}
                <span *ngIf='unassignedEquipments' class="custom-badge-warn" matBadge="{{ unassignedEquipments.itemsPage.length }}" matBadgePosition="above after" matBadgeOverlap="false"></span>
              </span>
            </ng-template>
            <ng-container *ngTemplateOutlet="tabUnassignedContent"></ng-container>
          </mat-tab>
        </mat-tab-group>
      </div>

      <div  class="col-md-5 ">
        <mat-card >
          <mat-card-content class='p-3'>
            <div class='inner-map-container'>
              <!-- <img src="https://i.stack.imgur.com/B6fEt.png" width="100%" height="auto" alt="" /> -->
              <app-ol-map   [zoom]='6' [containerWidth]="'100%'"
                          [containerHeight]="'400px'" [containerUniqueId]="'equipmentsCustomerLocationsListMap'"
                          [markersCoords]='customerLocationsMapCoords' (clickOnMap)='onClickOnMap($event)'></app-ol-map>
            </div>

            <app-customer-location-equipments-popup-map [heightContent]='270' [selectedMapMarkerCustomerLocation]='selectedMapMarkerCustomerLocation' [styles]="{
      position: 'absolute !important',
      top: '15px',
      left: '54px',
      width: '280px',
      'min-width':'280px',
      'max-width':'280px',
      height: '340px',
      'padding-right':' 0',
      'z-index':'1000'}"></app-customer-location-equipments-popup-map>

          </mat-card-content>

        </mat-card>
      </div>

    </div>
  </div>


<ng-template #tabContent>

  <mat-accordion *isLoading="isLoadingSearchEquipments">
    <ng-container *ngIf="customerLocationsWithEquipments?.customerLocationsPage?.length === 0">
      <div class="no-results-container">
        <img src="assets/images/no-matching-results.svg" />
        <div class="no-results-text">
          <p>{{ 'website.equipment.list.no_data' | translate }}</p>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!unassignedEquipments && customerLocationsWithEquipments">
      <mat-expansion-panel class='pv-list-central-expansion-panel-body' style='{{!equipmentGroup.is_active ? "opacity: 0.5; cursor: default;" : ""}}' *ngFor="let equipmentGroup of customerLocationsWithEquipments.customerLocationsPage"
        (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>{{ equipmentGroup.name }}

            <div style='margin-top:-6px'>
            <span class='custom-badge-danger'  [matBadgeDisabled]='!equipmentGroup.is_active'  [matBadge]="equipmentGroup.totalEquipmentsAlarmWithoutConnectionAlarm"
                  matTooltip="{{ 'website.equipment.alarm.red_badge' | translate }}"
                  [matBadgeHidden]="equipmentGroup?.totalEquipmentsAlarmWithoutConnectionAlarm < 1"></span>

              <span class='custom-badge-warn'  [matBadgeDisabled]='!equipmentGroup.is_active'  [matBadge]="equipmentGroup?.totalEquipmentsWithConnectionAlarm"
                    [matBadgeHidden]="equipmentGroup?.totalEquipmentsWithConnectionAlarm < 1"  matTooltip="{{ 'website.equipment.alarm.yellow_badge' | translate }}"></span>
            </div>

          </mat-panel-title>
        </mat-expansion-panel-header>

        <table mat-table [dataSource]="equipmentGroup.equipments" >
          <ng-container matColumnDef="equipmentNumber">
            <td mat-cell *matCellDef="let equipment">{{ displayEquipmentType(equipment) }} {{
              equipment.id }}
              <a fxFlexFill [queryParams]=' { id: equipment.id } ' [routerLink]='hasAdminPermission || (equipmentGroup.is_active && !isPolarBoxNotConnected(equipment) && !equipment.inactive) ?[ "details"]:null' class="mat-row-link"></a>
            </td>

          </ng-container>

          <ng-container matColumnDef="name">
            <td mat-cell *matCellDef="let equipment">{{ equipment.name }}</td>
          </ng-container>

          <ng-container matColumnDef="alarmInfo">
            <td mat-cell *matCellDef="let equipment" >

                <ng-container  *ngFor="let status of equipment.unit_flags_and_alarms?.simultaneous_unit_status">
              <app-badge [color]='equipmentStatusInfo[status]?.severityColor'>
                {{ 'website.alarms.' + equipmentStatusInfo[status]?.name | translate }}
              </app-badge>


                </ng-container>

              <app-badge color='secondary' *ngIf="isPolarBoxNotConnected(equipment)">
                {{ 'website.equipment.list.table.firstConnection' | translate }}
              </app-badge>

            </td>
          </ng-container>

          <ng-container matColumnDef="maintenanceDate">
            <td mat-cell *matCellDef="let equipment">
              <div *ngIf="isPolarBoxNotConnected(equipment)">
                {{ 'website.equipment.list.table.license' | translate }} :  {{equipment.licenceString}}
              </div>
              {{ equipment?.getYearlyControlToDoOn() | date }}
            </td>
          </ng-container>

          <tr style='{{!equipmentGroup.is_active || isPolarBoxNotConnected(equipment) || equipment.inactive ? "cursor: default; opacity: 0.5" : ""}}' mat-row *matRowDef="let equipment; columns: displayedColumns" class="equipment-row"
            ></tr>
        </table>
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
</ng-template>

<ng-template #tabUnassignedContent>
  <mat-accordion>
    <ng-container *ngIf="unassignedEquipments">
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title matBadgePosition="above after">{{ 'website.equipment.list.tabs.unassigned' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <table mat-table [dataSource]="unassignedEquipments.itemsPage">
          <ng-container matColumnDef="equipmentNumber">
            <td mat-cell *matCellDef="let equipment">{{ 'website.equipment.list.table.equipment_lbl' | translate }} {{
              equipment.id }}
              <a fxFlexFill [queryParams]=' { id: equipment.id } ' [routerLink]='[ "details"]' class="mat-row-link"></a>
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <td mat-cell *matCellDef="let equipment">{{ equipment.name }}</td>
          </ng-container>

          <tr mat-row *matRowDef="let equipment; columns: displayedUnassignedEquimentsColumns" class="equipment-row"
           ></tr>
        </table>
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
</ng-template>

  <div class='row' *ngIf='!isLoadingSearchEquipments &&( customerLocationsWithEquipments?.total>0 || unassignedEquipments?.total>0) '>
    <div class='col-md-7'>
      <ng-container *ngIf="this.statuses[this.statusIndex].value !== 'notAttached'">
        <mat-paginator [length]="customerLocationsWithEquipments?.total" [pageSize]="criteria.pagination.perPage"
                       [pageSizeOptions]="[2, 5, 10, 25, 100]" [pageIndex]="criteria.pagination.page"
                       (page)="onPaginatorChange($event)">
        </mat-paginator>
      </ng-container>
      <ng-container *ngIf="this.statuses[this.statusIndex].value === 'notAttached' ">
        <mat-paginator [length]="unassignedEquipments?.total" [pageSize]="criteria.pagination.perPage"
                       [pageSizeOptions]="[2, 5, 10, 25, 100]" [pageIndex]="criteria.pagination.page"
                       (page)="onPaginatorChange($event)">
        </mat-paginator>
      </ng-container>
    </div>

  </div>


