<div class='modal-header'>
  <h1 mat-dialog-title>
    {{action | translate}}
  </h1>
  <mat-icon tabindex="0" class='close' (click)="handleReject()">close</mat-icon>


</div>


<div *isLoading='isLoading' mat-dialog-content class='p-md-4'>
  <h3 class='alarms-number' *ngIf='data.act === "Acknowledge"'>
    {{alarms.length}} {{'website.equipment.detail.modalActions.alarmsNumber' | translate}}
  </h3>
  <ng-container *ngIf='alarms && data.act === "Acknowledge"'>
    <ng-container *ngIf='showAlarms'>
      <table mat-table [dataSource]='alarms' matSort #alarmsmattable>
        <ng-container matColumnDef='alarmType'>
          <th mat-header-cell *matHeaderCellDef>
            {{ 'website.equipment.detail.activity.alarm_type_label' | translate }}
          </th>
          <td mat-cell *matCellDef='let alarm' class='combined'>
          <span class='alarm-type-cell'>
            <img *ngIf='alarm?.alarm_type?.icon' [src]='alarm?.alarm_type?.icon' class='alarm-icon' />
            <span
              class='alert-message'
              [ngStyle]="{ 'color': alarm?.alarm_type?.color, 'background-color': alarm?.alarm_type?.backgroundColor }"
            >{{ alarm?.alarm_type?.mlVal('name', crtLang) }}</span
            >
          </span>
            <!-- <span class="custom-icon custom-icon-reload"></span> -->
            <!-- <mat-icon>circle</mat-icon> -->
          </td>
        </ng-container>

        <ng-container matColumnDef='value'>
          <th mat-header-cell *matHeaderCellDef class='desc-column'>
            {{ 'website.equipment.detail.activity.description_label' | translate }}
          </th>
          <td mat-cell *matCellDef='let element'>
            <span *ngIf='element?.code_name'>{{'website.alarms.' + element?.code_name | translate}}</span>
            <span *ngIf='!element?.code_name'>{{ element?.mlVal('value', crtLang) }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef='date'>
          <th mat-header-cell *matHeaderCellDef>
            {{ 'website.equipment.detail.activity.start_timestamp_label' | translate }}
          </th>
          <td mat-cell *matCellDef='let alarm'>
            {{ alarm.date_time | date: 'd/M/yyyy HH:mm:ss' }}
          </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef='displayedColumnsAlarms'></tr>
        <tr style='cursor: pointer' mat-row *matRowDef='let row; columns: displayedColumnsAlarms'></tr>
      </table>
    </ng-container>
    <p class='alarms-details' (click)='handleShowAlarms()'>
      <ng-container *ngIf='showAlarms'>
        {{'website.equipment.detail.modalActions.hide' | translate}}
      </ng-container>
      <ng-container *ngIf='!showAlarms'>
        {{'website.equipment.detail.modalActions.show' | translate}}
      </ng-container>

      {{'website.equipment.detail.modalActions.details' | translate}}
    </p>
    <mat-checkbox [color]="'primary'" *ngIf='!hideCheckboxForAlarms' (change)='handleCheckConfirmLeaks()'>{{ 'website.equipment.config.alarms.confirm_repaired_leaks' | translate }}</mat-checkbox>
  </ng-container>
</div>


<div mat-dialog-actions [align]='"end"' class='border-top border-1 '>
  <matelex-secondary-button style='margin-right: 15px' [label]="'website.equipment.detail.modalActions.cancel' | translate"
                            (click)='handleReject()'></matelex-secondary-button>

  <ng-container *ngIf='alarms && data.act === "Acknowledge"'>
    <matelex-primary-button [label]="'website.equipment.detail.modalActions.acknowledgeBtn' | translate"
                            (click)='handleAccept()' ></matelex-primary-button>
  </ng-container>

  <ng-container *ngIf='!alarms && data.act !== "Acknowledge"'>
    <matelex-primary-button [label]="'website.equipment.detail.modalActions.confirm' | translate"
                            (click)='handleAccept()'></matelex-primary-button>
  </ng-container>

</div>
