import { Directive, ElementRef, HostListener, Input, OnChanges, Renderer2 } from '@angular/core';

@Directive({
  selector: '[anaisShowPassword]'
})
export class ShowPasswordDirective implements OnChanges {
  @Input('anaisShowPassword') public passwordInput: HTMLInputElement;
  private hidden: boolean = true;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  public ngOnChanges(): void {
    if (this.passwordInput) {
      setTimeout(() => {
        this.renderer.setAttribute(this.passwordInput, 'type', 'password');
      }, 500);
    }
  }

  @HostListener('click')
  @HostListener('keydown.Enter')
  @HostListener('keydown.Space')
  public toggle(): void {
    this.hidden = !this.hidden;
    this.renderer.setAttribute(this.passwordInput, 'type', this.hidden ? 'password' : 'text');
    this.renderer.setAttribute(this.el.nativeElement, 'color', this.hidden ? 'black' : 'blue');
  }
}
