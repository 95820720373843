import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[matx-input-auto-width]'
})
export class MatxInputAutoWidth {
  constructor(private el: ElementRef) {}
  @Input('matx-min-width') minWidth: number = 15;

  @HostListener('keyup') onKeyup() {
    this.autoSizeHostInput();
  }

  ngAfterViewInit() {
    this.autoSizeHostInput();
  }

  private autoSizeHostInput() {
    let newInputWidth = this.el.nativeElement.value ? (this.el.nativeElement.value.length + 1) * 8 : this.minWidth;
    this.el.nativeElement.style.width = newInputWidth + 'px';
  }
}
