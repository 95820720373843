import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from '@users/domain/models/user';

@Component({
  selector: 'app-open-equipment-user',
  templateUrl: './open-equipment-user.component.html',
  styleUrls: ['./open-equipment-user.component.scss']
})
export class OpenEquipmentUserComponent {
  constructor(public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: User) {}

  public close() {
    this.dialogRef.close();
  }
}
