<div class="documents-tab">
  <mat-card *ngIf="files$ | async as files">
    <mat-card-header>
      <mat-card-title>{{ 'website.equipment.detail.documents.tab_label' | translate }}</mat-card-title>
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item>
          <mat-icon>dialpad</mat-icon>
          <span>Some action</span>
        </button>
        <button mat-menu-item disabled>
          <mat-icon>voicemail</mat-icon>
          <span>Some other action</span>
        </button>
      </mat-menu>
    </mat-card-header>
    <mat-divider></mat-divider>
    <mat-card-content>
      <div class="upload-container">
        <img src="../assets/images/import-icon.svg" alt="" />
        <div class="text">
          <h2>{{ 'website.equipment.detail.documents.import' | translate }}</h2>
          <p>
            {{ 'website.equipment.detail.documents.drop_here_or' | translate }} <a href="#">{{ 'website.equipment.detail.documents.browse' | translate }}</a>
          </p>
        </div>
      </div>

      <table mat-table [dataSource]="files">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>{{ 'website.equipment.detail.documents.name' | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <mat-icon>folder_shared</mat-icon>
            {{ element.fileName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="owner">
          <th mat-header-cell *matHeaderCellDef>{{ 'website.equipment.detail.documents.owner' | translate }}</th>
          <td mat-cell *matCellDef="let element">{{ element.owner.firstName }} {{ element.owner.lastName }}</td>
        </ng-container>

        <ng-container matColumnDef="editDate">
          <th mat-header-cell *matHeaderCellDef>{{ 'website.equipment.detail.documents.previous_modifications' | translate }}</th>
          <td mat-cell *matCellDef="let element">
            {{ element.dateUpdated | date: 'd/M/yy - hh:mm' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>{{ 'website.equipment.detail.documents.action' | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <a href="#">{{ 'website.equipment.detail.documents.edit' | translate }}</a> |
            <a href="#">{{ 'website.equipment.detail.documents.delete' | translate }}</a>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </mat-card-content>
    <!-- TODO [length] has to be replaced with total when the API will provide it -->
    <mat-paginator
      *ngIf='files?.length>0'
      [length]="files?.length"
      [pageSize]="criteria.pagination.perPage"
      [pageSizeOptions]="[5, 10, 25]"
      [pageIndex]="criteria.pagination.page"
      (page)="onPaginatorChange($event)"
    >
    </mat-paginator>
  </mat-card>
</div>
