<mat-card [style]='styles' *ngIf="selectedMapMarkerCustomerLocation">
  <mat-card-header class='d-flex justify-content-betwee'>
    <mat-icon class='popup-close-icon float-end position-absolute me-2' (click)='closePopup()'>close</mat-icon>
    <mat-card-title>{{ selectedMapMarkerCustomerLocation.name }}</mat-card-title>
    <mat-card-subtitle class='break-line'
    >{{ selectedMapMarkerCustomerLocation?.streetAndNumber }}, {{ selectedMapMarkerCustomerLocation?.city }}
      {{ selectedMapMarkerCustomerLocation?.postalCode }}</mat-card-subtitle
    >
  </mat-card-header>

  <mat-card-content>
    <div  style="height:{{heightContent}}px;overflow:auto;" >
      <mat-list *ngIf="selectedMapMarkerCustomerLocation?.equipments?.length">
        <mat-list-item  *ngFor="let equipment of selectedMapMarkerCustomerLocation?.equipments; let i = index" class='h-100 separator-custom py-1' >

          <div  matListItemIcon>
            <img   [src]="equipment?.status?.icon"  class="equipment-status-icon-wrapper" [ngStyle]="{ 'border-color': equipment?.status?.color }" />

          </div>
          <div class='d-flex break-line'>
            <div class='col-12'>
              <span class="fw-medium">{{ 'website.dashboard.map.customer_location_popup.equipment' | translate }} {{ equipment?.id }}</span><br>
              <span class='text-secondary'>{{ equipment?.name }}</span><br>
              <div class="col-12 text-end "> <!-- Utilisation de la classe text-end pour aligner le contenu à droite -->
                <a class='link'
                   *ngIf='hasAdminPermission || (selectedMapMarkerCustomerLocation.is_active && !isPolarBoxNotConnected(equipment) && !equipment.inactive)'
                  [attr.aria-label]="'dashboard-map-equipment-details-' + equipment?.id"
                  [routerLink]="['/' + crtLang, 'equipment', 'details']" [queryParams]="{ id: equipment?.id }"
                >{{ 'website.dashboard.map.customer_location_popup.towards_equipment' | translate }} {{ equipment?.id }}</a>
              </div>

            </div>
            <div>

            </div>
          </div>

        </mat-list-item>

        <mat-list-item class='h-100 py-1 mt-1 '>
          <img [src]="selectedMapMarkerCustomerLocation?.installer?.logo" *ngIf='selectedMapMarkerCustomerLocation?.installer?.logo' alt="" matListItemIcon/>
          <div class='d-flex my-auto py-auto'>
            <div class=' my-auto py-auto'>
              <span matListItemTitle>{{ 'website.dashboard.map.customer_location_popup.installer' | translate }}</span><br>
              <span >{{ selectedMapMarkerCustomerLocation?.installer?.name }}</span><br>
              <span class="text-secondary">{{ selectedMapMarkerCustomerLocation?.installer?.phone }}</span><br>
            </div>
          </div>
        </mat-list-item>
      </mat-list>
    </div>

  </mat-card-content>
</mat-card>
