import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { LanguageService } from '../../../shared/services/language.service';

import { Config } from '../../../config';
import { UserService } from '@users/domain/services/user.service';
import {Title} from "@angular/platform-browser";
import {TranslateService} from "@ngx-translate/core";

@Component({
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  // eslint-disable-next-line object-shorthand
})
export class ForgotPasswordComponent implements OnInit, AfterViewInit {
  @ViewChild('emailInput') public emailInput: ElementRef;
  public forgotPasswordForm: FormGroup;
  public email: string;
  public userEmail: string;
  public crtLng: string = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private userService: UserService,
    private titleService: Title
  ) {}

  public ngOnInit(): void {
    this.titleService.setTitle('Matelex - ' + this.translateService.instant('AUTH.LOGIN.FORGOT_PASSWORD'))

    this.crtLng = this.languageService.currentLanguage || Config.defaultLanguage;

    this.userEmail = this.route.snapshot.params.email || '';
    this.forgotPasswordForm = this.formBuilder.group({
      email: [this.userEmail, [Validators.required, Validators.email]]
    });
  }

  public ngAfterViewInit(): void {
    this.emailInput.nativeElement.focus();
  }

  public navigateToLogin() {
    this.router.navigateByUrl('/' + this.languageService.currentLanguage + '/users/login')
  }
  public async askRecover(): Promise<any> {
    const baseUrl = window.location.host;
    const protocol = window.location.protocol;
    const email = this.forgotPasswordForm.controls.email.value;

    const returnLink = `${protocol}//${baseUrl}/${this.crtLng}/users/login/reset-password/${email}`;

    console.log(returnLink);
    const askRecover = await this.userService.askRecover(email, returnLink, this.crtLng);
    // if (askRecover) {
    //   this.router.navigateByUrl(`${this.crtLng}/user/auth/reset-password/${email}`,
    //     /* Removed unsupported properties by Angular migration: relativeTo. */ {});
    // }
  }
}
