<div class="input-group">
  <span class="input-group-text bg-transparent border-end-0" >
    <mat-icon>search</mat-icon>
  </span>
  <input type="text" class="form-control  border-start-0"
         [(ngModel)]="value"
         [placeholder]="placeholder"
         [value]='value'
         [disabled]='isDisable'
         (keydown.enter)="search()">
  <button mat-button   (click)="search()" [disabled]='isDisable' class="btn-search">{{'website.customer_location.table.filters.search' | translate}}</button>
</div>
