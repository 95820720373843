import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { TooltipPosition } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { OpenUserInfoComponent } from '@equipment/components';
import { ConfigChangeRequestService } from '@equipment/services/config-change-request.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfigChangeRequestAction } from '@shared/models/config-change-request-action.enum';
import { InstallerChain } from '@shared/models/installer-chain';
import { PagedResult } from '@shared/services/paged-result';
import { Role } from '@users/domain/models/role';
import { User } from '@users/domain/models/user';
import { Permission } from '@users/domain/services/user.enums';
import { CustomerChainsResult } from '../../../equipment/services/customer-chains-result';
import { CustomerLocationsResult } from '../../../equipment/services/customer-locations-result';
import { CustomerLocation } from '../../../shared/models/customer-location';
import { CustomerChain } from '../../../shared/models/customer-location-chain';
import { Installer } from '../../../shared/models/installer';
import { CompanyType, GeneralCompany } from '../../../shared/services/general-company.interface';
import { LanguageService } from '../../../shared/services/language.service';
import { UserActivateDialogComponent } from './user-activate-dialog/user-activate-dialog.component';
import { UserDeactivateDialogComponent } from './user-deactivate-dialog/user-deactivate-dialog.component';
import { UserEditDialogComponent } from './user-edit-dialog/user-edit-dialog.component';
import { UserListCriteria } from './user-list-criteria.interface';
import { UserListService } from './user-list.service';
import { TokenSessionService } from '@shared/services/token.session.service';
import { Subscription } from 'rxjs';

const INITIAL_CRITERIA: UserListCriteria = {
  filters: {
    active: true,
    name: null,
    role: [],
    company: []
  },
  sort: {
    field: 'name',
    direction: 'ASC'
  },
  pagination: {
    page: 1,
    perPage: 10
  }
};

export interface DialogData {
  count: number;
}

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit, OnDestroy {
  public subscriptions: Subscription = new Subscription();
  public Permission: typeof Permission = Permission;
  public crtLang: string;
  public collection: PagedResult<User> = new PagedResult<User>([], 0);
  public criteria: UserListCriteria;
  public panelOpenState = false;
  public displayedColumns: string[] = ['select', 'name', 'firm', 'role', 'active', 'createdOn', 'action'];
  public searchInput: string;
  public trInstant: (key: string | Array<string>, interpolateParams?: Object) => string | any;

  public matTabIndex = 0;

  public positionOptions: TooltipPosition[] = ['above'];
  public position = new FormControl(this.positionOptions[0]);
  private tabIndexToFilter: { [tabIndex: number]: { filter: string; value: any } } = {
    0: {
      filter: 'active',
      value: true
    },
    1: {
      filter: 'active',
      value: false
    },
    2: {
      filter: 'active',
      value: true
    }
  };
  public initialSelection: User[] = [];
  public allowMultiSelect = true;
  public selection: SelectionModel<User>;

  public hasFilters = false;
  public availableCustomerLocations: PagedResult<CustomerLocation>;
  public availableCustomerChains: PagedResult<CustomerChain>;
  public availableInstallers: PagedResult<Installer>;
  public availableInstallerChains: PagedResult<InstallerChain>;
  public availableRoles: PagedResult<Role>;
  public rolesById: { [_id: string]: Role };
  public customerLocationChainsFilterOptions: CustomerChainsResult = null;
  public customerLocationsFilterOptions: CustomerLocationsResult = null;
  public generalCompanyFilterOptions: GeneralCompany[] = null;
  public rolesFilterOptions: Role[] = null;
  public filtersDataReadyForView = false;
  public isAdmin: boolean;
  isLoading = true;
  public totalAllSelected = 0;
  public isAllSelectedState = false;
  public rolesFilterCtrl: FormControl = new FormControl([]);
  public companiesFilterCtrl: FormControl = new FormControl([]);

  constructor(
    public dialog: MatDialog,
    private userListService: UserListService,
    private tokenSessionService: TokenSessionService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private configChangeRequestService: ConfigChangeRequestService
  ) {}



  public async ngOnInit(): Promise<void> {
    this.isAdmin = this.tokenSessionService.userService.loggedInUserHasPermissions([Permission.MatelexAdminPermission]);
    this.crtLang = this.languageService.currentLanguage;
    this.trInstant = this.translateService.instant;
    this.trInstant = this.trInstant.bind(this.translateService);

    this.readFiltersFromRoute();
    this.matTabIndex = this.findMatTabIndex();
    this.selection = new SelectionModel<User>(this.allowMultiSelect, this.initialSelection);

    this.availableCustomerLocations = await this.userListService.getUsersFilterCustomerLocations();
    this.availableCustomerChains = await this.userListService.getUsersFilterCustomerChains();
    this.availableInstallers = await this.userListService.getUsersFilterInstallers();
    this.availableInstallerChains = await this.userListService.getUsersFilterInstallerChains();
    this.updateGeneralCompanyFilterValues();
    this.availableRoles = await this.userListService.getUsersFilterRoles();
    this.rolesFilterOptions = this.availableRoles.itemsPage;
    this.onSelectCompanyFilter();
    this.onSelectRoleFilter();

    this.filtersDataReadyForView = true;
    await this.fetchData();
  }

  public readFiltersFromRoute(): void {
    if (!this.activatedRoute.snapshot.params.criteria) {
      this.criteria = JSON.parse(JSON.stringify(INITIAL_CRITERIA));
      console.log('UserListComponent readFiltersFromRoute 1 this.criteria ', this.criteria);
      return;
    }

    this.criteria = JSON.parse(this.activatedRoute.snapshot.params.criteria);
    console.log('UserListComponent readFiltersFromRoute 2 this.criteria', this.criteria);
  }

  public applyFilterToRoute(): void {
    const filtersForRoute = [{ criteria: JSON.stringify(this.criteria) }];
    console.log('UserListComponent applyFilterToRoute filtersForRoute', filtersForRoute);
    this.router.navigate(filtersForRoute, {
      relativeTo: this.activatedRoute,
      replaceUrl: true
    });
  }

  public findMatTabIndex(): number {
    return this.criteria.filters.active === false ? 1 : 0;
  }

  public masterToggle() {
    this.isAllSelectedState = !this.isAllSelectedState;
    this.selection.clear();
    if (this.isAllSelectedState) {
      this.collection?.itemsPage.forEach(row => {
        if (row.canBeEdited) {
          this.selection.toggle(row);
        }
      });
      this.totalAllSelected = this.collection?.total;
    } else {
      this.totalAllSelected = 0;
      this.selection.clear();
    }
  }

  public async handleRowCheckbox(row: any): Promise<void> {
    if (this.isAllSelectedState) {
      this.isAllSelectedState = false;
      this.selection.clear();
      this.collection?.itemsPage.forEach(row => this.selection.toggle(row));
    }
    this.selection.toggle(row);
    if (this.selection.selected.length === 0) {
      this.isAllSelectedState = false;
    }
    else if (this.selection.selected.length === this.collection.total) {
      this.isAllSelectedState = true;
      this.totalAllSelected = this.collection.total;
    }
  }

  public isChecked(row: User): boolean {
    return !!this.selection.selected.find(s => s._id === row._id);
  }

  public async onTabChange(tabIndex: number): Promise<void> {
    console.log('UserListComponent onTabChange tabIndex: ', tabIndex);
    this.matTabIndex = tabIndex;
    this.selection.clear();
    this.criteria = JSON.parse(JSON.stringify(INITIAL_CRITERIA));
    const filterInfo = this.tabIndexToFilter[tabIndex];
    (this.criteria.filters as any)[filterInfo.filter] = filterInfo.value;
    console.log('CRITERIA => ', this.criteria);
    this.isAllSelectedState = false;
    if (tabIndex !== 2) {
      await this.fetchData();
    }
    else {
      await this.fetchPendingApprovalUsers();
      console.log('COLLECTION ==> ', this.collection);
    }
    this.applyFilterToRoute();
  }

  public async deactivateBulk() {
    const dialogRef = this.dialog.open(UserDeactivateDialogComponent, {
      data: { count: this.isAllSelectedState ? this.totalAllSelected : this.selection.selected.length }
    });

    dialogRef.afterClosed().subscribe(async result => {
      let selectedUserIds: string[];
      if (result === 'yes') {
        if (this.isAllSelectedState) {
          const tmp = await this.userListService.getUserList({
            filters: this.criteria.filters,
            pagination: {
              page: 1,
              perPage: this.collection.total
            },
            sort: this.criteria.sort
          });
          selectedUserIds = tmp.itemsPage.map(user => {
            return user._id;
          });
        } else {
          selectedUserIds = this.selection.selected.map((user: User) => {
            return user._id;
          });
        }
        console.log('selectedUserIds', selectedUserIds);
        await this.userListService.deactivateBulk(selectedUserIds);
        await this.configChangeRequestService.requestAction('no equipment', ConfigChangeRequestAction.BlacklistToken);
        if (this.matTabIndex !== 2) {
          await this.fetchData();
        } else {
          await this.fetchPendingApprovalUsers();
        }
        this.selection.clear();
      }
    });
  }

  public async activateBulk() {
    const dialogRef = this.dialog.open(UserActivateDialogComponent, {
      data: { count: this.selection.selected.length }
    });

    dialogRef.afterClosed().subscribe(async result => {
      let selectedUserIds: string[];
      if (result === 'yes') {
        if (this.isAllSelectedState) {
          const tmp = await this.userListService.getUserList({
            filters: this.criteria.filters,
            pagination: {
              page: 1,
              perPage: this.collection.total
            },
            sort: this.criteria.sort
          });
          selectedUserIds = tmp.itemsPage.map(user => {
            return user._id;
          });
        } else {
          selectedUserIds = this.selection.selected.map((user: User) => {
            return user._id;
          });
        }
        console.log('selectedUserIds', selectedUserIds);

        await this.userListService.activateBulk(selectedUserIds);
        await this.configChangeRequestService.requestAction('no equipment', ConfigChangeRequestAction.WhitelistToken);
        if (this.matTabIndex !== 2) {
          await this.fetchData();
        } else {
          await this.fetchPendingApprovalUsers();
        }
        this.selection.clear();
      }
    });
  }

  public async updateGeneralCompanyFilterValues(): Promise<void> {
    const tmpGeneralCompanyFilterOptions: GeneralCompany[] = [];

    const companiesFormat = [
      { tab : this.availableCustomerLocations.itemsPage, type: CompanyType.CustomerLocation},
      {tab : this.availableCustomerChains.itemsPage , type: CompanyType.CustomerChain},
      {tab: this.availableInstallers.itemsPage, type:  CompanyType.Installer},
    {tab: this.availableInstallerChains.itemsPage, type:  CompanyType.InstallerChain},
    ];

    for (const item of companiesFormat) {

        const generalCompanyTab = item.tab.map((company: any) => {
            return {
              id:  company._id,
                type : item.type,
              name : company.name

          } as GeneralCompany; }
    );
        tmpGeneralCompanyFilterOptions.push(...generalCompanyTab);
    }

    this.generalCompanyFilterOptions = tmpGeneralCompanyFilterOptions;
  }



  public removeFilters(): void {
    this.criteria = JSON.parse(JSON.stringify(INITIAL_CRITERIA));
    this.searchInput = '';
    this.companiesFilterCtrl.patchValue([]);
    this.rolesFilterCtrl.patchValue([]);
    this.applyFilterToRoute();

    this.fetchData();

  }

  private async fetchUsers(): Promise<void> {
    if (this.matTabIndex !== 2) {
      await this.fetchData();
    } else {
      await this.fetchPendingApprovalUsers();
    }
  }

  public async onSelectCompanyFilter(): Promise<void> {
    this.companiesFilterCtrl.patchValue(this.criteria.filters.company);
    const companiesFilterChange = this.companiesFilterCtrl.valueChanges.subscribe(async (companies) => {
      this.criteria.filters.company = companies;
      await this.fetchUsers();
      this.applyFilterToRoute();
    });
    this.subscriptions.add(companiesFilterChange);
  }

  public compareObjects(o1: any, o2: any): boolean {
    return o1.id === o2.id;
  }

  public async onSelectRoleFilter(): Promise<void> {
    this.rolesFilterCtrl.patchValue(this.criteria.filters.role);
    const rolesFilterChange = this.rolesFilterCtrl.valueChanges.subscribe(async (roles) => {
      this.criteria.filters.role = roles;
      await  this.fetchUsers();
      this.applyFilterToRoute();
    });

    this.subscriptions.add(rolesFilterChange);

  }


  public openUserInfoModal(user: User) {
    if (!user.canBeRead) {
      return;
    }

    const dialogRef = this.dialog.open(OpenUserInfoComponent, {
      width: '700px',
      disableClose: true,
      data: user
    });
  }

  public openEditDialog(user: User): void {
    if (user && !user.canBeEdited) {
      return;
    }

    const dialogRef = this.dialog.open(UserEditDialogComponent, {
      width: '885px',
      data: { user, isMigrator: this.matTabIndex === 2 }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (this.matTabIndex !== 2) {
        this.fetchData();
      } else {
        this.fetchPendingApprovalUsers();
      }
    });
  }

  public async sortData(event: Sort): Promise<void> {
    console.log('event => ', event);
    console.log('criteria event => ', this.criteria.sort);
    if (event.direction === this.criteria.sort.direction.toLowerCase() && event.direction === 'asc') {
      console.log('event => here');
      this.criteria.sort = {
        field: event.active.split('_').join('.'),
        direction: 'DESC'
      };
    } else {
      console.log('event => la');
      this.criteria.sort = {
        field: event.active.split('_').join('.'),
        direction: 'ASC'
      };
    }
    if (this.matTabIndex !== 2) {
      await this.fetchData();
    } else {
      await this.fetchPendingApprovalUsers();
    }
  }

  public async search(search:string): Promise<void> {
    this.criteria.filters.name = this.searchInput;
    this.criteria.pagination.page = 1;

    if (this.matTabIndex !== 2) {
      await this.fetchData();
    } else {
      await this.fetchPendingApprovalUsers();
    }
  }

  public async onPaginatorChange(event: PageEvent): Promise<void> {
    this.criteria.pagination.perPage = event.pageSize;
    this.criteria.pagination.page = event.pageIndex + 1;
    if (this.matTabIndex !== 2) {
      await this.fetchData();
    } else {
      await this.fetchPendingApprovalUsers();
    }
  }

  private async fetchPendingApprovalUsers(): Promise<void> {
    this.isLoading = true;
    console.log('UserListComponent fetchPendingApprovalUsers this.criteria', this.criteria);
    this.collection = await this.userListService.getPendingApprovalUsers(this.criteria);
    this.updateHasFilters();
    this.isLoading = false;
  }
  private async fetchData(): Promise<void> {
    this.isLoading = true;
    console.log('UserListComponent fetchData this.criteria', this.criteria);
    this.collection = await this.userListService.getUserList(this.criteria);
    this.updateHasFilters();
    if (this.isAllSelectedState) {
      this.collection?.itemsPage.forEach(row => {
        if (row.canBeEdited) {
          this.selection.toggle(row);
        }
      });
    }
    this.isLoading = false;
  }

  private updateHasFilters(): void {
    this.hasFilters = !!Object.keys(this.criteria.filters).length;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
