import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { EquipmentDetailsService } from '@equipment/services/equipment-details.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '@shared/services/language.service';
import { Config } from 'src/app/config';
import { EquipmentSensibilityConversionService } from '@equipment/services/equipment-sensibility-conversion.service';

@Component({
  selector: 'app-alarm-dni-infos',
  templateUrl: './alarm-dni-infos.component.html',
  styleUrls: ['./alarm-dni-infos.component.scss']
})
export class AlarmDniInfosComponent implements OnInit {

  @Input() alarm_id: string ;
  @Input() alarmPreparedData: any;
  @Output() url = new EventEmitter<string>();

  public crtLang: string = Config.defaultLanguage;
  alarm: any = null;
  isLoading: boolean = true;
  calculatedSensibility: number;
  dniAlarmInfo = [
    [this.translateService.instant('website.equipment.detail.alarm_modal.dni.dni_id'), '1171'],
    [this.translateService.instant('website.equipment.detail.alarm_modal.dni.reference'), 'CGMA 0350940'],
    [this.translateService.instant('website.equipment.detail.alarm_modal.dni.name'), 'Centrale négative n°1'],
    [this.translateService.instant('website.equipment.detail.alarm_modal.dni.store'), 'Carrefour Market'],
    [this.translateService.instant('website.equipment.detail.alarm_modal.dni.address'), 'Zone d’Activité Commerciale de l’étoile 14125 Mondeville France']
  ];

  alarmInfo = [
    ['Type d’événement', 'Alarme statistique'],
    ['Horodatage', '16/11/2020 00:00:35'],
    ['Poids', '12 kg'],
    ['Temporisation', 'XXXX'],
    ['Sensibilité', 'XXXX']
  ];

  constructor(private languageService: LanguageService,
              private equipmentDetailsService: EquipmentDetailsService,
              private sensibilityConversionService: EquipmentSensibilityConversionService,
              private router: Router,
              private translateService: TranslateService) {}

  async ngOnInit() {
    this.calculatedSensibility = this.sensibilityConversionService
      .convertToIHM(this.alarmPreparedData.lpConsign, this.alarmPreparedData.sensibility);
    this.crtLang = this.languageService.currentLanguage;
    if(this.alarm_id) {
      (await this.equipmentDetailsService.getAlarm(this.alarm_id)).subscribe(
        (res: any) => {
          this.alarm = res;
          console.log(res);
          this.loadData();
        },
        (err: any) => console.log('AlarmDniInfoComponent : Error in fetching data', err)
      );
    }
  }

  loadData() {
    let address = this.alarm?.target_id?.customerLocation;

    this.alarmInfo[0][1] = this.alarm?.alarm_type?.id;
    this.alarmInfo[1][1] = this.alarm?.date;


    this.dniAlarmInfo[0][1] = this.alarm?.target_id?.name;
    this.dniAlarmInfo[1][1] = this.alarm?.target_id?.referenceCentrale;
    this.dniAlarmInfo[2][1] = this.alarm?.target_id?.name;
    this.dniAlarmInfo[3][1] = this.alarm?.target_id?.customerLocation?.name;
    this.dniAlarmInfo[4][1] = address?.streetAndNumber + ' ' + address?.postalCode + ' ' + address?.city + ' ' + address?.country;

    this.isLoading = false;
  }

  navigateTo() {
    this.url.emit(this.alarm.target_id.name);
  }
}
