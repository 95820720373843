export enum RoleBusinessId {
  MATELEX_ADMIN = 'MATELEX_ADMIN',
  CUSTOMER_CHAIN_NATIONAL_MANAGER = 'CUSTOMER_CHAIN_NATIONAL_MANAGER',
  CUSTOMER_CHAIN_REGIONAL_MANAGER = 'CUSTOMER_CHAIN_REGIONAL_MANAGER',
  CUSTOMER_LOCATION_MANAGER = 'CUSTOMER_LOCATION_MANAGER',
  CUSTOMER_LOCATION_ASISTANT_MANAGER = 'CUSTOMER_LOCATION_ASISTANT_MANAGER',
  CUSTOMER_LOCATION_SIMPLE_USER = 'CUSTOMER_LOCATION_SIMPLE_USER',
  CUSTOMER_LOCATION_RESTRICTED_USER = 'CUSTOMER_LOCATION_RESTRICTED_USER',
  INSTALLER = 'INSTALLER'
}
