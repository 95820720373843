import { Directive, ElementRef, Host, Input, OnChanges, OnInit, Optional, Self, SimpleChanges } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';

@Directive({
  selector: 'mat-paginator',
})
export class MatPaginatorHideDirective implements OnChanges, OnInit{
  @Input() length: number;
  constructor(
    @Host() @Self() @Optional() private readonly matPag: MatPaginator,
    private elementRef: ElementRef,
  ) {}




  public ngOnChanges(changes: SimpleChanges): void {
    console.log(changes.length);
    if (changes.length){
      this.hidePagination();
    }
  }

  public ngOnInit(): void {
    this.hidePagination();
  }

  private hidePagination(): void {
    if (this.length === 0) {
      this.elementRef.nativeElement.style.display = 'none';
    } else {
      this.elementRef.nativeElement.style.display = 'block';
    }
  }
}
