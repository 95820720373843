<div class="mat-card-item " #editInput>
  <span>{{fieldData.fieldLabel}}</span>
  <ng-container *ngIf='!editing'>
    <span class='me-2'>{{ cmpValue }}</span>
    <mat-icon class='icon-style icon-edit-pos' (click)='handleOnClickEdit()'>edit</mat-icon>
  </ng-container>
  <ng-container *ngIf='editing' class='mt-4'>
    <mat-form-field [appearance]='"outline"'class='pe-1 my-auto ' >
      <input #inputElement matInput [value]='cmpValue' (keydown.enter)='handleOnClickCheck(inputElement)' [placeholder]="cmpValue"/>
      <mat-icon matSuffix  class='icon-style' (click)='handleOnClear(inputElement)'>backspace</mat-icon>
    </mat-form-field>
    <ng-container *ngIf='isLoading'>
      <mat-spinner *ngIf="isLoading" diameter="20"></mat-spinner>
    </ng-container>
    <ng-container *ngIf='!isLoading'>

        <mat-icon class='icon-next-to-field icon-style ' *ngIf='!checkIsEmpty(inputElement.value)' style='cursor: pointer' (click)='handleOnClickCheck(inputElement)'>check</mat-icon>

        <mat-icon class='icon-next-to-field icon-style ' *ngIf='checkIsEmpty(inputElement.value)' style='cursor: pointer' (click)='handleFocusOut()'>close</mat-icon>
    </ng-container>
  </ng-container>
</div>
