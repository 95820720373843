<div class="dni-details-dialog">
  <div class="modal-header">
    <h1 mat-dialog-title>{{ data?.event?.date_time | date: 'd/M/yyyy HH:mm:ss' }}</h1>
    <mat-icon (click)="onCloseClick()">close</mat-icon>
  </div>

  <div mat-dialog-content class='p-0'>
    <mat-list class='w-100 container-fluid'>
      <mat-list-item class='separator-custom'>
        <div class='row p-2'>
          <div class='col-6'>{{ 'website.equipment.detail.activity.events.modal.event_type' | translate }}</div>
          <div class='col-6 text-end'><span
            class="alert-message"
            [ngStyle]="{ 'color': data?.event?.event_type?.color, 'background-color': data?.event?.event_type?.backgroundColor }"
          >{{ data?.event?.event_type?.mlVal('name', data.crtLang) }}</span
          >
          </div>
        </div>
      </mat-list-item>
      <mat-list-item class='separator-custom'>
        <div class='row p-2'>
          <div class='col-6'>{{ 'website.equipment.detail.activity.events.modal.description' | translate }}</div>
          <div class='col-6 text-end text-break '>
            <span class=' text-break'>{{'website.alarms.' + data?.event?.event_id  | translate : {event: data.event} }}</span>
          </div>
        </div>
      </mat-list-item>
      <mat-list-item class='separator-custom'>
        <div class='row p-2'>
          <div class='col-6'>{{ 'website.equipment.detail.activity.events.modal.generated_by' | translate }}</div>
          <div class='col-6 text-end'>
            <span>{{ data?.event?.generated_by }}</span>
          </div>
        </div>
      </mat-list-item>

      <mat-list-item class='separator-custom'>
        <div class='row p-2'>
          <div class='col-6'>{{ 'website.equipment.detail.activity.events.modal.code' | translate }}</div>
          <div class='col-6 text-end'>
            <span>{{ data?.event?.code }}</span>
          </div>
        </div>
      </mat-list-item>

    </mat-list>
  </div>

</div>
