import { Component, Input, OnInit } from '@angular/core';
import { EquipmentDetailsService } from '@equipment/services/equipment-details.service';

@Component({
  selector: 'app-alarm-accordion',
  templateUrl: './alarm-accordion.component.html',
  styleUrls: ['./alarm-accordion.component.scss']
})
export class AlarmAccordionComponent implements OnInit {

  @Input() alarm_id: string ;
  equipment: string;
  isLoading: boolean = true;
  maxDate: any;
  minDate: any;

  constructor(private equipmentDetailsService: EquipmentDetailsService) { }

  async ngOnInit() {
    if(this.alarm_id) {
      (await this.equipmentDetailsService.getAlarm(this.alarm_id)).subscribe(
        (res: any) => {
          this.equipment = res?.target_id?.id;

          this.maxDate = new Date(res?.date_time);
          this.minDate = new Date(this.maxDate);
          this.minDate.setMonth(this.maxDate.getMonth() - 1);
          this.isLoading = false;
        },
        (err: any) => console.log('AlarmAccordionComponent : Error in fetching data', err)
      );
    }
  }

}
