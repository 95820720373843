import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { PagedResult } from '@shared/services/paged-result';
import { User } from '@users/domain/models/user';

import { UserListService } from '@users/components/user-list/user-list.service';
import { EquipmentUserService } from './equipment-user.service';
import { OpenEquipmentUserComponent } from './open-equipment-user/open-equipment-user.component';
import { Sort } from '@angular/material/sort';
import { Equipment } from '@shared/models/equipment';

const INITIAL_CRITERIA = {
  pagination: {
    page: 1,
    perPage: 10
  }
};

@Component({
  selector: 'app-equipment-user-list',
  templateUrl: './equipment-user-list.component.html',
  styleUrls: ['./equipment-user-list.component.scss']
})
export class EquipmentUserListComponent implements OnInit {
  @Input() equipment: Equipment;
  public criteria: any;
  public displayedColumns: string[] = ['name', 'firm', 'role', 'alarms'];
  public isLoading = false;
  public usersResult: PagedResult<User>;

  constructor(private userListService: UserListService, private equipmentUserService: EquipmentUserService, private dialog: MatDialog, private route: ActivatedRoute) {}

  public async ngOnInit(): Promise<void> {
    this.criteria = JSON.parse(JSON.stringify(INITIAL_CRITERIA));
    this.isLoading = true;
    await this.fetchData();
    this.isLoading = false;
  }

  public openEquipmentUserModal(user: User) {
    const dialogRef = this.dialog.open(OpenEquipmentUserComponent, {
      width: '700px',
      disableClose: true,
      data: user
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('EquipmentDetailsComponent onClickUser afterClosed result', result);
    });
  }

  public async sortData(event: Sort): Promise<void> {
    this.criteria.sort = {
      field: event.active.split('_').join('.'),
      direction: event.direction.toUpperCase() || 'ASC'
    };
    await this.fetchData();
  }
  public async onPaginatorChange(event: PageEvent): Promise<void> {
    this.criteria.pagination.perPage = event.pageSize;
    this.criteria.pagination.page = event.pageIndex + 1;
    await this.fetchData();
  }

  public async fetchData(): Promise<void> {
    this.usersResult = await this.equipmentUserService.getEquipmentUserList(this.equipment.id, this.criteria);
    this.usersResult.itemsPage = this.usersResult.itemsPage.map((user) => {
      user.isAlarmsCustomerLocationDisabled = !user.isDisabledCustomerLocationAlarms(this.equipment.customerLocation._id) && !this.equipment.inactive   ? 'all' :  'none' ;
      return user;
    });
  }
}
