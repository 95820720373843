import gql from 'graphql-tag';

export const retrieveUserWithUserMigrationId = gql`
  query retrieveUserWithUserMigrationId($userMigrationId: String!) {
    retrieveUserWithUserMigrationId(userMigrationId: $userMigrationId) {
      _id
          id
          firstName
          lastName
          profileImage
          email
          phone
          createdOn
          lastConnection
          active
          canBeRead
          canBeEdited

          roles {
            _id
            name
            class
            allowedCompanyTypes
            userRole {
              customerChain {
                _id
                name
              }
              customerLocation {
                _id
                name
              }
              installerChain {
                _id
                name
              }
              installer {
                _id
                name
              }
            }
          }
          userRoles {
            _id
            customerLocation {
              name
            }
            customerChain {
              name
            }
            installer {
              name
            }
            installerChain {
              name
            }
            role {
              name
            }
          }
    }
  }
`;

export const retrieveUserMigration = gql`
  query retrieveUserMigration($userMigrationId: String!) {
    retrieveUserMigration(userMigrationId: $userMigrationId) {
      _id
      email
      status
      date
      accountExists
      token
    }
  }
`;

export const retrieveRolesList = gql`
  query retrieveRolesList {
    retrieveRolesList {
      _id
      name
      uniqueBusinessId
    }
  }
`;


export const askNewLink = gql`
  query askNewLink($email: String!, $userMigrationId: String!) {
    askNewLink(email: $email, userMigrationId: $userMigrationId)
  }
`;

export const retrieveUserMigrationWithEmail = gql`
  query retrieveUserMigrationWithEmail($email: String!) {
    retrieveUserMigrationWithEmail(email: $email) {
      _id
      email
      status
      date
      accountExists
      token
      firstName
      lastName
      role
      phone
      company
    }
  }
`;
