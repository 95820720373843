import { Injectable } from '@angular/core';
import { GraphqlService } from '@graphql/graphql.service';
import { Fluid } from '@shared/models/fluid';
import { PagedResult } from '@shared/services/paged-result';
import gql from 'graphql-tag';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FluidService {
  public constructor(private graphqlService: GraphqlService) {}

  public getFluids() {
    return this.graphqlService
      .query(this.getFluidsQuery(), {}, false)
      .pipe(
        map(resultData => {
          const result: PagedResult<Fluid> = { itemsPage: [], total: 0 };
          const fluidsData = resultData.data.fluids.items;
          if (Array.isArray(fluidsData)) {
            result.itemsPage = fluidsData.map(fluidData => {
              return new Fluid(fluidData);
            });
          }
          result.total = resultData.data.fluids.meta.total;
          return result;
        })
      )
      .pipe(take(1))
      .toPromise() as Promise<PagedResult<Fluid>>;
  }

  private getFluidsQuery() {
    return gql`
      query getFluids {
        fluids(criteria: {
          pagination: { page: 1, perPage: 1000 }
          sort: { field: "name", direction: ASC }
        }) {
          meta {
            total
          }
          items {
            id
            name
          }
        }
      }
    `;
  }
}
