<div class='d-flex'>
  <div class='d-inline-flex'>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'website.equipment.config.performance.consumers' | translate }}</mat-label>
      <mat-select (selectionChange)='handleSelectChange($event)'>
        <mat-option *ngFor="let series of allData" [value]="series.id">
          {{series.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div>
  <h4 mat-dialog-title>{{currentData.name | titlecase}} - {{minDateSelected | date}}</h4>
  <h2 mat-dialog-title style='text-align: center'>{{ 'website.equipment.config.performance.work_monitoring' | translate }}</h2>
</div>
<div mat-dialog-content>
  <div class='d-flex'>
    <div class='d-inline-flex'>
      <p class='graph-title'>{{ 'website.equipment.config.performance.engine_startup' | translate }}</p>

      <app-equipment-performances-charts
        [data]='currentData'
        [minDate]='minDateSelected'
        [maxDate]='maxDateSelected'
        [height]='200'
        [width]='900'
      ></app-equipment-performances-charts>
    </div>
  </div>
</div>
<div mat-dialog-content>
  <div class='d-flex'>
    <table mat-table [dataSource]='[currentData]'>

      <ng-container matColumnDef='tempsTotal'>
        <th class='mat-header-cell' mat-header-cell *matHeaderCellDef><p>{{ 'website.equipment.config.performance.work_time' | translate }}</p></th>
        <td mat-cell class='a-center' *matCellDef='let element'>{{element.performanceData.tempsTotal}}</td>
      </ng-container>

      <ng-container matColumnDef='freqDemar'>
        <th class='mat-header-cell' mat-header-cell *matHeaderCellDef><p>{{ 'website.equipment.config.performance.startup_count' | translate }}</p></th>
        <td mat-cell class='a-center' *matCellDef='let element'>{{element.performanceData.freqDemar}}</td>
      </ng-container>
      <ng-container matColumnDef='tpsMinBetweenDemar'>
        <th class='mat-header-cell' mat-header-cell *matHeaderCellDef><p>{{ 'website.equipment.config.performance.time_btw_stup' | translate }}</p></th>
        <td mat-cell class='a-center' *matCellDef='let element'>{{element.performanceData.tpsMinBetweenDemar}}</td>
      </ng-container>

      <ng-container matColumnDef='tpsMinWorking'>
        <th class='mat-header-cell' mat-header-cell *matHeaderCellDef><p>{{ 'website.equipment.config.performance.min_work_time' | translate }}</p></th>
        <td mat-cell class='a-center' *matCellDef='let element'>{{element.performanceData.tpsMinWorking}}</td>
      </ng-container>

      <ng-container matColumnDef='tpsMaxWorking'>
        <th class='mat-header-cell' mat-header-cell *matHeaderCellDef><p>{{ 'website.equipment.config.performance.max_work_time' | translate }}</p></th>
        <td mat-cell class='a-center' *matCellDef='let element'>{{element.performanceData.tpsMaxWorking}}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
      <tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
    </table>
  </div>
</div>
<div mat-dialog-content>
  <div class='d-flex'>
    <div class='d-inline-flex'>
      <mat-spinner class="loading-spinner" *ngIf="chartIsLoading" diameter="50"></mat-spinner>
      <highcharts-chart
        class="chart"
        [Highcharts]="Highcharts"
        [options]="chartOptions"
        [(update)]='updateFlag'
        *ngIf="!chartIsLoading"
      ></highcharts-chart>

      <mat-divider [vertical]='true'></mat-divider>

      <highcharts-chart
        class="chart"
        [Highcharts]="Highcharts"
        [options]="chartOptionsHours"
        [(update)]='updateFlag'
        *ngIf="!chartIsLoading"
      ></highcharts-chart>
    </div>
  </div>
</div>
<div mat-dialog-content>
  <div class='d-flex'>
    <mat-spinner class="loading-spinner" *ngIf="chartIsLoading" diameter="50"></mat-spinner>
    <highcharts-chart
      class="chart"
      [Highcharts]="Highcharts"
      [options]="chartOptionsEnergy"
      [(update)]='updateFlag'
      *ngIf="!chartIsLoading"
    ></highcharts-chart>
  </div>
</div>


