import { UserRoleUpdateInput } from '@users/components/user-list/user-role.update.input';

export class EditUserFromFrontendInput {
  public _id: string;
  public email: string;
  public phone: string;
  public firstName: string;
  public lastName: string;
  customerLocationAlarmsDisabled:string[];
  public active: boolean;
  public userRoles: UserRoleUpdateInput[];
  public isMigrator: boolean;
  public useFilteredAlarms: boolean;
}
