<div id="forgot-password" fxLayout="column">
  <div id="forgot-password-form-wrapper" fxLayout="column" fxLayoutAlign="center center">
    <div id="forgot-password-form" [@animate]="{ value: '*', params: { duration: '300ms', y: '100px' } }">
      <div class="logo">
        <img src="assets/images/polarvisor-logo.svg" />
      </div>

      <div class="title">{{ 'AUTH.FORGOT_PASSWORD.TITLE' | translate }}</div>

      <form name="forgotPasswordForm" [formGroup]="forgotPasswordForm" novalidate>
        <mat-form-field>
          <input #emailInput matInput [placeholder]="'AUTH.PLACEHOLDERS.EMAIL' | translate" required formControlName="email" />
          <mat-error *ngIf="forgotPasswordForm.get('email').hasError('required')">
            {{ 'AUTH.VALIDATIONS.EMAIL_REQUIRED' | translate }}
          </mat-error>
          <mat-error *ngIf="forgotPasswordForm.get('email').hasError('email')">
            {{ 'AUTH.VALIDATIONS.EMAIL_INVALID' | translate }}
          </mat-error>
        </mat-form-field>

        <button mat-raised-button class="submit-button" color="accent" [disabled]="forgotPasswordForm.invalid" (click)="askRecover()">
          {{ 'AUTH.FORGOT_PASSWORD.ACTION' | translate }}
        </button>
      </form>

      <div class="login" fxLayout="row" fxLayoutAlign="center center">
        <a class="link" (click)="navigateToLogin()">
          {{ 'AUTH.FORGOT_PASSWORD.REDIRECT_LOGIN' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
