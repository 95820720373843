export enum Permission {
  CreateUser = 'CreateUser',
  RetrieveUser = 'RetrieveUser',
  UpdateUser = 'UpdateUser',
  DeleteUser = 'DeleteUser',
  ActivateUser = 'ActivateUser',
  UpdateMyUser = 'UpdateMyUser',
  RetrieveMyUser = 'RetrieveMyUser',

  CreateRole = 'CreateRole',
  RetrieveRole = 'RetrieveRole',
  UpdateRole = 'UpdateRole',
  DeleteRole = 'DeleteRole',

  CreateStaticPage = 'CreateStaticPage',
  RetrieveStaticPage = 'RetrieveStaticPage',
  UpdateStaticPage = 'UpdateStaticPage',
  DeleteStaticPage = 'DeleteStaticPage',
  DeleteStaticPageTranslation = 'DeleteStaticPageTranslation',

  CreateLabel = 'CreateLabel',
  RetrieveLabel = 'RetrieveLabel',
  UpdateLabel = 'UpdateLabel',
  DeleteLabel = 'DeleteLabel',
  DeleteLabelTranslation = 'DeleteLabelTranslation',

  CreateLanguage = 'CreateLanguage',
  RetrieveLanguage = 'RetrieveLanguage',
  UpdateLanguage = 'UpdateLanguage',
  DeleteLanguage = 'DeleteLanguage',

  CreateCustomerChain = 'CreateCustomerChain',
  RetrieveCustomerChain = 'RetrieveCustomerChain',
  UpdateCustomerChain = 'UpdateCustomerChain',
  DeleteCustomerChain = 'DeleteCustomerChain',

  CreateCustomerLocation = 'CreateCustomerLocation',
  RetrieveCustomerLocation = 'RetrieveCustomerLocation',
  UpdateCustomerLocation = 'UpdateCustomerLocation',
  DeleteCustomerLocation = 'DeleteCustomerLocation',

  CreateInstallerChain = 'CreateInstallerChain',
  RetrieveInstallerChain = 'RetrieveInstallerChain',
  UpdateInstallerChain = 'UpdateInstallerChain',
  DeleteInstallerChain = 'DeleteInstallerChain',

  CreateInstaller = 'CreateInstaller',
  RetrieveInstaller = 'RetrieveInstaller',
  UpdateInstaller = 'UpdateInstaller',
  DeleteInstaller = 'DeleteInstaller',

  CreateDevice = 'CreateDevice',
  RetrieveDevice = 'RetrieveDevice',
  UpdateDevice = 'UpdateDevice',
  DeleteDevice = 'DeleteDevice',

  CreateEquipment = 'CreateEquipment',
  RetrieveEquipment = 'RetrieveEquipment',
  UpdateEquipment = 'UpdateEquipment',
  DeleteEquipment = 'DeleteEquipment',

  CreateAlarm = 'CreateAlarm',
  RetrieveAlarm = 'RetrieveAlarm',
  UpdateAlarm = 'UpdateAlarm',
  DeleteAlarm = 'DeleteAlarm',
  AcquitAlarm = 'AcquitAlarm',

  CreateAdvice = 'CreateAdvice',
  RetrieveAdvice = 'RetrieveAdvice',
  UpdateAdvice = 'UpdateAdvice',
  DeleteAdvice = 'DeleteAdvice',

  CreateAdviceType = 'CreateAdviceType',
  RetrieveAdviceType = 'RetrieveAdviceType',
  UpdateAdviceType = 'UpdateAdviceType',
  DeleteAdviceType = 'DeleteAdviceType',

  CreateAlarmType = 'CreateAlarmType',
  RetrieveAlarmType = 'RetrieveAlarmType',
  UpdateAlarmType = 'UpdateAlarmType',
  DeleteAlarmType = 'DeleteAlarmType',

  CreateEquipmentStatus = 'CreateEquipmentStatus',
  RetrieveEquipmentStatus = 'RetrieveEquipmentStatus',
  UpdateEquipmentStatus = 'UpdateEquipmentStatus',
  DeleteEquipmentStatus = 'DeleteEquipmentStatus',

  CreateEquipmentConfig = 'CreateEquipmentConfig',
  RetrieveEquipmentConfig = 'RetrieveEquipmentConfig',
  UpdateEquipmentConfig = 'UpdateEquipmentConfig',
  DeleteEquipmentConfig = 'DeleteEquipmentConfig',

  CreateEventType = 'CreateEventType',
  RetrieveEventType = 'RetrieveEventType',
  UpdateEventType = 'UpdateEventType',
  DeleteEventType = 'DeleteEventType',

  CreateActionType = 'CreateActionType',
  RetrieveActionType = 'RetrieveActionType',
  UpdateActionType = 'UpdateActionType',
  DeleteActionType = 'DeleteActionType',

  CreateHistory = 'CreateHistory',
  RetrieveHistory = 'RetrieveHistory',
  UpdateHistory = 'UpdateHistory',
  DeleteHistory = 'DeleteHistory',

  CreateFluid = 'CreateFluid',
  RetrieveFluid = 'RetrieveFluid',
  UpdateFluid = 'UpdateFluid',
  DeleteFluid = 'DeleteFluid',

  CreateConfigChangeRequest = 'CreateConfigChangeRequest',
  RetrieveConfigChangeRequest = 'RetrieveConfigChangeRequest',
  UpdateConfigChangeRequest = 'UpdateConfigChangeRequest',
  DeleteConfigChangeRequest = 'DeleteConfigChangeRequest',

  CreateAcknowledgeConfigChangeRequest = 'CreateAcknowledgeConfigChangeRequest',
  CreateRelearningConfigChangeRequest= 'CreateRelearningConfigChangeRequest',
  CreateRebootConfigChangeRequest= 'CreateRebootConfigChangeRequest',
  CreateRecalculationConfigChangeRequest= 'CreateRecalculationConfigChangeRequest',
  CreateForceSyncConfigChangeRequest= 'CreateForceSyncConfigChangeRequest',

  MatelexAdminPermission = 'MatelexAdminPermission',


}

export enum CustomEntityPermissions {}
// PublishArticle = 'PublishArticle',
// UnpublishArticle = 'UnpublishArticle',

export enum CustomNoEntityPermissions {
  GeneralSettingsRead = 'GeneralSettingsRead',
  GeneralSettingsUpdate = 'GeneralSettingsUpdate',
  GeneralSettingsDownloadLogs = 'GeneralSettingsDownloadLogs'
}

// temporary solution, they will be read differently after an improvement
export enum Entities {
  User = 'User',
  Role = 'Role',
  StaticPage = 'StaticPage',
  Label = 'Label',
  Language = 'Language',
  Advice = 'Advice',
  AdviceType = 'AdviceType',
  Alarm = 'Alarm',
  Equipment = 'Equipment',
  Device = 'Device',
  Installer = 'Installer',
  InstallerChain = 'InstallerChain',
  CustomerLocation = 'CustomerLocation',
  CustomerChain = 'CustomerChain',
  AlarmType = 'AlarmType',
  EquipmentStatus = 'EquipmentStatus',
  EquipmentConfig = 'EquipmentConfig',
  EventType = 'EventType',
  ActionType = 'ActionType',
  History = 'History',
  Fluid = 'Fluid',
  ConfigChangeRequest = 'ConfigChangeRequest'
}

// For DEMO . to be removed
export enum RoleTypes {
  Guest = 'Guest',
  User = 'User',
  Admin = 'Admin'
}
