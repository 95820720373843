import { Injectable } from '@angular/core';
import { CustomerLocationCreateInput } from '@customer-locations/domain/customer-location/customer-location.create';
import { CustomerLocationUpdateInput } from '@customer-locations/domain/customer-location/customer-location.update';
import { CustomerLocationsResult } from '@equipment/services/customer-locations-result';
import gql from 'graphql-tag';
import { map, take } from 'rxjs/operators';
import { CustomerLocation } from 'src/app/shared/models/customer-location';
import { CrudService } from 'src/app/shared/services/crud/crud.service';
import { GraphqlService } from '../../graphql/graphql.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerLocationService extends CrudService<CustomerLocation, CustomerLocationCreateInput, CustomerLocationUpdateInput> {
  public fragment: any;
  public modelName: string = 'CustomerLocation';

  public constructor(private graphqlService: GraphqlService) {
    super();
    this.setFragment();
  }

  private customerLocationForDashboardMapPinQuery: any = gql`
    query getCustomerLocationForDashboardMapPin($customerLocationId: String!) {
      getCustomerLocationForDashboardMapPin(customerLocationId: $customerLocationId) {
          name
          streetAndNumber
          city
          postalCode
          installer {
            logo
            name
            phone
          }
          equipments {
            id
            name
            status {
              color
              icon
            }
          }
      }
    }
  `;

  private customerLocationsQuery: any = gql`
    query getCustomerLocations($userId: String,$is_active: Boolean, $name: String, $sortField: String, $sortDir: String, $page: Float, $perPage: Float) {
      getCustomerLocations(criteria: { userId:$userId,is_active: $is_active, name: $name, sortField: $sortField, sortDir: $sortDir, page: $page, perPage: $perPage }) {
        itemsPage {
          _id
          name
          is_active
          installer {
            _id
            name
          }
          chain {
            _id
            name
          }
          dateCreated
          phone
          email
          country
          countryISO
          postalCode
          city
          latitude
          longitude
          streetAndNumber
          manager {
            _id
            name
          }
          equipments {
            _id
          }
          subscriptioncls {
            _id
            idCustomerLocation
            is_active
            subscriptionDate
            unSubscriptionDate
            subType {
              subtype
              description
              label
            }
          }
          totalEquipments
        }
        total
      }
    }
  `;

  public async getCustomerLocationForDashboardMapPin(customerLocationId: string): Promise<CustomerLocation> {
    return await this.graphqlService
      .query(this.customerLocationForDashboardMapPinQuery, {customerLocationId: customerLocationId}, false)
      .pipe(
        take(1),
        map((result: any) => {
          return result.data.getCustomerLocationForDashboardMapPin;
        })
      )
      .toPromise();
  }

  public async getCustomerLocations(criteria: any): Promise<CustomerLocationsResult> {
    const gqlParams = {} as any;

    if (criteria.name) {
      gqlParams.name = criteria.name;
    }

    if (criteria.userId) {
      gqlParams.userId = criteria.userId;
    }

    if (criteria.is_active) {
      gqlParams.is_active = true
    } else if (criteria.is_active === false) {
      gqlParams.is_active = false;
    } else {
      gqlParams.is_active = undefined;
    }

    if (criteria.sort) {
      gqlParams.sortField = criteria.sort.field;
      gqlParams.sortDir = criteria.sort.direction;
    }

    if (criteria.pagination) {
      gqlParams.page = criteria.pagination.page;
      gqlParams.perPage = criteria.pagination.perPage;
    }

    return await this.graphqlService
      .query(this.customerLocationsQuery, gqlParams, false)
      .pipe(
        take(1),
        map((result: any) => {
          const customerLocationsResult: CustomerLocationsResult = {
            customerLocationsPage: result.data.getCustomerLocations.itemsPage,
            total: result.data.getCustomerLocations.total
          };
          return customerLocationsResult;
        })
      )
      .toPromise();
  }

  private setFragment(fragment?: any): void {
    this.fragment =
      fragment ||
      gql`
        fragment fields on ${this.modelName} {
          _id
          name
          installer {
            _id
            name
          }
          chain {
            _id
            name
          }
          phone
          email
          country
          countryISO
          postalCode
          city
          latitude
          longitude
          streetAndNumber
          manager {
            _id
            name
          }
          equipments {
            _id
          }
          totalEquipments
        }
      `;
  }
}
