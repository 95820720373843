import {Injectable} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {GraphqlService} from "@graphql/graphql.service";
import {BannerService} from "@shared/components/banner/banner.service";
import * as MUTATIONS from "./landing-page.mutations";
import * as QUERIES from "./landing-page.query";
import {BannerTypes} from "@shared/shared.enums";
import {map, take} from "rxjs/operators";
import {UserMigration} from "../user-migration";
import {User} from "@users/domain/models/user";
import {UserMigrationUpdateInput} from "../user-migration-update.input";
import {UserMigrationCreateInput} from "../user-migration-create.input";
import {RoleService} from "@users/domain/services/role.service";
import {UserService} from "@users/domain/services/user.service";
import {Role} from "@users/domain/models/role";

@Injectable({
  providedIn: 'root'
})
export class LandingPageService {
  constructor(
    private translateService: TranslateService,
    private graphqlService: GraphqlService,
    private bannerService: BannerService,
  ) {}

  public async retrieveRolesList(): Promise<Role[]> {
    try {
      return await this.graphqlService.query(QUERIES.retrieveRolesList, {}).pipe(
        take(1),
        map((result: any) => {
          const roles: Role[] = result?.data?.retrieveRolesList.map((role: Role) => {
            // handle default user
            if (role.uniqueBusinessId)
              role.name = this.translateService.instant('USER_MIGRATION_FORM.ROLES.' + role.uniqueBusinessId)
            else
              role.name = this.translateService.instant('USER_MIGRATION_FORM.ROLES.DEFAULT_USER')

            return role;
          })
          return roles;
        })
      ).toPromise();
    } catch(error) {
      return undefined;
    }
  }

  public async saveUserInfos(input: UserMigrationUpdateInput): Promise<any> {
    try {
      return await this.graphqlService.mutate(MUTATIONS.saveUserInfos, {input}).pipe(
        take(1),
        map((result: any) => {
          let bannerMsg = '';
          if (result?.saveUserInfos?.status === 'pending_approval') {
            bannerMsg = this.translateService.instant(`USER_MIGRATION_FORM.CONFIRM_INFOS`);
          } else if (result?.saveUserInfos?.status === 'migration_approved') {
            bannerMsg = this.translateService.instant(`USER_MIGRATION_FORM.APPROVED_ACTIVATE`);
          }
          this.bannerService.addMessage(bannerMsg, BannerTypes.SUCCESS, 10000);
          return result?.saveUserInfos;
        })
      ).toPromise();
    } catch(err) {
      const bannerMsg = this.translateService.instant(`USER_MIGRATION_FORM.ERROR_SAVE`);
      this.bannerService.addMessage(bannerMsg, BannerTypes.ERROR, 10000);
    }
  }

  public async createUserFromMigration(input: UserMigrationCreateInput): Promise<any> {
    try {
      return await this.graphqlService.mutate(MUTATIONS.createUserFromMigration, {input}).pipe(
        take(1),
        map((result: any) => {
          if (result.createUserFromMigration.status === 'EMAIL_IN_USE') {
            const bannerMsg = this.translateService.instant(`USER_MIGRATION_FORM.ERRORS.` + result.createUserFromMigration.status);
            this.bannerService.addMessage(bannerMsg, BannerTypes.ERROR, 10000);
          } else if (result.createUserFromMigration.status === 'GENERIC_ERROR') {
            const bannerMsg = this.translateService.instant(`USER_MIGRATION_FORM.ERRORS.` + result.createUserFromMigration.status);
            this.bannerService.addMessage(bannerMsg, BannerTypes.ERROR, 10000);
          } else {
            const bannerMsg = this.translateService.instant(`USER_MIGRATION_FORM.CREATE_USER_INFOS`);
            this.bannerService.addMessage(bannerMsg, BannerTypes.SUCCESS, 10000);
          }
          return result?.createUserFromMigration;
        })
      ).toPromise();
    } catch(err) {
      const bannerMsg = this.translateService.instant(`USER_MIGRATION_FORM.ERROR_CREATE`);
      this.bannerService.addMessage(bannerMsg, BannerTypes.ERROR, 10000);
    }
  }
  public async retrieveUserMigration(userMigrationId: string): Promise<UserMigration> {
    try {
      return await this.graphqlService.query(QUERIES.retrieveUserMigration, {userMigrationId}).pipe(
        take(1),
        map((result: any) => {
          return result?.data?.retrieveUserMigration;
        })
      ).toPromise();
    } catch(error) {
      return undefined
    }
  }

  public async askNewLink(email: string, userMigrationId: string): Promise<boolean> {
    try {
      return await this.graphqlService.query(QUERIES.askNewLink, {email, userMigrationId}).pipe(
        take(1),
        map((result: any) => {
          const bannerMsg = this.translateService.instant(`LANDING_PAGE.RESENT_LINK`);
          this.bannerService.addMessage(bannerMsg, BannerTypes.SUCCESS, 10000);
          return true;
        })
      ).toPromise();
    } catch(error) {
      return false
    }
  }
  public async retrieveUserWithUserMigrationId(userMigrationId: string): Promise<User> {
    try {
      return await this.graphqlService.query(QUERIES.retrieveUserWithUserMigrationId, {userMigrationId}).pipe(
        take(1),
        map((result: any) => {
          return new User(result?.data?.retrieveUserWithUserMigrationId);
        })
      ).toPromise();
    } catch(error) {
      return undefined
    }
  }

  public async retrieveUserMigrationWithEmail(email: string): Promise<UserMigration> {
    try {
      return await this.graphqlService.query(QUERIES.retrieveUserMigrationWithEmail, {email}).pipe(
        take(1),
        map((result: any) => {
          const retrieveUserMigrationWithEmail = result?.data?.retrieveUserMigrationWithEmail;
          if (retrieveUserMigrationWithEmail.status === 'waiting_form') {
            return retrieveUserMigrationWithEmail;
          } else if (retrieveUserMigrationWithEmail.status === 'pending_approval') {
            const bannerMsg = this.translateService.instant(`USER_MIGRATION_FORM.pending_approval`);
            console.log('BANNER MSG =>', bannerMsg)
            this.bannerService.addMessage(bannerMsg, BannerTypes.INFO, 10000);
          } else if (retrieveUserMigrationWithEmail.status === 'migration_rejected')  {
            const bannerMsg = this.translateService.instant(`USER_MIGRATION_FORM.migration_rejected`);
            console.log('BANNER MSG =>', bannerMsg)
            this.bannerService.addMessage(bannerMsg, BannerTypes.ALERT, 10000);
          } else if (retrieveUserMigrationWithEmail.status === 'migration_approved')  {
            const bannerMsg = this.translateService.instant(`USER_MIGRATION_FORM.migration_approved`);
            console.log('BANNER MSG =>', bannerMsg)
            this.bannerService.addMessage(bannerMsg, BannerTypes.SUCCESS, 10000);
          }
          console.log('RESULT => ', retrieveUserMigrationWithEmail);
          return retrieveUserMigrationWithEmail;
        })
      ).toPromise();
    } catch(error) {
      console.log('ERROR', error);
      return undefined
    }
  }
  public async saveUserMigration(email: string): Promise<void> {
    try {
      return await this.graphqlService.mutate(MUTATIONS.saveUserMigration, { email }).pipe(
        take(1),
        map((result: any) => {
            if (result.saveUserMigration.accountExists) {
              const bannerMsg = this.translateService.instant(`LANDING_PAGE.EXISTING_ACCOUNT`);
              console.log('BANNER MSG =>', bannerMsg)
              this.bannerService.addMessage(bannerMsg, BannerTypes.SUCCESS, 10000);
            } else {
              const bannerMsg = this.translateService.instant(`LANDING_PAGE.NEW_ACCOUNT`);
              console.log('BANNER MSG =>', bannerMsg)
              this.bannerService.addMessage(bannerMsg, BannerTypes.SUCCESS, 10000);
            }
        })
      ).toPromise();
    } catch (error) {
      this.bannerService.addMessage('Couldnt save user migration', BannerTypes.ERROR, 10000);
      return undefined;
    }
  }
}
