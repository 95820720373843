<ng-container *ngIf="isLoading">
  <mat-spinner [diameter]="75" style="margin: 0 auto"></mat-spinner>
</ng-container>
<div *ngIf="!isLoading" class="users-tab">
  <mat-card>
    <mat-card-header>
      <mat-card-title>{{ 'website.equipment.detail.users.tab_label' | translate }}</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <table mat-table [dataSource]="usersResult?.itemsPage" matSort (matSortChange)="sortData($event)">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'website.equipment.detail.users.first_name_last_name' | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <div class="user-name-wrapper">
              <div class="user-image" *ngIf="element.profileImage" [ngStyle]="{ 'background-image': 'url(' + element.profileImage + ')' }"></div>
              <div class="user-image" *ngIf="!element.profileImage">
                <span class="image-placeholder">{{ element.firstName[0] + element.lastName[0] }}</span>
              </div>
              {{ element.firstName + ' ' + element.lastName }}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="firm">
          <th mat-header-cell *matHeaderCellDef>{{ 'website.equipment.detail.users.firm' | translate }}</th>
          <td mat-cell *matCellDef="let element">{{ element.companyName }}</td>
        </ng-container>

        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef>{{ 'website.equipment.detail.users.role' | translate }}</th>
          <td mat-cell *matCellDef="let element">
            {{ element.roleName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="alarms">
          <th mat-header-cell *matHeaderCellDef>{{ 'website.equipment.detail.users.alarms' | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <span>{{ 'website.users.dialog.tabs.stores.enums.alarms.' + element.isAlarmsCustomerLocationDisabled | translate }}</span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let user; columns: displayedColumns" (click)="openEquipmentUserModal(user)"></tr>
      </table>
    </mat-card-content>
    <mat-paginator *ngIf='usersResult?.total>0' (page)="onPaginatorChange($event)" [length]="usersResult?.total" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </mat-card>
</div>
