import { Component, Input } from '@angular/core';
import { UserService } from '@users/domain/services/user.service';
import { Permission } from '@users/domain/services/user.enums';
import { Equipment } from '@shared/models/equipment';
import { CustomerLocation } from '@shared/models/customer-location';
import { Config } from '../../../config';
import { LanguageService } from '@shared/services/language.service';

@Component({
  selector: 'app-customer-location-equipments-popup-map',
  templateUrl: './customer-location-equipments-popup-map.component.html',
  styleUrls: ['./customer-location-equipments-popup-map.component.scss']
})
export class CustomerLocationEquipmentsPopupMapComponent {
  @Input() selectedMapMarkerCustomerLocation: CustomerLocation;
  @Input() styles :any ={};
  @Input() heightContent :number;
  protected hasAdminPermission = false;
  public crtLang = Config.defaultLanguage;

  constructor(  public userService: UserService, private languageService: LanguageService  ) {
    this.crtLang = this.languageService.currentLanguage;
    this.hasAdminPermission = this.userService.loggedInUserHasPermissions([Permission.MatelexAdminPermission]);
  }

  public isPolarBoxNotConnected(equipment: Equipment): boolean {
    if (equipment.main_device_id?.hardware_version !== '1.0.0' && !equipment.main_device_id?.license_enabled ) {
      return true;
    } else {
      return false;
    }
  }

  public closePopup(): void {
    this.selectedMapMarkerCustomerLocation = null;
  }
}
