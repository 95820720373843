import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslationsResolver } from './shared/resolvers/translations.resolver';
import { AuthGuard } from './shared/guards/auth.guard';
import * as CMP from '@users/components';
import { UsersLoginResolver } from '@shared/resolvers/users-login.resolver';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: localStorage.getItem('defaultLanguage') + '/dashboard' },
  {
    path: ':lang',
    // canActivateChild: [AuthGuard],
    resolve: {
      lang: TranslationsResolver
    },
    children: [

      { path: '',
        loadChildren: () => import('./layouts/dashboard-layout/dashboard-layout.module').then(m => m.DashboardLayoutModule),
        canActivate: [AuthGuard] },

      {
        path: 'migration',
        loadChildren: () => import('./landing-page/landing-page.module').then(m => m.LandingPageModule),
      },
      {
        path: 'migration-end',
        loadChildren: () => import('./landing-page-v2/landing-page-v2.module').then(m => m.LandingPageV2Module),
      },
      {
        path: 'migration-form',
        loadChildren: () => import('./user-migration/user-migration-form.module').then(m => m.UserMigrationFormModule),
      },
      {
        path: 'migration-validation',
        loadChildren: () => import('./user-migration-validation/user-migration-validation.module')
          .then(m => m.UserMigrationValidationModule),
      },
      {
        path: 'users',
        children: [
          {
            path: 'login',
            component: CMP.LoginComponent,
            resolve: {
              UsersLoginResolver
            }
          },
          {
            path: 'auth/token/:token',
            component: CMP.LoginComponent,
            resolve: {
              UsersLoginResolver
            }
          },
          {
            path: 'login/forgot-password',
            component: CMP.ForgotPasswordComponent,
            resolve: {
              UsersLoginResolver
            }
          },
          {
            path: 'login/forgot-password/:email',
            component: CMP.ForgotPasswordComponent,
            resolve: {
              UsersLoginResolver
            }
          },
          {
            path: 'login/reset-password/:email',
            component: CMP.ResetPasswordComponent,
            resolve: {
              UsersLoginResolver
            }
          },
          {
            path: 'login/reset-password/:email/:resetCode',
            component: CMP.ResetPasswordComponent,
            resolve: {
              UsersLoginResolver
            }
          },
        ]
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
