import { Directive, ElementRef, Input } from '@angular/core';
import { Permission } from '../../users/domain/services/user.enums';
import { UserService } from '../../users/domain/services/user.service';

@Directive({
  selector: '[matx-if-has-permission]'
})
export class MatxIfHasPermission {
  constructor(private elementRef: ElementRef, private userService: UserService) {}
  @Input('matx-if-has-permission') permissions: string[] = null;

  private backupElementDisplay: string = null;

  ngOnInit() {
    this.backupElementDisplay = this.elementRef.nativeElement.style.display;
    this.elementRef.nativeElement.style.display = 'none';
    this.checkAccess();
  }

  private checkAccess() {
    if (this.permissions === null || this.permissions.length <= 0){
      this.elementRef.nativeElement.style.display='block';

    }else{
      if (this.userService.loggedInUserHasPermissions(this.permissions as Permission[])) {
        this.elementRef.nativeElement.style.display = this.backupElementDisplay ? this.backupElementDisplay : 'block';
      }
    }

  }
}
