import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EquipmentSensibilityConversionService {

  conversionTable2 = [
    {
      API: -7,
      IHM: 1
    },
    {
      API: -6,
      IHM: 2
    },
    {
      API: -5.5,
      IHM: 3
    },
    {
      API: -5,
      IHM: 4
    },
    {
      API: -4.5,
      IHM: 5
    },
    {
      API: -4,
      IHM: 6
    }
  ];
  conversionTable1 = [
    {
      API: -5.5,
      IHM: 1
    },
    {
      API: -5,
      IHM: 2
    },
    {
      API: -4.5,
      IHM: 3
    },
    {
      API: -4,
      IHM: 4
    },
    {
      API: -3.5,
      IHM: 5
    },
    {
      API: -3,
      IHM: 6
    }
  ];



  /**
   *
   * @param lp_consign
   * @param sensibility
   * @returns sensibility value that should be displayed
   */
  convertToIHM(lp_consign: number, sensibility: number) : number {

    let roundedSensibility = Math.round(sensibility/0.5)*0.5; //round by 0.5 step
    let displayedSensibility = sensibility;


    if(lp_consign >= -15) {
      if(!sensibility) {
        displayedSensibility = 4;
        return displayedSensibility;
      }
      if(sensibility <= -7) return 1;
      if(sensibility >= -4) return 6;

      return this.conversionTable2.find(v => v.API == roundedSensibility)?.IHM;

    } else {
      if(!sensibility) {
        displayedSensibility = 6;
        return displayedSensibility;
      }
      if(sensibility <= -5.5) return 1;
      if(sensibility >= -3) return 6;

      return this.conversionTable1.find(v => v.API == roundedSensibility)?.IHM;
    }
  }


  /**
   *
   * @param lp_consign
   * @param sensibility
   * @returns sensibility value that should be stored
   */
  convertToAPI(lp_consign: number, sensibility: number): number {
    if(lp_consign >= -15) {
      return this.conversionTable2.find(v => v.IHM == sensibility)?.API;
    } else {
      return this.conversionTable1.find(v => v.IHM == sensibility)?.API;
    }
  }

}
