<div class='graph-tab'>
  <mat-card class='graph-card'>
    <mat-card-header>
      <mat-card-title>{{ 'website.equipment.config.performance.startup_per_engines' | translate }}</mat-card-title>
    </mat-card-header>

    <mat-divider></mat-divider>

    <mat-card-content>
      <div class='graph-container'>
        <div class='graph-container-header-field'>

          <mat-form-field appearance='outline' (click)='instDatePicker.open()'>
            <mat-label>{{ 'website.equipment.detail.charts.period' | translate }}</mat-label>
            <input
              matInput
              (dateChange)='changeDatePicker($event)'
              [matDatepickerFilter]='instDatePickerFilter'
              [matDatepicker]='instDatePicker'
              value='{{currentDayDate}}'
              readonly
              formControlName='instDateFilter'
            />
            <mat-datepicker-toggle matSuffix [for]='instDatePicker'>
              <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #instDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
        <mat-spinner class="charts-loading-spinner" *ngIf="!readyToRender" diameter="75"></mat-spinner>
        <table *ngIf='readyToRender && dataSource.data.length' mat-table [dataSource]='dataSource'>
          <ng-container matColumnDef='label'>
            <th class='mat-header-cell' mat-header-cell *matHeaderCellDef></th>
            <td mat-cell class='a-center' *matCellDef='let element'>{{element.name}}</td>
          </ng-container>
          <ng-container matColumnDef='chronogramme'>
            <th class='mat-header-cell' mat-header-cell *matHeaderCellDef><span class='chrono-st'>00:00</span><span class='chrono-end'>24:00</span></th>

            <td mat-cell class='a-center' *matCellDef='let element'>
                <app-equipment-performances-charts
                  [data]='element'
                  [minDate]='this.instCriteria.filters.minDate'
                  [maxDate]='this.instCriteria.filters.maxDate'
                  [width]='700'></app-equipment-performances-charts>
            </td>
          </ng-container>

          <ng-container matColumnDef='tempsTotal'>
            <th class='mat-header-cell' mat-header-cell *matHeaderCellDef><p>{{ 'website.equipment.config.performance.total_work_time' | translate }}</p></th>
            <td mat-cell class='a-center' *matCellDef='let element'>{{element.performanceData.tempsTotal}}</td>
          </ng-container>

          <ng-container matColumnDef='freqDemar'>
            <th class='mat-header-cell' mat-header-cell *matHeaderCellDef><p>{{ 'website.equipment.config.performance.startup_frequency' | translate }}</p></th>
            <td mat-cell class='a-center' *matCellDef='let element'>{{element.performanceData.freqDemar}}</td>
          </ng-container>

          <ng-container matColumnDef='tpsMinBetweenDemar'>
            <th class='mat-header-cell' mat-header-cell *matHeaderCellDef><p>{{ 'website.equipment.config.performance.time_btw_stup' | translate }}</p></th>
            <td mat-cell class='a-center' *matCellDef='let element'>{{element.performanceData.tpsMinBetweenDemar}}</td>
          </ng-container>

          <ng-container matColumnDef='tpsMinWorking'>
            <th class='mat-header-cell' mat-header-cell *matHeaderCellDef><p>{{ 'website.equipment.config.performance.min_work_time' | translate }}</p></th>
            <td mat-cell class='a-center' *matCellDef='let element'>{{element.performanceData.tpsMinWorking}}</td>
          </ng-container>

          <ng-container matColumnDef='tpsMaxWorking'>
            <th class='mat-header-cell' mat-header-cell *matHeaderCellDef><p>{{ 'website.equipment.config.performance.max_work_time' | translate }}</p></th>
            <td mat-cell class='a-center' *matCellDef='let element'>{{element.performanceData.tpsMaxWorking}}</td>
          </ng-container>

          <ng-container matColumnDef='informations'>
            <th class='mat-header-cell' mat-header-cell *matHeaderCellDef><p>{{ 'website.equipment.config.performance.informations' | translate }}</p></th>
            <td mat-cell class='a-center' *matCellDef='let element' (click)="openEquipmentUserModal(element)">
              <mat-icon matDatepickerToggleIcon>keyboard_arrow_right</mat-icon>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
          <tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
          <!-- Group header -->
          <ng-container matColumnDef="groupHeader">
            <td colspan="999" mat-cell *matCellDef="let group">
              <mat-icon *ngIf="group.expanded">expand_less</mat-icon>
              <mat-icon *ngIf="!group.expanded">expand_more</mat-icon>
              <strong>{{group[groupByColumns[group.level-1]] | titlecase}}</strong>
            </td>
          </ng-container>

          <tr style='cursor: pointer' mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup" (click)="groupHeaderClick(row)"> </tr>
        </table>
      </div>
    </mat-card-content>

  </mat-card>

</div>
