import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';

import { ProfileService } from '@shared/services/profile.service';

import { environment } from '../../../environments/environment';
import { LanguageService } from '../../shared/services/language.service';
import { TokenSessionService } from '../../shared/services/token.session.service';
import { UserInfo } from '../../users/domain/services/user.interfaces';
import { LoginStatusUpdate, UserService } from '../../users/domain/services/user.service';
import { Permission } from '@users/domain/services/user.enums';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public isAuthenticated = false;
  public isAdmin = false;
  private _userInfo: UserInfo = null;
  public isLandingPage = false;
  public homeUrl = '';
  constructor(
    private tokenSessionService: TokenSessionService,
    private profileService: ProfileService,
    private router: Router,
    private languageService: LanguageService,
    private userService: UserService,
    public changeDetectorRef: ChangeDetectorRef
  ) {}

  public async ngOnInit(): Promise<void> {
    if (location.pathname.includes('migration') || location.pathname.includes('migration-form') || location.pathname.includes('migration-validation')) {
      this.homeUrl = location.origin.concat('/').concat(this.languageService.currentLanguage).concat('/').concat('migration');
      this.isLandingPage = true;
    }
    this.userService.loginStatusUpdates.subscribe(async (loginStatusUpdate: LoginStatusUpdate) => {
      console.log('HeaderComponent ngOnInit this.userService.loginStatusUpdates.subscribe loginStatusUpdate', loginStatusUpdate);
      this.refreshUserInfo(loginStatusUpdate);
      if (this.profileService.isAuthenticated()) {
        await this.userService.retrieveNotifications();
        const featuresFlags = (await this.userService.retrieveFeaturesFlag());
        localStorage.setItem(btoa('featuresFlag'), btoa(JSON.stringify(featuresFlags)));
      }
      this.changeDetectorRef.detectChanges();
    });
    this.refreshUserInfo();
  }

  public refreshUserInfo(loginStatusUpdate?: LoginStatusUpdate) {
    this._userInfo = loginStatusUpdate ? loginStatusUpdate.userInfo : this.profileService.userInfo;
    this.isAuthenticated = this.profileService.isAuthenticated();
    this.isAdmin = loginStatusUpdate ? loginStatusUpdate.userInfo.permissions
      .includes(Permission.MatelexAdminPermission) : this.profileService.isAdmin();
  }

  public get userInfo(): UserInfo {
    if (!this._userInfo) {
      this._userInfo = this.profileService.userInfo;
    }
    return this._userInfo;
  }

  public logout() {
    setTimeout(() => {
      this.tokenSessionService.logout();
      this.isAuthenticated = false;
      this.isAdmin = false;
      window.location.reload();
    }, 100);
  }

  public goToLogin() {
    this.router.navigate([`/${this.languageService.currentLanguage}/users/login`]);
    // setTimeout(() => {
    //   window.location.reload();
    // }, 100);
  }

  public goToAdmin() {
    const adminUrl =
      window.location.protocol +
      '//' +
      window.location.hostname +
//      environment.kickstartFrontendPort +
      environment.urlPrefix +
      '/' +
      this.languageService.currentLanguage +
      '/admin';
    console.log('HeaderComponent goToAdmin adminUrl', adminUrl);
    const win = window.open(adminUrl, '_blank');
    win.focus();
  }

  public setSidebarOpen() {
    if (document.querySelector('.sidebar')) {
      document.querySelector('.sidebar').classList.add('open');
    }
  }





  public goToDashboard(): void {
    this.router.navigate([this.languageService.currentLanguage, 'dashboard']);
  }
}
