<div class='row ' >
  <div [ngClass]='{
  "col-md-6": !isEditMode,
   "col-md-4":isEditMode
  }' class='my-auto'>
    {{label}}
  </div>
    <div [ngClass]='{
  "col-md-5": !isEditMode,
  "col-md-7":isEditMode
  }'>

      <div class='d-flex justify-content-end my-auto'>
        <mat-form-field class='col-md-11 mt-3'  appearance='outline' *ngIf='isEditMode'>
          <input matInput type="text" [formControl]="formControl">
          <button  matSuffix  mat-icon-button aria-label="Clear"  class='icon-style' (click)="clearValue()" >
            <mat-icon >backspace</mat-icon>
          </button>
        </mat-form-field>
        <div class='col-md-11 my-auto me-1 text-break' *ngIf="!isEditMode" >{{defaultValue ?? placeholderUndefined}}</div>
        <div class='col-md-1 my-auto' >
          <button mat-icon-button  class='icon-style' *ngIf="formControl?.valid && isEditMode && formControl.value !==this.defaultValue"  aria-label="Validate" (click)="submit()">
            <mat-icon>done</mat-icon>
          </button>


          <button  mat-icon-button   class='icon-style' *ngIf="(formControl?.invalid  && isEditMode)|| (formControl.value===this.defaultValue  && isEditMode)    "  aria-label="Close" (click)="switchEditMode()">
            <mat-icon>close</mat-icon>
          </button>


          <button  mat-icon-button   class='icon-style' *ngIf="!isEditMode && editable"  [attr.matx-if-has-permission]='hasPermissionToEdit.length>0?hasPermissionToEdit:null'   aria-label="Edit" (click)="switchEditMode()">
            <mat-icon>edit</mat-icon>
          </button>
        </div>
      </div>

    </div>


</div>
