import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDateRangePicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { EventType } from '../../../../shared/models/event-type';
import { LanguageService } from '../../../../shared/services/language.service';
import { EquipmentDetailsService } from '../../../services/equipment-details.service';
import { EventTypesResult } from '../../../services/event-types-result';
import { EventsResult } from '../../../services/events-result';
import {
  LogsListSearchCriteria,
  LogsListSearchCriteriaForView
} from '../../../services/logs-table-search-criteria.interface';
import { LogDetailsDialogComponent } from './log-details-dialog/log-details-dialog.component';

const INITIAL_CRITERIA: LogsListSearchCriteria = {
  filters: {
    types: []
  },
  sort: {
    field: 'date',
    direction: 'DESC'
  },
  pagination: {
    perPage: 5,
    page: 0
  }
};

@Component({
  selector: 'app-logs-table',
  templateUrl: './logs-table.component.html',
  styleUrls: ['./logs-table.component.scss']
})
export class LogsTableComponent implements OnInit, OnDestroy {

  @Input() minDate: any;
  @Input() maxDate: any;

  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  eventTypeFilterControl = new FormControl();
  Events: string[] = ['Alarme statistique'];
  allEvents: string[] = ['Alarme statistique', 'Démarrage du DNI', 'Déconnexion du DNI', 'Connexion lente', 'Normal'];
  isLoading: boolean = true;

  public eventsResult: EventsResult = { eventsPage: [], total: 0 };
  public crtLang = '';
  public trInstant: (key: string | Array<string>, interpolateParams?: Object) => string | any;

  @ViewChild('eventInput') eventInput: ElementRef<HTMLInputElement>;

  public filterEventTypeInputSubject: BehaviorSubject<any> = new BehaviorSubject('');

  // end filter

  public displayedColumnsLogs: string[] = ['date', 'type', 'description'];
  public eventTypes: EventTypesResult = {
    eventTypesPage: [],
    total: 0
  };
  filteredEventTypes: EventTypesResult = {
    eventTypesPage: [],
    total: 0
  };

  public mappedEventTypes: EventType[];
  public selectedMappedEventTypes: EventType[];
  // public dniLogs$: Observable<any> | undefined;
  public criteria: LogsListSearchCriteria;
  public criteriaForView: LogsListSearchCriteriaForView = {
    filters: {
      eventTypesById: {}
    }
  };

  @Input() public equipmentId: string;

  @ViewChild(MatSort) public sort: any;
  private subscriptions: Subscription[] = [];

  constructor(
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    // private dniDetailsService: EquipmentDetailsService
    private equipmentDetailsService: EquipmentDetailsService,
    private translateService: TranslateService,
    private languageService: LanguageService
  ) {}

  async ngOnInit(): Promise<void> {
    this.crtLang = this.languageService.currentLanguage;
    this.trInstant = this.translateService.instant;
    this.trInstant = this.trInstant.bind(this.translateService);

    this.criteria = JSON.parse(JSON.stringify(INITIAL_CRITERIA));

    if (this.minDate && this.maxDate) {
      this.criteria.filters.minDate = this.minDate.toISOString();
      this.criteria.filters.maxDate = this.maxDate.toISOString();
    } else {
      this.criteria.filters.minDate = moment().subtract(1, 'month').toISOString();
      // maxDate is not needed if we are looking at latest events
      this.criteria.filters.maxDate = moment().toISOString();
    }

    await this.getLogs();
    await this.getEventTypes();
    const eventTypesWithName =  this.eventTypes.eventTypesPage.map((evt: EventType) => {
      evt.group = this.trInstant('website.equipment.detail.activity.events.all_events');
      evt.name = evt.mlVal('name', this.crtLang) as string;
      return evt;
    });
    this.mappedEventTypes = _.uniqBy(eventTypesWithName, 'name');

    this.eventTypeFilterControl.valueChanges.pipe(debounceTime(200)).subscribe((ngModel: EventType[]) => {

     this.criteria.filters.types = eventTypesWithName.filter((e) =>
       ngModel.some((eventFromSelect) => eventFromSelect.name === e.name))
       .map(model => {
        return model.id;
      });
     console.log('LogsTableComponent selectedEventType this.criteria.filters', this.criteria.filters);
     this.getLogs();
    });
  }

  onOpen() {
    if (this.selectedMappedEventTypes) {
      this.selectedMappedEventTypes.forEach(evt => {
        this.mappedEventTypes = this.mappedEventTypes.filter(mappedEvt => mappedEvt.id !== evt.id);
      });
      this.mappedEventTypes = this.selectedMappedEventTypes.concat(this.mappedEventTypes);
      setTimeout(() => {
        const scrollContainer = document.querySelector('.ng-dropdown-panel-items');
        if (scrollContainer) {
          scrollContainer.scrollTop = 0;
        }
      }, 0);
    }
  }

  public openFilterEventTypeDatePicker(daterPickerReference: MatDateRangePicker<any>): void {
    if (!daterPickerReference.opened) {
      daterPickerReference.open();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  public onSortChange(event: Sort): void {
    this.criteria.sort.field = event.active;
    this.criteria.sort.direction = event.direction.toUpperCase();
    this.getLogs();
  }

  public onPaginatorChange(event: PageEvent): void {
    this.criteria.pagination.perPage = event.pageSize;
    this.criteria.pagination.page = event.pageIndex;
    this.getLogs();
  }

  public async getLogs(): Promise<void> {
    // this.dniLogs$ = this.equipmentDetailsService.getDniLogs('613647004', this.criteria);
    this.eventsResult = await this.equipmentDetailsService.getEventsForEquipment(this.equipmentId, this.criteria);
    console.log('LogsTableComponent ngOnInit this.eventsResult', this.eventsResult);
    this.isLoading = false;
  }

  public async getEventTypes(): Promise<void> {
    this.eventTypes = await this.equipmentDetailsService.getEventTypes();
    console.log('LogsTableComponent getEventTypes this.eventTypes', this.eventTypes);

    if (Array.isArray(this.eventTypes.eventTypesPage)) {
      this.criteriaForView.filters.eventTypesById = _.keyBy(this.eventTypes.eventTypesPage, 'id') as any;
    } else {
      this.criteriaForView.filters.eventTypesById = {};
    }
  }

  public resetPaging(): void {
    this.criteria.pagination.page = 0;
  }

  public openLogDetailsDialog(event: any): void {
    const dialogRef = this.dialog.open(LogDetailsDialogComponent, {
      data: { event, crtLang: this.crtLang }
    });

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      })
    );
  }

  eventTypeOnAddChip(event: MatChipInputEvent): void {
    console.log('LogsTableComponent eventTypeOnAddChip this.eventInput.nativeElement.value', this.eventInput.nativeElement.value);
    // Clear the input value
    event.chipInput!.clear();
    this.eventTypeFilterControl.setValue(null);
    this.eventInput.nativeElement.value = '';
  }

  public onFilterEventTypeMinDateChanged(dateChangedEvent: any) {
    console.log('LogsTableComponent onFilterEventTypeMinDateChanged dateChangedEvent', dateChangedEvent);
    if (!dateChangedEvent.value) {
      return;
    }

    const minDate: Date = dateChangedEvent.value;
    const minDateIsoStr = minDate.toISOString();
    console.log('LogsTableComponent onFilterEventTypeMinDateChanged minDateIsoStr', minDateIsoStr);

    this.criteria.filters.minDate = minDateIsoStr;
  }

  public onFilterEventTypeMaxDateChanged(dateChangedEvent: any) {
    console.log('LogsTableComponent onFilterEventTypeMaxDateChanged dateChangedEvent', dateChangedEvent);
    if (!dateChangedEvent.value) {
      return;
    }

    const maxDate: Date = dateChangedEvent.value;
    const maxDateIsoStr = maxDate.toISOString();
    console.log('LogsTableComponent onFilterEventTypeMaxDateChanged maxDateIsoStr', maxDateIsoStr);

    this.criteria.filters.maxDate = maxDateIsoStr;

    this.getLogs();
  }
}
