import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { EquipmentService } from '@equipment/services/equipment.service';
import { debounceTime, distinctUntilChanged, map, take } from 'rxjs/operators';

export class EquipmentHasCustomerLocationValidator {
  static validator(equipmentService: EquipmentService): AsyncValidatorFn {
    return async (control: AbstractControl): Promise<ValidationErrors> => {
      return await control.valueChanges
        .pipe(
          debounceTime(500),
          distinctUntilChanged(),
          map(async value => {
            const equipment = await equipmentService.getEquipment(value);
            return equipment && !equipment.customerLocation ? null : { equipmentHasCustomerLocationOrInvalidId: true };
          }),
          take(1)
        )
        .toPromise();
    };
  }
}
