import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AlarmsListSearchCriteria } from '@equipment/services/alarms-list-search-criteria.interface';
import { AvgChartDataQueryCriteria } from '@equipment/services/avg-chart-data-query-criteria.interface';
import { EquipmentChartsService } from '@equipment/services/equipment-charts.service';
import { EquipmentDetailsService } from '@equipment/services/equipment-details.service';
import { HistoryListSearchCriteria } from '@equipment/services/history-list-search-criteria.interface';
import { HistoryService } from '@equipment/services/history.service';
import { InstChartDataQueryCriteria } from '@equipment/services/inst-chart-data-query-criteria.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { AvgChartData } from '@shared/models/avg-chart-data';
import { History } from '@shared/models/history';
import { InstChartData } from '@shared/models/inst-chart-data';
import { LanguageService } from '@shared/services/language.service';
import { PagedResult } from '@shared/services/paged-result';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import highchartsFullScreen from 'highcharts/modules/full-screen';
import HighchartsBoost from 'highcharts/modules/boost';
import noData from 'highcharts/modules/no-data-to-display';
import moment from 'moment-timezone';
import { debounceTime } from 'rxjs/operators';

HC_exporting(Highcharts);
highchartsFullScreen(Highcharts);
HighchartsBoost(Highcharts);
noData(Highcharts);
window.moment = moment;

enum CHART_DATA_TYPE {
  INST,
  AVG
}

enum CHART_SERIES_TYPE {
  Y = 'Y',
  ALARMS = 'ALARMS',
  REFILLS = 'REFILLS'
}

const COLORS = [
  '#a6cee3', '#1f78b4', '#b2df8a', '#33a02c', '#fb9a99',
  '#e31a1c', '#fdbf6f', '#ff7f00', '#cab2d6', '#6a3d9a',
  '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce',
  '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a',
]
const INST_QUERY_CRITERIA: InstChartDataQueryCriteria = {
  columns: [],
  filters: {
    minDate: moment().startOf('day').toISOString(),
    maxDate: moment().endOf('day').toISOString()
  },
  pagination: {
    page: 1,
    perPage: 30000
  }
};

const AVG_QUERY_CRITERIA: AvgChartDataQueryCriteria = {
  columns: [],
  filters: {
    minDate: moment().subtract(6, 'days').startOf('day').toISOString(),
    maxDate: moment().endOf('day').toISOString()
  },
  pagination: {
    page: 1,
    perPage: 30000
  }
};

const ALARMS_INST_QUERY_CRITERIA: AlarmsListSearchCriteria = {
  filters: {
    minDate: moment().startOf('day').toISOString(),
    maxDate: moment().endOf('day').toISOString()
  },
  sort: {
    field: 'date',
    direction: 'ASC'
  },
  pagination: {
    page: 0,
    perPage: 30000
  }
};

const ALARMS_AVG_QUERY_CRITERIA: AlarmsListSearchCriteria = {
  filters: {
    minDate: moment().subtract(6, 'days').startOf('day').toISOString(),
    maxDate: moment().endOf('day').toISOString()
  },
  sort: {
    field: 'date',
    direction: 'ASC'
  },
  pagination: {
    page: 0,
    perPage: 30000
  }
};

const HISTORY_INST_QUERY_CRITERIA: HistoryListSearchCriteria = {
  filters: {
    type: ['refill'],
    minDate: moment().startOf('day').toISOString(),
    maxDate: moment().endOf('day').toISOString()
  },
  sort: {
    field: 'date',
    direction: 'ASC'
  },
  pagination: {
    page: 0,
    perPage: 30000
  }
};

const HISTORY_AVG_QUERY_CRITERIA: HistoryListSearchCriteria = {
  filters: {
    type: ['refill'],
    minDate: moment().subtract(6, 'days').startOf('day').toISOString(),
    maxDate: moment().endOf('day').toISOString()
  },
  sort: {
    field: 'date',
    direction: 'ASC'
  },
  pagination: {
    page: 0,
    perPage: 30000
  }
};

const FILTERS = [
  {
    key: 'total_consumption',
    translationSlug: 'website.equipment.detail.charts.energy.total_consumption',
    checked: true,
    filters: []
  },
  {
    key: 'cooling_capacity',
    translationSlug: 'website.equipment.detail.charts.energy.cooling_capacity',
    checked: false,
    filters: [
      {
        key: 'cooling_capacity_calculated',
        type: CHART_SERIES_TYPE.Y,
        translationSlug: 'website.equipment.detail.charts.energy.cooling_capacity_calculated',
        valueSuffix: 'kW/h',
        checked: false,
        showInChart: true,
        colorIndex: 0,
        dataType: [CHART_DATA_TYPE.INST, CHART_DATA_TYPE.AVG],
        tooltipSeries: null as any
      },
      {
        key: 'cooling_capacity_measured',
        type: CHART_SERIES_TYPE.Y,
        translationSlug: 'website.equipment.detail.charts.energy.cooling_capacity_measured',
        valueSuffix: 'kW/h',
        checked: false,
        showInChart: true,
        colorIndex: 1,
        dataType: [CHART_DATA_TYPE.INST, CHART_DATA_TYPE.AVG],
        tooltipSeries: null
      }
    ]
  },
  {
    key: 'cop',
    translationSlug: 'website.equipment.detail.charts.energy.cop',
    checked: false,
    filters: [
      {
        key: 'compressor_measured_cop',
        type: CHART_SERIES_TYPE.Y,
        translationSlug: 'website.equipment.detail.charts.energy.compressor_measured_cop',
        valueSuffix: '',
        checked: false,
        showInChart: true,
        colorIndex: 2,
        dataType: [CHART_DATA_TYPE.AVG],
        tooltipSeries: null
      },
      {
        key: 'compressor_condensor_measured_cop',
        type: CHART_SERIES_TYPE.Y,
        translationSlug: 'website.equipment.detail.charts.energy.compressor_condensor_measured_cop',
        valueSuffix: '',
        checked: false,
        showInChart: true,
        colorIndex: 3,
        dataType: [CHART_DATA_TYPE.INST, CHART_DATA_TYPE.AVG],
        tooltipSeries: null
      },
      {
        key: 'compressor_condensor_pumps_measured_cop',
        type: CHART_SERIES_TYPE.Y,
        translationSlug: 'website.equipment.detail.charts.energy.compressor_condensor_pumps_measured_cop',
        valueSuffix: '',
        checked: false,
        showInChart: true,
        colorIndex: 4,
        dataType: [CHART_DATA_TYPE.INST, CHART_DATA_TYPE.AVG],
        tooltipSeries: null
      },
      {
        key: 'compressor_calculated_cop',
        type: CHART_SERIES_TYPE.Y,
        translationSlug: 'website.equipment.detail.charts.energy.compressor_calculated_cop',
        valueSuffix: '',
        checked: false,
        showInChart: true,
        colorIndex: 5,
        dataType: [CHART_DATA_TYPE.INST, CHART_DATA_TYPE.AVG],
        tooltipSeries: null
      },
      {
        key: 'compressor_condensor_calculated_cop',
        type: CHART_SERIES_TYPE.Y,
        translationSlug: 'website.equipment.detail.charts.energy.compressor_condensor_calculated_cop',
        valueSuffix: '',
        checked: false,
        showInChart: true,
        colorIndex: 6,
        dataType: [CHART_DATA_TYPE.INST, CHART_DATA_TYPE.AVG],
        tooltipSeries: null
      },
      {
        key: 'compressor_condensor_pumps_calculated_cop',
        type: CHART_SERIES_TYPE.Y,
        translationSlug: 'website.equipment.detail.charts.energy.compressor_condensor_pumps_calculated_cop',
        valueSuffix: '',
        checked: false,
        showInChart: true,
        colorIndex: 7,
        dataType: [CHART_DATA_TYPE.INST, CHART_DATA_TYPE.AVG],
        tooltipSeries: null
      }
    ]
  },
  {
    key: 'mass_flow',
    translationSlug: 'website.equipment.detail.charts.energy.debit',
    checked: false,
    filters: [
      {
        key: 'mass_flow_calculated',
        type: CHART_SERIES_TYPE.Y,
        translationSlug: 'website.equipment.detail.charts.energy.mass_flow_calculated',
        valueSuffix: 'kg/s',
        checked: false,
        showInChart: true,
        colorIndex: 8,
        dataType: [CHART_DATA_TYPE.INST, CHART_DATA_TYPE.AVG],
        tooltipSeries: null
      }
    ]
  },
  {
    key: 'other',
    translationSlug: 'website.equipment.detail.charts.energy.other',
    checked: false,
    filters: [
      {
        key: 'condensor_evacuated_power',
        type: CHART_SERIES_TYPE.Y,
        translationSlug: 'website.equipment.detail.charts.energy.condensor_evacuated_power',
        valueSuffix: '',
        checked: false,
        showInChart: true,
        colorIndex: 9,
        dataType: [CHART_DATA_TYPE.INST],
        tooltipSeries: null
      }
    ]
  },
  {
    key: 'events',
    translationSlug: 'website.equipment.detail.charts.events',
    checked: true,
    filters: [
      {
        key: 'alarms',
        type: CHART_SERIES_TYPE.ALARMS,
        translationSlug: 'website.equipment.detail.charts.alarms',
        valueSuffix: '',
        checked: true,
        showInChart: true,
        colorIndex: 10,
        dataType: [CHART_DATA_TYPE.INST, CHART_DATA_TYPE.AVG],
        tooltipSeries: null
      },
      {
        key: 'refills',
        type: CHART_SERIES_TYPE.REFILLS,
        translationSlug: 'website.equipment.detail.charts.refills',
        valueSuffix: '',
        checked: false,
        showInChart: true,
        colorIndex: 11,
        dataType: [CHART_DATA_TYPE.INST, CHART_DATA_TYPE.AVG],
        tooltipSeries: null
      }
    ]
  }
];

@UntilDestroy()
@Component({
  selector: 'app-energy-charts',
  templateUrl: './energy-charts.component.html',
  styleUrls: ['./energy-charts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnergyChartsComponent implements OnInit ,AfterViewInit{
  public chartIsLoading = true;
  public filtersFormGroup = new FormGroup({});

  public crtLang = '';
  public trInstant: (key: string | Array<string>, interpolateParams?: Object) => string | any;
  public Highcharts: typeof Highcharts = Highcharts;
  public chartOptions: Highcharts.Options;
  public chartDataType = CHART_DATA_TYPE;
  public activeTab = CHART_DATA_TYPE.AVG;
  public filterCategories: any[] = [];

  private instCriteria: InstChartDataQueryCriteria;
  private avgCriteria: AvgChartDataQueryCriteria;
  private alarmsInstCriteria: AlarmsListSearchCriteria;
  private alarmsAvgCriteria: AlarmsListSearchCriteria;
  private historyInstCriteria: HistoryListSearchCriteria;
  private historyAvgCriteria: HistoryListSearchCriteria;

  private chartSeries: any[] = [];
  private minChartValue: number = 0;
  private chartHasError: boolean;
  public readyToRender = false;

  @Input() public equipmentId: string;
  @Input() public equipmentEngines: any[];
  @ViewChild('graphCard') graphCard: ElementRef<HTMLInputElement>;
  newFilters: any[];
  newAvgCriterias: any;
  newInstCriterias: any;
  private instDatePickerMinDate: Date;
  public instDatePickerFilter = (date: Date | null): boolean => {
    if (!date) {
      return true;
    }
    if (moment(date).isBefore(this.instDatePickerMinDate)) {
      return false;
    }
    return true;
  };

  constructor(
    private languageService: LanguageService,
    private translateService: TranslateService,
    private equipmentChartsService: EquipmentChartsService,
    private equipmentDetailsService: EquipmentDetailsService,
    private historyService: HistoryService,
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this.refreshData();

    this.readyToRender = true;
    }

  public async ngOnInit(): Promise<void> {
    const NEW_FILTERS = [...FILTERS];
    NEW_FILTERS[0].filters = [];
    const NEW_AVG_QUERY_CRITERIA = {...AVG_QUERY_CRITERIA};
    const NEW_INST_QUERY_CRITERIA = {...INST_QUERY_CRITERIA};
    let equipLength = this.equipmentEngines.length;
    this.equipmentEngines?.forEach((engine, index) => {
      const newPowerFilter = {
        key: 'energy.power.' + engine.id,
        type: CHART_SERIES_TYPE.Y,
        translationSlug: engine.name,
        valueSuffix: 'kW/h',
        checked: true,
        showInChart: true,
        colorIndex: index,
        dataType: [CHART_DATA_TYPE.INST, CHART_DATA_TYPE.AVG],
        tooltipSeries: null as any
      };
      const newIntensityFilter = {
        key: 'energy.intensity.' + engine.id,
        type: CHART_SERIES_TYPE.Y,
        translationSlug: 'Intensity ' + engine.name,
        valueSuffix: 'kW/h',
        checked: true,
        showInChart: true,
        colorIndex: ((index + equipLength)),
        dataType: [CHART_DATA_TYPE.INST],
        tooltipSeries: null as any
      };
      const newCosPhiFilter = {
        key: 'energy.cos_phi.' + engine.id,
        type: CHART_SERIES_TYPE.Y,
        translationSlug: 'Cos Phi ' + engine.name,
        valueSuffix: 'kW/h',
        checked: true,
        showInChart: true,
        colorIndex: ((index + (equipLength * 2))),
        dataType: [CHART_DATA_TYPE.INST],
        tooltipSeries: null as any
      };
      let indexOfTotalConsumption = 0;
      NEW_FILTERS.forEach((filter, index) => {
        if (filter.key === 'total_consumption') {
          indexOfTotalConsumption = index;
        }
      });
      const energyColumn = 'energy.power.' + engine.id;
      const intensityColumn = 'energy.intensity.' + engine.id;
      const cosPhiColumn = 'energy.cos_phi.' + engine.id;
      NEW_AVG_QUERY_CRITERIA.columns.push(energyColumn);
      NEW_INST_QUERY_CRITERIA.columns.push(energyColumn);
      NEW_INST_QUERY_CRITERIA.columns.push(intensityColumn);
      NEW_INST_QUERY_CRITERIA.columns.push(cosPhiColumn);
      NEW_FILTERS[indexOfTotalConsumption].filters.push(newPowerFilter);
      NEW_FILTERS[indexOfTotalConsumption].filters.push(newIntensityFilter);
      NEW_FILTERS[indexOfTotalConsumption].filters.push(newCosPhiFilter);
    });
    this.newFilters = NEW_FILTERS;
    this.newAvgCriterias = NEW_AVG_QUERY_CRITERIA;
    this.newInstCriterias = NEW_INST_QUERY_CRITERIA;
    this.crtLang = this.languageService.currentLanguage;
    this.trInstant = this.translateService.instant;
    this.trInstant = this.trInstant.bind(this.translateService);

    this.instDatePickerMinDate = moment(new Date()).subtract(3, 'months').toDate();

    this.instCriteria = JSON.parse(JSON.stringify(this.newInstCriterias));
    this.avgCriteria = JSON.parse(JSON.stringify(this.newAvgCriterias));
    this.alarmsInstCriteria = JSON.parse(JSON.stringify(ALARMS_INST_QUERY_CRITERIA));
    this.alarmsAvgCriteria = JSON.parse(JSON.stringify(ALARMS_AVG_QUERY_CRITERIA));
    this.historyInstCriteria = JSON.parse(JSON.stringify(HISTORY_INST_QUERY_CRITERIA));
    this.historyAvgCriteria = JSON.parse(JSON.stringify(HISTORY_AVG_QUERY_CRITERIA));

    this.filterCategories = JSON.parse(JSON.stringify(this.newFilters));
    this.chartIsLoading = true;

    this.activeTab = CHART_DATA_TYPE.AVG;
    this.buildDateFilters();
    this.buildChartFilters();
    this.resetChartFilters();

    this.toggleAllFilterFormControlsState(true);
    const chartData = await this.loadChartData(this.activeTab);
    const alarmsData = await this.loadAlarmsData(CHART_DATA_TYPE.AVG);
    const refillsData = await this.loadHistoryData(CHART_DATA_TYPE.AVG);
    this.chartIsLoading = false;
    this.toggleAllFilterFormControlsState(false);
    this.buildChart(chartData, alarmsData, refillsData);

    this.filtersFormGroup.valueChanges.pipe(untilDestroyed(this), debounceTime(50)).subscribe(async (data) => {
      this.chartIsLoading = true;
      this.toggleAllFilterFormControlsState(true);
      const chartData = await this.loadChartData(this.activeTab);
      const alarmsData = await this.loadAlarmsData(this.activeTab);
      const refillsData = await this.loadHistoryData(this.activeTab);
      this.chartIsLoading = false;
      const categoryFilters = this.getUpdatedFilters(data);
      categoryFilters.forEach((c: any, index: number) => {
        if (this.filterCategories[0].filters[index]) {
          this.filterCategories[0].filters[index].checked = data[c];
        }
      });
      this.toggleAllFilterFormControlsState(false);
      this.buildChart(chartData, alarmsData, refillsData);
    });

  }

  getUpdatedFilters(data: any): any {
    const dataToArray = Object.keys(data);
    const enginePrefixes = INST_QUERY_CRITERIA.columns;
    const engineFilters: any[] = [];
    dataToArray.forEach(d => {
      const foundPrefix = enginePrefixes.filter(e => e === d);
      if (foundPrefix.length > 0) {
        engineFilters.push(foundPrefix[0]);
      }
    });
    return engineFilters;
  }
  buildChartFiltersWithExistingData(): any {
    const totalConsumptionFilters = this.filterCategories[0].filters;
    const maxEngineNumber = this.equipmentEngines.length;
    const energyEnginesId: any = this.equipmentEngines.map(engines => engines.id);
    let newTotalConsumptionFilters: any[] = [];
    let index = 0;
    totalConsumptionFilters.every((filter: any, i: number) => {
      if ((i + 1) % 3 === 0) {
        index++;
      }
      newTotalConsumptionFilters.push(filter);
      return index !== maxEngineNumber;
    });
    index = 0;
    newTotalConsumptionFilters = newTotalConsumptionFilters.map((filter: any, i: number) => {
      const newFilter = { ...filter };
      if (filter.key === 'energy_power_' + index) {
        newFilter.translationSlug = 'website.equipment.detail.charts.energy.' + energyEnginesId[index];
      } else if (filter.key === 'energy_intensity_' + index) {
        newFilter.translationSlug = 'website.equipment.detail.charts.energy.energy_intensity_' + energyEnginesId[index];
      } else if (filter.key === 'energy_cos_phi_' + index) {
        newFilter.translationSlug = 'website.equipment.detail.charts.energy.energy_cos_phi_' + energyEnginesId[index];
      }
      if ((i + 1) % 3 === 0) {
        index++;
      }
      return newFilter;
    });
    return newTotalConsumptionFilters;
  }

  private async loadChartData(dataType: CHART_DATA_TYPE): Promise<PagedResult<InstChartData | AvgChartData>> {
    let chartResult;

    if (dataType === CHART_DATA_TYPE.INST) {
      chartResult = await this.loadInstChartData();
    } else if (dataType == CHART_DATA_TYPE.AVG) {
      chartResult = await this.loadAvgChartData();
    }
    return chartResult;
  }

  private async loadAlarmsData(dataType: CHART_DATA_TYPE): Promise<PagedResult<any>> {
    let alarmsResult;

    if (dataType === CHART_DATA_TYPE.INST) {
      alarmsResult = await this.loadAlarmsInstData();
    } else if (dataType == CHART_DATA_TYPE.AVG) {
      alarmsResult = await this.loadAlarmsAvgData();
    }

    return alarmsResult;
  }

  refreshData() {
    let event = { index: 0 };

    const chart = Highcharts.charts.filter(Boolean)[0];

    let series = chart?.series;
    let desabled_series = [];
    for (let index = 0; index < series?.length; index++) {
      if(!series[index].visible) desabled_series.push(series[index].name);
    }

    this.selectedTabChange(event, desabled_series);
  }

  public async selectedTabChange(event: any, desabled_series?: any) {
    this.chartIsLoading = true;
    this.activeTab = event.index;
    this.buildCriteriaColumnsToQuery();
    const chartData = await this.loadChartData(this.activeTab);
    const alarmsData = await this.loadAlarmsData(this.activeTab);
    const refillsData = await this.loadHistoryData(this.activeTab);
    this.chartIsLoading = false;
    this.buildChart(chartData, alarmsData, refillsData, desabled_series);
  }

  private buildChart(chartData: any, alarmsData: any, refillsData: any, desabled_series?: any) {
    this.buildChartSeries(chartData, alarmsData, refillsData);
    this.buildChartOptions();
    window.setTimeout(() => {
      this.cdr.detectChanges();
      const chart = Highcharts.charts.filter(Boolean)[0];

      if(desabled_series) {
        console.log(desabled_series);
        let series: any = chart?.series;
        series.map((item:any) => {
          if( desabled_series.includes(item.name)) item.hide();
        })
      }
      chart.reflow();
    }, 0);
  }

  private async loadHistoryData(dataType: CHART_DATA_TYPE): Promise<PagedResult<History>> {
    let historyResult;

    if (dataType === CHART_DATA_TYPE.INST) {
      historyResult = await this.loadHistoryInstData();
    } else if (dataType == CHART_DATA_TYPE.AVG) {
      historyResult = await this.loadHistoryAvgData();
    }

    return historyResult;
  }

  public async loadInstChartData(): Promise<PagedResult<InstChartData>> {
    this.chartHasError = false;
    try {
      const instChartResult = await this.equipmentChartsService.getEnergyInstChartData(this.equipmentId, this.equipmentEngines, this.instCriteria);
      return instChartResult;
    } catch (error) {
      this.chartHasError = true;
      return { itemsPage: [] } as PagedResult<InstChartData>;
    }
  }

  private async loadAvgChartData(): Promise<PagedResult<AvgChartData>> {
    this.chartHasError = false;
    try {
      const avgChartResult = await this.equipmentChartsService.getEnergyAvgChartData(this.equipmentId, this.equipmentEngines, this.avgCriteria);
      return avgChartResult;
    } catch (error) {
      this.chartHasError = true;
      return { itemsPage: [] } as PagedResult<AvgChartData>;
    }
  }

  private async loadAlarmsInstData(): Promise<PagedResult<any>> {
    const alarmsResult = await this.equipmentDetailsService.getEquipmentActiveAlarms(this.equipmentId, this.alarmsInstCriteria);
    return alarmsResult;
  }

  private async loadAlarmsAvgData(): Promise<PagedResult<any>> {
    const alarmsResult = await this.equipmentDetailsService.getEquipmentActiveAlarms(this.equipmentId, this.alarmsAvgCriteria);
    return alarmsResult;
  }

  private async loadHistoryInstData(): Promise<PagedResult<History>> {
    const historyResult = await this.historyService.getHistoryForEquipment(this.equipmentId, this.historyInstCriteria);
    return historyResult;
  }

  private async loadHistoryAvgData(): Promise<PagedResult<History>> {
    const historyResult = await this.historyService.getHistoryForEquipment(this.equipmentId, this.historyAvgCriteria);
    return historyResult;
  }

  private buildDateFilters(): void {
    const instDateFilterFormControl = new FormControl(moment().toDate());
    this.filtersFormGroup.addControl('instDateFilter', instDateFilterFormControl);

    const avgStartDateFilterFormControl = new FormControl(moment().subtract(6, 'days').toDate());
    this.filtersFormGroup.addControl('avgStartDateFilter', avgStartDateFilterFormControl);

    const avgEndDateFilterFormControl = new FormControl(moment().toDate());
    this.filtersFormGroup.addControl('avgEndDateFilter', avgEndDateFilterFormControl);

    instDateFilterFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.instCriteria.filters.minDate = moment(this.filtersFormGroup.get('instDateFilter').value).startOf('day').toISOString();
      this.instCriteria.filters.maxDate = moment(this.filtersFormGroup.get('instDateFilter').value).endOf('day').toISOString();

      this.alarmsInstCriteria.filters.minDate = moment(this.filtersFormGroup.get('instDateFilter').value).startOf('day').toISOString();
      this.alarmsInstCriteria.filters.maxDate = moment(this.filtersFormGroup.get('instDateFilter').value).endOf('day').toISOString();

      this.historyInstCriteria.filters.minDate = moment(this.filtersFormGroup.get('instDateFilter').value).startOf('day').toISOString();
      this.historyInstCriteria.filters.maxDate = moment(this.filtersFormGroup.get('instDateFilter').value).endOf('day').toISOString();
    });

    avgStartDateFilterFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.avgCriteria.filters.minDate = moment(this.filtersFormGroup.get('avgStartDateFilter').value).startOf('day').toISOString();
      this.alarmsAvgCriteria.filters.minDate = moment(this.filtersFormGroup.get('avgStartDateFilter').value).startOf('day').toISOString();
      this.historyAvgCriteria.filters.minDate = moment(this.filtersFormGroup.get('avgStartDateFilter').value).startOf('day').toISOString();
    });

    avgEndDateFilterFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.avgCriteria.filters.maxDate = moment(this.filtersFormGroup.get('avgEndDateFilter').value).endOf('day').toISOString();
      this.alarmsAvgCriteria.filters.maxDate = moment(this.filtersFormGroup.get('avgEndDateFilter').value).endOf('day').toISOString();
      this.historyAvgCriteria.filters.maxDate = moment(this.filtersFormGroup.get('avgEndDateFilter').value).endOf('day').toISOString();
    });
  }

  private resetDateFilters(): void {
    // @ts-ignore
    this.filtersFormGroup.get('instDateFilter').setValue(moment().startOf('day').toDate());
    // @ts-ignore
    this.filtersFormGroup.get('avgStartDateFilter').setValue(moment().subtract(6, 'days').startOf('day').toDate());
    // @ts-ignore
    this.filtersFormGroup.get('avgEndDateFilter').setValue(moment().subtract(6, 'days').endOf('day').toDate());
  }

  private resetChartFilters(): void {
    const filtersCopy = JSON.parse(JSON.stringify(this.newFilters));
    for (let categoryIndex = 0; categoryIndex < this.filterCategories.length; categoryIndex++) {
      this.filterCategories[categoryIndex].checked = filtersCopy[categoryIndex].checked;
      this.filtersFormGroup.controls[this.filterCategories[categoryIndex].key].setValue(this.filterCategories[categoryIndex].checked);
      for (let filterIndex = 0; filterIndex < this.filterCategories[categoryIndex].filters.length; filterIndex++) {
        this.filterCategories[categoryIndex].filters[filterIndex].checked = filtersCopy[categoryIndex].filters[filterIndex].checked;
        this.filtersFormGroup.controls[this.filterCategories[categoryIndex].filters[filterIndex].key]
          .setValue(this.filterCategories[categoryIndex].filters[filterIndex].checked);
      }
    }
  }

  private async buildChartFilters() {
    for (const filterCategory of this.filterCategories) {
      const categoryFilterFormControl = new FormControl();
      this.filtersFormGroup.addControl(filterCategory.key, categoryFilterFormControl);

      categoryFilterFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe(categoryFilterChecked => {
        filterCategory.checked = categoryFilterChecked;
      });

      for (const filterItem of filterCategory.filters) {
        const filterFormControl = new FormControl();
        this.filtersFormGroup.addControl(filterItem.key, filterFormControl);

        filterFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe(filterChecked => {
          filterItem.checked = filterChecked;
          this.buildCriteriaColumnsToQuery();
        });
      }
      categoryFilterFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe(categoryFilterChecked => {
        for (const filter of filterCategory.filters) {
          this.filtersFormGroup.controls[filter.key].setValue(categoryFilterChecked);
        }
      });
    }
  }

  private buildSeriesObject(filterItem: any, chartData: PagedResult<InstChartData | AvgChartData>) {
    const seriesObject = {
      id: filterItem.key,
      color: filterItem.showInChart && filterItem.dataType.includes(this.activeTab) ? COLORS[filterItem.colorIndex] : 'transparent',
      borderColor: filterItem.showInChart && filterItem.dataType.includes(this.activeTab) ? COLORS[filterItem.colorIndex] : 'transparent',
      showInLegend: filterItem.showInChart && filterItem.dataType.includes(this.activeTab) ? undefined : false,
      name: this.trInstant(filterItem.translationSlug),
      data: this.buildEnergySeriesPointValues(chartData, filterItem.key),
      tooltip: {
        valueSuffix: filterItem.valueSuffix
      },
      boostThreshold: 1,
      yAxis: 0
    };
    return seriesObject;
  }

  private buildEnergySeriesPointValues(chartData: PagedResult<InstChartData | AvgChartData>, filterKey: string): any[] {
    let pointValues: any[];
    if (!filterKey.includes('energy.power.') && !filterKey.includes('energy.intensity.') && !filterKey.includes('energy.cos_phi.')) {
      pointValues = chartData.itemsPage.map((item: any) => [new Date(item.date_time).getTime(), item[filterKey]]);
    } else {
      this.equipmentEngines?.forEach(engine => {
        const items = chartData.itemsPage.map((item: any) => {
          const itemEnergy = item.energy?.find((element: any) => element.engine_id === engine.id);
          return [item, itemEnergy];
        });

        if (filterKey === 'energy.power.' + engine.id) {
          pointValues = items.map((item: any) => [new Date(item[0]?.date_time).getTime(),
            this.activeTab === CHART_DATA_TYPE.AVG ? (item[1] ? item[1].energy : 0) : (item[1] ? item[1].power : 0)]);
        } else if (filterKey === 'energy.intensity.' + engine.id) {
          pointValues = items.map((item: any) => [new Date(item[0]?.date_time).getTime(), (item[1] ? item[1].intensity : 0)]);
        } else if (filterKey === 'energy.cos_phi.' + engine.id) {
          pointValues = items.map((item: any) => [new Date(item[0]?.date_time).getTime(), (item[1] ? item[1].cos_phi : 0)]);
        }
      });
    }
    return pointValues;
  }

  private buildAlarmsSeriesObject(filterItem: any, alarmsData: PagedResult<any>): object {
    const seriesObject = {
      type: 'scatter',
      id: filterItem.key,
      showInLegend: true,
      name: this.trInstant(filterItem.translationSlug),
      data: alarmsData.itemsPage.map(item => [new Date(item.date).getTime(), this.minChartValue]),
      marker: {
        enabled: true,
        symbol: 'circle',
        fillColor: 'red',
        radius: 10
      },
      tooltip: {
        shared: false
      },
      boostThreshold: 1,
      yAxis: 1
    };

    return seriesObject;
  }

  private buildRefillsSeriesObject(filterItem: any, refillsData: PagedResult<any>): object {
    const seriesObject = {
      type: 'scatter',
      id: filterItem.key,
      showInLegend: true,
      name: this.trInstant(filterItem.translationSlug),
      data: refillsData.itemsPage.map(item => [new Date(item.date).getTime(), this.minChartValue]),
      marker: {
        enabled: true,
        symbol: 'circle',
        fillColor: 'green',
        radius: 10
      },
      tooltip: {
        shared: false
      },
      boostThreshold: 1,
      yAxis: 1
    };

    return seriesObject;
  }

  private buildChartSeries(
    chartData: PagedResult<InstChartData | AvgChartData>,
    alarmsData: PagedResult<any>,
    refillsData: PagedResult<any>
  ) {
    this.chartSeries = [];
    for (const filterCategory of this.filterCategories) {
      for (const filterItem of filterCategory.filters) {
        if (!filterItem.checked) {
          continue;
        }
        if (filterItem.dataType.includes(this.activeTab) && filterItem.type === CHART_SERIES_TYPE.Y) {
          const series = this.buildSeriesObject(filterItem, chartData);
          this.chartSeries.push(series);
        } else if (filterItem.dataType.includes(this.activeTab) && filterItem.type === CHART_SERIES_TYPE.ALARMS) {
          const series = this.buildAlarmsSeriesObject(filterItem, alarmsData);
          this.chartSeries.push(series);
        } else if (filterItem.dataType.includes(this.activeTab) && filterItem.type === CHART_SERIES_TYPE.REFILLS) {
          const series = this.buildRefillsSeriesObject(filterItem, refillsData);
          this.chartSeries.push(series);
        }

        if (filterItem.tooltipSeries?.length) {
          for (const tooltipSeries of filterItem.tooltipSeries) {
            if (tooltipSeries.dataType.includes(this.activeTab)) {
              const series = this.buildSeriesObject(tooltipSeries, chartData);
              this.chartSeries.push(series);
            }
          }
        }
      }
    }
  }

  private buildChartOptions(): void {
    const ctx = this;
    this.chartOptions = {
      chart: {
        zoomType: 'xy',
        marginTop: 100,
        height: 600
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: ['viewFullscreen'],
            symbol: 'url(../../../../../../assets/images/open_in_full.png)',
            symbolSize: 20,
            symbolX: 23,
            symbolY: 23
          }
        }
      },
      colors: COLORS,
      time: {
        timezone: moment.tz.guess()
      },
      title: {
        text: ''
      },
      xAxis: [
        {
          type: 'datetime'
        }
      ],
      yAxis: [
        {
          title: {
            text: this.trInstant('website.equipment.detail.charts.values')
          },
          events: {
            afterSetExtremes: event => {
              this.minChartValue = event.min;
            }
          }
        },
        {
          title: {
            text: ''
          },
          labels: {
            enabled: false
          }
        }
      ],
      tooltip: {
        formatter: function () {
          if (this.points) {
            return this.points.reduce((previous, current) => {
              return `${previous}<br/>${current.series.name}: ${current.y}${current.series.options.tooltip.valueSuffix}`;
            }, `<i>${ctx.trInstant('website.equipment.detail.charts.drag_to_zoom')}</i><br/><br/><b>${moment(this.x).format('DD/MM/YYYY HH:mm:ss')}</b>`);
          } else {
            return `<b>${moment(this.x).format('DD/MM/YYYY HH:mm:ss')}</b>`;
          }
        },
        style: {
          color: '#66788a',
          fontWeight: 'normal',
          fontSize: '12px',
          letterSpacing: '-0.04px',
          lineHeight: '20px',
          fontFamily: 'Ubuntu'
        },
        shared: true,
        borderWidth: 0,
        backgroundColor: '#fff',
        borderRadius: 8,
        padding: 15
      },
      legend: {
        layout: 'horizontal',
        align: 'center',
        itemMarginBottom: 3,
        verticalAlign: 'top',
        floating: true,
        backgroundColor: Highcharts.defaultOptions.legend.backgroundColor
      },
      series: this.chartSeries,
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 700
            },
            chartOptions: {
              legend: {
                enabled: false
              },
              yAxis: [
                {
                  labels: {
                    align: 'right',
                    x: 0,
                    y: -6
                  },
                  showLastLabel: true
                },
                {
                  labels: {
                    align: 'left',
                    x: 0,
                    y: -6
                  },
                  showLastLabel: true
                },
                {
                  visible: false
                }
              ]
            }
          }
        ]
      },
      credits: {
        enabled: false
      },
      lang: {
        noData: this.chartHasError
          ? this.trInstant('website.equipment.detail.charts.error')
          : this.trInstant('website.equipment.detail.charts.no_data')
      }
    };
  }

  private buildCriteriaColumnsToQuery(): void {
    this.instCriteria.columns = this.avgCriteria.columns = this.filterCategories
      .map(filterCategory => filterCategory.filters)
      .flat()
      .filter(
        filterItem =>
          filterItem.checked &&
          filterItem.type === CHART_SERIES_TYPE.Y &&
          filterItem.checked &&
          filterItem.dataType.includes(this.activeTab)
      )
      .map(filterItem => filterItem.key);

    const tooltipSeriesColumns = this.filterCategories
      .map(filterCategory => filterCategory.filters)
      .flat()
      .filter(
        filterItem =>
          filterItem.checked &&
          filterItem.type === CHART_SERIES_TYPE.Y &&
          filterItem.checked &&
          filterItem.dataType.includes(this.activeTab) &&
          filterItem.tooltipSeries !== null
      )
      .map(filterItem => filterItem.tooltipSeries)
      .flat()
      .filter(tooltipSeries => tooltipSeries.dataType.includes(this.activeTab))
      .map(tooltipSeries => tooltipSeries.key);

    this.instCriteria.columns = [...this.instCriteria.columns, ...tooltipSeriesColumns];
    this.avgCriteria.columns = [...this.avgCriteria.columns, ...tooltipSeriesColumns];
  }

  private toggleAllFilterFormControlsState(chartIsLoading: boolean) {
    for (const formControlKey in this.filtersFormGroup.controls) {
      const formControl = this.filtersFormGroup.controls[formControlKey];
      chartIsLoading ? formControl.disable({ emitEvent: false }) : formControl.enable({ emitEvent: false });
    }
  }



  public getCheckedFiltersInCategoryCount(filterCategory: any) {
    return filterCategory.filters.filter((filterItem: any) => filterItem.checked === true && filterItem.dataType.includes(this.activeTab))
      .length;
  }

  public getAvailableFiltersInCategoryCount(filterCategory: any) {
    return filterCategory.filters.filter((filterItem: any) => filterItem.dataType.includes(this.activeTab)).length;
  }

  public async resetFilters() {
    window.scroll(0, 0);
    this.activeTab = CHART_DATA_TYPE.AVG;
    this.chartIsLoading = true;

    this.filtersFormGroup.reset();
    this.resetDateFilters();
    this.resetChartFilters();

    this.buildCriteriaColumnsToQuery();
    this.toggleAllFilterFormControlsState(true);
    const chartData = await this.loadChartData(CHART_DATA_TYPE.AVG);
    const alarmsData = await this.loadAlarmsData(CHART_DATA_TYPE.AVG);
    const refillsData = await this.loadHistoryData(CHART_DATA_TYPE.AVG);
    this.chartIsLoading = false;
    this.toggleAllFilterFormControlsState(false);
    this.buildChart(chartData, alarmsData, refillsData);
  }
}
