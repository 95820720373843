
<main>
  <app-sidebar></app-sidebar>
  <div class='main-content'>
  <router-outlet></router-outlet>
</div>
</main>






