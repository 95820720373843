import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit, OnChanges {
  @Input() public elements: any[] | undefined;
  @Input() public dropDownElements: any[] | undefined;
  @Input() public selectedValue: string | number | undefined;
  @Input() public classList = '';
  @Output() dropdownChange = new EventEmitter<number>();
  public pageTitle = '';

  constructor(private titleService: Title) {}

  ngOnInit(): void {
    this.elements.forEach((e, index) => {
      if (this.elements[index + 1]) {
        this.pageTitle = this.pageTitle.concat(e.displayValue).concat(' - ');
      }
      else {
        this.pageTitle = this.pageTitle.concat(e.displayValue);
      }
    });
    this.titleService.setTitle(this.pageTitle);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dropDownElements?.currentValue) {
      changes.dropDownElements.currentValue.forEach((v: any) => {
        if (v.value === this.selectedValue) {
          this.pageTitle = this.pageTitle.concat(' - ').concat(v.value).concat(' - ').concat(v.displayValue);
          this.titleService.setTitle(this.pageTitle);
        }
      });
    }
  }

  public emitDropDownValue(event: MatSelectChange): void {
    this.dropdownChange.emit(event.value);
  }
}
