import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop } from 'rxjs';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchBarComponent),
      multi: true,
    },
  ],
})
export class SearchBarComponent implements  ControlValueAccessor {
  @Input() placeholder = '';
  @Output() onClickSearchEvent = new EventEmitter<{search: string}>();


  private innerValue: any = '';
  private disable = false;


  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  search(): void {
    this.onClickSearchEvent.emit({search: this.value});
  }
  get value(): any {
    return this.innerValue;
  }
  get isDisable(): any {
    return this.disable;
  }

  set value(v: any){
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }


  onBlur(): void {
    this.onTouchedCallback();
  }

  writeValue(value: any): void {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disable = isDisabled;
  }


}
