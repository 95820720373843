import { Injectable } from '@angular/core';
import { GraphqlService } from '@graphql/graphql.service';
import { CustomerLocation } from '@shared/models/customer-location';
import { CustomerChain } from '@shared/models/customer-location-chain';
import { Installer } from '@shared/models/installer';
import { InstallerChain } from '@shared/models/installer-chain';
import { GeneralCompany } from '@shared/services/general-company.interface';
import { PagedResult } from '@shared/services/paged-result';
import { EditUserFromFrontendInput } from '@users/components/user-list/edit-user-from-frontend.input';
import { Role } from '@users/domain/models/role';
import { User } from '@users/domain/models/user';
import { UserRole } from '@users/domain/models/user-role';
import { gql } from 'apollo-angular';
import { filter, map, take } from 'rxjs/operators';
import { CustomerLocationUserListCriteria, UserListCriteria } from './user-list-criteria.interface';


@Injectable({
  providedIn: 'root'
})
export class UserListService {
  constructor(private graphqlService: GraphqlService) {}

  private getPendingApprovalUsersQuery: any = gql`
    query getPendingApprovalUsers(
      $name: String
      $page: Float!
      $perPage: Float!
      $sortField: String
      $sortDir: SortDirection
      $company: [GeneralCompanyInput]
      $role: [String]) {
      getPendingApprovalUsers(
        criteria: {
          name: $name
          page: $page
          perPage: $perPage
          sortField: $sortField
          sortDir: $sortDir
          company: $company
          role: $role
        }
      ) {
        itemsPage {
          _id
          id
          firstName
          lastName
          profileImage
          email
          phone
          createdOn
          lastConnection
          active
          canBeRead
          canBeEdited
          roles {
            _id
            name
            class
            allowedCompanyTypes
            userRole {
              customerChain {
                _id
                name
              }
              customerLocation {
                _id
                name
              }
              installerChain {
                _id
                name
              }
              installer {
                _id
                name
              }
            }
          }
          userRoles {
            _id
            customerLocation {
              name
            }
            customerChain {
              name
            }
            installer {
              name
            }
            installerChain {
              name
            }
            role {
              name
            }
          }
        }
        total
        }
      }
  `;


  private getCustomerLocationUserListQuery: any = gql`
    query getCustomerLocationUserList(
      $name: String
      $customerLocationId: String!
      $page: Float!
      $perPage: Float!
    ) {
      getCustomerLocationUserList(
        criteria: {
          name: $name
          customerLocationId: $customerLocationId
          page: $page
          perPage: $perPage
        }
      ) {
        itemsPage {
          _id
          id
          firstName
          lastName
          profileImage
          email
          phone
          createdOn
          lastConnection
          active
          canBeRead
          canBeEdited
          customerLocationAlarmsDisabled
          useFilteredAlarms
          roles {
            _id
            name
            class
            allowedCompanyTypes
            userRole {
              customerChain {
                _id
                name
              }
              customerLocation {
                _id
                name
              }
              installerChain {
                _id
                name
              }
              installer {
                _id
                name
              }
            }
          }
          userRoles {
            _id
            customerLocation {
              name
            }
            customerChain {
              name
            }
            installer {
              name
            }
            installerChain {
              name
            }
            role {
              name
            }
          }
        }
        total
      }
    }
  `;
  private getUserListQuery: any = gql`
    query getUserList(
      $name: String
      $page: Float!
      $perPage: Float!
      $sortField: String
      $sortDir: SortDirection
      $active: Boolean!
      $company: [GeneralCompanyInput]
      $role: [String]
    ) {
      getUserList(
        criteria: {
          name: $name
          page: $page
          perPage: $perPage
          sortField: $sortField
          sortDir: $sortDir
          active: $active
          company: $company
          role: $role
        }
      ) {
        itemsPage {
          _id
          id
          firstName
          lastName
          profileImage
          email
          phone
          createdOn
          lastConnection
          active
          canBeRead
          canBeEdited
          customerLocationAlarmsDisabled
          useFilteredAlarms
          roles {
            _id
            name
            class
            allowedCompanyTypes
            uniqueBusinessId
            userRole {
              customerChain {
                _id
                name
              }
              customerLocation {
                _id
                name
              }
              installerChain {
                _id
                name
              }
              installer {
                _id
                name
              }
            }
          }
          userRoles {
            _id
            customerLocation {
              name
            }
            customerChain {
              name
            }
            installer {
              name
            }
            installerChain {
              name
            }
            role {
              name
            }
          }
        }
        total
      }
    }
  `;

  public async getPendingApprovalUsers(criteria: UserListCriteria): Promise<PagedResult<User>> {
    const gqlParams: any = {};
    if (criteria.pagination) {
      gqlParams.page = criteria.pagination.page;
      gqlParams.perPage = criteria.pagination.perPage;
    }

    if (criteria?.filters?.name) {
      gqlParams.name = criteria.filters.name;
    }

    if (criteria?.filters?.company && criteria?.filters?.company.length) {
      gqlParams.company = criteria.filters.company;
    }

    if (criteria?.filters?.role && criteria?.filters?.role.length) {
      gqlParams.role = criteria.filters.role;
    }

    if (criteria.sort) {
      gqlParams.sortField = criteria.sort.field;
      gqlParams.sortDir = criteria.sort.direction;
    }

    console.log('CRITERIA getPendingApprovalUsersQuery => ', gqlParams)
    return await this.graphqlService
      .query(this.getPendingApprovalUsersQuery, gqlParams, false)
      .pipe(
        take(1),
        map((result: any) => {
          let res: PagedResult<User> = new PagedResult<User>([], 0);
          res.itemsPage = result?.data?.getPendingApprovalUsers?.itemsPage?.map((item: User) => {
            if (Array.isArray(item.roles)) {
              item.roles = item.roles.map((roleData: any) => {
                if (roleData.userRole) {
                  roleData.userRole = new UserRole(roleData.userRole);
                }
                return new Role(roleData);
              });
            }
            return new User(item);
          });
          res.total = result?.data?.getPendingApprovalUsers?.total;
          return res;
        })
      )
      .toPromise();
  }

  public async getCustomerLocationUserList(criteria: CustomerLocationUserListCriteria): Promise<PagedResult<User>> {
    const gqlParams: any = {};

    if (criteria.pagination) {
      gqlParams.page = criteria.pagination.page;
      gqlParams.perPage = criteria.pagination.perPage;
    }

    if (criteria?.filters?.customerLocationId) {
      gqlParams.customerLocationId = criteria.filters.customerLocationId;
    }
    if (criteria?.filters?.name) {
      gqlParams.name = criteria.filters.name;
    }


    return await this.graphqlService
      .query(this.getCustomerLocationUserListQuery, gqlParams, false)
      .pipe(
        take(1),
        map((result: any) => {
          let res: PagedResult<User> = new PagedResult<User>([], 0);
          res.itemsPage = result?.data?.getCustomerLocationUserList?.itemsPage?.map((item: User) => {
            if (Array.isArray(item.roles)) {
              item.roles = item.roles.map((roleData: any) => {
                if (roleData.userRole) {
                  roleData.userRole = new UserRole(roleData.userRole);
                }
                return new Role(roleData);
              });
            }
            return new User(item);
          });
          res.total = result?.data?.getCustomerLocationUserList?.total;
          return res;
        })
      )
      .toPromise();
  }

  /**
   * @return users that "belong" to one of the same CustomerLocations that the current loggedin user "belongs" to
   */
  public async getUserList(criteria: UserListCriteria): Promise<PagedResult<User>> {
    const gqlParams: any = {};

    if (criteria.pagination) {
      gqlParams.page = criteria.pagination.page;
      gqlParams.perPage = criteria.pagination.perPage;
    }

    if (criteria?.filters?.name) {
      gqlParams.name = criteria.filters.name;
    }

    if (criteria?.filters?.active === false) {
      gqlParams.active = false;
    } else gqlParams.active = true;

    if (criteria?.filters?.company && criteria?.filters?.company.length) {
      gqlParams.company = criteria.filters.company;
    }

    if (criteria?.filters?.role && criteria?.filters?.role.length) {
      gqlParams.role = criteria.filters.role;
    }

    if (criteria.sort) {
      gqlParams.sortField = criteria.sort.field;
      gqlParams.sortDir = criteria.sort.direction;
    }


    return await this.graphqlService
      .query(this.getUserListQuery, gqlParams, false)
      .pipe(
        take(1),
        map((result: any) => {
          let res: PagedResult<User> = new PagedResult<User>([], 0);
          res.itemsPage = result?.data?.getUserList?.itemsPage?.map((item: User) => {
            if (Array.isArray(item.roles)) {
              item.roles = item.roles.map((roleData: any) => {
                if (roleData.userRole) {
                  roleData.userRole = new UserRole(roleData.userRole);
                }
                return new Role(roleData);
              });
            }
            return new User(item);
          });
          res.total = result?.data?.getUserList?.total;
          return res;
        })
      )
      .toPromise();
  }

  private deactivateBulkMutation(): any {
    return gql`
      mutation deactivateBulk($userIds: [String!]!) {
        deactivateBulk(userIds: $userIds)
      }
    `;
  }

  public deactivateBulk(userIds: string[]): Promise<any> {
    return this.graphqlService.mutate(this.deactivateBulkMutation(), { userIds }).toPromise();
  }

  private activateBulkMutation(): any {
    return gql`
      mutation activateBulk($userIds: [String!]!) {
        activateBulk(userIds: $userIds)
      }
    `;
  }

  public activateBulk(userIds: string[]): Promise<any> {
    return this.graphqlService.mutate(this.activateBulkMutation(), { userIds }).toPromise();
  }

  private getUsersFilterCustomerChainsQuery: any = gql`
    query getUsersFilterCustomerChains {
      getUsersFilterCustomerChains(criteria: { page: 1, perPage: 2000 }) {
        itemsPage {
          _id
          name
        }
        total
      }
    }
  `;

  public async getUsersFilterCustomerChains(): Promise<PagedResult<CustomerChain>> {
    return this.graphqlService
      .query(this.getUsersFilterCustomerChainsQuery, {}, false)
      .pipe(
        take(1),
        map((result: any) => {
          const customerChainsResult: PagedResult<CustomerChain> = {
            itemsPage: result.data.getUsersFilterCustomerChains.itemsPage,
            total: result.data.getUsersFilterCustomerChains.total
          };
          return customerChainsResult;
        })
      )
      .toPromise() as Promise<PagedResult<CustomerChain>>;
  }

  private getUsersFilterCustomerLocationsQuery: any = gql`
    query getUsersFilterCustomerLocations {
      getUsersFilterCustomerLocations(criteria: { page: 1, perPage: 2000 }) {
        itemsPage {
          _id
          name
          phone
          email
          country
          postalCode
          city
          streetAndNumber
          latitude
          longitude
        }
        total
      }
    }
  `;

  public async getUsersFilterCustomerLocations(): Promise<PagedResult<CustomerLocation>> {
    return this.graphqlService
      .query(this.getUsersFilterCustomerLocationsQuery, {}, false)
      .pipe(
        take(1),
        map((result: any) => {
          const customerLocationsResult: PagedResult<CustomerLocation> = {
            itemsPage: result.data.getUsersFilterCustomerLocations.itemsPage,
            total: result.data.getUsersFilterCustomerLocations.total
          };
          return customerLocationsResult;
        })
      )
      .toPromise() as Promise<PagedResult<CustomerLocation>>;
  }

  private getUsersFilterInstallersQuery: any = gql`
    query getUsersFilterInstallers {
      getUsersFilterInstallers(criteria: { page: 1, perPage: 2000 }) {
        itemsPage {
          _id
          name
        }
        total
      }
    }
  `;

  public async getUsersFilterInstallers(): Promise<PagedResult<Installer>> {
    return this.graphqlService
      .query(this.getUsersFilterInstallersQuery, {}, false)
      .pipe(
        take(1),
        map((result: any) => {
          const installersResult: PagedResult<Installer> = {
            itemsPage: result.data.getUsersFilterInstallers.itemsPage,
            total: result.data.getUsersFilterInstallers.total
          };
          return installersResult;
        })
      )
      .toPromise() as Promise<PagedResult<Installer>>;
  }

  private getUsersFilterInstallerChainsQuery: any = gql`
    query getUsersFilterInstallerChains {
      getUsersFilterInstallerChains(criteria: { page: 1, perPage: 2000 }) {
        itemsPage {
          _id
          name
        }
        total
      }
    }
  `;

  public async getUsersFilterInstallerChains(): Promise<PagedResult<InstallerChain>> {
    return this.graphqlService
      .query(this.getUsersFilterInstallerChainsQuery, {}, false)
      .pipe(
        take(1),
        map((result: any) => {
          const installerChainsResult: PagedResult<Installer> = {
            itemsPage: result.data.getUsersFilterInstallerChains.itemsPage,
            total: result.data.getUsersFilterInstallerChains.total
          };
          return installerChainsResult;
        })
      )
      .toPromise() as Promise<PagedResult<InstallerChain>>;
  }

  private getUsersFilterRolesQuery: any = gql`
    query getUsersFilterRoles {
      getUsersFilterRoles {
        itemsPage {
          _id
          name
          uniqueBusinessId
          description
          class
          permissions
          allowedCompanyTypes
        }
        total
      }
    }
  `;

  public async getUsersFilterRoles(): Promise<PagedResult<Role>> {
    const resultPromise: Promise<PagedResult<Role>> = this.graphqlService
      .query(this.getUsersFilterRolesQuery, {}, false)
      .pipe(
        take(1),
        filter(result => {
          if (
            !result ||
            !result.data ||
            !result.data.getUsersFilterRoles ||
            !result.data.getUsersFilterRoles.itemsPage ||
            !result.data.getUsersFilterRoles.itemsPage.length ||
            !result.data.getUsersFilterRoles.itemsPage[0]
          ) {
            return false;
          }
          return true;
        }),
        map((result: any) => {
          const roles: Role[] = result.data.getUsersFilterRoles.itemsPage.map((roleData: any) => {
            if (roleData.userRole) {
              roleData.userRole = new UserRole(roleData.userRole);
            }
            return new Role(roleData);
          });

          const rolesResult: PagedResult<Role> = {
            itemsPage: roles,
            total: +result.data.getUsersFilterRoles.total
          };
          return rolesResult;
        })
      )
      .toPromise() as Promise<PagedResult<Role>>;

    let result: PagedResult<Role> = await resultPromise;
    result = result || { itemsPage: [], total: 0 };
    return result;
  }

  public async editUserFromFrontend(userDataToSet: EditUserFromFrontendInput): Promise<User> {
    const savedUserData: any = await this.graphqlService
      .mutate(this.editUserFromFrontendMutation(), { userDataToSet })
      .pipe(
        take(1),
        map((response: any) => {
          console.log('UserListService editUserFromFrontend response', response);
          return response;
        })
      )
      .toPromise();
    return new User(savedUserData as any);
  }

  private editUserFromFrontendMutation(): any {
    return gql`
      mutation editUserFromFrontend($userDataToSet: EditUserFromFrontendInput!) {
        editUserFromFrontend(userDataToSet: $userDataToSet) {
          _id
          firstName
          lastName
          email
          phone
          customerLocationAlarmsDisabled
          useFilteredAlarms
          userRoles {
            _id
            role {
              _id
              name
            }
            customerChain {
              _id
              name
            }
            customerLocation {
              _id
              name
            }
            installerChain {
              _id
              name
            }
            installer {
              _id
              name
            }
          }
        }
      }
    `;
  }

  public async searchCompaniesForEditUser(name: string): Promise<PagedResult<GeneralCompany>> {
    return this.graphqlService
      .query(this.getSearchCompaniesForEditUserQuery(), { name, page: 1, perPage: 40 }, false)
      .pipe(
        take(1),
        map((result: any) => {
          const companyResult: PagedResult<GeneralCompany> = {
            itemsPage: result.data.searchCompaniesForEditUser.itemsPage,
            total: result.data.searchCompaniesForEditUser.total
          };
          return companyResult;
        })
      )
      .toPromise() as Promise<PagedResult<GeneralCompany>>;
  }

  private getSearchCompaniesForEditUserQuery(): any {
    return gql`
      query searchCompaniesForEditUser($name: String!, $page: Float!, $perPage: Float!) {
        searchCompaniesForEditUser(criteria: { name: $name, page: $page, perPage: $perPage }) {
          total
          itemsPage {
            id
            type
            name
          }
        }
      }
    `;
  }
}
