import { Options } from '@angular-slider/ngx-slider';
import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TooltipPosition } from '@angular/material/tooltip';
import { EngineType } from '@equipment/domain/equipment-config/engine_type';
import { EquipmentSensibilityConversionService } from '@equipment/services/equipment-sensibility-conversion.service';
import { EquipmentUtilsService } from '@equipment/services/equipment-utils.service';
import { EquipmentService } from '@equipment/services/equipment.service';
import { FluidService } from '@equipment/services/fluid.service';
import { TranslateService } from '@ngx-translate/core';
import { Fluid } from '@shared/models/fluid';
import { PagedResult } from '@shared/services/paged-result';
import { Permission } from '@users/domain/services/user.enums';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import {
  EngineMode,
  EqConfigEnergyIsConfigured,
  EqConfigInstallationInformationColumnType,
  EqConfigInstallationInformationCompressorType,
  EqConfigInstallationInformationCondeserType,
  EqConfigInstallationInformationTankType,
  EqConfigModulePowerNumberTi,
  EqConfigModulePowerRatio,
  EqConfigModulePowerTiRange,
  EqConfigModulePowerType,
  EqConfigNetworkConnectionType,
  EqConfigNetworkDhcp,
  EquipmentConfig,
  EquipmentConfigGeneralStatistic,
  EquipmentConfigService,
  EquipmentConfigUpdate
} from 'src/app/equipment/domain/equipment-config';
import { EquipmentConfigCreate } from 'src/app/equipment/domain/equipment-config/equipment-config.create';
import { Device } from 'src/app/shared/models/device';

import { UtilsService } from '../../../../shared/services/utils.service';

export interface Item {
  name: string;
}

@Component({
  selector: 'app-equipment-config-modal',
  templateUrl: './equipment-config-modal.component.html',
  styleUrls: ['./equipment-config-modal.component.scss']
})
export class EquipmentConfigModalComponent implements OnInit, OnDestroy {

  // just for test => to be removed later
  engine_nbr = 3;
  enginesPerStage = 1;
  public Permission = Permission;
  public subscriptions: Subscription[] = [];
  sensibilityOptions: Options;
  isChecked = true;
  isloading = true;
  public totalEnergyModules: number;
  positioningStatus: Boolean;
  columnMaterialSelect: any = ['Cuivre', 'Other', 'Scroll'];
  // SLIDERS
  consignesHP: FormControl = new FormControl([15, 50]);
  consignesBP: FormControl = new FormControl([-50, 10]);
  pressureTransmitterHP: FormControl = new FormControl([-1, 200]);
  pressureTransmitterBP: FormControl = new FormControl([-1, 200]);
  public context: 'CREATE' | 'UPDATE';
  public equipmentConfigGeneralStatistic = EquipmentConfigGeneralStatistic;
  // forms
  public form: FormGroup;
  public alarmsForm: FormGroup;
  public configurationForm: FormGroup;
  public measureCardForm: FormGroup;
  public energyModuleForm: FormGroup;
  public energyModuleV2Form: FormGroup;
  // error tooltip messages
  public invalidAlarmDataMessage: string;
  public invalidConfigurationDataMessage: string;
  public invalidMeasureCardDataMessage: string;
  public invalidEnergyModuleForm: string;
  public fluidsResult: PagedResult<Fluid> = { itemsPage: [], total: 0 };
  public filteredFluides: Observable<Fluid[]>;
  public selectedFluid: Fluid = null;
  // enums
  public EqConfigInstallationInformationTankNumber: number[] = [1, 2, 3, 4, 5];
  public EqConfigInstallationInformationTankType = EqConfigInstallationInformationTankType;
  public EqConfigInstallationInformationCompressorType = EqConfigInstallationInformationCompressorType;
  public EqConfigInstallationInformationCondeserType = EqConfigInstallationInformationCondeserType;
  public EqConfigInstallationInformationColumnType = EqConfigInstallationInformationColumnType;
  public EqConfigEnergyIsConfigured = Object.entries(EqConfigEnergyIsConfigured).reduce((acc, curr) => {
    acc[curr[0]] = curr[1];
    return acc;
  }, {} as any);
  public EqConfigModulePowerType = EqConfigModulePowerType;
  public EqConfigModulePowerTiRange = EqConfigModulePowerTiRange;
  public EqConfigModulePowerNumberTi = EqConfigModulePowerNumberTi;
  public EqConfigModulePowerRatio: {
    key: string;
    value: number
  }[] = Object.entries(EqConfigModulePowerRatio).reduce((acc, curr) => {
    typeof curr[1] === 'number' && acc.push({ key: curr[0], value: curr[1] });
    return acc;
  }, []);
  public energyVoltageRecordingValues: number[] = [3, 4, 5, 6, 7, 8, 9, 10];
  public EqConfigNetworkConnectionType = Object.entries(EqConfigNetworkConnectionType).reduce((acc, curr) => {
    acc[curr[0]] = curr[1];
    return acc;
  }, {} as any);
  public EqConfigNetworkDhcp = Object.entries(EqConfigNetworkDhcp).reduce((acc, curr) => {
    acc[curr[0]] = curr[1];
    return acc;
  }, {} as any);
  public EngineMode = EngineMode;
  public EngineType = EngineType;
  public positionOptions: TooltipPosition[] = ['above'];
  public position = new FormControl(this.positionOptions[0]);
  lastSensibility: number = null;
  dni = true; // default
  polarbox = false;
  selectedStage = 0;
  @ViewChild('itemInput') itemInput: ElementRef<HTMLInputElement>;
  step = 0;

  public constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      equipmentId: string;
      measure_device: Device;
      equipment: any;
      energy_devices: any;
      controller_device: any;
      config: EquipmentConfig;
      totalUnsolvedChangeRequests: number;
    },
    public dialogRef: MatDialogRef<EquipmentConfigModalComponent>,
    public equipmentConfigService: EquipmentConfigService,
    public equipmentService: EquipmentService,
    private fluidService: FluidService,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private utilsService: UtilsService,
    private sensibilityConversionService: EquipmentSensibilityConversionService,
    public equipmentUtilsService: EquipmentUtilsService
  ) {
  }

  get stages_form_array(): FormArray {
    return this.energyModuleV2Form.controls.stages_form_array as FormArray;
  }

  get pumpdownStatus(): FormControl {
    return this.alarmsForm.get('pumpdown_status') as FormControl;
  }

  get generalLowlevelLimitPumpdown(): FormControl {
    return this.alarmsForm.get('general_low_level_limit_pumpdown') as FormControl;
  }

  get generalLowLevelDelayPumpdown(): FormControl {
    return this.alarmsForm.get('general_low_level_delay_pumpdown') as FormControl;
  }

  public async ngOnInit(): Promise<void> {
    console.log('data', this.data);
    if (this.data.controller_device?.hardware_version != '1.0.0') {
      this.dni = false;
      this.polarbox = true;
    }
    const lowSensibility = this.translateService.instant('website.equipment.config.alarms.fields.general_statistic_3days_limit.legends.low');
    const highSensibility = this.translateService.instant(
      'website.equipment.config.alarms.fields.general_statistic_3days_limit.legends.high'
    );
    this.sensibilityOptions = {
      floor: 1,
      ceil: 6,
      showTicks: true,
      stepsArray: [
        { value: 1 },
        { value: 2 },
        { value: 3 },
        { value: 4 },
        { value: 5 },
        { value: 6 }
      ]
    };

    this.context = 'CREATE';
    if (this.polarbox) {
      this.engine_nbr = this.equipmentUtilsService.getTotalStages(this.data.equipment.engines);
    }
    this.buildForm();
    this.fluidsResult = await this.fluidService.getFluids();
    this.filteredFluides = this.configurationForm.controls.installation_information_fluid_type.valueChanges.pipe(
      startWith(''),
      map(val => {
        // val can be a string or Fluid type
        const value: string = val?.id || val || this.configurationForm.controls.installation_information_fluid_type.value?.id || '';
        if (value && !this.fluidsResult.itemsPage.find(f => value === f.id)) {
          this.configurationForm.controls.installation_information_fluid_type.setErrors({ perfect_match: true });
        }
        return this._filter(value);
      })
    );
    this.totalEnergyModules = this.data.energy_devices?.length;
    this.pumpdownStatusChangeSubscribe();
  }

  numSequence(n: number): Array<number> {
    return Array(n);
  }

  public close(save: boolean = false): void {
    this.dialogRef.close(save);
  }

  setStep(index: number) {
    this.step = index;
  }

  public async submit(): Promise<void> {
    if (!this.alarmsForm.controls.general_statistic_3days_limit.dirty) {
      this.alarmsForm.get('general_statistic_3days_limit').setValue(this.lastSensibility);
    } else {
      this.alarmsForm
        .get('general_statistic_3days_limit')
        .setValue(
          this.sensibilityConversionService.convertToAPI(
            this.configurationForm.controls.installation_information_lp_consign.value,
            this.alarmsForm.controls.general_statistic_3days_limit.value
          )
        );
    }

    const input: any = {
      equipment: {
        id: this.data.equipment?.id,
        // unit
        low_level_delay: this.alarmsForm.controls.general_low_level_delay.dirty ? +this.alarmsForm.controls.general_low_level_delay.value : null,
        low_level_limit: this.alarmsForm.controls.general_low_level_limit.dirty ? this.utilsService.kgToGrams(+this.alarmsForm.controls.general_low_level_limit.value) : null,
        low_level_limit_pumpdown: this.alarmsForm.controls.general_low_level_limit_pumpdown.dirty ? this.utilsService.kgToGrams(this.alarmsForm.getRawValue().general_low_level_limit_pumpdown) : null,
        low_level_delay_pumpdown: this.alarmsForm.controls.general_low_level_delay_pumpdown.dirty ? this.alarmsForm.getRawValue().general_low_level_delay_pumpdown : null,
        pumpdown_status: this.pumpdownStatus.dirty ? +this.pumpdownStatus.value : null,
        statistics_3_days_limit: this.alarmsForm.controls.general_statistic_3days_limit.dirty ? this.alarmsForm.controls.general_statistic_3days_limit.value : null,
        fluid_type: this.configurationForm.controls.installation_information_fluid_type.dirty ? this.configurationForm.controls.installation_information_fluid_type.value?.id : null,
        fluid_charge: this.configurationForm.controls.installation_information_fluid_charge.dirty ? this.utilsService.kgToGrams(
          +this.configurationForm.controls.installation_information_fluid_charge.value
        ) : null,
        // teq_CO2: this.configurationForm.controls.teq_CO2.value,

        tank_type: this.configurationForm.controls.installation_information_tank_type.dirty ? this.configurationForm.controls.installation_information_tank_type.value : null,
        tank_tilt: this.configurationForm.controls.installation_information_tank_tilt.dirty ? +this.configurationForm.controls.installation_information_tank_tilt.value : null,
        tank_number: this.configurationForm.controls.installation_information_tank_number.dirty ? +this.configurationForm.controls.installation_information_tank_number.value : null,
        tank_volume: this.configurationForm.controls.installation_information_tank_volume.dirty ? +this.configurationForm.controls.installation_information_tank_volume.value : null,
        tank_height: this.configurationForm.controls.installation_information_tank_height.dirty ? +this.configurationForm.controls.installation_information_tank_height.value : null,
        tank_diameter: this.configurationForm.controls.installation_information_tank_diameter.dirty ? +this.configurationForm.controls.installation_information_tank_diameter.value : null,
        sub_cooler: this.configurationForm.controls.flags_cooler.dirty ? this.configurationForm.controls.flags_cooler.value : null,
        condenser_volume: this.configurationForm.controls.installation_information_condenser_volume.dirty ? +this.configurationForm.controls.installation_information_condenser_volume.value : null,
        hp_consign: this.configurationForm.controls.installation_information_hp_consign.dirty ? +this.configurationForm.controls.installation_information_hp_consign.value : null,

        floating_hp: this.configurationForm.controls.flags_floating_hp.value,
        floating_lp: this.configurationForm.controls.flags_floating_lp.value,
        lp_consign: this.configurationForm.controls.installation_information_lp_consign.dirty ? +this.configurationForm.controls.installation_information_lp_consign.value : null,
        compressor_type: this.configurationForm.controls.installation_information_compressor_type.dirty ? this.configurationForm.controls.installation_information_compressor_type.value : null,
        condenser_type: this.configurationForm.controls.installation_information_condeser_type.dirty ? this.configurationForm.controls.installation_information_condeser_type.value : null,
        condenser_power: this.configurationForm.controls.installation_information_condenser_power.dirty ? +this.configurationForm.controls.installation_information_condenser_power.value : null,

        // polarbox
        heat_recovery: this.configurationForm.controls.heat_recovery.dirty ? +this.configurationForm.controls.heat_recovery.value : null,
        watering_condenser: this.configurationForm.controls.watering_condenser.dirty ? +this.configurationForm.controls.watering_condenser.value : null,
        tank_length: this.configurationForm.controls.tank_length.dirty ? +this.configurationForm.controls.tank_length.value : null,

        // measure card
        column_type: this.measureCardForm.controls.installation_information_column_type.dirty ? this.measureCardForm.controls.installation_information_column_type.value : null,
        column_tare: this.measureCardForm.controls.installation_information_column_tare.dirty ? this.measureCardForm.controls.installation_information_column_tare.value : null,

        external_temp_correction_auto: this.measureCardForm.controls.external_temp_correction_auto.dirty ? this.measureCardForm.controls.external_temp_correction_auto.value : null,
        external_temp_correction: this.measureCardForm.controls.external_temp_correction.dirty ? this.measureCardForm.controls.external_temp_correction.value : null,

        quilting: this.measureCardForm.controls.quilting.dirty ? +this.measureCardForm.controls.quilting.value : null
      },

      measure: {
        id: this.measureCardForm.dirty ? this.data?.measure_device?.id : null,
        hp_min: this.measureCardForm.controls.measure_configuration_hp_min.dirty ? +this.measureCardForm.controls.measure_configuration_hp_min.value : null,
        hp_max: this.measureCardForm.controls.measure_configuration_hp_max.dirty ? +this.measureCardForm.controls.measure_configuration_hp_max.value : null,
        hp_calibration: this.measureCardForm.controls.measure_configuration_hp_calibration.dirty ? +this.measureCardForm.controls.measure_configuration_hp_calibration.value : null,
        lp_calibration: this.measureCardForm.controls.measure_configuration_lp_calibration.dirty ? +this.measureCardForm.controls.measure_configuration_lp_calibration.value : null,
        hp_gas_calibration: this.measureCardForm.controls.measure_configuration_hp_gas_calibration.dirty ? +this.measureCardForm.controls.measure_configuration_hp_gas_calibration.value : null,
        lp_min: this.measureCardForm.controls.measure_configuration_lp_min.dirty ? +this.measureCardForm.controls.measure_configuration_lp_min.value : null,
        lp_max: this.measureCardForm.controls.measure_configuration_lp_max.dirty ? +this.measureCardForm.controls.measure_configuration_lp_max.value : null,
        hp_gas_min: this.measureCardForm.controls.measure_configuration_hp_gas_min.dirty ? +this.measureCardForm.controls.measure_configuration_hp_gas_min.value : null,
        hp_gas_max: this.measureCardForm.controls.measure_configuration_hp_gas_max.dirty ? +this.measureCardForm.controls.measure_configuration_hp_gas_max.value : null,

        // added fields
        input_temp_correction_auto: this.measureCardForm.controls.input_temp_correction_auto.dirty ? this.measureCardForm.controls.input_temp_correction_auto.value : null,
        input_temp_correction: this.measureCardForm.controls.input_temp_correction.dirty ? this.measureCardForm.controls.input_temp_correction.value : null,
        output_temp_correction_auto: this.measureCardForm.controls.output_temp_correction_auto.dirty ? this.measureCardForm.controls.output_temp_correction_auto.value : null,
        output_temp_correction: this.measureCardForm.controls.output_temp_correction.dirty ? this.measureCardForm.controls.output_temp_correction.value : null,
        discharge_temp_correction_auto: this.measureCardForm.controls.discharge_temp_correction_auto.dirty ? this.measureCardForm.controls.discharge_temp_correction_auto.value : null,
        discharge_temp_correction: this.measureCardForm.controls.discharge_temp_correction.dirty ? this.measureCardForm.controls.discharge_temp_correction.value : null,
        suction_temp_correction_auto: this.measureCardForm.controls.suction_temp_correction_auto.dirty ? this.measureCardForm.controls.suction_temp_correction_auto.value : null,
        suction_temp_correction: this.measureCardForm.controls.suction_temp_correction.dirty ? this.measureCardForm.controls.suction_temp_correction.value : null
      },
      energy: this.dni ? this.updatEnergyCards() :
        {
          engines: this.updatEnergyCards()
        }
    };

    // remove null values
    input.measure = Object.fromEntries(Object.entries(input?.measure).filter(([_, v]) => v != null));
    input.equipment = Object.fromEntries(Object.entries(input?.equipment).filter(([_, v]) => v != null));

    console.log('input', input);

    this.context === 'CREATE' ? await this.create(input) : await this.update(input);
    this.close(true);
  }

  public isFormInvalid(): boolean {
    this.invalidAlarmDataMessage = this.getFormDataInvalidMessage(this.alarmsForm, 'alarms');
    this.invalidConfigurationDataMessage = this.getFormDataInvalidMessage(this.configurationForm, 'configuration');
    this.invalidMeasureCardDataMessage = this.getFormDataInvalidMessage(this.measureCardForm, 'measureCard');
    this.invalidEnergyModuleForm = this.getFormDataInvalidMessage(this.energyModuleForm, 'energyModule');

    return !this.form.invalid;
  }

  public resetField(formControls: string[]) {
    formControls.forEach(formControl => {
      this.measureCardForm.controls[formControl].setValue(0);
      this.measureCardForm.controls[formControl].markAsDirty();
    });
  }

  public validateMinimumMaximum(min: number, max: number): boolean {
    return min <= max;
  }

  public displayFluidName(fluid: Fluid): string {
    return fluid?.name || '';
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  map_stages(engines_list: any): FormArray {
    const form = new FormArray([]);
    if (engines_list) {
      const stages = this.equipmentUtilsService.structureStage(engines_list);
      // parcourir les étages
      for (let i = 0; i < 3; i++) {
        const current_stage = stages[i];
        if (current_stage.length != 0) {
          const engine_form_array = new FormArray([]);
          // parcourir les engines
          for (let j = 0; j < current_stage.length; j++) {
            engine_form_array.push(this.addEngine(current_stage[j]));
          }
          form.push(engine_form_array);
        }
      }
    }
    return form;
  }

  engines_form_array(index: number) {
    const stages = this.energyModuleV2Form.controls.stages_form_array as FormArray;
    return stages.controls[index] as FormArray;
  }

  addEngine(engine?: any) {
    const engine_input = this.formBuilder.group({
      id: new FormControl(engine?.id ?? ''),
      variator: new FormControl(engine?.variator ?? false),
      reference: new FormControl(engine?.reference ?? ''),
      distribution: new FormControl(engine?.distribution ?? 0, [Validators.min(0), Validators.max(100)]),
      name: new FormControl(engine?.name ?? 'moteur', [Validators.required]),
      engine_mode: new FormControl(engine?.engine_mode ?? 'MODE_THREEPHASE_BALANCED', [Validators.required]),
      engine_type: new FormControl(engine?.engine_type ?? 'COMPRESSOR', [Validators.required]),
      stage: new FormControl(engine?.stage ?? this.selectedStage + 1),
      itemCtrl: new FormControl(this.data.energy_devices[0]?.engines_mapping?.find((element: any) =>
        element.engine_id === engine.name
      ) ?? ['1.1'])
    });
    return engine_input;
  }

  removeEngine(index: number) {
    this.engines_form_array(this.selectedStage).removeAt(index);
  }

  updateEngineV2() {

    const new_engines_nbr: number = this.enginesPerStage;
    const old_engines_nbr: number = this.engines_form_array(this.selectedStage).value?.length;

    // max nbr of engines per étage is 9
    if (new_engines_nbr <= 9) {
      // add new engines
      if (old_engines_nbr < new_engines_nbr) {
        const engine_to_add = new_engines_nbr - old_engines_nbr;
        for (let i = 0; i < engine_to_add; i++) {
          this.engines_form_array(this.selectedStage).push(this.addEngine());
        }
      }

      // delete engines
      if (old_engines_nbr > new_engines_nbr) {
        const engines_to_delete = old_engines_nbr - new_engines_nbr;
        for (let i = new_engines_nbr; i < old_engines_nbr; i++) {
          this.removeEngine(i);
        }
      }
    }
  }

  private pumpdownStatusChangeSubscribe() {

    const pumpdownStatusChange$ = this.pumpdownStatus.valueChanges.subscribe((pumpdownStatus) => {
      this.updateFieldPumpdownStatus(pumpdownStatus);
    });
    this.subscriptions.push(pumpdownStatusChange$);
  }

  private updateFieldPumpdownStatus(pumpdownStatus): void {
    if (pumpdownStatus) {
      this.generalLowLevelDelayPumpdown.enable();
      this.generalLowlevelLimitPumpdown.enable();

      this.generalLowlevelLimitPumpdown.setValue(this.data.equipment?.low_level_limit_pumpdown);
      this.generalLowLevelDelayPumpdown.setValue(this.data.equipment?.low_level_delay_pumpdown);

      this.generalLowLevelDelayPumpdown.setValidators([Validators.required]);
      this.generalLowlevelLimitPumpdown.setValidators([Validators.required]);

    } else {
      this.generalLowlevelLimitPumpdown.setValue(0);
      this.generalLowLevelDelayPumpdown.setValue(0);

      this.generalLowLevelDelayPumpdown.disable();
      this.generalLowlevelLimitPumpdown.disable();

      this.generalLowLevelDelayPumpdown.setValidators(null);
      this.generalLowlevelLimitPumpdown.setValidators(null);

    }
    this.generalLowlevelLimitPumpdown.updateValueAndValidity();
    this.generalLowLevelDelayPumpdown.updateValueAndValidity();
  }

  private updatEnergyCards() {
    if (this.dni) { // dni
      const count_cards = this.data?.energy_devices?.length;
      if (count_cards == 0) {
        return null;
      } else {
        const energy_cards = [];
        const cards = this.data?.energy_devices;
        const all_engines = this.updateEngines();

        for (let index = 0; index < count_cards; index++) {
          if (all_engines[index].length != 0) {
            energy_cards.push({ id: cards[index].id, engines: all_engines[index] });
          }
        }
        return energy_cards;
      }
    } else { // polarbox
      const energy_engines: any[] = [];
      for (let i = 0; i < this.stages_form_array.length; i++) {
        for (let j = 0; j < this.engines_form_array(i)?.value.length; j++) {
          if (this.engines_form_array(i).controls[j].dirty) {
            const engine = this.engines_form_array(i).controls[j] as FormGroup;
            engine.removeControl('itemCtrl');
            energy_engines.push(engine.value);
          }
        }
      }
      return energy_engines;
    }
  }

  private updateEngines(): any[] {
    const engines = [];
    for (let i = 1; i < 4; i++) {
      const module = [];
      for (let j = 1; j < 4; j++) {
        const power: any = {
          assignement: eval('this.energyModuleForm.controls.module' + i + '_power' + j + '_type?.dirty') ? eval('this.energyModuleForm.controls.module' + i + '_power' + j + '_type?.value') : null,
          power_ti: eval('this.energyModuleForm.controls.module' + i + '_power' + j + '_ti_range?.dirty') ? eval('this.energyModuleForm.controls.module' + i + '_power' + j + '_ti_range?.value') : null,
          number_ti: eval('this.energyModuleForm.controls.module' + i + '_power' + j + '_number_ti?.dirty') ? eval('this.energyModuleForm.controls.module' + i + '_power' + j + '_number_ti?.value') : null,
          assignement_percent: eval('this.energyModuleForm.controls.module' + i + '_power' + j + '_power_ratio?.dirty') ? eval('this.energyModuleForm.controls.module' + i + '_power' + j + '_power_ratio?.value') : null
        };
        // remove empty values
        Object.keys(power).forEach(item => (!power[item] && power[item] !== undefined) && delete power[item]);
        if (Object.keys(power).length != 0) {
          power.index = j;
          module.push(power);
        }
      }
      engines.push(module);
    }
    return engines;
  }

  private getFormDataInvalidMessage(form: FormGroup, context: string): string {
    let message: string;
    if (form.invalid) {
      message = this.translateService.instant('website.equipment.config.validations.some_missing', {
        fields: this.getAllFieldsWithErrors(form)
          .map(field => '> ' + this.translateService.instant(`website.equipment.config.${context}.fields.${field}.label`))
          .join('\n')
      });
    }

    return message;
  }

  private getAllFieldsWithErrors(form: FormGroup): string[] {
    const fields = Object.keys(form.controls);

    return fields.reduce((acc, curr) => {
      if (form.get(curr).invalid) {
        acc.push(curr);
      }

      return acc;
    }, []);
  }

  private async create(input: Partial<EquipmentConfigCreate>): Promise<void> {
    const config = Object.assign(new EquipmentConfigCreate(), { equipment_id: this.data.equipmentId }, input);
    await this.equipmentConfigService.create(config);
  }

  private async update(input: Partial<EquipmentConfigUpdate>): Promise<void> {
    const config = Object.assign(new EquipmentConfigUpdate(), { /*_id: this.data.config._id,*/
      equipment_id: this.data.equipmentId
    }, input);
    await this.equipmentConfigService.update(config);
  }

  private _filter(value: string): Fluid[] {
    const filterValue = (value || '').toLowerCase();
    return this.fluidsResult.itemsPage.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  private generateSubscriberForSliderMinMax(
    context: FormGroup,
    formControlNameValidated: string,
    formControlNameReference: string,
    isValidedMax: boolean = false
  ): void {
    const subs = context.controls[formControlNameValidated].valueChanges.subscribe(value => {
      let min, max;
      if (isValidedMax) {
        [min, max] = [context.controls[formControlNameReference].value, value];
      } else {
        [min, max] = [value, context.controls[formControlNameReference].value];
      }

      const isValid = this.validateMinimumMaximum(min, max);
      if (!isValid) {
        context.controls[formControlNameValidated].setErrors({ minGtMax: true });
        context.controls[formControlNameReference].setErrors({ minGtMax: true });
      } else {
        context.controls[formControlNameValidated].setErrors(null);
        context.controls[formControlNameReference].setErrors(null);
      }
    });
    this.subscriptions.push(subs);
  }

  // todo: split form in sub-forms when the component is splitted
  private buildForm(): void {
    // keep track of the last sensibility value
    this.lastSensibility = this.data.equipment?.statistics_3_days_limit;
    // this.checkEnumValidity();
    // forms

    this.alarmsForm = this.formBuilder.group({
      general_low_level_limit: [this.utilsService.gramsToKg(this.data.equipment?.low_level_limit), Validators.required],
      general_low_level_delay: [this.data.equipment?.low_level_delay, Validators.required],
      general_low_level_limit_pumpdown: [this.utilsService.gramsToKg(this.data.equipment?.low_level_limit_pumpdown), Validators.required],
      general_low_level_delay_pumpdown: [this.data.equipment?.low_level_delay_pumpdown, { disabled: true }],
      pumpdown_status: [this.data.equipment?.pumpdown_status],
      general_statistic_3days_limit: [
        this.sensibilityConversionService.convertToIHM(this.data.equipment?.lp_consign, this.lastSensibility)
      ]
    });
    this.updateFieldPumpdownStatus(this.data.equipment?.pumpdown_status);
    this.configurationForm = this.formBuilder.group({
      installation_information_fluid_charge: this.utilsService.gramsToKg(this.data.equipment?.fluid_charge),
      installation_information_fluid_type: [this.data.equipment?.fluid],
      teq_CO2: [{
        value: this.utilsService.gramsToTonnes(this.data.equipment?.fluid_charge * this.data.equipment?.fluid?.gwp),
        disabled: true
      }],
      installation_information_tank_type: [this.data.equipment?.tank_type],
      installation_information_tank_tilt: [this.data.equipment?.tank_tilt],
      installation_information_tank_number: [this.data.equipment?.tank_number],
      installation_information_tank_volume: [this.data.equipment?.tank_volume],
      // installation_information_tank_height: [this.data.equipment?.tank_height],
      installation_information_tank_height: [this.polarbox ? this.data.equipment?.tank_length : (this.data.equipment?.tank_type == 'VERTICAL' ? this.data.equipment?.column_height : this.data.equipment?.tank_height)],
      installation_information_tank_diameter: [this.data.equipment?.tank_diameter],
      flags_cooler: [this.data.equipment?.sub_cooler],
      installation_information_condenser_volume: [this.data.equipment?.condenser_volume],
      installation_information_hp_consign: [this.data.equipment?.hp_consign],
      flags_floating_hp: [this.data.equipment?.floating_hp],
      flags_floating_lp: [this.data.equipment?.floating_lp],
      installation_information_lp_consign: [this.data.equipment?.lp_consign],
      installation_information_compressor_type: [this.data.equipment?.compressor_type],
      installation_information_condeser_type: [this.data.equipment?.condenser_type],
      installation_information_condenser_power: [this.data.equipment?.condenser_power],

      // polarbox
      heat_recovery: [this.data.equipment?.heat_recovery ? this.data.equipment?.heat_recovery : false],
      watering_condenser: [this.data.equipment?.watering_condenser ? this.data.equipment?.watering_condenser : false],
      tank_length: [this.data.equipment?.tank_length ? this.data.equipment?.tank_length : null]
    });

    this.measureCardForm = this.formBuilder.group({
      measure_configuration_hp_min: [this.data.measure_device?.hp_min || 0],
      measure_configuration_hp_max: [this.data.measure_device?.hp_max || 0],
      measure_configuration_hp_calibration: [{ value: this.data.measure_device?.hp_calibration, disabled: true }],
      installation_information_column_type: [this.data.equipment?.column_type],
      installation_information_column_tare: [{ value: this.data.equipment?.column_tare, disabled: true }],

      measure_configuration_lp_min: [this.data.measure_device?.lp_min || 0],
      measure_configuration_lp_max: [this.data.measure_device?.lp_max || 0],
      measure_configuration_lp_calibration: [{ value: this.data.measure_device?.lp_calibration, disabled: true }],

      measure_configuration_hp_gas_calibration: [{
        value: this.data.measure_device?.hp_gas_calibration,
        disabled: true
      }],
      measure_configuration_hp_gas_min: [this.data.measure_device?.hp_gas_min || 0],
      measure_configuration_hp_gas_max: [this.data.measure_device?.hp_gas_max || 0],

      // added fields
      input_temp_correction_auto: [this.data.measure_device?.input_temp_correction_auto], // device
      input_temp_correction: [this.data.measure_device?.input_temp_correction],

      output_temp_correction_auto: [this.data.measure_device?.output_temp_correction_auto], // device
      output_temp_correction: [this.data.measure_device?.output_temp_correction],

      discharge_temp_correction_auto: [this.data.measure_device?.discharge_temp_correction_auto], // device
      discharge_temp_correction: [this.data.measure_device?.discharge_temp_correction],

      external_temp_correction_auto: [this.data.equipment?.external_temp_correction_auto], // equipment
      external_temp_correction: [this.data.equipment?.external_temp_correction],

      suction_temp_correction_auto: [this.data.measure_device?.suction_temp_correction_auto],
      suction_temp_correction: [this.data.measure_device?.suction_temp_correction],

      // polarbox
      quilting: [{ value: this.data.equipment?.quilting ? this.data.equipment?.quilting : null, disabled: true }],
      top_bottom_tapping: [{
        value: this.polarbox ? this.data.equipment?.column_height : (this.data.equipment?.tank_type == 'VERTICAL' ? this.data.equipment?.column_height : this.data.equipment?.tank_diameter),
        disabled: true
      }]

    });

    this.energyModuleForm = this.formBuilder.group({
      // energy_voltage_recording: [this.data.config?.energy_voltage_recording],
      // energy_voltage_recalculation: [this.data.config?.energy_voltage_recalculation],

      // Module 1
      module1_power1_type: [this.data.energy_devices[0]?.engines ? this.data.energy_devices[0]?.engines[0]?.assignement : null],
      module1_power2_type: [this.data.energy_devices[0]?.engines ? this.data.energy_devices[0]?.engines[1]?.assignement : null],
      module1_power3_type: [this.data.energy_devices[0]?.engines ? this.data.energy_devices[0]?.engines[2]?.assignement : null],
      module1_power1_ti_range: [this.data.energy_devices[0]?.engines ? this.data.energy_devices[0]?.engines[0]?.power_ti : null],
      module1_power2_ti_range: [this.data.energy_devices[0]?.engines ? this.data.energy_devices[0]?.engines[1]?.power_ti : null],
      module1_power3_ti_range: [this.data.energy_devices[0]?.engines ? this.data.energy_devices[0]?.engines[2]?.power_ti : null],
      module1_power1_number_ti: [this.data.energy_devices[0]?.engines ? this.data.energy_devices[0]?.engines[0]?.number_ti : null],
      module1_power2_number_ti: [this.data.energy_devices[0]?.engines ? this.data.energy_devices[0]?.engines[1]?.number_ti : null],
      module1_power3_number_ti: [this.data.energy_devices[0]?.engines ? this.data.energy_devices[0]?.engines[2]?.number_ti : null],
      module1_power1_power_ratio: [this.data.energy_devices[0]?.engines ? this.data.energy_devices[0]?.engines[0]?.assignement_percent : null],
      module1_power2_power_ratio: [this.data.energy_devices[0]?.engines ? this.data.energy_devices[0]?.engines[1]?.assignement_percent : null],
      module1_power3_power_ratio: [this.data.energy_devices[0]?.engines ? this.data.energy_devices[0]?.engines[2]?.assignement_percent : null],


      // Module 2
      module2_power1_type: [this.data.energy_devices[1]?.engines ? this.data.energy_devices[1]?.engines[0]?.assignement : null],
      module2_power2_type: [this.data.energy_devices[1]?.engines ? this.data.energy_devices[1]?.engines[1]?.assignement : null],
      module2_power3_type: [this.data.energy_devices[1]?.engines ? this.data.energy_devices[1]?.engines[2]?.assignement : null],
      module2_power1_ti_range: [this.data.energy_devices[1]?.engines ? this.data.energy_devices[1]?.engines[0]?.power_ti : null],
      module2_power2_ti_range: [this.data.energy_devices[1]?.engines ? this.data.energy_devices[1]?.engines[1]?.power_ti : null],
      module2_power3_ti_range: [this.data.energy_devices[1]?.engines ? this.data.energy_devices[1]?.engines[2]?.power_ti : null],
      module2_power1_number_ti: [this.data.energy_devices[1]?.engines ? this.data.energy_devices[1]?.engines[0]?.number_ti : null],
      module2_power2_number_ti: [this.data.energy_devices[1]?.engines ? this.data.energy_devices[1]?.engines[1]?.number_ti : null],
      module2_power3_number_ti: [this.data.energy_devices[1]?.engines ? this.data.energy_devices[1]?.engines[2]?.number_ti : null],
      module2_power1_power_ratio: [this.data.energy_devices[1]?.engines ? this.data.energy_devices[1]?.engines[0]?.assignement_percent : null],
      module2_power2_power_ratio: [this.data.energy_devices[1]?.engines ? this.data.energy_devices[1]?.engines[1]?.assignement_percent : null],
      module2_power3_power_ratio: [this.data.energy_devices[1]?.engines ? this.data.energy_devices[1]?.engines[2]?.assignement_percent : null],


      // Module 3
      module3_power1_type: [this.data.energy_devices[1]?.engines ? this.data.energy_devices[2]?.engines[0]?.assignement : null],
      module3_power2_type: [this.data.energy_devices[1]?.engines ? this.data.energy_devices[2]?.engines[1]?.assignement : null],
      module3_power3_type: [this.data.energy_devices[1]?.engines ? this.data.energy_devices[2]?.engines[2]?.assignement : null],
      module3_power1_ti_range: [this.data.energy_devices[1]?.engines ? this.data.energy_devices[2]?.engines[0]?.power_ti : null],
      module3_power2_ti_range: [this.data.energy_devices[1]?.engines ? this.data.energy_devices[2]?.engines[1]?.power_ti : null],
      module3_power3_ti_range: [this.data.energy_devices[1]?.engines ? this.data.energy_devices[2]?.engines[2]?.power_ti : null],
      module3_power1_number_ti: [this.data.energy_devices[1]?.engines ? this.data.energy_devices[2]?.engines[0]?.number_ti : null],
      module3_power2_number_ti: [this.data.energy_devices[1]?.engines ? this.data.energy_devices[2]?.engines[1]?.number_ti : null],
      module3_power3_number_ti: [this.data.energy_devices[1]?.engines ? this.data.energy_devices[2]?.engines[2]?.number_ti : null],
      module3_power1_power_ratio: [this.data.energy_devices[1]?.engines ? this.data.energy_devices[2]?.engines[0]?.assignement_percent : null],
      module3_power2_power_ratio: [this.data.energy_devices[1]?.engines ? this.data.energy_devices[2]?.engines[1]?.assignement_percent : null],
      module3_power3_power_ratio: [this.data.energy_devices[1]?.engines ? this.data.energy_devices[2]?.engines[2]?.assignement_percent : null]
    });

    if (this.polarbox && this.engine_nbr !== 0) {
      this.energyModuleV2Form = this.formBuilder.group({
        stages_form_array: this.map_stages(this.data.equipment.engines)
      });
    } else if (this.polarbox) {
      this.energyModuleV2Form = this.formBuilder.group({ stages_form_array: new FormArray([]) });
    }

    this.form = this.formBuilder.group({
      alarms: this.alarmsForm,
      configuration: this.configurationForm,
      measureCard: this.measureCardForm,
      energyModule: this.energyModuleForm,
      energyV2Module: this.energyModuleV2Form
    });


    console.log('buillld form ', this.form);

    this.generateSubscriberForSliderMinMax(this.measureCardForm, 'measure_configuration_hp_min', 'measure_configuration_hp_max', false);
    this.generateSubscriberForSliderMinMax(this.measureCardForm, 'measure_configuration_hp_max', 'measure_configuration_hp_min', true);

    this.generateSubscriberForSliderMinMax(this.measureCardForm, 'measure_configuration_lp_min', 'measure_configuration_lp_max', false);
    this.generateSubscriberForSliderMinMax(this.measureCardForm, 'measure_configuration_lp_max', 'measure_configuration_lp_min', true);

    this.generateSubscriberForSliderMinMax(
      this.measureCardForm,
      'measure_configuration_hp_gas_min',
      'measure_configuration_hp_gas_max',
      false
    );
    this.generateSubscriberForSliderMinMax(
      this.measureCardForm,
      'measure_configuration_hp_gas_max',
      'measure_configuration_hp_gas_min',
      true
    );

    this.isloading = false;
  }


}
