import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { EquipmentService } from '@equipment/services/equipment.service';
import { CustomerLocation } from '@shared/models/customer-location';
import { take } from 'rxjs/operators';

import { EquipmentHasCustomerLocationValidator } from './equipment-has-customer-location.validator';
import { EquipmentPolarBoxResult } from '@equipment/domain/equipment-polarbox-result/equipment-polarbox-result';

@Component({
  selector: 'app-equipment-create-dialog',
  templateUrl: './equipment-create-dialog.component.html',
  styleUrls: ['./equipment-create-dialog.component.scss']
})
export class EquipmentCreateDialogComponent implements OnInit {
  public form: FormGroup;

  public pbForm: FormGroup;
  public currentTab: number = 0;

  public customerLocations: CustomerLocation[];
  public isLoading: boolean;
  public queryResult: EquipmentPolarBoxResult;

  constructor(
    private dialogRef: MatDialogRef<EquipmentCreateDialogComponent>,
    private equipmentService: EquipmentService,
    private formBuilder: FormBuilder
  ) {}

  public async ngOnInit(): Promise<void> {
    this.buildForm();
    this.equipmentService.getEquipmentFilterCustomerLocations();
    this.customerLocations = (await this.equipmentService.customerLocations$$.pipe(take(1)).toPromise()).customerLocationsPage;
  }

  public dismiss(): void {
    this.dialogRef.close();
  }

  public async submit(): Promise<void> {
    if (this.currentTab === 0) {
      const input = {
        id: this.form.controls.equipmentId.value,
        name: this.form.controls.equipmentName.value,
        referenceCentrale: this.form.controls.referenceCentrale.value,
        customerLocation: this.form.controls.customerLocation.value || undefined,
      };
      await this.equipmentService.updateEquipment(input as any);
      const equipmentUpdated = (await this.equipmentService.getEquipment(this.form.controls.equipmentId.value))
      const deviceInput = {
        id: equipmentUpdated.main_device_id.id,
        serial_number: this.form.controls.serial_number.value,
      };
      await this.equipmentService.updateDevice(deviceInput as any);
      this.dialogRef.close(true);
    } else {
      this.isLoading = true;
      const input = {
        id: this.pbForm.controls.equipmentId.value,
        unit_count: this.pbForm.controls.unit_count.value,
        wireless_module: this.pbForm.controls.wireless_module.value,
        customerLocation: this.pbForm.controls.customerLocation.value || undefined
      };
      this.queryResult = await this.equipmentService.createPolarBoxAndEquipments(input as any);
      this.isLoading = false;
      if (this.queryResult?.saved)
        this.dialogRef.close(true);
    }
  }

  public setTab(event: any): void {
    this.currentTab = event.index;
    if (!this.pbForm || !this.form) {
      this.buildForm()
    }
  }
  public get isFormValid(): boolean {
    if (this.currentTab === 0)
      return this.form.valid;
    else
      return this.pbForm.valid;
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      equipmentId: ['', Validators.required, EquipmentHasCustomerLocationValidator.validator(this.equipmentService)],
      equipmentName: ['', Validators.required],
      referenceCentrale: [''],
      customerLocation: [],
      serial_number: ['', Validators.required]
    });
    this.pbForm = this.formBuilder.group({
      equipmentId: ['', [Validators.required, Validators.pattern('[0-9]*')]],
      unit_count: ['', Validators.required],
      wireless_module: [''],
      customerLocation: []
    });
    }
}
