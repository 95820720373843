import { Injectable } from '@angular/core';
import { GraphqlService } from '@graphql/graphql.service';
import { PagedResult } from '@shared/services/paged-result';
import { User } from '@users/domain/models/user';
import { gql } from 'apollo-angular';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EquipmentUserService {
  constructor(private graphqlService: GraphqlService) {}

  private getEquipmentUserListQuery: any = gql`
    query getEquipmentUserList($equipmentId: String!, $page: Float!, $perPage: Float!) {
      getEquipmentUserList(criteria: { equipmentId: $equipmentId, page: $page, perPage: $perPage }) {
        itemsPage {
          _id
          name
          firstName
          lastName
          profileImage
          email
          phone
          lastConnection
          customerLocationAlarmsDisabled
          useFilteredAlarms
          userRolesForEquipment {
            _id
            customerLocation {
              name
            }
            customerChain {
              name
            }
            installer {
              name
            }
            installerChain {
              name
            }
            role {
              name
            }
          }
          userRoles {
            _id
          }
        }
        total
      }
    }
  `;

  public async getEquipmentUserList(equipmentId: string, criteria: any): Promise<PagedResult<User>> {
    const gqlParams: any = { equipmentId };

    if (criteria.pagination) {
      gqlParams.page = criteria.pagination.page;
      gqlParams.perPage = criteria.pagination.perPage;
    }

    return await this.graphqlService
      .query(this.getEquipmentUserListQuery, gqlParams, false)
      .pipe(
        take(1),
        map((result: any) => {
          let res: PagedResult<User> = new PagedResult<User>([], 0);
          res.itemsPage = result?.data?.getEquipmentUserList?.itemsPage?.map((item: User) => {
            return new User(item);
          });
          res.total = result?.data?.getEquipmentUserList?.total
          return res;
        })
      )
      .toPromise();
  }
}
