import { Observable } from 'rxjs';

import { Component, Input, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';

import { EquipmentDetailsService } from '../../../services/equipment-details.service';

const INITIAL_CRITERIA: any = {
  filters: {
    dniId: {
      operation: 'EQ',
      value: null
    }
  },
  pagination: {
    perPage: 10,
    page: 0
  }
};

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss']
})
export class FilesComponent implements OnInit {
  public displayedColumns: string[] = ['name', 'owner', 'editDate', 'actions'];
  public files$: Observable<any> | undefined;
  public criteria: any;
  @Input() public equipmentId: number | undefined;

  constructor(private dniDetailsService: EquipmentDetailsService) {}

  ngOnInit(): void {
    this.criteria = JSON.parse(JSON.stringify(INITIAL_CRITERIA));
    this.criteria.filters.dniId.value = this.equipmentId;
    this.getFiles();
  }

  public onPaginatorChange(event: PageEvent): void {
    this.criteria.pagination.perPage = event.pageSize;
    this.criteria.pagination.page = event.pageIndex;
    this.getFiles();
  }

  public getFiles(): void {
    this.files$ = this.dniDetailsService.getFiles(this.criteria);
  }
}
