import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { animations } from '../../../../style/animations';
import { LanguageService } from '../../../shared/services/language.service';

import { StrengthPasswordValidator } from '../password-strength/strength.password.validator';
import { UserService } from '@users/domain/services/user.service';
import {Title} from "@angular/platform-browser";
import {TranslateService} from "@ngx-translate/core";

@Component({
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  // eslint-disable-next-line object-shorthand
  animations: animations
})
export class ResetPasswordComponent implements OnInit {
  public resetPasswordForm: FormGroup;
  public userEmail: string;
  public resetCode: string;
  public crtLang: string = 'fr';

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private languageService: LanguageService,
    private titleService: Title,
    private translateService: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.titleService.setTitle('Matelex - ' + this.translateService.instant('AUTH.LOGIN.REINIT_PASSWORD'))
    this.crtLang = this.route.parent.snapshot.params.lang;
    this.userEmail = this.route.snapshot.params.email;
    this.resetCode = this.route.snapshot.params.resetCode;
    this.resetPasswordForm = this.formBuilder.group({
      email: [this.userEmail, [Validators.required, Validators.email]],
      resetCode: [this.resetCode, [Validators.required]],
      password: ['', [Validators.required, StrengthPasswordValidator]]
    });
  }

  public navigateToLogin() {
    this.router.navigateByUrl('/' + this.languageService.currentLanguage + '/users/login')
  }

  public async onResetSubmit(): Promise<any> {
    const recover = await this.userService.recover(
      this.resetPasswordForm.controls.email.value,
      this.resetPasswordForm.controls.resetCode.value,
      this.resetPasswordForm.controls.password.value
    );
    if (recover) {
      // this.router.navigate([this.userEmail ? '../../' : '../'], { relativeTo: this.route });
      this.router.navigate(['/' + this.crtLang + `/users/login`]);
    }
  }

  public redirectToLogin(): void {
    const lang = this.languageService.currentLanguage;
    this.router.navigateByUrl(`/${lang}/user/auth`);
  }
}
