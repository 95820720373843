import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router, UrlSerializer } from '@angular/router';
import { EquipmentDetailsService } from '@equipment/services/equipment-details.service';
import { LanguageService } from '@shared/services/language.service';
import { UtilsService } from '@shared/services/utils.service';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-alarm-modal',
  templateUrl: './alarm-modal.component.html',
  styleUrls: ['./alarm-modal.component.scss']
})
export class AlarmModalComponent implements OnInit {

  id: string;
  reg_time: string;
  eventId: string;
  showCopiedToClipboard = false;
  disableSharebtn = false;

  constructor(public dialogRef: MatDialogRef<AlarmModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private equipmentDetailsService: EquipmentDetailsService,
              private utilsService: UtilsService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private clipBoard: Clipboard,
              private serializer: UrlSerializer,
              private languageService: LanguageService) { }

  alarmPreparedData: any;
  async ngOnInit(): Promise<void> {
    if (this.data.id) {
      this.id = this.data.id;
      this.eventId = this.data.eventId;
      this.alarmPreparedData = {
        lpConsign: this.data.lpConsign,
        sensibility: this.data.sensibility,
        delay: this.data.delay,
        consign: this.data.consign
      };

      (await this.equipmentDetailsService.getAlarm(this.id)).subscribe(
        async (alarm: any) => {
          console.log('this is modal', alarm);
          if (this.utilsService.gramsToTonnes(alarm?.target_id?.fluid_charge * alarm?.target_id?.fluid?.gwp) >= 500) {
            this.reg_time = '12';
          }
          else {
            this.reg_time = '24';
          }
          (await this.equipmentDetailsService.getEventForAlarmModal(alarm.evt_source_id, alarm.source_id.id)).subscribe((event: any) => {
              this.alarmPreparedData.alarmLimit = event[0]?.extra_info?.alarm_limit;
            },
            (err: any) => console.log('AlarmModalComponent : Error in fetching event data', err)
          );
        },
        (err: any) => console.log('AlarmDniInfoComponent : Error in fetching data', err)
      );
    }
  }

  copyPasta(): void {
    const objUrl = {
      alarm_id: this.id,
      event_id: this.eventId
    }
    const pathOrigin = window.location.origin;
    const rtr = this.router.createUrlTree([], {
      queryParams: objUrl,
      queryParamsHandling: 'merge',
    });
    let activePath = pathOrigin + this.serializer.serialize(rtr);
    this.clipBoard.copy(activePath);
    this.showCopiedToClipboard = true;
    this.disableSharebtn = true;
    setTimeout(() => {
      this.showCopiedToClipboard = false;
      this.disableSharebtn = false;
    }, 3000);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  naviagteTo($event: any) {
    console.log($event);
    this.dialogRef.afterClosed().subscribe(result => {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([this.languageService.currentLanguage, 'equipment', 'details'], {
        queryParams: { id: $event, tab: 'activity' }
      })
    );
    });
    this.dialogRef.close();
  }

}
