export interface GeneralCompany {
  id: string;
  type: CompanyType;
  name?: string;
}

export enum CompanyType {
  CustomerLocation = 'CustomerLocation',
  CustomerChain = 'CustomerChain',
  Installer = 'Installer',
  InstallerChain = 'InstallerChain'
}
