<div *ngIf="!componentIsUsedAsTokenReceiver" class="auth-container">
  <div class="auth-form mat-card">
    <img src="../assets/images/icon-login-locker.svg" class="auth-img" alt="" />
    <h1>{{ 'website.AUTH.LOGIN.TITLE' | translate }}</h1>
    <p>{{ 'website.AUTH.LOGIN.EXPLANATION' | translate }}</p>

    <!-- <div class="separation-line"></div>
    <button
      id="submit-button"
      class="login-keycloak-button"
      mat-stroked-button
      aria-labelledby="login-with-keycloak"
      (click)="loginWithKeycloak()"
    >
      {{ 'website.AUTH.LOGIN.LOGIN_WITH_KEYCLOAK' | translate }}
    </button>
    <div class="separator">
      <span class="text">{{ 'website.AUTH.LOGIN.LABEL_OR' | translate }}</span>
    </div> -->

    <form name="loginForm" [formGroup]="loginForm" novalidate (ngSubmit)="onLoginSubmit()">
      <mat-form-field appearance='outline'>
        <mat-label>{{ 'AUTH.PLACEHOLDERS.EMAIL' | translate }}</mat-label>
        <input matInput [placeholder]="'AUTH.PLACEHOLDERS.EMAIL' | translate" formControlName="email" autofocus required />
        <mat-error *ngIf="loginForm.get('email').hasError('required')" aria-invalid="loginForm.get('email').hasError('required')">
          {{ 'AUTH.VALIDATIONS.EMAIL_REQUIRED' | translate }}
        </mat-error>
        <mat-error
          *ngIf="!loginForm.get('email').hasError('required') && loginForm.get('email').hasError('email')"
          aria-invalid="!loginForm.get('email').hasError('required') && loginForm.get('email').hasError('email')"
        >
          {{ 'AUTH.VALIDATIONS.EMAIL_INVALID' | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance='outline'>
        <mat-label>{{ 'AUTH.PLACEHOLDERS.PASS' | translate }}</mat-label>
        <input
          type="password"
          matInput
          [placeholder]="'AUTH.PLACEHOLDERS.PASS' | translate"
          formControlName="password"
          #passwordInput
          required
          id="show-password"
        />
        <div class="show-password" [anaisShowPassword]="passwordInput" tabindex="0">
          <i class="material-icons" aria-labelledby="show-password" role="button">
            {{ 'visibility' + (passwordInput.type === 'password' ? '_off' : '') }}
          </i>
        </div>
        <mat-error *ngIf="loginForm.get('password').hasError('required')" aria-invalid="loginForm.get('password').hasError('required')">
          {{ 'AUTH.VALIDATIONS.PASS_REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>
      <div class="action-links">
        <a
          id="forgot-password"
          class="forgot-password"
          [routerLink]="['forgot-password', loginForm.get('email').value]"
          aria-labelledby="forgot-password"
        >{{ 'AUTH.FORGOT_PASSWORD.LINK_TEXT' | translate }}</a>
      </div>

      <div [matTooltip]="loginForm.invalid ? tooltipMessage : null">
        <button id="submit-button" mat-raised-button color="primary" class="submit-button" aria-labelledby="submit-button">
          {{ 'website.AUTH.LOGIN.LOGIN_BTN' | translate }}
        </button>

        <!-- <div class="separation-line"></div>
        <div class="action-links">
          <span>Not yet registred ?</span>
          <a href="#">Request an access</a>
        </div> -->
      </div>
    </form>
  </div>
</div>
