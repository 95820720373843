import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProfileService } from '@shared/services/profile.service';
import { Permission } from '@users/domain/services/user.enums';
import { UserInfo } from '@users/domain/services/user.interfaces';
import { LoginStatusUpdate, UserService } from '@users/domain/services/user.service';
import { Subscription } from 'rxjs';
import { EquipmentAlarmsChange } from '../../equipment/services/equipment-alarms-change.interface';
import { EquipmentDetailsService } from '../../equipment/services/equipment-details.service';
import { EquipmentService } from '../../equipment/services/equipment.service';
import { LanguageService } from '../../shared/services/language.service';

export class CountAlarm{

  public totalEquipmentsWithActiveAlarms: number;

  public totalEquipmentsWithConnectionAlarm?: number;
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  public Permission: typeof Permission = Permission;
  public totalEquipmentsHavingAlarms: CountAlarm;
  public crtLang: string = null;
  private subscriptions: Subscription[] = [];
  public userInfo: UserInfo;
  public itemsSideBar: { hasPermissions ?: string[], title: string; hide?: boolean, items: {icon: string, title: string, ariaLabel: string, link: string|string[], disabled?: boolean, hasPermissions?: string[]}[]}[] = [];

  hide = true;
  token: string = null;

  constructor(
    private languageService: LanguageService,
    private profileService: ProfileService,
    private equipmentService: EquipmentService,
    private equipmentDetailsService: EquipmentDetailsService,
    private userService: UserService
  ) {
    this.crtLang = this.languageService.currentLanguage;
    this.itemsSideBar = [
      {
        title: 'website.sidebar.follow',
        items: [
          {
            title: 'website.sidebar.dashboard',
            icon: 'custom-icon-layout',
            link: ['/', this.crtLang, 'dashboard'],
            ariaLabel: 'sidebar-dashboard'
          },
          {
            title: 'website.sidebar.equipment',
            icon: 'custom-icon-command',
            link: ['/', this.crtLang, 'equipment'],
            ariaLabel: 'sidebar-equipment-list'
          }
        ]
      },
      {
        title: 'website.sidebar.management',
        items: [
          {
            title: 'website.sidebar.users',
            icon: 'custom-icon-users',
            link: ['/', this.crtLang, 'users'],
            ariaLabel: 'sidebar-users-list'
          },
          {
            title: 'website.sidebar.shops',
            icon: 'custom-icon-location',
            link: ['/', this.crtLang, 'customer-locations'],
            ariaLabel: 'sidebar-customer-locations'
          },  {
            title: 'website.sidebar.documents',
            icon: 'custom-icon-folder',
            link: ['/', this.crtLang, 'documents'],
            disabled: true,
            ariaLabel: 'sidebar-documents'
          }
        ]
      },

      {
        hide: true,
        title: 'website.sidebar.analysis',
        items: [
          {
            title: 'website.sidebar.energy',
            icon: 'custom-icon-energy',
            link: ['/', this.crtLang, 'energy'],
            disabled: true,
            ariaLabel: 'sidebar-energy'
          },
          {
            title: 'website.sidebar.reports',
            icon: 'custom-icon-raports',
            link: ['/', this.crtLang, 'rapports'],
            disabled: true,
            ariaLabel: 'sidebar-rapports'
          }
        ]
      },
      {
        hasPermissions: [Permission.MatelexAdminPermission],
        title: 'Administration',
        items: [
          {
            title: 'Gestion des devices',
            icon: 'custom-icon-tool',
            link: ['/', this.crtLang, 'admin', 'devices-details'],
            hasPermissions: [Permission.MatelexAdminPermission],
            ariaLabel: 'sidebar-device-details'
          },
        ]
      },


    ];
  }

  async ngOnInit(): Promise<void> {

    this.totalEquipmentsHavingAlarms = await this.equipmentService.countEquipmentsHavingAlarms(true);
    this.subscriptions.push(    this.userService.loginStatusUpdates.subscribe(async (loginStatusUpdate: LoginStatusUpdate) => {
      console.log('HeaderComponent ngOnInit this.userService.loginStatusUpdates.subscribe loginStatusUpdate', loginStatusUpdate);
      this.userInfo = this.profileService.userInfo;
      this.totalEquipmentsHavingAlarms = await this.equipmentService.countEquipmentsHavingAlarms(true);
    }));
    this.subscriptions.push(
      this.equipmentDetailsService.equipmentAlarmsChanged.subscribe(async (equipmentAlarmsChange: EquipmentAlarmsChange) => {
        if (equipmentAlarmsChange?.activeAlarmsCountChanged) {
          console.log(
            'SidebarComponent ngOnInit this.equipmentDetailsService.equipmentAlarmsChanged equipmentAlarmsChange',
            equipmentAlarmsChange
          );
          this.totalEquipmentsHavingAlarms = await this.equipmentService.countEquipmentsHavingAlarms(true);
        }
      })
    );

    this.userInfo = this.profileService.userInfo;
    this.userService.getMyToken().subscribe(
      res => {
        this.userInfo = this.profileService.userInfo;
        this.token = res;
      },
      err => console.log('SideBarComponent : Error in fetching token', err)
    );
  }

  closeSidebar(event: any) {
    const sidebarElement = document.querySelector('.sidebar');
    if (event.offsetX > event.target.offsetWidth) {
      sidebarElement.classList.remove('open');
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  public async reloadCounterAlarms(): Promise<void> {
    this.userInfo = this.profileService.userInfo;
    this.totalEquipmentsHavingAlarms = await this.equipmentService.countEquipmentsHavingAlarms(true);
  }
}
