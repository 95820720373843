<div class="dni-detail-user-modal-wrapper">
  <h1 mat-dialog-title>{{ 'website.equipment.detail.users.modal.title' | translate: { name: data.firstName + " " + data.lastName } }}</h1>
  <a class="modal-close" (click)="close()">
    <span class="material-icons">close</span>
  </a>
  <div mat-dialog-content>
    <div class="user-list-item">
      <span class="category">{{ 'website.equipment.detail.users.modal.fields.first_name' | translate }}</span>
      <span>{{ data.firstName }}</span>
    </div>
    <div class="user-list-item">
      <span class="category">{{ 'website.equipment.detail.users.modal.fields.last_name' | translate }}</span>
      <span>{{ data.lastName }}</span>
    </div>
    <div class="user-list-item">
      <span class="category">{{ 'website.equipment.detail.users.modal.fields.company' | translate }}</span>
      <span>{{ data.companyName }}</span>
    </div>
    <div class="user-list-item">
      <span class="category">{{ 'website.equipment.detail.users.modal.fields.role' | translate }}</span>
      <span>{{ data.roleName }}</span>
    </div>
    <!-- todo: add alarmType when is implemented in UserRole entity -->
    <!-- <div class="user-list-item">
      <span class="category">{{ 'website.equipment.detail.users.modal.fields.alarms' | translate }}</span>
      <span>Non</span>
    </div> -->
    <div class="user-list-item">
      <span class="category">{{ 'website.equipment.detail.users.modal.fields.email' | translate }}</span>
      <span>{{ data.email || '-' }}</span>
    </div>
    <div class="user-list-item">
      <span class="category">{{ 'website.equipment.detail.users.modal.fields.phone' | translate }}</span>
      <span>{{ data.phone || '-' }}</span>
    </div>
    <div class="user-list-item">
      <span class="category">{{ 'website.equipment.detail.users.modal.fields.last_connection' | translate }}</span>
      <span>{{ data.lastConnection | date:'medium' }}</span>
    </div>
  </div>
  <div mat-dialog-actions>
    <matelex-primary-button (click)="close()" [label]="'BUTTONS.CLOSE' | translate "></matelex-primary-button>
  </div>
</div>
