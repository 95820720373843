<p id="password-strength" class="mt-0" tabindex="0">{{ 'AUTH.VALIDATIONS.PASSWORD_SHOULD_CONTAIN' | translate }}</p>
<ul fxLayout="column" fxLayoutAlign="start start">
  <li
    [ngClass]="{ 'password-validator': !control.hasError('notLength') }"
    tabindex="0"
    aria-invalid="control.hasError('notLength')"
    aria-labelledby="password-strength"
  >
    {{ 'AUTH.VALIDATIONS.PASSWORD_NO_LENGTH' | translate }}
  </li>
  <li
    [ngClass]="{ 'password-validator': !control.hasError('notLower') }"
    tabindex="0"
    aria-invalid="control.hasError('notLower')"
    aria-labelledby="password-strength"
  >
    {{ 'AUTH.VALIDATIONS.PASSWORD_NO_LOWERCASE' | translate }}
  </li>
  <li
    [ngClass]="{ 'password-validator': !control.hasError('notUpper') }"
    tabindex="0"
    aria-invalid="control.hasError('notUpper')"
    aria-labelledby="password-strength"
  >
    {{ 'AUTH.VALIDATIONS.PASSWORD_NO_UPPERCASE' | translate }}
  </li>
  <li
    [ngClass]="{ 'password-validator': !control.hasError('notNumber') }"
    tabindex="0"
    aria-invalid="control.hasError('notNumber')"
    aria-labelledby="password-strength"
  >
    {{ 'AUTH.VALIDATIONS.PASSWORD_NO_NUMBER' | translate }}
  </li>
</ul>
<div class="mt-12 mb-20" *ngIf="control.value.length">
  <mat-progress-bar
    mode="determinate"
    [value]="strength"
    [matTooltip]="statusMessage"
    matTooltipPosition="below"
    matTooltipClass="mat-tooltip"
    [ngClass]="{
      'progress-bar-format': strength >= 0,
      quarter: strength < 50,
      half: strength > 25,
      'three-quarters': strength > 50,
      full: strength > 75
    }"
  ></mat-progress-bar>
</div>
